import React, { Fragment, useState, useEffect } from "react";
import seven from "../../assets/images/user/7.jpg";
import { Company, Username, UsersCountryMenu, AboutMe, UpdateProfile, UsersTableTitle, FirstName, LastName, Address, EmailAddress, PostalCode, Country, UsersTableHeader, City, Edit, Update, Delete, Profile, Notifications, Billing } from "../../constant";
import usereditdata from "../../api/user-edit-table.json";
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Tab, TabContent, TabPane, Tabs } from "react-bootstrap";
import { Nav, NavItem, NavLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Label } from 'reactstrap';
import { Link } from "react-router-dom";
import CKEditors from "react-ckeditor-component";
import { Input } from "reactstrap";
import Accountdeltail from "./tabcontentent/accountDetails";
import Preferences from "./tabcontentent/preferences";
import Companyinformation from "./tabcontentent/companyinformation";
import Notificationsuserprofile from "./tabcontentent/notifications";
import Integrations from "./tabcontentent/Integrations";
import Teams from "./tabcontentent/teams";
import Oureminology from "./tabcontentent/ourerminology";
import Meeting from "./tabcontentent/meeting";
import Billings from "./tabcontentent/billing";
import { FaPlus } from "react-icons/fa";
import ContactForms from "./tabcontentent/contactforms";
import { MdDelete } from "react-icons/md";
import ContactuseprofilForms from "./contact";
import Metrics from "./metrics";
import { FaCamera } from 'react-icons/fa';
import PersonalInformation from "./tabcontentent/personalInformation";
import Social from "./Userinformation/Social";
import { isSubscribedAccountant } from '../../utils';

const UserEdit = () => {
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState();
  const [VerticleTab, setVerticleTab] = useState('1');
  const [content, setContent] = useState('');

  const onChange = (evt) => {
    const newContent = evt.editor.getData();
    setContent(newContent)
  }

  useEffect(() => {
    setData(usereditdata)
  }, []);

  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    switch (path) {
      case '/users/billings':
        setVerticleTab('9');
        break;
      // Handle other paths if needed
      default:
        setVerticleTab('1');
        break;
    }
  }, [location.pathname]);

  const [formData, setFormData] = useState({
    forms: [{ id: Date.now(), name: '', relationship: '', phoneNumber: '', emergencyContact: '' }],
    firstname: '',
    lastName: '',
    pronouns: '',
    dateofbirth: '',
    title: '',
    street: '',
    city: '',
    region: '',
    pinCode: '',
    country: '',
    emergencyContact: '',
    biography: '',
    Company: '',
    Username: '',
    EmailAddress: '',
    PostalCode: '',
    Address: '',
    city: '',
    region: '',
    AboutMe: '',
    FirstName: '',
    LastName: '',
    linkedin: '',
  });

  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Perform validation for the first set of form fields
    const validationErrors = {};
    if (formData.firstname.trim() === "") {
      validationErrors.firstname = "This field is required";
    }

    if (formData.lastName.trim() === "") {
      validationErrors.lastName = "This field is required";
    }

    if (formData.dateofbirth.trim() === "") {
      validationErrors.dateofbirth = "This field is required";
    }

    if (formData.title.trim() === "") {
      validationErrors.title = "This field is required";
    }

    // Perform validation for the second set of form fields
    if (formData.street.trim() === "") {
      validationErrors.street = "This field is required";
    }

    if (formData.region.trim() === "") {
      validationErrors.region = "This field is required";
    }

    if (formData.city.trim() === "") {
      validationErrors.city = "This field is required";
    }

    if (formData.country.trim() === "") {
      validationErrors.country = "This field is required";
    }

    if (formData.pinCode.trim() === "") {
      validationErrors.pinCode = "Pin Code is required";
    } else if (!/^\d{6}$/.test(formData.pinCode.trim())) {
      validationErrors.pinCode = "Pin Code must be a 6-digit number";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    console.log(formData);
    setValidated(true);

    const allFormsValid = forms.every(isFormValid);
    if (allFormsValid) {
      console.log('Forms submitted:', forms);
    } else {
      console.log('Some forms have errors');
    }
  };

  const [forms, setForms] = useState([{ id: Date.now(), name: '', relationship: '', phoneNumber: '', emergencyContact: '' }]);

  const validateField = (id, name, value) => {
    let errorMsg = '';
    switch (name) {
      case 'name':
        if (!value.trim()) {
          errorMsg = 'Name field cannot be empty';
        }
        break;
      case 'relationship':
        if (!value.trim()) {
          errorMsg = 'Relationship field cannot be empty';
        }
        break;
      case 'phoneNumber':
        if (!value.trim()) {
          errorMsg = 'Phone number field cannot be empty';
        } else if (!/^\d{10}$/.test(value)) {
          errorMsg = 'Phone number must be 10 digits';
        }
        break;
      case 'emergencyContact':
        if (!value.trim()) {
          errorMsg = 'Contact field cannot be empty';
        } else if (value.length < 10) {
          errorMsg = 'Contact must be at least 10 characters long';
        }
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: { ...prevErrors[id], [name]: errorMsg }
    }));
  };
  const isFormValid = (form) => {
    return (
      form.name.trim() !== '' &&
      form.relationship.trim() !== '' &&
      form.phoneNumber.trim() !== '' &&
      /^\d{10}$/.test(form.phoneNumber) &&
      form.emergencyContact.trim() !== '' &&
      form.emergencyContact.length >= 10
    );
  };
  const [profileImage, setProfileImage] = useState(seven);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);


  const navigate = useNavigate()
  useEffect(() => {
    if (!isSubscribedAccountant()) {
      navigate("/users/billings");
      window.location.reload();
    }
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="edit-profile">
          <div className="row">

            <Col className="user-edit-sublink d-none d-md-flex" sm="2" xs="12">
            <Nav className=" nav-useredit flex-column w-100 nav-pills ">
                <div className="heading-useredit">
                  <h6 className="my-4">User Settings</h6>
                </div>
                <NavItem className="my-2 ">
                  <Link className={VerticleTab === '1' ? 'active' : ''} onClick={() => setVerticleTab('1')}>Profile</Link>
                </NavItem>
                <NavItem className="my-2 ">
                  <Link className={VerticleTab === '2' ? 'active' : ''} onClick={() => setVerticleTab('2')}>Security</Link>
                </NavItem>
                {/* <NavItem className="my-2 ">
                  <Link className={VerticleTab === '3' ? 'active' : ''} onClick={() => setVerticleTab('3')}>Preferences</Link>
                </NavItem> */}
                <NavItem className="my-2 ">
                  <Link className={VerticleTab === '4' ? 'active' : ''} onClick={() => setVerticleTab('4')}>Notifications</Link>
                </NavItem>
                <NavItem className="my-2 ">
                  <Link className={VerticleTab === '5' ? 'active' : ''} onClick={() => setVerticleTab('5')}>Integrations</Link>
                </NavItem>

                <div className="heading-useredit">
                  <h6 className="my-4">Company Settings</h6>
                </div>

                <NavItem className="my-2 ">
                  <Link className={VerticleTab === '6' ? 'active' : ''} onClick={() => setVerticleTab('6')}>Configurations</Link>
                </NavItem>

                <NavItem className="my-2 ">
                  <Link className={VerticleTab === '7' ? 'active' : ''} onClick={() => setVerticleTab('7')}>Team Members</Link>
                </NavItem>

                {/* <NavItem className="my-2 ">
                  <Link className={VerticleTab === '8' ? 'active' : ''} onClick={() => setVerticleTab('8')}>Our Terminology</Link>
                </NavItem> */}

                <NavItem className="my-2 ">
                  <Link className={VerticleTab === '9' ? 'active' : ''} onClick={() => setVerticleTab('9')}>Meeting Agendas</Link>
                </NavItem>

                <NavItem className="my-2 ">
                  <Link className={VerticleTab === '10' ? 'active' : ''} onClick={() => setVerticleTab('10')}>Billing</Link>
                </NavItem>

              </Nav>
            </Col>

            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="my-3 d-md-none">
              <DropdownToggle>
                <FaPlus />
              </DropdownToggle>
              <DropdownMenu className="user-edit-sublink ">
              <DropdownItem>
                  <Nav className="nav nav-useredit flex-column w-100 nav-pills ">
                    <NavItem className="my-2 ">
                      <Link className={VerticleTab === '1' ? 'active' : ''} onClick={() => setVerticleTab('1')}>Profile</Link>
                    </NavItem>
                    <NavItem className="my-2 ">
                      <Link className={VerticleTab === '2' ? 'active' : ''} onClick={() => setVerticleTab('2')}>Account</Link>
                    </NavItem>
                    <NavItem className="my-2 ">
                      <Link className={VerticleTab === '3' ? 'active' : ''} onClick={() => setVerticleTab('3')}>Preferences</Link>
                    </NavItem>
                    <NavItem className="my-2 ">
                      <Link className={VerticleTab === '4' ? 'active' : ''} onClick={() => setVerticleTab('4')}>Notifications</Link>
                    </NavItem>
                    <NavItem className="my-2 ">
                      <Link className={VerticleTab === '5' ? 'active' : ''} onClick={() => setVerticleTab('5')}>Integrations</Link>
                    </NavItem>
                    <NavItem className="my-2 ">
                      <Link className={VerticleTab === '6' ? 'active' : ''} onClick={() => setVerticleTab('6')}>Configurations</Link>
                    </NavItem>
                    <NavItem className="my-2 ">
                      <Link className={VerticleTab === '7' ? 'active' : ''} onClick={() => setVerticleTab('7')}>Team</Link>
                    </NavItem>
                    <NavItem className="my-2 ">
                      <Link className={VerticleTab === '8' ? 'active' : ''} onClick={() => setVerticleTab('8')}>Our Terminology</Link>
                    </NavItem>
                    <NavItem className="my-2 ">
                      <Link className={VerticleTab === '9' ? 'active' : ''} onClick={() => setVerticleTab('9')}>Meeting</Link>
                    </NavItem>
                    <NavItem className="my-2 ">
                      <Link className={VerticleTab === '10' ? 'active' : ''} onClick={() => setVerticleTab('10')}>Billing</Link>
                    </NavItem>
                  </Nav>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>

            <Col sm="10" xs="12">
              <TabContent >
                <TabPane tabId="1" active={VerticleTab === '1'}>
                  <div className="">
                    <Tabs
                      defaultActiveKey="Personal"
                      id="responsive-tab-example"
                      className="border-tab nav-primary col-md-12 col-sm-6 pt-3 mb-3"
                    >
                      <Tab eventKey="Personal" title="Personal">
                        <>
                          <PersonalInformation />
                        </>
                      </Tab>
                      <Tab eventKey="Personality Test Results" title="Personality Test Results">
                        <Metrics />
                      </Tab>
                      <Tab eventKey="Contact" title="Contact">
                        <ContactuseprofilForms />
                      </Tab>
                      <Tab eventKey="Social" title="Social">
                        <Social />
                      </Tab>
                    </Tabs>
                  </div>
                </TabPane>

                <TabPane tabId="2" active={VerticleTab === '2'}>
                  <Accountdeltail />
                </TabPane>
                <TabPane tabId="3" active={VerticleTab === '3'}>
                  <Preferences />
                </TabPane>
                <TabPane tabId="4" active={VerticleTab === '4'}>
                  <Notificationsuserprofile />
                </TabPane>
                <TabPane tabId="5" active={VerticleTab === '5'}>
                  <Integrations />
                </TabPane>
                <TabPane tabId="6" active={VerticleTab === '6'}>
                  <Companyinformation />
                </TabPane>
                <TabPane tabId="7" active={VerticleTab === '7'}>
                  <Teams />
                </TabPane>
                <TabPane tabId="8" active={VerticleTab === '8'}>
                  <Oureminology />
                </TabPane>
                <TabPane tabId="9" active={VerticleTab === '9'}>
                  <Meeting />
                </TabPane>
                <TabPane tabId="10" active={VerticleTab === '10'}>
                  <Billings />
                </TabPane>
              </TabContent>
            </Col >
          </div >
        </div >
      </div >
    </>
  );
};

export default UserEdit;
