import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import { MdDelete } from 'react-icons/md';
import DataTable from 'react-data-table-component';
import { FaEye, FaRegEdit } from "react-icons/fa";
import DatePicker from "react-datepicker";
import API from "../../../utils";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import debounce from 'lodash/debounce';


const Team = () => {
  const uid = localStorage.getItem("user_id");
  const [validated, setValidated] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [formData, setFormData] = useState({
    Member: "",
    Strength: "",
    Weakness: "",
  });
  
  const [validationErrors, setValidationErrors] = useState({});

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    setValidationErrors({});
  };


  const handleCancel = () => {
    // Reset form data
    setFormData({

      Member: "",
      Strength: "",
      Weakness: "",

    });
    setValidationErrors({});
    setIsPopupOpen(false);
  };


  const fetchData = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('user_id', uid);

      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.team_effectiveness_get}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        // setAffirmationEntries(response.data.data);
        const sortedTasks = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setAffirmationEntries(sortedTasks);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Fetch data error', error);
    }
  };


  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    formDataToSend.append('strength', formData.Strength);
    formDataToSend.append('weakness', formData.Weakness);
    formDataToSend.append('member', formData.Member);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.team_effectiveness_insert}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        // toast.success(response.data.message);
        toast.success("Team Effectiveness Exercise Added Successfully");
        handleCancel();
        setFormData({
          Member: "",
          Strength: "",
          Weakness: "",
        });
        fetchData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Profile update failed.");
    }
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const [AffirmationEntries, setAffirmationEntries] = useState([]);
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredEntries = AffirmationEntries.filter(entry =>
    (entry.strength || "").toLowerCase().includes((searchQuery || "").toLowerCase()) ||
    (entry.weakness || "").toLowerCase().includes((searchQuery || "").toLowerCase()) ||
    (entry.member || "").toLowerCase().includes((searchQuery || "").toLowerCase())

  );

  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const handleView = (item) => {
    setSelectedItem(item); // Set the selected item
    toggleViewModal(); // Open the view modal
  };
  const toggleViewModal = () => {
    setIsViewModalOpen(!isViewModalOpen); // Toggle view modal state
  };
  const thStyle = {
    border: "1px solid gray",
    padding: "8px",
    textAlign: "left",
    background: "black",
    color: "white"
  };


  const [updatedTask, setUpdatedTask] = useState(null);

  const debouncedUpdateTask = useCallback(
    debounce(async (task) => {
      const formDataToSend = new FormData();
      formDataToSend.append('id', task.goalid);
      formDataToSend.append('filed', task.field);
      formDataToSend.append('text', task.value);


      try {
        const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.team_effectiveness_Update}`,
          formDataToSend,
          {
            headers: {
              Authorization: `${API.AUTHORIZATION_TOKEN}`,
            },
          }
        );

        if (response.data.status === "success") {
          toast.success("Team Effectiveness Exercise Updating Successfully");
          fetchData();
          // toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("An error occurred while updating data.");
      }
    }, 1000),
    []
  );

  const handleEditInputChange = (e, index, field, goalid) => {
    const { value } = e.target;
    const updatedOutcomes = [...AffirmationEntries];
    updatedOutcomes[index][field] = value;
    setAffirmationEntries(updatedOutcomes);

    setUpdatedTask({ field, value, goalid });
  };

  useEffect(() => {
    if (updatedTask) {
      debouncedUpdateTask(updatedTask);
    }
  }, [updatedTask, debouncedUpdateTask]);
  const columns = [

    {
      name: 'Team Member',
      selector: (row, index) => (
        <div className="businessroles-table">
        <input
          type="text"
          value={row.member}
          className="form-control"
          onChange={(e) => handleEditInputChange(e, index, "member", row.id)}
        />
        </div>
      ),
      //cell: (row) => row.Member,
      sortable: true,
    },
    {
      name: 'Strength',
      selector: (row, index) => (
        <div className="businessroles-table">

        <input
          type="text"
          value={row.strength}
          className="form-control"
          onChange={(e) => handleEditInputChange(e, index, "strength", row.id)}
        />
        </div>
      ),
      //cell: (row) => row.Strength,
      sortable: true,
    },
    {
      name: 'Weakness',
      selector: (row, index) => (
        <div className="businessroles-table">
        <input
          type="text"
          value={row.weakness}
          className="form-control"
          onChange={(e) => handleEditInputChange(e, index, "weakness", row.id)}
        />
        </div>
      ),
      //  cell: (row) => row.Weakness,
      sortable: true,
    },

  ];

  const isSaveButtonEnabled = () =>
    formData.Strength.trim() !== ''



  return (
    <div className="container-fluid">

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className=" align-items-center justify-content-between d-flex">
                  <h5>Team Effectiveness Exercise</h5>
                  {/* <Button className="btn-success main-buttons" onClick={togglePopup}>Add Team Effectiveness Exercise</Button> */}
                </div>
              </div>
            </div>

            <div className="card-body">
              <form className="theme-form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-group mb-3 col-lg-6">
                    <label><b>Strength Single Most Important Behavioral Quality Contributing to the Team</b></label>
                    <textarea
                      className="form-control"
                      type="text"
                      name="Strength"
                      placeholder="Enter Strength Single Most Important Behavioral Quality Contributing to the Team."
                      value={formData.Strength}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="form-group mb-3 col-lg-6">
                    <label><b>Weakness Single Most Important Behavioral Quality Detracting from the Team</b></label>
                    <textarea
                      className="form-control"
                      type="text"
                      name="Weakness"
                      placeholder="Enter Weakness Single Most Important Behavioral Quality Detracting from the Team"
                      value={formData.Weakness}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="form-group mb-3 col-lg-6">
                    <label><b>Team Member</b></label>
                    <textarea
                      className="form-control"
                      type="text"
                      name="Member"
                      placeholder="Enter Team Member."
                      value={formData.Member}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="d-flex card-footer justify-content-end">
                  <Button
                    className="btn btn-success"
                    type="submit"
                    disabled={!isSaveButtonEnabled()}
                  >
                    Save
                  </Button>
                </div>
              </form>
              <div className="row">
                <div className="col-md-9"></div>
                <div className="col-md-3">
                  <div className="form-group text-end mb-3 ">
                    <input className="form-control" type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchChange} />
                  </div>
                </div>
              </div>
              <DataTable
                columns={columns}
                data={filteredEntries}
                persistTableHead={true}
                pagination
                className="rolmodel-table"

              />
            </div>
          </div>
        </div>
      </div>


    </div>
  );
};

export default Team;
