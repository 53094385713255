import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import API, { isSubscribedAccountant } from "../../../utils";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import moment from "moment";

const PowerHourPlanner = () => {
    const uid = localStorage.getItem('user_id');
    const defaultItems = ["", "", ""];
    const [todayItems, setTodayItems] = useState(defaultItems);
    const [this_weekItems, setthis_weekItems] = useState(defaultItems);
    const [this_monthItems, setthis_monthItems] = useState(defaultItems);
    const [this_quarterItems, setthis_quarterItems] = useState(defaultItems);
    const [this_yearItems, setthis_yearItems] = useState(defaultItems);

    const navigate = useNavigate();

    useEffect(() => {
        if (!isSubscribedAccountant()) {
            navigate("/users/billings");
            window.location.reload();
        }
    }, [navigate]);

    const fetchEvents = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_task_calendar_event}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.status === "success") {
                const eventData = response.data.data[0];

                // Use fetched data or fall back to defaults, ensuring three fields are always available
                setTodayItems(eventData.today.concat(defaultItems).slice(0, 3));
                setthis_weekItems(eventData.this_week.concat(defaultItems).slice(0, 3));
                setthis_monthItems(eventData.this_month.concat(defaultItems).slice(0, 3));
                setthis_quarterItems(eventData.this_quarter.concat(defaultItems).slice(0, 3));
                setthis_yearItems(eventData.this_year.concat(defaultItems).slice(0, 3));
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error fetching events:", error);
        }
    };

    useEffect(() => {
        fetchEvents();
    }, [uid]);

    const handleInputChange = (e, index, section) => {
        const { value } = e.target;
        switch (section) {
            case 'today':
                setTodayItems(todayItems.map((item, i) => i === index ? value : item));
                break;
            case 'this_week':
                setthis_weekItems(this_weekItems.map((item, i) => i === index ? value : item));
                break;
            case 'this_month':
                setthis_monthItems(this_monthItems.map((item, i) => i === index ? value : item));
                break;
            case 'this_quarter':
                setthis_quarterItems(this_quarterItems.map((item, i) => i === index ? value : item));
                break;
            case 'this_year':
                setthis_yearItems(this_yearItems.map((item, i) => i === index ? value : item));
                break;
            default:
                break;
        }
    };

    const appendItemsToFormData = (formData, items, prefix) => {
        items.forEach((item, index) => {
            if (item.trim() !== '') {
                formData.append(`${prefix}[${index}]`, item);
            }
        });
    };

    const handleInputBlur = async () => {
        const uid = localStorage.getItem('user_id');
    
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
    
        appendItemsToFormData(formDataToSend, todayItems, 'today');
        appendItemsToFormData(formDataToSend, this_weekItems, 'this_week');
        appendItemsToFormData(formDataToSend, this_monthItems, 'this_month');
        appendItemsToFormData(formDataToSend, this_quarterItems, 'this_quarter');
        appendItemsToFormData(formDataToSend, this_yearItems, 'this_year');
    
        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_updated_task_calender_event}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            if (response.data.status === "success") {
                toast.success("Data saved successfully.");
                fetchEvents();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error saving data:", error);
            toast.error("Failed to save data.");
        }
    };
    
    const renderInputs = (items, section) => (
        items.map((item, index) => (
            <Form.Group key={index} controlId={`form${section}Item${index}`}>
                <Form.Control className="my-2"
                    type="text"
                    value={item}
                    placeholder={`Item ${index + 1}`}
                    onChange={(e) => handleInputChange(e, index, section)}
                    onBlur={handleInputBlur} // Call the API on blur
                />
            </Form.Group>
        ))
    );

    return (
        <div className="container-fluid">
            <div className="mt-4 mb-4 d-flex justify-content-end"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h5>Power Hour Planner</h5>
                        </div>
                        <div className="card-body">
                            <div className="textnots-container-persomality mb-3">
                                <strong>Power Hour Planner:</strong><br />
                                <strong>Step 1 </strong>
                                <p className="textnots-text">During your Power Hour, you’ll focus exclusively on the three highest priority activities of the day, this_week, this_quarter and this_year. Write down those priorities so you know exactly what to focus on.</p>
                                <strong>Step 2</strong>
                                <p className="textnots-text">   Set a recurring calendar appointment each day of the this_week whenever it is best for you. This is your Power Hour, your appointment with yourself. Like all appointments, Power Hour has a precise start and a precise end.</p>
                                <strong>Step 3</strong>
                                <p className="textnots-text"> Post your Power Hour Planner on the wall to hold yourself accountable to it. In Power Hour, no distractions are allowed.</p>
                            </div>

                            <div className="power-hour-sections">
                                <h6>Today</h6>
                                {renderInputs(todayItems, 'today')}
                                <h6>This Week</h6>
                                {renderInputs(this_weekItems, 'this_week')}
                                <h6>This Month</h6>
                                {renderInputs(this_monthItems, 'this_month')}
                                <h6>This Quarter</h6>
                                {renderInputs(this_quarterItems, 'this_quarter')}
                                <h6>This Year</h6>
                                {renderInputs(this_yearItems, 'this_year')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PowerHourPlanner;
