import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Button, FormGroup, Label, Input, ModalFooter } from 'reactstrap';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from '../../../utils';
import axios from 'axios';

const CreateNewCompanyModal = ({ isOpen, toggle }) => {
    const [formData, setFormData] = useState({
        companyName: "",
        companyLogo: null,
        companyNumber: "",
        industry: "",
        employees: ""
    });
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const user_id = localStorage.getItem('user_id');

    const handleFileChange = (e) => {
        setFormData({ ...formData, companyLogo: e.target.files[0] });
        if (submitted) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                companyLogo: ""
            }));
        }
    
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        // Clear error for the specific field when user starts typing
        if (submitted) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: ""
            }));
        }
    };

    const handleToggle = () => {
        setErrors({});
        setSubmitted(false);
        toggle();
    };

    const handleCompanySubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true); // Set submitted state to true

        const validationErrors = {};

        if (formData.companyName.trim() === "") {
            validationErrors.companyName = "This Field Is Required";
        }
        if (formData.industry.trim() === "") {
            validationErrors.industry = "This Field Is Required";
        }
        if (formData.employees.trim() === "") {
            validationErrors.employees = "This Field Is Required";
        }
        if (!formData.companyLogo) {
            validationErrors.companyLogo = "This Field Is Required";
        }
        if (formData.companyNumber.trim() === "") {
            validationErrors.companyNumber = "This Field Is Required";
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        const form = new FormData();
        form.append('company_name', formData.companyName);
        form.append('image', formData.companyLogo);
        form.append('number', formData.companyNumber);
        form.append('industry', formData.industry);
        form.append('employees', formData.employees);
        form.append('user_id', user_id);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.add_new_company}`,
                form,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                toggle();
                setFormData({
                    companyName: "",
                    companyLogo: null,
                    companyNumber: "",
                    industry: "",
                    employees: ""
                });
                // window.location.reload();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            toast.error('An error occurred while submitting the form.');
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={handleToggle}>
            <ModalHeader toggle={handleToggle}>Create New Company</ModalHeader>
            <form onSubmit={handleCompanySubmit}>
                <ModalBody>
                    <FormGroup>
                        <Label for="companyName">Company Name</Label>
                        <Input
                            type="text"
                            name="companyName"
                            id="companyName"
                            value={formData.companyName}
                            placeholder="Enter a Company name"
                            onChange={handleChange}
                            className={`form-control ${submitted && errors.companyName ? 'is-invalid' : ''}`}
                        />
                        {submitted && errors.companyName && <div className="text-danger">{errors.companyName}</div>}
                    </FormGroup>

                    <FormGroup>
                        <Label for="companyLogo">Company Logo</Label>
                        <Input
                            type="file"
                            name="companyLogo"
                            id="companyLogo"
                            onChange={handleFileChange}
                            className={`form-control ${submitted && errors.companyLogo ? 'is-invalid' : ''}`}
                        />
                        {submitted && errors.companyLogo && <div className="text-danger">{errors.companyLogo}</div>}
                    </FormGroup>

                    <FormGroup>
                        <Label for="companyNumber">Company Number</Label>
                        <Input
                            type="text" 
                            name="companyNumber"
                            id="companyNumber"
                            value={formData.companyNumber}
                            placeholder="Enter a Company Number"
                            onChange={handleChange}
                            className={`form-control ${submitted && errors.companyNumber ? 'is-invalid' : ''}`}
                        />
                        {submitted && errors.companyNumber && <div className="text-danger">{errors.companyNumber}</div>}
                    </FormGroup>

                    <FormGroup>
                        <Label for="industry">Industry</Label>
                        <Input
                            type="select"
                            name="industry"
                            id="industry"
                            value={formData.industry}
                            onChange={handleChange}
                            className={`form-control ${submitted && errors.industry ? 'is-invalid' : ''}`}
                        >
                            <option value="">Choose Option</option>
                            <option value="professionalService">Professional Service</option>
                            <option value="constructionEngineering">Construction & Engineering</option>
                            <option value="capitalMarkets">Capital Markets</option>
                            <option value="diversiiedFinancialServices">Diversified Financial Services</option>
                            <option value="media">Media</option>
                            <option value="educationServices">Education Services</option>
                        </Input>
                        {submitted && errors.industry && <div className="text-danger">{errors.industry}</div>}
                    </FormGroup>

                    <FormGroup>
                        <Label for="employees">Employees</Label>
                        <Input
                            type="select"
                            name="employees"
                            id="employees"
                            value={formData.employees}
                            onChange={handleChange}
                            className={`form-control ${submitted && errors.employees ? 'is-invalid' : ''}`}
                        >
                            <option value="">Choose Option</option>
                            <option value="1-10">1-10</option>
                            <option value="11-50">11-50</option>
                            <option value="51-250">51-250</option>
                            <option value="250-1k">250-1k</option>
                            <option value="1k+">1k+</option>
                        </Input>
                        {submitted && errors.employees && <div className="text-danger">{errors.employees}</div>}
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={handleToggle}>Cancel</Button>
                    <Button color="success" type="submit">Save</Button>
                </ModalFooter>
            </form>
        </Modal>
    );
};

export default CreateNewCompanyModal;
