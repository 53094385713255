import axios from "axios";
import React, { useEffect, useState, useRef, useCallback } from "react";
import Button from 'react-bootstrap/Button';
import DataTable from 'react-data-table-component';
import API from "../../../utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import debounce from 'lodash/debounce';
import { FaFileUpload } from "react-icons/fa";

const SopsFinance = () => {
  const uid = localStorage.getItem("user_id");
  const [formData, setFormData] = useState({
    procedure_description: "",
    owner: "",
    update_frequency: "",
    status: "",
    last_updated: "",
    file: null,
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [sop, setSop] = useState([]);

  const fileInputRef = useRef(null);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredEntries = sop.filter(entry =>
    Object.values(entry).some(value =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );


  const [updatedTask, setUpdatedTask] = useState(null); // Define updatedTask

  const debouncedUpdateTask = useCallback(
    debounce(async (task) => {
      const formDataToSend = new FormData();
      formDataToSend.append('sop_id', task.id);
      // formDataToSend.append('text', task.value);
      // formDataToSend.append('file', task.field);
      if (task.field === 'file') {
        formDataToSend.append('field', task.field);
        formDataToSend.append('file', task.value);
      } else {
        formDataToSend.append('text', task.value);
        formDataToSend.append('field', task.field);
      }

      try {
        const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.updated_sops}`,
          formDataToSend,
          {
            headers: {
              Authorization: `${API.AUTHORIZATION_TOKEN}`,
            },
          }
        );

        console.log("Response received:", response.data);

        if (response.data.status === "success") {
          fetchTasks();
          toast.success(response.data.message);

        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("An error occurred while updating data.");
      }
    }, 1000),
    []
  );

  const handleEditInputChange = (e, index, field, id) => {
    const updatedEntries = [...sop];

    if (field === "file") {
      updatedEntries[index][field] = e.target.files[0];
      setUpdatedTask({ field, value: e.target.files[0], id }); // Set the file directly
    } else {
      const { value } = e.target;
      updatedEntries[index][field] = value;
      setUpdatedTask({ field, value, id }); // Set the text value
    }
  };

  useEffect(() => {
    if (updatedTask) {
      debouncedUpdateTask(updatedTask);
    }
  }, [updatedTask, debouncedUpdateTask]);


  const columns = [
    {
      name: 'Standard Operating Procedure Name',
      selector: row => row.procedure_description,
      cell: (row, index) => (
        <div className="sops-table">
          <input
            type="text"
            name="procedure_description"
            value={row.procedure_description}
            className="form-control"
            onChange={(e) => handleEditInputChange(e, index, "procedure_description", row.id)}
          />
        </div>
      ),
      sortable: true,
    },
    {
      name: 'Owner',
      selector: row => row.owner,
      cell: (row, index) => (
        <div className="sops-table">
          <input
            type="text"
            name="owner"
            value={row.owner}
            className="form-control"
            onChange={(e) => handleEditInputChange(e, index, "owner", row.id)}
          />
        </div>
      ),
      sortable: true,
    },
    {
      name: 'SOP Update Frequency',
      selector: row => row.update_frequency,
      cell: (row, index) => (
        <div className="sops-table">
          <select
            className="form-control"
            name="update_frequency"
            value={row.update_frequency}
            onChange={(e) => handleEditInputChange(e, index, "update_frequency", row.id)}
          >
            <option value="">Select SOP Update Frequency</option>
            <option value="Monthly">Monthly</option>
            <option value="Quarterly">Quarterly</option>
            <option value="Annually">Annually</option>
          </select>
        </div>
      ),
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => row.status,
      cell: (row, index) => (
        <div className="sops-table">

          <select
            className="form-control"
            name="status"
            value={row.status}
            onChange={(e) => handleEditInputChange(e, index, "status", row.id)}
          >
            <option value="">Select Status</option>
            <option value="Not-Yet-Documented">Not Yet Documented</option>
            <option value="Assigned">Assigned</option>
            <option value="Documented-Pending-Approval">Documented & Pending Approval</option>
            <option value="Documented-Approved">Documented & Approved</option>
            <option value="Not-Applicable">Not Applicable</option>
          </select>
        </div>
      ),
      sortable: true,
    },
    {
      name: 'Last Updated',
      selector: row => row.last_updated,
      cell: (row, index) => (
        <div className="sops-table">
          <input
            type="date"
            name="last_updated"
            value={row.last_updated}
            className="form-control"
            onChange={(e) => handleEditInputChange(e, index, "last_updated", row.id)}
          />
        </div>
      ),
      sortable: true,
    },
    {
      name: 'Document/File',
      cell: row => {
        const fileUrl = row.file;
        console.log("File URL:", fileUrl);

        if (typeof fileUrl === 'string' && fileUrl.trim() !== "") {
          return (
            <a href={fileUrl} target="_blank" rel="noopener noreferrer">View Document</a>
          );
        }
        return 'No File';
      },
      sortable: false,
    },
    {
      name: 'Change File',
      selector: row => row.file,
      cell: (row, index) => (
        
        <div
          style={{ margin: 'auto', cursor: 'pointer', display: 'flex', alignItems: 'center' }}
          onClick={() => document.getElementById(`fileInput-${index}`).click()}
        >
          <input
            type="file"
            id={`fileInput-${index}`} // Unique ID for each input
            name="file"
            className="form-control"
            
            onChange={(e) => handleEditInputChange(e, index, "file", row.id)}
          />
        </div>
      ),
    },
  ];


  const handleSubmit = async () => {

    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    formDataToSend.append('category_id', 2);
    formDataToSend.append('procedure_description', formData.procedure_description);
    formDataToSend.append('owner', formData.owner);
    formDataToSend.append('update_frequency', formData.update_frequency);
    formDataToSend.append('status', formData.status);
    formDataToSend.append('last_updated', formData.last_updated);
    formDataToSend.append('file', formData.file);

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_sops}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        setFormData({
          procedure_description: "",
          owner: "",
          update_frequency: "",
          status: "",
          last_updated: "",
          file: null,
        });
        // Reset the file input field
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        fetchTasks();

      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error during task submission:", error);
      toast.error("An error occurred while submitting the task.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'file') {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }

    const errorsCopy = { ...validationErrors };
    switch (name) {
      case "owner":
        errorsCopy.owner = value.trim() ? "" : "This field is required";
        break;
      case "procedure_description":
        errorsCopy.procedure_description = value.trim() ? "" : "This field is required";
        break;
      case "update_frequency":
        errorsCopy.update_frequency = value.trim() ? "" : "This field is required";
        break;
      case "status":
        errorsCopy.status = value.trim() ? "" : "This field is required";
        break;
      case "last_updated":
        errorsCopy.last_updated = value.trim() ? "" : "This field is required";
        break;
      case "file":
        errorsCopy.file = files.length > 0 ? "" : "This field is required";
        break;
      default:
        break;
    }
    setValidationErrors(errorsCopy);
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    formDataToSend.append('category_id', 2);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_all_sops}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === 'success') {
        // setSop(response.data.data);
        const sortedTasks = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setSop(sortedTasks);
        
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Error during task fetching:', error);
    }
  };

  const isSaveButtonEnabled = () => formData.owner.trim() !== '';

  return (
    <>
      <div>
        <div className="row">
          <div className="col-sm-12 ">
            <div className="card mt-4">
              <div className="card-header ">
                <div className="row">
                  <div className="align-items-center justify-content-between d-flex">
                    <h5>Finance</h5>
                  </div>
                </div>
              </div>
              <form className="m-3">
                <div className="row">
                  <div className="form-group mb-3 col-lg-4 col-md-6">
                    <label><b>Standard Operating Procedure Name</b></label>
                    <input
                      className={`form-control ${validationErrors.procedure_description ? 'is-invalid' : ''}`}
                      placeholder="Enter Standard Operating Procedure Name"
                      type="text"
                      value={formData.procedure_description}
                      name="procedure_description"
                      onChange={handleInputChange}
                    />
                    {validationErrors.procedure_description && <div className="text-danger">{validationErrors.procedure_description}</div>}
                  </div>
                  <div className="form-group mb-3 col-lg-4 col-md-6">
                    <label><b>SOP Owner</b></label>
                    <input
                      className={`form-control ${validationErrors.owner ? 'is-invalid' : ''}`}
                      placeholder="Enter Owner"
                      type="text"
                      value={formData.owner}
                      name="owner"
                      onChange={handleInputChange}
                    />
                    {validationErrors.owner && <div className="text-danger">{validationErrors.owner}</div>}
                  </div>
                  <div className="form-group mb-3 col-lg-4 col-md-6">
                    <label><b>SOP Update Frequency</b></label>
                    <select
                      className={`form-control ${validationErrors.update_frequency ? 'is-invalid' : ''}`}
                      value={formData.update_frequency}
                      name="update_frequency"
                      onChange={handleInputChange}
                    >
                      <option value="">Select SOP Update Frequency</option>
                      <option value="Monthly">Monthly</option>
                      <option value="Quarterly">Quarterly</option>
                      <option value="Annually">Annually</option>
                    </select>
                    {validationErrors.update_frequency && <div className="text-danger">{validationErrors.update_frequency}</div>}
                  </div>
                  <div className="form-group mb-3 col-lg-4 col-md-6">
                    <label><b>Status</b></label>
                    <select
                      className={`form-control ${validationErrors.status ? 'is-invalid' : ''}`}
                      value={formData.status}
                      name="status"
                      onChange={handleInputChange}
                    >
                      <option value="">Select Status</option>
                      <option value="Not-Yet-Documented">Not Yet Documented</option>
                      <option value="Assigned">Assigned</option>
                      <option value="Documented-Pending-Approval">Documented & Pending Approval</option>
                      <option value="Documented-Approved">Documented & Approved</option>
                      <option value="Not-Applicable">Not Applicable</option>
                    </select>
                    {validationErrors.status && <div className="text-danger">{validationErrors.status}</div>}
                  </div>
                  <div className="form-group mb-3 col-lg-4 col-md-6">
                    <label><b>Last Updated</b></label>
                    <input
                      className={`form-control ${validationErrors.last_updated ? 'is-invalid' : ''}`}
                      type="date"
                      name="last_updated"
                      value={formData.last_updated}
                      onChange={handleInputChange}
                    />
                    {validationErrors.last_updated && <div className="text-danger">{validationErrors.last_updated}</div>}
                  </div>
                  <div className="form-group mb-3 col-lg-4 col-md-6">
                    <label><b>File</b></label>
                    <input
                      className={`form-control ${validationErrors.file ? 'is-invalid' : ''}`}
                      type="file"
                      name="file"
                      ref={fileInputRef}
                      onChange={handleInputChange}
                    />
                    {validationErrors.file && <div className="text-danger">{validationErrors.file}</div>}
                  </div>
                  <div className="d-flex justify-content-end align-content-center mb-2">
                    <Button variant="success"
                      disabled={!isSaveButtonEnabled()}
                      onClick={handleSubmit}
                    >Document SOP</Button>
                  </div>
                </div>
              </form>
              <div className="col-md-12">
                <div className="card-body ">
                  <div className="row">
                    <div className="col-md-9"></div>
                    <div className="col-md-3">
                      <div className="form-group text-end mb-3">
                        <input className="form-control" type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchChange} />
                      </div>
                    </div>
                  </div>
                  <DataTable
                    columns={columns}
                    data={filteredEntries}
                    pagination
                    persistTableHead={true}
                    className="rolmodel-table"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SopsFinance;
