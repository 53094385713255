import React, { useState } from "react";
import { Container } from "react-bootstrap";

const OnePagePersonalPlan = () => {
  const [tableData, setTableData] = useState({
    faith: {
      relationships: "",
      achievements: "",
      rituals: "",
      wealth: "",
    },
    family: {
      relationships: "",
      achievements: "",
      rituals: "",
      wealth: "",
    },
    friends: {
      start: "",
      stop: "",
    },
    finance: {
      start: "",
      stop: "",
    },
  });

  const handleChange = (e, category, field) => {
    const { value } = e.target;
    setTableData((prevData) => ({
      ...prevData,
      [category]: {
        ...prevData[category],
        [field]: value,
      },
    }));
  };

  const tableStyle = {
    border: "1px solid gray",
    borderCollapse: "collapse",
    width: "100%",
  };

  const thStyle = {
    border: "1px solid gray",
    padding: "8px",
    textAlign: "left",
    background: "#161b40",
    color: "white",
  };

  const tdStyle = {
    border: "1px solid gray",
    padding: "8px",
    textAlign: "left",
    height: "100px",
  };

  return (
    <Container fluid={true}>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header p-2">
              <div className="row">
                <div className="col-md-6 align-items-center d-flex">
                  <h5>One-Page Personal Plan (OPPP)</h5>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="mt-4 life-planing-div">
                <div className="table-responsive">
                  <table
                    className="life-planing-table table table-bordered"
                    style={tableStyle}
                  >
                    <thead>
                      <tr>
                        <th style={thStyle} colSpan="2"></th>
                        <th style={thStyle}>Relationships</th>
                        <th style={thStyle}>Achievements</th>
                        <th style={thStyle}>Rituals</th>
                        <th style={thStyle}>Wealth ($)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={tdStyle} className="vertical-text">Faith</td>
                        <td style={tdStyle} className="vertical-text">10–25 Years (Aspirations)</td>
                        <td style={tdStyle}>
                          <textarea
                          rows={10}
                            value={tableData.faith.relationships}
                            onChange={(e) => handleChange(e, "faith", "relationships")}
                            style={{ width: "100%", height: "100%" ,border:"none" }}
                          />
                        </td>
                        <td style={tdStyle}>
                          <textarea
                           rows={10}
                            value={tableData.faith.achievements}
                            onChange={(e) => handleChange(e, "faith", "achievements")}
                            style={{ width: "100%", height: "100%" ,border:"none" }}
                          />
                        </td>
                        <td style={tdStyle}>
                          <textarea
                            value={tableData.faith.rituals}
                            onChange={(e) => handleChange(e, "faith", "rituals")}
                            style={{ width: "100%", height: "100%" ,border:"none" }}
                          />
                        </td>
                        <td style={tdStyle}>
                          <textarea
                           rows={10}
                            value={tableData.faith.wealth}
                            onChange={(e) => handleChange(e, "faith", "wealth")}
                            style={{ width: "100%", height: "100%" ,border:"none"  }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={tdStyle} className="vertical-text">Family</td>
                        <td style={tdStyle} className="vertical-text">1 Year (Activities)</td>
                        <td style={tdStyle}>
                          <textarea
                           rows={10}
                            value={tableData.family.relationships}
                            onChange={(e) => handleChange(e, "family", "relationships")}
                            style={{ width: "100%", height: "100%" ,border:"none" }}
                          />
                        </td>
                        <td style={tdStyle}>
                          <textarea
                           rows={10}
                            value={tableData.family.achievements}
                            onChange={(e) => handleChange(e, "family", "achievements")}
                            style={{ width: "100%", height: "100%" ,border:"none" }}
                          />
                        </td>
                        <td style={tdStyle}>
                          <textarea
                            value={tableData.family.rituals}
                            onChange={(e) => handleChange(e, "family", "rituals")}
                            style={{ width: "100%", height: "100%" ,border:"none" }}
                          />
                        </td>
                        <td style={tdStyle}>
                          <textarea
                           rows={10}
                            value={tableData.family.wealth}
                            onChange={(e) => handleChange(e, "family", "wealth")}
                            style={{ width: "100%", height: "100%" ,border:"none" }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={tdStyle} className="vertical-text" rowSpan="2">Friends</td>
                        <td style={tdStyle} className="vertical-text" rowSpan="4">90 Days (Actions)</td>
                        <th style={thStyle}>Start</th>
                        <th style={thStyle}>Start</th>
                        <th style={thStyle}>Start</th>
                        <th style={thStyle}>Start</th>
                      </tr>
                      <tr>
                        <td style={tdStyle}>
                          <textarea
                           rows={10}
                            value={tableData.friends.start}
                            onChange={(e) => handleChange(e, "friends", "start")}
                            style={{ width: "100%", height: "100%" ,border:"none"  }}
                          />
                        </td>
                        <td style={tdStyle}>
                          <textarea
                           rows={10}
                            value={tableData.friends.start}
                            onChange={(e) => handleChange(e, "friends", "start")}
                            style={{ width: "100%", height: "100%" ,border:"none" }}
                          />
                        </td>
                        <td style={tdStyle}>
                          <textarea
                           rows={10}
                            value={tableData.friends.start}
                            onChange={(e) => handleChange(e, "friends", "start")}
                            style={{ width: "100%", height: "100%" ,border:"none"  }}
                          />
                        </td>
                        <td style={tdStyle}>
                          <textarea
                           rows={10}
                            value={tableData.friends.start}
                            onChange={(e) => handleChange(e, "friends", "start")}
                            style={{ width: "100%", height: "100%" ,border:"none" }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={tdStyle} className="vertical-text">Finance</td>
                        <th style={thStyle}>Stop</th>
                        <th style={thStyle}>Stop</th>
                        <th style={thStyle}>Stop</th>
                        <th style={thStyle}>Stop</th>
                      </tr>
                      <tr>
                        <td style={tdStyle} className="vertical-text">Finance</td>
                        <td style={tdStyle}>
                          <textarea
                           rows={10}
                            value={tableData.finance.stop}
                            onChange={(e) => handleChange(e, "finance", "stop")}
                            style={{ width: "100%", height: "100%" ,border:"none"  }}
                          />
                        </td>
                        <td style={tdStyle}>
                          <textarea
                           rows={10}
                            value={tableData.finance.stop}
                            onChange={(e) => handleChange(e, "finance", "stop")}
                            style={{ width: "100%", height: "100%" ,border:"none" }}
                          />
                        </td>
                        <td style={tdStyle}>
                          <textarea
                           rows={10}
                            value={tableData.finance.stop}
                            onChange={(e) => handleChange(e, "finance", "stop")}
                            style={{ width: "100%", height: "100%" ,border:"none" }}
                          />
                        </td>
                        <td style={tdStyle}>
                          <textarea
                           rows={10}
                            value={tableData.finance.stop}
                            onChange={(e) => handleChange(e, "finance", "stop")}
                            style={{ width: "100%", height: "100%" ,border:"none"  }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default OnePagePersonalPlan;
