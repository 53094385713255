import axios from "axios";
import React, { useEffect, useState, useRef, useCallback } from "react";
import Button from 'react-bootstrap/Button';
import DataTable from 'react-data-table-component';
import API from "../../../utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import debounce from 'lodash/debounce';
import { FaUpload } from "react-icons/fa";

const People = () => {
    const user_id = localStorage.getItem('user_id');
    const [searchQuery, setSearchQuery] = useState("");
    const [Peoples, setPeople] = useState([]); // Ensure initial state is an empty array

    const [formData, setFormData] = useState({
        fname: '',
        lname: '',
        title: "",
        role: "",
        report_to: "",
        team: "",
        last_active: "",
        email: '',
        people_type: '',
        profile: null,
    });

    useEffect(() => {
        fetchTasks();
    }, []);

    const fetchTasks = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', user_id);
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_businesstools_people}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                // setPeople(response.data.info || []);
                const sortedTasks = response.data.info.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setPeople(sortedTasks);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error during task fetching:', error);
        }
    };

    const fileInputRef = useRef(null);


    const [validationErrors, setValidationErrors] = useState({});

    const handleSave = async (e) => {
        e.preventDefault();
        // setValidationErrors({});

        // if (!validateForm(formData)) return;

        const formDataToSend = new FormData();
        formDataToSend.append('user_id', user_id);
        formDataToSend.append('fname', formData.fname);
        formDataToSend.append('lname', formData.lname);
        formDataToSend.append('profile', formData.profile);
        formDataToSend.append('title', formData.title);
        formDataToSend.append('role', formData.role);
        formDataToSend.append('report_to', formData.report_to);
        formDataToSend.append('team', formData.team);
        formDataToSend.append('last_active', formData.last_active);
        formDataToSend.append('people_type', formData.people_type);
        formDataToSend.append('email', formData.email);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.add_businesstools_people}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        'Content-Type': 'multipart/form-data'
                    },
                }
            );

            if (response.data.status === "success") {
                // toast.success(response.data.message);
                toast.success("People Added Successfully");        
                fetchTasks();
                setFormData({
                    title: "",
                    fname: "",
                    lname: "",
                    role: "",
                    report_to: "",
                    team: "",
                    last_active: "",
                    email: '',
                    people_type: '',
                    profile: null,
                });
                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                }
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("An error occurred while submitting data.");
        }
    };

    const validateForm = (data) => {
        let errors = {};
        let isValid = true;

        const requiredFields = ['title', 'role', 'report_to', 'team', 'last_active', 'email'];

        // Check if all required fields are filled
        requiredFields.forEach(field => {
            if (!data[field]) {
                errors[field] = "This field is required";
                isValid = false;
            }
        });

        setValidationErrors(errors);
        return isValid;
    };

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'profile') {
            setFormData({ ...formData, [name]: files[0] });
        } else {
            setFormData({ ...formData, [name]: value });
        }

        const errorsCopy = { ...validationErrors };
        switch (name) {
            case "title":
                errorsCopy.title = value.trim() ? "" : "This field is required";
                break;
            case "role":
                errorsCopy.role = value.trim() ? "" : "This field is required";
                break;
            case "report_to":
                errorsCopy.report_to = value.trim() ? "" : "This field is required";
                break;
            case "team":
                errorsCopy.team = value.trim() ? "" : "This field is required";
                break;
            case "last_active":
                errorsCopy.last_active = value.trim() ? "" : "This field is required";
                break;
            case "email":
                errorsCopy.email = value.trim() ? "" : "This field is required";
                break;
            case "profile":
                errorsCopy.profile = files.length > 0 ? "" : "This field is required";
                break;
            default:
                break;
        }
        setValidationErrors(errorsCopy);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const [updatedTask, setUpdatedTask] = useState(null);

    const debouncedUpdateTask = useCallback(
        debounce(async (task) => {
            const formDataToSend = new FormData();
            formDataToSend.append('people_id', task.id);
            if (task.field === 'profile') {
                formDataToSend.append('field', task.field);
                formDataToSend.append('file', task.value);
            } else {
                formDataToSend.append('text', task.value);
                formDataToSend.append('field', task.field);
            }

            try {
                const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.update_businesstools_people}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );

                console.log("Response received:", response.data);

                if (response.data.status === "success") {
                    fetchTasks();
                    toast.success("People Updating Successfully");        
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                toast.error("An error occurred while updating data.");
            }
        }, 1000),
        []
    );

    const handleEditInputChange = (e, index, field, id) => {
        const updatedEntries = [...Peoples];
        if (field === "profile") {
            updatedEntries[index][field] = e.target.files[0];
            setUpdatedTask({ field, value: e.target.files[0], id });
        } else {
            const { value } = e.target;
            updatedEntries[index][field] = value;
            setUpdatedTask({ field, value, id });
        }
    };

    useEffect(() => {
        if (updatedTask) {
            debouncedUpdateTask(updatedTask);
        }
    }, [updatedTask, debouncedUpdateTask]);

    const columns = [
        {
            name: 'First Name',
            selector: row => row.fname,
            cell: (row, index) => (
                <div className="business-tool-table">
                    <input
                        type="text"
                        value={row.fname}
                        className="form-control"
                        onChange={(e) => handleEditInputChange(e, index, "fname", row.id)}
                    />
                </div>
            ),
            sortable: true
        },
        {
            name: 'Last Name',
            selector: row => row.lname,
            cell: (row, index) => (
                <div className="business-tool-table">
                    <input
                        type="text"
                        value={row.lname}
                        className="form-control"
                        onChange={(e) => handleEditInputChange(e, index, "lname", row.id)}
                    />
                </div>
            ),
            sortable: true
        },
        {
            name: 'Title',
            selector: row => row.title,
            cell: (row, index) => (
                <div className="business-tool-table">
                    <input
                        type="text"
                        value={row.title}
                        className="form-control"
                        onChange={(e) => handleEditInputChange(e, index, "title", row.id)}
                    />
                </div>
            ),
            sortable: true
        },
      
        {
            name: 'Hire Date',
            selector: row => row.last_active,
            cell: (row, index) => (
                <div className="business-tool-table">
                    <input
                        type="date"
                        value={row.last_active}
                        className="form-control"
                        onChange={(e) => handleEditInputChange(e, index, "last_active", row.id)}
                    />
                </div>
            ),
            sortable: true
        },
        {
            name: 'People Type',
            selector: row => row.people_type,
            cell: (row, index) =>
            (
                <div className="business-tool-table">
                    <select

                        value={row.people_type}
                        className="form-control"
                        onChange={(e) => handleEditInputChange(e, index, "people_type", row.id)}
                    >
                        <option value="">Select People Type</option>
                        <option value="Employee">Employee</option>
                        <option value="Contractor">Contractor</option>
                        <option value="Vendor">Vendor</option>
                        <option value="Client">Client</option>
                        <option value="Customer">Customer</option>
                        <option value="Advisor">Advisor</option>
                        <option value="Board Member">Board Member</option>
                        <option value="Supplier">Supplier</option>
                    </select>
                </div>
            ), sortable: true
        },
        {
            name: 'Email',
            selector: row => row.email,
            cell: (row, index) => (
                <div className="business-tool-table">
                <input
                    type="text"
                    value={row.email}
                    className="form-control"
                    onChange={(e) => handleEditInputChange(e, index, "email", row.id)}
                />
                </div>
            ),
            sortable: true
        },
        {
            name: 'Profile Upload',
            selector: row => row.profile,
            cell: (row, index) => (
                <div style={{ display: 'flex', width: 100, justifyContent: 'center', cursor: 'pointer' }}>
                    <input
                        id={`file-input-${index}`} // Unique ID for each input
                        type="file"
                        name="profile"
                        className="form-control"
                        onChange={(e) => handleEditInputChange(e, index, "profile", row.id)}
                    />
                </div>
            ),
        },
        {
            name: 'Profile Image',
            cell: row => {
                const fileUrl = row.profile;
                return (
                    // style={{ width: '50px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #ddd', borderRadius: '4px' }}
                    <div >
                        {fileUrl ? (
                            <img
                                src={fileUrl}
                                alt="Profile"
                                style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                            />
                        ) : (
                            <p>No Image</p>
                        )}
                    </div>
                );
            }
        }
    ];


    const filteredEntries = (Peoples || []).filter(entry =>
        (entry.title || "").toLowerCase().includes((searchQuery || "").toLowerCase())
    );

    const isSaveButtonEnabled = () => formData.title.trim() !== '';

    return (
        <>
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center justify-content-between">
                                <h5>People</h5>
                            </div>
                        </div>
                        <form className="m-3" onSubmit={handleSave}>
                            <div className="row">
                                <div className="form-group mt-2 mb-3 col-lg-4 col-md-6">
                                    <label><b>profile Upload</b></label>
                                    <input
                                        className='form-control'
                                        // className={`form-control ${validationErrors.profile ? 'is-invalid' : ''}`}
                                        onChange={handleInputChange}
                                        name="profile"
                                        type="file"
                                        ref={fileInputRef}
                                    />
                                    {/* {validationErrors.profile && <div className="text-danger">{validationErrors.profile}</div>} */}
                                </div>
                                <div className="form-group mt-2 mb-3 col-lg-4 col-md-6">
                                    <label><b>First Name</b></label>
                                    <input
                                        type="text"
                                        className='form-control'
                                        // className={`form-control ${validationErrors.title ? 'is-invalid' : ''}`}
                                        name="fname"
                                        value={formData.fname}
                                        onChange={handleInputChange}
                                        placeholder="First Name"
                                    />
                                    {/* {validationErrors.title && <p className="text-danger">{validationErrors.title}</p>} */}
                                </div>
                                <div className="form-group mt-2 mb-3 col-lg-4 col-md-6">
                                    <label><b>Last Name</b></label>
                                    <input
                                        type="text"
                                        className='form-control'
                                        // className={`form-control ${validationErrors.title ? 'is-invalid' : ''}`}
                                        name="lname"
                                        value={formData.lname}
                                        onChange={handleInputChange}
                                        placeholder="Last Name"
                                    />
                                    {/* {validationErrors.title && <p className="text-danger">{validationErrors.title}</p>} */}
                                </div>
                                <div className="form-group mt-2 mb-3 col-lg-4 col-md-6">
                                    <label><b>Title</b></label>
                                    <input
                                        type="text"
                                        className='form-control'
                                        // className={`form-control ${validationErrors.title ? 'is-invalid' : ''}`}
                                        name="title"
                                        value={formData.title}
                                        onChange={handleInputChange}
                                        placeholder="Title"
                                    />
                                    {/* {validationErrors.title && <p className="text-danger">{validationErrors.title}</p>} */}
                                </div>
                             
                                <div className="mt-2 mb-3 col-lg-4 form-group col-md-6">
                                    <label><b>Hire Date</b></label>
                                    <input
                                        // className={`form-control ${validationErrors.last_active ? 'is-invalid' : ''}`}
                                        className='form-control'
                                        name="last_active"
                                        type="date"
                                        placeholder="Last Active"
                                        onChange={handleInputChange}
                                        value={formData.last_active}
                                    />
                                    {/* {validationErrors.last_active && <div className="text-danger">{validationErrors.last_active}</div>} */}
                                </div>
                                <div className="mt-2 mb-3 col-lg-4 form-group col-md-6">
                                    <label><b>Email</b></label>
                                    <input
                                        className='form-control'
                                        // className={`form-control ${validationErrors.email ? 'is-invalid' : ''}`}
                                        name="email"
                                        type="text"
                                        placeholder="Email"
                                        onChange={handleInputChange}
                                        value={formData.email}
                                    />
                                    {/* {validationErrors.email && <div className="text-danger">{validationErrors.email}</div>} */}
                                </div>
                                <div className="form-group mt-2 mb-3 col-lg-4 col-md-6">
                                    <label><b>People Type</b></label>
                                    <select
                                        className={`form-control ${validationErrors.people_type ? 'is-invalid' : ''}`}
                                        name="people_type"
                                        onChange={handleInputChange}
                                        value={formData.people_type}
                                    >
                                        <option value="">Select People Type</option>
                                        <option value="Employee">Employee</option>
                                        <option value="Contractor">Contractor</option>
                                        <option value="Vendor">Vendor</option>
                                        <option value="Client">Client</option>
                                        <option value="Customer">Customer</option>
                                        <option value="Advisor">Advisor</option>
                                        <option value="Board Member">Board Member</option>
                                        <option value="Supplier">Supplier</option>
                                    </select>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end align-content-center">
                                <Button type="submit"
                                    disabled={!isSaveButtonEnabled()} // Disable if `request_review_email` is empty
                                    variant="success">Save</Button>
                            </div>
                        </form>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-9"></div>
                                <div className="col-md-3">
                                    <div className="form-group text-end mb-3">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Search..."
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <DataTable
                                    columns={columns}
                                    data={filteredEntries}
                                    pagination
                                    persistTableHead={true}
                                    className="keyrelationship-table"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default People;
