import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row, Button, Form, Modal } from "react-bootstrap";
import 'reactflow/dist/style.css';
import { toast } from 'react-toastify';
import API from '../../../utils';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { MdDelete } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { FaRegEdit } from 'react-icons/fa';

const Partnership = () => {
    const user_id = localStorage.getItem('user_id');
    const [showModal, setShowModal] = useState(false);
    const [currentCardIndex, setCurrentCardIndex] = useState(null);
    const [oldworkflow_name, setOldWorkflowName] = useState('');
    const [oldshape, setOldShape] = useState('');
    const [workflow_name, setWorkflowName] = useState('');
    const [shape, setShape] = useState('');
    const [founderosId, setFoundrosId] = useState("")
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedItem, setSelectedItem] = useState(null); // For storing the item to be deleted
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // For controlling the delete modal visibility
    const [DeleteIndex, setDeleteIndex] = useState(null);
    const handleDelete = (row, index) => {
        setSelectedItem({
            id: row.id,
            workflow_name: row.label, // Use `label` as it is the Process Steps
            shape: row.shape,          // Use the correct shape value

        });
        const foundSectionIndex = sections.findIndex(section =>
            section.tableData.some(item => item.id === row.id)
        );
        setCurrentCardIndex(foundSectionIndex);
        setDeleteIndex(row.index);
        setIsDeleteModalOpen(true);
    };

    // const toggleDeleteModal = () => {
    //     setIsDeleteModalOpen(!isDeleteModalOpen);
    // };
    const [editIndex, setEditIndex] = useState(null);

    const handleEdit = (row) => {
        setOldWorkflowName(row.label);
        setOldShape(row.shape);
        setWorkflowName(row.label);
        setShape(row.shape);

        const foundSectionIndex = sections.findIndex(section =>
            section.tableData.some(item => item.id === row.id)
        );
        setCurrentCardIndex(foundSectionIndex);
        setEditIndex(row.index); // Set edit index based on row data
        setShowModal(true);
    };


    const [sections, setSections] = useState([{
        id: Date.now().toString(),
        tableData: [],
        workflow_name: '',
        shape: '',
        errorMessage: '',
        columns: [
            //{ name: 'Index', selector: row => row.index, sortable: true }, 
            { name: 'Process Steps', selector: row => row.label, sortable: true },
            { name: 'Shape', selector: row => row.shape, sortable: true },

            {
                name: 'Actions',
                cell: row => (
                    <div className="d-lg-flex">
                        <Button onClick={() => handleEdit(row)} className="btn-secondary btn-pill main-edit-view-buttons m-1"><FaRegEdit /></Button>
                        <Button
                            onClick={() => handleDelete(row)}
                            className="btn-danger btn-pill main-delete-buttons m-1">
                            <MdDelete />
                        </Button>


                    </div>
                ),
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
            }
        ]
    }]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', user_id);
            formDataToSend.append('category_name', "Partnership");

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_founderos_data_by_cat_name}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`
                },
            });

            if (response.data.status === "success") {

                const data = response.data.data[0];
                setFoundrosId(data.id);
                let transformedSections = [];
                const { workflow_name } = data;

                Object.entries(workflow_name).forEach(([index, value]) => {
                    const { workflow_name: names, shapes } = value;
                    let transformedData = [];

                    names.forEach((name, i) => {
                        transformedData.push({
                            id: `${index}-${i}`,
                            index: parseInt(index),
                            label: name,
                            shape: shapes[i] || 'undefined'
                        });
                    });

                    transformedSections.push({
                        id: index,
                        tableData: transformedData,
                        workflow_name: '',
                        shape: '',
                        errorMessage: '',
                        columns: [
                            // { name: 'Index', selector: row => row.index, sortable: true },
                            { name: 'Process Steps', selector: row => row.label, sortable: true },
                            { name: 'Shape', selector: row => row.shape, sortable: true },
                            {
                                name: 'Actions',
                                cell: row => (
                                    <div className="d-lg-flex">
                                        <Button onClick={() => handleEdit(row)} className="btn-secondary btn-pill main-edit-view-buttons m-1"><FaRegEdit /></Button>
                                        <Button
                                            onClick={() => handleDelete(row)}
                                            className="btn-danger btn-pill main-delete-buttons m-1">
                                            <MdDelete />
                                        </Button>


                                    </div>
                                ),
                                ignoreRowClick: true,
                                allowOverflow: true,
                                button: true,
                            }
                        ]
                    });
                });

                setSections(transformedSections);
            } else {
                console.error('Error: Status not success');
            }
        } catch (error) {
            console.error('Error fetching workflow steps:', error);
        }
    };

    const handleLabelChange = (index, e) => {
        const value = e.target.value;
        const updatedSections = [...sections];
        updatedSections[index].workflow_name = value;
        if (value.trim() === '') {
            updatedSections[index].errorMessage = 'This Field is required';
        } else {
            updatedSections[index].errorMessage = '';
        }
        setSections(updatedSections);
    };

    const addStep = async (event, sectionIndex) => {
        event.preventDefault();
        const section = sections[sectionIndex];
        if (section.errorMessage || section.workflow_name.trim() === '') {
            const updatedSections = [...sections];
            updatedSections[sectionIndex].errorMessage = 'This Field is required';
            setSections(updatedSections);
            return;
        }

        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', user_id);
            formDataToSend.append('index[]', sectionIndex + 1);
            formDataToSend.append('category_name', "Partnership");
            formDataToSend.append('workflow_name[]', section.workflow_name);
            formDataToSend.append('shape[]', section.shape);
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.add_founderos_step}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            if (response.data.status === "success") {
                toast.success("Partnership workflow added successfully");
                setSections((prevSections) => prevSections.map((s, i) =>
                    i === sectionIndex ? { ...s, workflow_name: '', shape: '', errorMessage: '' } : s
                ));
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error adding step:', error);
        }
    };

    const navigate = useNavigate();

    const viewFlow = () => {
        navigate(`/partership/partnershpiworkflow`);
    };

    const addNewFlow = () => {
        setSections((prevSections) => {
            const newId = (prevSections.length > 0 ? Math.max(...prevSections.map(section => parseInt(section.id))) : 0) + 1;
            return [...prevSections, {
                id: newId.toString(),
                tableData: [],
                workflow_name: '',
                shape: '',
                errorMessage: '',
                columns: [
                    //{ name: 'Index', selector: row => row.index, sortable: true },
                    { name: 'Process Steps', selector: row => row.label, sortable: true },
                    { name: 'Shape', selector: row => row.shape, sortable: true },
                    {
                        name: 'Actions',
                        cell: row => (
                            <div className="d-lg-flex">
                                <Button onClick={() => handleEdit(row)} className="btn-secondary btn-pill main-edit-view-buttons m-1"><FaRegEdit /></Button>
                                <Button
                                    onClick={() => handleDelete(row)}
                                    className="btn-danger btn-pill main-delete-buttons m-1">
                                    <MdDelete />
                                </Button>


                            </div>
                        ),
                        ignoreRowClick: true,
                        allowOverflow: true,
                        button: true,
                    }
                ]
            }];
        });
    };

    const handleDeleteItem = async () => {
        if (!founderosId || !selectedItem) {
            toast.error("Failed to delete the item. Required data is missing.");
            return;
        }

        const formDataToSend = new FormData();
        formDataToSend.append('founderos_id', founderosId);
        formDataToSend.append('category_name', "Partnership");
        formDataToSend.append('wkflow_name', selectedItem.workflow_name);
        formDataToSend.append('shapes', selectedItem.shape);
        formDataToSend.append('index', DeleteIndex !== null ? DeleteIndex : currentCardIndex + 1);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.delete_founderos_step}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success("Partnership workflow deleted successfully");
                fetchData();
                // Update the sections state to remove the deleted item
                setSections((prevSections) =>
                    prevSections.map((section) => {
                        if (section.id === selectedItem.id.split('-')[0]) {
                            const updatedTableData = section.tableData.filter((item) => item.id !== selectedItem.id);

                            return {
                                ...section,
                                tableData: updatedTableData.filter(item => item.shape !== 'undefined') // Ensure 'undefined' shapes are removed
                            };
                        }
                        return section;
                    })
                );

            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Failed to delete the item.");
        }
        setIsDeleteModalOpen(false);
    };




    const handleViewFlow = (index) => {
        navigate(`/partership/partnershpiworkflow?index=${index + 1}`);
    };
    const handleModalSubmit = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', user_id);
            formDataToSend.append('index[]', editIndex !== null ? editIndex : currentCardIndex + 1); // Use editIndex here
            formDataToSend.append('category_name', "Partnership");
            formDataToSend.append('oldworkflow_name[]', oldworkflow_name);
            formDataToSend.append('oldshape[]', oldshape);
            formDataToSend.append('newworkflow_name[]', workflow_name);
            formDataToSend.append('newshape[]', shape);
            formDataToSend.append('founderos_id', founderosId);

            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.update_founderos_step}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success("Partnership workflow updated successfully");
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error updating step:', error);
            setErrorMessage('Failed to update step. Please try again.');
        }
        setShowModal(false);
    };


    return (
        <Container fluid>
             <Form className="justify-content-between">
                                        <Row className='justify-content-between mb-3'>
                                            <Col md={4}>
                                                <Form.Group controlId="workflowName">
                                                    <Form.Label><b>Process Name</b></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter Process Name"
                                                        value={workflow_name}
                                                        onChange={handleLabelChange}
                                                    />
                                                    {errorMessage && <div className="text-danger">{errorMessage}</div>}
                                                </Form.Group>
                                            </Col>

                                            {/* <div className="d-flex justify-content-end align-content-center col-md-4">
                                                <Button type="submit" style={{ height: 'fit-content' }} className="btn btn-success mt-4">Submit</Button>
                                            </div> */}
                                        </Row>
            </Form>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader>
                            <div className='align-items-center d-flex justify-content-between'>
                                <h5>Partnership</h5>
                                <div>
                                    <Button onClick={addNewFlow} className="btn btn-success">Add a Process</Button>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody>
                            {sections.map((section, index) => (
                                <div key={section.id} style={{ marginBottom: '20px' }}>
                                    <Form onSubmit={(event) => addStep(event, index)}>
                                        <Row>
                                            <Col md={4}>
                                                <Form.Group controlId={`workflowName-${section.id}`}>
                                                    <Form.Label><b>Process Steps</b></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter Process Steps"
                                                        value={section.workflow_name}
                                                        onChange={(e) => handleLabelChange(index, e)}
                                                    />
                                                    {section.errorMessage && <div className="text-danger">{section.errorMessage}</div>}
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId={`shape-${section.id}`}>
                                                    <Form.Label><b>Select Shape</b></Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        value={section.shape}
                                                        onChange={(e) => setSections((prev) => {
                                                            const updated = [...prev];
                                                            updated[index].shape = e.target.value;
                                                            return updated;
                                                        })}
                                                    >
                                                        <option value="">Select Shape</option>
                                                        <option value="Rectangle">Rectangle</option>
                                                        <option value="Round">Round</option>
                                                        <option value="Diamond">Diamond</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <div className="d-flex justify-content-end align-content-center col-md-4">
                                                <Button type="submit" style={{ height: 'fit-content' }} className="btn btn-success mt-4">Add a Step</Button>
                                            </div>
                                        </Row>
                                    </Form>
                                    <div className='d-flex justify-content-end mx-2 my-3 workflow_sales_data'>
                                        {section.tableData.length > 0 && (
                                            <a href={`/partership/partnershpiworkflow?index=${index + 1}`} className='link'>
                                                View Partnership Workflow
                                            </a>
                                        )}
                                    </div>
                                    {/* <Button  onClick={() => handleViewFlow(index)} className='btn btn-primary mt-3'>
                                        View Flow
                                    </Button> */}
                                    <DataTable
                                        columns={section.columns}
                                        data={section.tableData}
                                        persistTableHead={true}
                                        pagination
                                        className="altimate-table"
                                    />
                                    <hr className='hrline' />
                                </div>
                            ))}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Node</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md="12">
                                <label><b>Process Steps</b></label>
                                <input
                                    type="text"
                                    placeholder='Enter Process Steps'
                                    className="form-control"
                                    value={workflow_name}
                                    onChange={(e) => setWorkflowName(e.target.value)}
                                />
                            </Col>
                            <Col md="12" className="mt-3">
                                <label><b>Shape</b></label>
                                <select
                                    className="form-control"
                                    value={shape}
                                    onChange={(e) => setShape(e.target.value)}
                                >
                                    <option value="">Select Shape</option>
                                    <option value="Rectangle">Rectangle</option>
                                    <option value="Round">Round</option>
                                    <option value="Diamond">Diamond</option>
                                </select>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                    <Button variant="success" onClick={handleModalSubmit}>Save</Button>
                </Modal.Footer>
            </Modal>

            <Modal centered={true} show={isDeleteModalOpen} onHide={() => setIsDeleteModalOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete {selectedItem && selectedItem.wkflow_name}?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={() => setIsDeleteModalOpen(false)}>Cancel</button>
                    <button className="delete-btn btn-danger me-1" onClick={handleDeleteItem}>Delete</button>
                </Modal.Footer>
            </Modal>


        </Container>
    );
};

export default Partnership;
