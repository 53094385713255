import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Button, FormGroup, Label, Input, ModalFooter } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import API from "../utils";

const ChangePassword = ({ isOpen, toggle }) => {
    const [newPassword, setNewPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errors, setErrors] = useState({});
    const user_id = localStorage.getItem('user_id');
    const [loading, setLoading] = useState(false);

    // Function to handle form validation
    const validateForm = () => {
        const validationErrors = {};

        if (oldPassword.trim() === "") {
            validationErrors.oldPassword = "Old Password is required";
        }

        if (newPassword.trim() === "") {
            validationErrors.newPassword = "New Password is required";
        } else if (newPassword.trim().length < 6) {
            validationErrors.newPassword = "Password must be at least 6 characters long";
        }

        if (confirmPassword.trim() === "") {
            validationErrors.confirmPassword = "Confirm Password is required";
        } else if (confirmPassword !== newPassword) {
            validationErrors.confirmPassword = "Passwords do not match";
        }

        return validationErrors;
    };

    // Function to handle form submission for changing password
    const handlePasswordSubmit = async (e) => {
        e.preventDefault();

        // Perform validation
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length === 0) {
            setLoading(true);
            const formDataToSend = {
                new_password: newPassword,
                current_password: oldPassword,
                confirm_password: confirmPassword,
                user_id: user_id
            };

            try {
                const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.changepassword}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    });

                if (response.data.status === "success") {
                    toast.success(response.data.message);
                    // Clear the form upon successful response
                    setNewPassword("");
                    setOldPassword("");
                    setConfirmPassword("");
                    setErrors({});
                    toggle(); // Close the modal
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error('Change Password error', error);
                toast.error("Change Password failed.");
            } finally {
                setLoading(false);
            }
        } else {
            setErrors(validationErrors);
        }
    };

    // Function to handle modal close and clear form
    const handleToggle = () => {
        setNewPassword("");
        setOldPassword("");
        setConfirmPassword("");
        setErrors({});
        toggle();
    };

    return (
        <Modal isOpen={isOpen} toggle={handleToggle}>
            <ModalHeader toggle={handleToggle}>Change Password</ModalHeader>
            <form onSubmit={handlePasswordSubmit}>
                <ModalBody>
                    <FormGroup>
                        <Label for="oldPassword"><b>Old Password</b></Label>
                        <Input
                            type="password"
                            className={`form-control ${errors.oldPassword ? 'is-invalid' : ''}`}
                            name="oldPassword"
                            id="oldPassword"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            placeholder="Enter Old Password"
                        />
                        {errors.oldPassword && <div className="text-danger">{errors.oldPassword}</div>}
                    </FormGroup>
                    <FormGroup>
                        <Label for="newPassword"><b>New Password</b></Label>
                        <Input
                            type="password"
                            name="newPassword"
                            id="newPassword"
                            value={newPassword}
                            placeholder="Enter New Password"
                            onChange={(e) => setNewPassword(e.target.value)}
                            className={`form-control ${errors.newPassword ? 'is-invalid' : ''}`}
                        />
                        {errors.newPassword && <div className="text-danger">{errors.newPassword}</div>}
                    </FormGroup>
                    <FormGroup>
                        <Label for="confirmPassword"><b>Confirm Password</b></Label>
                        <Input
                            type="password"
                            name="confirmPassword"
                            id="confirmPassword"
                            value={confirmPassword}
                            placeholder="Confirm New Password"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                        />
                        {errors.confirmPassword && <div className="text-danger">{errors.confirmPassword}</div>}
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={handleToggle}>Cancel</Button>
                    <Button className="btn btn-success" type="submit" disabled={loading}>
                        {loading ? 'Submitting...' : 'Submit'}
                    </Button>
                </ModalFooter>
            </form>
        </Modal>
    );
};

export default ChangePassword;
