import PuffLoader from 'react-spinners/PuffLoader'

export default function MypuffLoader({ active, children }) {
    return (
        <PuffLoader
            active={active}
        >
            {children}
        </PuffLoader>
    )
}
