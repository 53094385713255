import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import axios from 'axios';
import API from '../../../../../utils';
import { toast } from 'react-toastify';
import { FaCheck, FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const FinanceActions = () => {
    const actionsList = {
        "Funding Growth": [
            { id: 1, name:"(Re-)Determine funding needs based on the company’s (refreshed) business strategy"},
            { id: 2, name:"Develop a funding strategy that includes full consideration of working capital, M&A/divestitures and tax strategy"},
            { id: 3, name:"Develop and execute a robust capital allocation plan"},
            { id: 4, name:"Review and improve working capital position"},
            { id: 5, name:"Further professionalize functions to manage banks, investors and other stakeholders (e.g. treasury, tax, risk and investor relations)"},
            { id: 6, name:"Recruit talent to aid better access to multiple sources of finance"}
        ],
        "People and Organization": [
            { id: 7, name:"Perform cost-benefit analysis of outsourcing elements of the finance function"},
            { id: 8, name:"Implement a structure with an optimal combination of shared services, outsourcing and local in-house capability, which enables the group to deliver consistent services and to be highly agile"},
            { id: 9, name:"Create a finance workforce of the future that has the new capabilities needed; upskill in areas like data science"},
            { id: 10, name:"Create a compelling career path for finance professionals along with a strong purpose that inspires existing employees and potential hires"},
            { id: 11, name:"Introduce management development program"},
            { id: 12, name:"Improve cross-functional collaboration (e.g. with HR, Operations, Strategy, IT, Business Units) to improve cash fl}ow, lower costs, forecast financial and risk positions, increase workforce productivity, enable new business models and/or create value"},
            { id: 13, name:"Make finance a strategic business partner"}
        ],
        "Finance Process and Controls": [
            { id: 14, name:"Build and stress test integrated financial scenarios, factoring in cost & revenue forecasts, macroeconomic and government intervention"},
            { id: 15, name:"Move towards dynamic business planning from traditional quarterly/annual planning cycles to allow for agile decision making"},
            { id: 16, name:"Improve management information to drive business performance"},
            { id: 17, name:"Embed financial and management reporting into strategic decision-making process"},
            { id: 18, name:"Develop consistent finance policies, procedures and controls across entire organization"},
            { id: 19, name:"Perform review of financial controls"},
            { id: 20, name:"Enable internal controls to safeguard against interdependent risks including geopolitics, disruptive technology and cybersecurity"},
            { id: 21, name:"Conduct a systematic risk analysis of financial and operating processes, incl. outside, downside, and upside risks (risk taking required to realize the company’s growth and long-term value objectives)"},
            { id: 22, name:"Integrate financial and tax planning provisioning and compliance, minimizing financial and reputational risk"}
        ],
        "Data and Technology": [
            { id: 23, name:"Implement consistent and robust financial system"},
            { id: 24, name:"Review financial data for quality and consistency across enterprise"},
            { id: 25, name:"Explore new technologies and deploy them at speed for a transparent, data-driven and smart function"},
            { id: 26, name:"Automate finance processes end-to-end, reducing cost and making the function highly agile and future-ready"},
            { id: 27, name:"Enhance data analytics capabilities to improve forecasting and scenario planning, risk management, cost reduction and understanding of value drivers"},
            { id: 28, name:"Initiate robust, data-driven financial strategy that integrates business scenarios and insights"}
        ],
        "Stakeholder Management": [
            { id: 29, name:"Identify key stakeholders and assign responsibilities to manage their needs across senior management"},
            { id: 30, name:"Develop corporate story and tailor for specific stakeholders"},
            { id: 31, name:"Articulate the company’s objectives and approach to long-term value creation for each stakeholder group"},
            { id: 32, name:"Build a robust and disciplined approach to evaluating the companies’ non-financial performances on factors such as environment, social impact and governance (ESG)"},
            { id: 33, name: "Organize/enhance pro-active communication of performance and risk management matters (incl. non-financial) to each stakeholder group (no surprises)"}
        ]
    };
    const [selectedActions, setSelectedActions] = useState([]);
    const [openCategory, setOpenCategory] = useState(null);
    const [checkedValue, setCheckedValue] = useState({});
    const uid = localStorage.getItem("user_id");

    const handleCategoryToggle = (category) => {
        setOpenCategory(openCategory === category ? null : category);
    };

    // Function to fetch data from API
    const fetchData = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_growth_finance}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.status === "success") {
                const jsonData = response.data.data.technology_action;

                if (jsonData) {
                    try {
                        const data = JSON.parse(jsonData);

                        const parseCategoryData = (categoryData) => {
                            return categoryData ? categoryData.split(',') : [];
                        };

                        const actions = {
                            "Funding Growth": parseCategoryData(data.funding_growth || ''),
                            "People and Organization": parseCategoryData(data.people_organization || ''),
                            "Finance Process and Controls": parseCategoryData(data.finance_process_control || ''),
                            "Data and Technology": parseCategoryData(data.data_technology || ''),
                            "Stakeholder Management": parseCategoryData(data.stakeholder_management || ''),
                        };

                        setCheckedValue(actions);
                    } catch (error) {
                        console.error('Error parsing JSON:', error);
                    }
                } else {
                    console.error('No data found for technology_action.');
                }
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Fetch technology actions error', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Function to format selected actions for API
    const formatSelectedActions = (selectedActions) => {
        const categories = {
            "Funding Growth": "funding_growth[]",
            "People and Organization": "people_organization[]",
            "Finance Process and Controls": "finance_process_control[]",
            "Data and Technology": "data_technology[]",
            "Stakeholder Management": "stakeholder_management[]",
        };
    
     
        const formatted = Object.keys(categories).reduce((acc, category) => {
            const selectedData = selectedActions
                .filter(action => action.category === category)
                .map(action => {
                    const actionDetails = actionsList[category].find(a => a.id === action.id);
                    return actionDetails ? actionDetails.name : null;  // Directly returning the action name
                })
                .filter(action => action !== null);
    
            // Ensure this is an array, even if empty
            acc[categories[category]] = selectedData;
            return acc;
        }, {});
    
        return formatted;
    };

    // Function to handle checkbox change
    const handleCheckboxChange = (category, actionId) => {
        setSelectedActions(prev => {
            const updated = [...prev];
            const index = updated.findIndex(a => a.category === category && a.id === actionId);

            if (index > -1) {
                updated.splice(index, 1);
            } else {
                updated.push({ category, id: actionId });
            }

            // Trigger the API call on each checkbox change
            handleUpdateActions(updated);
            
            return updated;
        });
    };

    // Function to handle updating actions on checkbox change
    const handleUpdateActions = async (updatedActions) => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
    
            // Format the actions correctly
            const formattedActions = formatSelectedActions(updatedActions);
    
            // Append formatted actions to FormData
            Object.entries(formattedActions).forEach(([key, value]) => {
                if (value.length > 0) {
                    value.forEach(item => formDataToSend.append(key, item));
                } else {
                    formDataToSend.append(key, '[]');  // To represent an empty array
                }
            });
    
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_update_finance_action}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Submit technology actions error', error);
        }
    };
    

    const handleAddToActions = async (category, action, index) => {
        try {
            // Toggle selection state
            const newCheckedValue = { ...checkedValue };
            const isSelected = newCheckedValue[category]?.includes(action.id);
            if (isSelected) {
                newCheckedValue[category] = newCheckedValue[category].filter(id => id !== action.id);
            } else {
                newCheckedValue[category] = [...(newCheckedValue[category] || []), action.id];
            }
            setCheckedValue(newCheckedValue);
    
            // Update selected actions state
            const updatedActions = Object.keys(newCheckedValue).flatMap(cat =>
                newCheckedValue[cat].map(id => ({ category: cat, id }))
            );
            setSelectedActions(updatedActions);
    
            // Make API call
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
    
            // Format the actions correctly
            const formattedActions = formatSelectedActions(updatedActions);
    
            // Append formatted actions to FormData
            Object.entries(formattedActions).forEach(([key, value]) => {
                if (value.length > 0) {
                    value.forEach(item => formDataToSend.append(key, item));
                } else {
                    formDataToSend.append(key, '[]');  // To represent an empty array
                }
            });
    
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_update_finance_action}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Submit technology actions error', error);
            toast.error("An error occurred while updating data.");
        }
    };

    const renderActions = (category) => {
        const categoryActions = actionsList[category] || [];

        return categoryActions.map((action, index) => (
            <Col xs={12} md={6} lg={3} key={action.id} className="mb-3">
                <div className="action-card p-3 border">
                    <p>{action.name}</p>
                    <Button 
                        variant={checkedValue[category]?.includes(action.id) ? "success" : "primary"} 
                        onClick={() => handleAddToActions(category, action, index)}
                        className="d-flex align-items-center mt-3 p-2"
                    >
                        {checkedValue[category]?.includes(action.id) ? <FaCheck className="me-2" /> : <FaPlus className="me-2" />}
                        {checkedValue[category]?.includes(action.id) ? "Selected" : "Add to Actions"}
                    </Button>
                </div>
            </Col>
        ));
    };

    return (
        <div className="col-sm-12">
        <div className="card mt-4">
            <div className="card-header">
                <div className='justify-content-between d-flex '>
                    <div className="">
                        <h5>Actions for Finance</h5>
                    </div>
                    <div>
                        <Link className='backto-ondriver' to="/growthframeworks/founderdriver">Back to the Founder OS Drivers of Growth</Link>
                    </div>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="card-body">
                    <p>To help you achieve your goals we have created actions learned from our insights of working with market leaders. Add ones that you feel are best for your business.</p>
                    <Accordion defaultActiveKey="0" activeKey={openCategory}>
                {Object.keys(actionsList).map(category => (
                    <Accordion.Item eventKey={category} key={category}>
                        <Accordion.Header onClick={() => handleCategoryToggle(category)}>
                        <div className='d-flex justify-content-between align-items-center w-100'>
              <h5 className='mb-0 fw-bold'>{category}</h5>
              <p className='mb-0 text-primary'>View Actions</p></div>
                           
                        </Accordion.Header>
                        <Accordion.Body>
                            <Row>
                                {openCategory === category && renderActions(category)}
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </div>
        </div>
        </div>
        </div>
    );
};

export default FinanceActions;
