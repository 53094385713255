import React, { useState, useEffect, useCallback } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import { toast } from 'react-toastify';
import API from "../../../utils";
import debounce from 'lodash/debounce';

const CreatedByme = ({ mytasks, fetchData }) => {
  const [tasks, setTasks] = useState(mytasks);
  const [searchQuery, setSearchQuery] = useState("");
  const [updatedTask, setUpdatedTask] = useState(null);

  useEffect(() => {
    setTasks(mytasks);
  }, [mytasks]);

  const debouncedUpdateTask = useCallback(
    debounce(async (task) => {
      const formDataToSend = new FormData();
      formDataToSend.append('task_id', task.id);
      formDataToSend.append('field', task.field);

      // Check if the field is due_date and format it accordingly
      if (task.field === 'due_date') {
        const formattedDueDate = new Date(task.value).toISOString();
        formDataToSend.append('text', formattedDueDate);
      } else {
        formDataToSend.append('text', task.value);
      }

      try {
        const response = await axios.post(
          `${API.BASE_URL}${API.ENDPOINTS.update_task}`,
          formDataToSend,
          {
            headers: {
              Authorization: `${API.AUTHORIZATION_TOKEN}`,
            },
          }
        );

        if (response.data.status === "success") {
          // toast.success(response.data.message);
          toast.success(`Task Successfully Updated`);
          fetchData();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error("Error during update:", error);
        toast.error('An error occurred while updating the task.');
      }
    }, 1000),
    []
  );

  const handleEditInputChange = (e, index, field, taskid) => {
    const { value } = e.target;
    const newTasks = [...tasks];
    newTasks[index][field] = value;
    setTasks(newTasks);

    setUpdatedTask({ id: taskid, field, value });
  };

  useEffect(() => {
    if (updatedTask) {
      debouncedUpdateTask(updatedTask);
    }
  }, [updatedTask, debouncedUpdateTask]);

  const handleCheckboxCheck = async (taskid, isChecked) => {
    const newTasks = tasks.map(task =>
      task.id === taskid ? { ...task, is_complete: isChecked ? "1" : "0" } : task
    );
    setTasks(newTasks);

    const formDataToSend = new FormData();
    formDataToSend.append('my_task_id', taskid);
    formDataToSend.append('is_check', isChecked ? "1" : "0");

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.is_check_my_task}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        fetchData();
        toast.success(`Task Successfully Complete`);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error during update:", error);
      toast.error('An error occurred while updating the task.');
    }
  };


  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredTasks = tasks.filter(task =>
    task.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    task.criticality.toLowerCase().includes(searchQuery.toLowerCase()) ||
    task.task_type.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const [isDatePickerVisible, setDatePickerVisible] = useState(false);
  const columns = [
    {
      name: 'Completed',
      selector: (row) => (
        <input
          className="checkbox_animated"
          id={`chk-ani${row.id}`}
          type="checkbox"
          checked={row.status === "completed"}
          onChange={(e) => handleCheckboxCheck(row.id, e.target.checked)}
        />
      ),
      sortable: false,
    },
    {
      name: 'Task',
      selector: row => row.title,
      sortable: true,
      cell: (row, index) => (
        <div className="task-table w-100">
          <input
            name="title"
            type="text"
            value={row.title}
            className="form-control w-100 py-2"
            onChange={(e) => handleEditInputChange(e, index, "title", row.id)}
          />
        </div>
      ),
      width: '300px', // Wider width for the Task column
    },
    {
      name: 'Criticality',
      selector: row => row.criticality,
      sortable: true,
      cell: (row, index) => (
        <div className="task-table">
          <select
            value={row.criticality}
            className="form-control"
            onChange={(e) => handleEditInputChange(e, index, "criticality", row.id)}
          >
            <option value="general">Choose Option</option>
            <option value="Critical">Critical</option>
            <option value="High">High</option>
            <option value="Medium">Medium</option>
            <option value="Low">Low</option>
          </select>
        </div>
      ),
    },
    {
      name: 'Task Type',
      selector: row => row.task_type,
      sortable: true,
      cell: (row, index) => (
        <div className="task-table">
          <select
            name="task_type"
            value={row.task_type}
            className="form-control"
            onChange={(e) => handleEditInputChange(e, index, "task_type", row.id)}
          >
            <option value="general">Choose Option</option>
            <option value="Personal">Personal</option>
            <option value="Professional">Professional</option>
          </select>
        </div>
      ),
    },
    {
      name: 'Status',
      selector: row => row.status,
      sortable: true,
      cell: (row, index) => (
        <div className="task-table">
          <select
            name="Status"
            value={row.status}
            className="form-control"
            onChange={(e) => handleEditInputChange(e, index, "status", row.id)}
            style={{ backgroundColor: 
                row.status === 'completed' ? '#43AA8B' :
                row.status === 'in_process' ? '#90BE6D' :
                row.status === 'on_hold' ? '#F8961E' :
                row.status === 'not_started' ? '#F9C74F' :
                '' 
            }}
          >
            <option value="general">Choose Option</option>
            <option value="not_started">Not Started</option>
            <option value="in_process">In Process</option>
            <option value="on_hold">On Hold</option>
            <option value="completed">Completed</option>
          </select>
        </div>
      ),
    },
    {
      name: 'Due Date',
      selector: row => row.due_date,
      sortable: true,
      cell: (row) => {
        if (!row.due_date) return 'No Due Date';
    
        // Get today's date and the due_date
        const dueDate = new Date(row.due_date);
        const today = new Date();
    
        // Calculate the difference in time (milliseconds)
        const timeDifference = dueDate.getTime() - today.getTime();
    
        // Convert the time difference to days (1 day = 24 * 60 * 60 * 1000 milliseconds)
        const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    
        // Determine the display message based on whether the date is past or future
        let displayMessage = '';
        if (dayDifference > 0) {
          displayMessage = `Due in ${dayDifference} Day${dayDifference > 1 ? 's' : ''}`;
        } else if (dayDifference === 0) {
          displayMessage = 'Due Today';
        } else {
          displayMessage = `Overdue by ${Math.abs(dayDifference)} Day${Math.abs(dayDifference) > 1 ? 's' : ''}`;
        }
    
        // Return a non-editable date field with the due date display message
        return (
          <div className="task-table">
            {/* Display the message without any input field */}
            <span>
              {displayMessage}
            </span>
          </div>
        );
      },
      width: '160px',
    },
    
    {
      name: 'Description',
      selector: row => row.description,
      sortable: true,
      cell: (row, index) => (
        <div className="task-table w-100">
          <input
            name="description"
            type="text"
            value={row.description}
            className="form-control w-100 py-2  "
            onChange={(e) => handleEditInputChange(e, index, "description", row.id)}
          />
        </div>
      ),
    },
  ];
  


  return (
    <>
      <div>
        <div className='row'>
          <div className="col-md-9"></div>
          <div className="col-md-3 col-sm-12 text-end taskapp-table-Search">
            <input
              className="form-control"
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div>
          <DataTable
            columns={columns}
            data={filteredTasks}
            className='taskapp-tables'
            pagination
            persistTableHead={true}
          />
        </div>
      </div>
    </>
  );
};

export default CreatedByme;
