import React, { Fragment, useState } from 'react';
import { Email } from "../constant";
import { Link } from 'react-router-dom';
import Forgotpasswordbg from "../assets/images/authimg/forgotpasswordbg.svg";
import Logosvg from '../assets/images/logo/auth.svg';
import API from '../utils';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import MyLoader from '../components/common/myloader';
import Loginbg from "../../src/assets/images/logo/bg.png";

const ForgetPwd = () => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [loading, setLoading] = useState(false);

    const validateEmail = () => {
        const emailRegex = /\S+@\S+\.\S+/;
        if (!email) {
            setEmailError('Email is required.');
            return false;
        } else if (!emailRegex.test(email)) {
            setEmailError('Please enter a valid email address.');
            return false;
        }
        return true;
    };

    const handleSendResetLink = async () => {
        const isValidEmail = validateEmail();
        if (isValidEmail) {
            setLoading(true);
            const formDataToSend = new FormData();
            formDataToSend.append('email', email);

            try {
                const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.forgotPassword}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );

                if (response.data.status === "success") {
                    toast.success(response.data.message);
                    setEmail(""); // Reset email state
                    setEmailError(""); // Reset email error state
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                toast.error("Failed to fetch user data");
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <>
            <MyLoader active={loading} >
                <Fragment>
                    <div className="login-container d-flex">
                        <div className="background-image">
                            <div className="p-3 text-white justify-content-end text-end align-items-center">
                                <div className="">
                                    <div className="d-flex justify-content-end">
                                        <img src={Loginbg} className="Logosvg-main1" alt="background" />
                                    </div>
                                    <div className="d-flex align-items-lg-center">
                                        <h2 className="text-white mb-0 fw-bold ">  Your Roadmap to a $1 <br /> Million Business & Beyond</h2>
                                        {/* <h1 className="display-4 fw-bold ">Personal OS</h1> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="forgot-box">
                            <div className=".auth-heding-main-forgot">
                                <h2 className="auth-heding">Forget Password</h2>
                                <p className="auth-peregraft">Enter your email and we'll send you instructions to reset your password.</p>
                            </div>
                            <form className="theme-form">
                                <div className="form-group">
                                    <label className="col-form-label">{Email}</label>
                                    <input
                                        className={`form-control ${emailError ? 'is-invalid' : ''}`}
                                        type="email"
                                        placeholder="Enter your email"
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                            setEmailError('');
                                        }}
                                    />
                                    {emailError && <div className="text-danger">{emailError}</div>}
                                </div>
                                <div className="form-group mt-3 mb-0 d-grid">
                                    <button
                                        className="login-button"
                                        type="button"
                                        onClick={handleSendResetLink}
                                        disabled={loading}
                                    >
                                        {loading ? 'Sending...' : 'Send Reset Link'}
                                    </button>
                                </div>
                            </form>
                            <div className="account-auth text-center">
                                <p className="mb-0 mt-4">Wait, I remember my password... <Link to="/login" >Click here</Link></p>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </Fragment>
            </MyLoader>
        </>
    );
};

export default ForgetPwd;
