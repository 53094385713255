import React, { useEffect, useState } from "react";
import { Container, Dropdown, Tab, Tabs } from "react-bootstrap";
import CustomerAnalysis from "./customeranalysis";
import CustomerActions from "./customeraction";
import CustomerAssessment from "./customerassessment";

const Customerindex = () => {
    const [tabs] = useState([
        { id: 1, sub_category: 'Customer Analysis' },
        { id: 2, sub_category: 'Customer Assessment' },
        { id: 3, sub_category: 'Customer Actions' },
    ]);

    const [activeTab, setActiveTab] = useState('Customer Analysis');
    const [isMobile, setIsMobile] = useState(false);

    // Function to handle tab selection
    const handleTabSelect = (key) => {
        setActiveTab(key); // Update active tab state
    };

    // Function to render tab content based on selected tab
    const getTabContent = (tabName, activeTab) => {
        switch (tabName) {
            case 'Customer Analysis':
                return <CustomerAnalysis activeTab={activeTab} />;
            case 'Customer Assessment':
                return <CustomerAssessment activeTab={activeTab} />;
            case 'Customer Actions':
                return <CustomerActions activeTab={activeTab} />;
            default:
                return null;
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Set breakpoint for mobile screens
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Container fluid>
            {isMobile ? (
                <>
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {activeTab}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {tabs.map((tab) => (
                                <Dropdown.Item
                                    key={tab.id}
                                    onClick={() => handleTabSelect(tab.sub_category)}
                                >
                                    {tab.sub_category}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    {getTabContent(activeTab)} {/* Render only once for mobile */}
                </>
            ) : (
                <Tabs
                    activeKey={activeTab}
                    onSelect={handleTabSelect}
                    id="justify-tab-example"
                    className="border-tab nav-primary mb-3"
                >
                    {tabs.map(tab => (
                        <Tab key={tab.id} eventKey={tab.sub_category} title={tab.sub_category}>
                            {getTabContent(tab.sub_category)} {/* Render content inside the Tabs */}
                        </Tab>
                    ))}
                </Tabs>
            )}
        </Container>
    );
};

export default Customerindex;
