import React, { useState, useEffect, useCallback } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { debounce } from 'lodash';
import API from "../../../utils";

const FiveDisfunctions = () => {
  const uid = localStorage.getItem("user_id");
  const [formData, setFormData] = useState({
    Remembered: "",
    parents: "",
    colleagues: "",
    friends: "",
    Spouse: "",
    Commitment: "",
    period: "",
    challenging: "",
  });

  const debouncedSave = useCallback(
    debounce(async (updatedFormData) => {
      try {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        Object.keys(updatedFormData).forEach(key => {
          formDataToSend.append(key, updatedFormData[key]);
        });

        const response = await axios.post(
          `${API.BASE_URL}${API.ENDPOINTS.five_dysfunctions_insert}`,
          formDataToSend,
          {
            headers: {
              Authorization: `${API.AUTHORIZATION_TOKEN}`,
            },
          }
        );

        if (response.data.status === "success") {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Profile update failed.");
      }
    }, 500), // Adjust the debounce delay as needed
    []
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = {
      ...formData,
      [name]: value
    };
    setFormData(updatedFormData);
    debouncedSave(updatedFormData);
  };

  const fetchData = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('user_id', uid);

      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.five_dysfunctions_get}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        const fetchedData = response.data.data[0];

        // Populate the form fields with the fetched data
        setFormData({
          Remembered: fetchedData.Remembered || "",
          Spouse: fetchedData.Spouse || "",
          Commitment: fetchedData.Commitment || "",
          parents: fetchedData.parents || "",
          colleagues: fetchedData.colleagues || "",
          challenging: fetchedData.challenging || "",
          period: fetchedData.period || "",
          friends: fetchedData.friends || "",
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Fetch data error', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container fluid={true}>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>Five Dysfunctions of a Team Exercise </h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group ">
                    <label><b>Inattention to Results</b></label>
                    <textarea
                      className="form-control"
                      name="Remembered"
                      value={formData.Remembered}
                      onChange={handleChange}
                      rows="4"
                    ></textarea>
                  </div>

                  <div className="form-group ">
                    <label><b>Avoidance of Accountability</b></label>
                    <textarea
                      className="form-control"
                      name="Spouse"
                      value={formData.Spouse}
                      onChange={handleChange}
                      rows="4"
                    ></textarea>
                  </div>

                  <div className="form-group ">
                    <label><b>Lack of Commitment</b></label>
                    <textarea
                      className="form-control"
                      name="Commitment"
                      value={formData.Commitment}
                      onChange={handleChange}
                      rows="4"
                    ></textarea>
                  </div>

                  <div className="form-group ">
                    <label><b>Fear of Conflict</b></label>
                    <textarea
                      className="form-control"
                      name="parents"
                      value={formData.parents}
                      onChange={handleChange}
                      rows="4"
                    ></textarea>
                  </div>

                  <div className="form-group ">
                    <label><b>Absence of Trust</b></label>
                    <textarea
                      className="form-control"
                      name="colleagues"
                      value={formData.colleagues}
                      onChange={handleChange}
                      rows="4"
                    ></textarea>
                  </div>

                  <div className="form-group ">
                    <label><b>Which Dysfunction is the least challenging for our team? Why do you think this is?</b></label>
                    <textarea
                      className="form-control"
                      name="challenging"
                      value={formData.challenging}
                      onChange={handleChange}
                      rows="4"
                    ></textarea>
                  </div>
                  <div className="form-group ">
                    <label><b>What's one thing we can do as a team next period to improve the team dynamics? Refine and rewrite your idea as a S.M.A.R.T. priority</b></label>
                    <textarea
                      className="form-control"
                      name="period"
                      value={formData.period}
                      onChange={handleChange}
                      rows="4"
                    ></textarea>
                  </div>
                  <div className="form-group ">
                    <label><b>Which Dysfunction is the least challenging for our team? Why do you think this is?</b></label>
                    <textarea
                      className="form-control"
                      name="friends"
                      value={formData.friends}
                      onChange={handleChange}
                      rows="4"
                    ></textarea>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default FiveDisfunctions;
