import React, { useEffect, useState } from "react";
import { Container, Row, Nav } from "react-bootstrap";
import { FaArchive, FaBalanceScaleRight, FaChartArea, FaGofore, FaHome, FaSuperpowers } from "react-icons/fa";
import { Tb360View, TbReportSearch } from "react-icons/tb";
import { FaMaskVentilator, FaTableCells } from "react-icons/fa6";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink, useNavigate } from "react-router-dom";
import { isSubscribedAccountant } from "../../utils";

const Indexreporting = () => {
    const [BasicLineTab, setBasicLineTab] = useState('1');

    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false
                }
            }
        ]
    };

    const navigate = useNavigate()
    useEffect(() => {
        if (!isSubscribedAccountant()) {
            navigate("/users/billings");
            window.location.reload();
        }
    }, []);

    return (
        <Container fluid>
            <div className="mx-3">
                <div className="slider-item">
                    <Nav variant="pills" className="border-tab nav-primary">
                        <Nav.Item>
                            <Nav.Link as={NavLink} exact to="/people/pace">
                                <FaBalanceScaleRight className="me-1" />Goal Scorecard
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link as={NavLink} exact to="/Monthly">
                                <TbReportSearch className="me-1" />Reports
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link as={NavLink} exact to="/reporting-data">
                                <Tb360View className="me-1" />Reporting Data
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
            </div>
        </Container>
    );
};

export default Indexreporting;
