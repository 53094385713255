import React, { useEffect, useState, useCallback } from "react";
import { Button } from "react-bootstrap";
import { Input } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { debounce } from 'lodash';
import API, { isSubscribedAccountant } from "../../utils";
import { useNavigate } from "react-router-dom";
import MyLoader from "../common/myloader";

const Inviteteammatesmember = () => {
    const user_id = localStorage.getItem("user_id");
    const [forms, setForms] = useState([{ id: null, first_name: '', last_name: '', email: '', role: '' }]);
    const [errors, setErrors] = useState([{}]);
    const [updatedForm, setUpdatedForm] = useState(null);
    const uid = localStorage.getItem('user_id');
    const [loading, setLoading] = useState(false);
    const [licenses, setLicenses] = useState(0);
    const [usedLicenses, setUsedLicenses] = useState(1);

    const addForm = () => {


        if ((licenses === 0 && forms.length >= 1) || (licenses > 0 && forms.length >= licenses + 1)) {
            toast.error("You cannot add more users than available licenses.");
            return;
        }

        setForms(prevForms => [...prevForms, { id: null, first_name: '', last_name: '', email: '', role: '', status: "0" }]);
        setErrors(prevErrors => [...prevErrors, {}]);
    };


    const debouncedUpdateForm = useCallback(
        debounce(async (form, index) => {
            if (!form.id) return;

            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            formDataToSend.append('id', form.id);
            formDataToSend.append(`first_name`, form.first_name);
            formDataToSend.append(`last_name`, form.last_name);
            formDataToSend.append(`email`, form.email);
            formDataToSend.append(`role`, form.role);
            formDataToSend.append('sendmail', form.status);

            try {
                const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.update_teammates_invite_to_company}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );

                if (response.data.status === "success") {
                    toast.success("People Update successfully");
                    fetchData();
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                toast.error("Failed to update the form. Please try again later.");
            }
        }, 2500),
        []
    );

    const handleChange = (index, field, value) => {
        const newForms = [...forms];
        if (field === 'status') {
            newForms[index][field] = value ? "1" : "0";
        } else {
            newForms[index][field] = value;
        }
        setForms(newForms);
        setUpdatedForm({ ...newForms[index], index });
    };

    useEffect(() => {
        if (updatedForm) {
            debouncedUpdateForm(updatedForm, updatedForm.index);
        }
    }, [updatedForm, debouncedUpdateForm]);

    const fetchData = async () => {
        setLoading(true);
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_all_teammates_invite_to_company}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );
            if (response.data.status === 'success') {
                const data = response.data.data || [];
                const formattedForms = data.map(item => ({
                    id: item.id,
                    first_name: item.first_name || '',
                    last_name: item.last_name || '',
                    email: item.email || '',
                    role: item.role || '',
                    status: item.status,
                }));
                setForms(formattedForms);
                setUsedLicenses(formattedForms.length); // Update the number of used licenses
            }
        } catch (error) {
            console.error('Error during data fetching:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchLicenses = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', user_id);

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_licence}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`
                },
            });

            if (response.data.status === "success") {
                // Assuming licenses are returned as an array
                const licensesArray = response.data.data || []; // Default to an empty array if not found
                const totalLicenses = licensesArray.length;

                setLicenses(totalLicenses);
            } else {
                //toast.error("Failed to fetch licenses.");
            }
        } catch (error) {
            console.error('Error fetching licenses:', error);
            // toast.error("An error occurred while fetching licenses.");
        }
    };


    useEffect(() => {
        fetchData();
        fetchLicenses();
    }, []);

    const handleSubmit = async (index) => {
        setLoading(true);
        const formDataToSend = new FormData();
        const form = forms[index];
        formDataToSend.append('user_id', uid);
        formDataToSend.append('first_name', form.first_name);
        formDataToSend.append('last_name', form.last_name);
        formDataToSend.append('email', form.email);
        formDataToSend.append('role', form.role || '');
        formDataToSend.append('sendmail', form.status);

        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_teammates_invite_to_company}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success("People Added successfully");
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error during request:", error);
            toast.error('Failed to invite teammates. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const deleteForm = async (index) => {
        if (index === 0) {
            toast.error("The default form cannot be deleted.");
            return;
        }

        const formToDelete = forms[index];

        if (!formToDelete || !formToDelete.id) {
            toast.error('Invalid form data.');
            return;
        }

        const formDataToSend = new FormData();
        formDataToSend.append('id', formToDelete.id);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.delete_teammates_invite_to_company}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error during delete request:", error);
            toast.error('Failed to delete the form. Please try again later.');
        } finally {
            setLoading(false);
        }
    };


    return (
        <MyLoader active={loading} >
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-6 align-items-center d-flex">
                                    <h5>Add People</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <span className="m-2"><b>You are currently using {usedLicenses} of {licenses + 1} available licenses.</b></span>

                            </div>
                            {forms.map((form, index) => (
                                <div className="card-body invertmember-addmore my-2 m-3" key={`form-${index}`}>
                                    <div className="card-body">
                                        <div className="row">
                                            {index > 0 && (
                                                <div className="col-md-12 text-end">
                                                    <Button variant="danger" className="invert-member text-end p-0 m-0" onClick={() => deleteForm(index)}>
                                                        <i className="fa fa-times"></i>
                                                    </Button>
                                                </div>
                                            )}
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group mb-3">
                                                    <label className="form-label"><b>First Name</b></label>
                                                    <input
                                                        className={`form-control ${errors[index]?.first_name ? 'is-invalid' : ''}`}
                                                        type="text"
                                                        placeholder="First Name"
                                                        name="first_name"
                                                        value={form.first_name}
                                                        onChange={(e) => handleChange(index, 'first_name', e.target.value)}
                                                    />
                                                    {errors[index]?.first_name && <div className="text-danger">{errors[index]?.first_name}</div>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group mb-3">
                                                    <label className="form-label"><b>Last Name</b></label>
                                                    <input
                                                        className={`form-control ${errors[index]?.last_name ? 'is-invalid' : ''}`}
                                                        type="text"
                                                        placeholder="Last Name"
                                                        value={form.last_name}
                                                        onChange={(e) => handleChange(index, 'last_name', e.target.value)}
                                                    />
                                                    {errors[index]?.last_name && <div className="text-danger">{errors[index]?.last_name}</div>}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group mb-3">
                                                    <label className="form-label"><b>Email</b></label>
                                                    <input
                                                        className={`form-control ${errors[index]?.email ? 'is-invalid' : ''}`}
                                                        type="text"
                                                        placeholder="Enter Email"
                                                        value={form.email}
                                                        onChange={(e) => handleChange(index, 'email', e.target.value)}
                                                    />
                                                    {errors[index]?.email && <div className="text-danger">{errors[index]?.email}</div>}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group mb-3">
                                                    <label><b>Title / Role</b></label>
                                                    <Input
                                                        placeholder="Enter Title/Role"
                                                        className={`js-example-disabled-results ${errors[index]?.role ? 'is-invalid' : ''}`}
                                                        name="role"
                                                        value={form.role}
                                                        onChange={(e) => handleChange(index, 'role', e.target.value)}
                                                    />
                                                    {errors[index]?.role && <div className="text-danger">{errors[index]?.role}</div>}
                                                </div>
                                            </div>
                                            <div className='col-md-4 m-2'>
                                                <label className="d-block" htmlFor={`chk-ani-${index}`}>
                                                    <input
                                                        className="checkbox_animated"
                                                        id={`chk-ani-${index}`}
                                                        type="checkbox"
                                                        checked={form.status === "1"}
                                                        onChange={(e) => handleChange(index, 'status', e.target.checked)}
                                                    />
                                                    {" "} Send invite email
                                                </label>
                                            </div>
                                            <div className="col-lg-12 col-md-12 text-end">

                                                <Button variant="success" onClick={() => handleSubmit(index)}>Save</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>

                        <span className="m-2">Adding users to paid roles beyond your current license count will increase your subscription cost. The card on file will be immediately charged the prorated difference. <b>Have multiple users? Add them now to prevent multiple charges on your credit card.</b></span>
                        <div className="card-footer text-end">
                            <Button type="button" onClick={addForm} className="btn btn-primary">
                                Add More People
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </MyLoader>
    );
};

export default Inviteteammatesmember;