import React, { useState, useEffect } from "react";
import { Container, Tab, Tabs, Dropdown } from "react-bootstrap";
import PeopleAnalysis from "./peopleanalysis";
import PeopleAssessment from "./peopleassessment";
import PeopleActions from "./peopleactions";

const Publicindex = () => {
    const [tabs] = useState([
        { id: 1, sub_category: 'People Analysis' },
        { id: 2, sub_category: 'People Assessment' },
        { id: 3, sub_category: 'People Actions' },
    ]);
    
    const [activeTab, setActiveTab] = useState('People Analysis');
    const [isMobile, setIsMobile] = useState(false);

    // Function to handle tab selection
    const handleTabSelect = (key) => {
        setActiveTab(key);
    };

    // Detect screen width
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Set breakpoint for mobile screens
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Function to render tab content based on selected tab
    const getTabContent = (tabName) => {
        switch (tabName) {
            case 'People Analysis':
                return <PeopleAnalysis activeTab={activeTab} />;
            case 'People Assessment':
                return <PeopleAssessment activeTab={activeTab} />;
            case 'People Actions':
                return <PeopleActions activeTab={activeTab} />;
            default:
                return null;
        }
    };

    return (
        <Container fluid>
            {isMobile ? (
                <>
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {activeTab}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {tabs.map((tab) => (
                                <Dropdown.Item
                                    key={tab.id}
                                    onClick={() => handleTabSelect(tab.sub_category)}
                                >
                                    {tab.sub_category}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    {getTabContent(activeTab)} {/* Render only once for mobile */}
                </>
            ) : (
                <Tabs
                    activeKey={activeTab}
                    onSelect={handleTabSelect}
                    id="justify-tab-example"
                    className="border-tab nav-primary mb-3"
                >
                    {tabs.map(tab => (
                        <Tab key={tab.id} eventKey={tab.sub_category} title={tab.sub_category}>
                            {getTabContent(tab.sub_category)} {/* Render content inside the Tabs */}
                        </Tab>
                    ))}
                </Tabs>
            )}
        </Container>
    );
};

export default Publicindex;
