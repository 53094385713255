import React, { useEffect } from "react";
import { Container, Row, Nav } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { FaArchive, FaHome, FaHornbill, FaMoneyBillWave, FaPlus } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isSubscribedAccountant } from "../../utils";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { MdOutlineSettings } from "react-icons/md";

const Sops = () => {
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        // centerMode: true,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false
                }
            }
        ]
    };


    const navigate = useNavigate()
    useEffect(() => {
      if (!isSubscribedAccountant()) {
        navigate("/users/billings");
        window.location.reload();
      }
    }, []);

    return (
        <Container fluid>
            <Row className="mx-2">
                <Slider {...settings}>
                    <div className="slider-item">

                        <Nav variant="pills" className="border-tab nav-primary">
                            <Nav.Item>
                                <Nav.Link as={NavLink} to="/sales" exact>
                                    <FaHome className="me-1" /> Sales
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="slider-item">

                        <Nav variant="pills" className="border-tab nav-primary">
                            <Nav.Item>
                                <Nav.Link as={NavLink} exact to="/founderos/finance">
                                    <FaMoneyBillWave className="me-1" /> Finance
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="slider-item">

                        <Nav variant="pills" className="border-tab nav-primary">
                            <Nav.Item>
                                <Nav.Link as={NavLink} exact to="/founderos/marketing">
                                    <FaMoneyBillTrendUp  className="me-1" /> Marketing
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="slider-item">

                        {/* <Nav variant="pills" className="border-tab nav-primary">
                            <Nav.Item>
                                <Nav.Link as={NavLink} exact to="/founderos/partnership">
                                    <FaArchive className="me-1" /> Partnerships
                                </Nav.Link>
                            </Nav.Item>
                        </Nav> */}
                    </div>
                    <div className="slider-item">

                        <Nav variant="pills" className="border-tab nav-primary">
                            <Nav.Item>
                                <Nav.Link as={NavLink} exact to="/founderos/founderosadmin">
                                    <FaHornbill  className="me-1" /> Admin
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="slider-item">

                        <Nav variant="pills" className="border-tab nav-primary">
                            <Nav.Item>
                                <Nav.Link as={NavLink} exact to="/founderos/operations">
                                    <MdOutlineSettings  className="me-1" /> Operations
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="slider-item">

                        <Nav variant="pills" className="border-tab nav-primary">
                            <Nav.Item>
                                <Nav.Link exact as={NavLink} to="/founderos/information-technology">
                                    <FaPlus className="me-1" /> Information Technology
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="slider-item">

                        <Nav variant="pills" className="border-tab nav-primary">
                            <Nav.Item>
                                <Nav.Link exact as={NavLink} to="/founderos/human-resources">
                                    <FaArchive className="me-1" /> Human Resources
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>

                </Slider>
            </Row>
        </Container>
    );
};

export default Sops;
