import React, { useEffect } from "react";
import { useState } from "react";
import { Container, Row, Nav } from "react-bootstrap";
import { FaFileContract, FaHome, FaMeetup, FaPaperPlane } from "react-icons/fa";
import { TbReportSearch } from "react-icons/tb";
import { NavLink, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isSubscribedAccountant } from "../../utils";

const IndexMeeting = () => {
    const [BasicLineTab, setBasicLineTab] = useState('1');
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        // centerMode: true,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false
                }
            }
        ]
    };


    const navigate = useNavigate()
    useEffect(() => {
      if (!isSubscribedAccountant()) {
        navigate("/users/billings");
        window.location.reload();
      }
    }, []);
    
    return (
        <Container fluid>
            <Row className="mx-3">
                <Slider {...settings}>
                    <div className="slider-item">
                        <Nav variant="pills" className="border-tab nav-primary ">
                            <Nav.Item>
                                <Nav.Link as={NavLink} to="/meeting/meetings" exact>
                                    <FaHome className="me-1" /> Meetings
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                  
                    <div className="slider-item">
                        <Nav variant="pills" className="border-tab nav-primary ">
                            <Nav.Item>
                                <Nav.Link as={NavLink} exact to="/meeting/agenda">
                                    <FaFileContract  className="me-1" /> Meeting Agenda Templates 
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    {/* <div className="slider-item">
                        <Nav variant="pills" className="border-tab nav-primary ">
                            <Nav.Item>
                                <Nav.Link as={NavLink} exact to="/meeting/meeting-type">
                                    <TbReportSearch className="me-1" /> Meeting Types
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div> */}
                </Slider>
            </Row>
        </Container>
    );
};

export default IndexMeeting;
