import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button } from 'react-bootstrap';
import API, { isSubscribedAccountant } from '../../utils';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import debounce from 'lodash.debounce';

const NoteFinance = () => {
    const [linkName, setLinkName] = useState('');
    const [document, seDocument] = useState('');
    const uid = localStorage.getItem("user_id");
    const [activeTab, setActiveTab] = useState(null);
    const [question, setQuestion] = useState(null);

    const [formData, setFormData] = useState({
        answer: '',
        user_links: [],
        documents: [],
        user_link_name: [],
        user_document_name: []
    });

    const [errors, setErrors] = useState({
        answer: '',
        user_links: '',
        documents: '',
        user_document_name: '',
    });

    const [newLink, setNewLink] = useState('');
    const [documentsByQuestion, setDocumentsByQuestion] = useState({});
    const [loading, setLoading] = useState(false);
    const [clickedLinkIndex, setClickedLinkIndex] = useState(null);
    
    useEffect(() => {
        fetchData();
    }, [uid, activeTab]);

    useEffect(() => {
        if (!isSubscribedAccountant()) {
            navigate("/users/userEdit");
            window.location.reload();
        }
    }, []);

    const fetchData = async () => {
        setLoading(true);
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('category_id', 3);

        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_department_notes_data_by_uid_qid}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === 'success') {
                const userLinks = Array.isArray(response.data.data.user_links)
                    ? response.data.data.user_links
                    : JSON.parse(response.data.data.user_links || '[]');

                const userLinkNames = Array.isArray(response.data.data.user_link_name)
                    ? response.data.data.user_link_name
                    : JSON.parse(response.data.data.user_link_name || '[]');

                const documents_name = Array.isArray(response.data.data.documents_name)
                    ? response.data.data.documents_name
                    : JSON.parse(response.data.data.documents_name || '[]');

                const documents = Array.isArray(response.data.data.documents)
                    ? response.data.data.documents
                    : [];

                setFormData(prevState => ({
                    ...prevState,
                    answer: response.data.data.answer || '',
                    user_links: userLinks,
                    user_link_name: userLinkNames,
                    documents: documents,
                    user_document_name: documents_name,
                }));

                setDocumentsByQuestion(prevState => ({
                    ...prevState,
                    [activeTab]: documents
                }));

                console.log('Documents fetched:', documents);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // toast.error('Error fetching data');
        } finally {
            setLoading(false);
        }
    };

    // Debounced API call function
    const debouncedSubmitAnswer = useCallback(
        debounce(async (answer) => {
            if (!validateAnswer(answer)) return;

            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            formDataToSend.append('category_id', 3);
            formDataToSend.append('answer', answer);

            try {
                const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_update_departments_notes}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );

                if (response.data.status === 'success') {
                    fetchData();
                    setErrors({});
                    // toast.success(response.data.message);
                } else {
                    setErrors(prevState => ({ ...prevState, answer: response.data.message }));
                }
            } catch (error) {
                console.error('Error updating answer:', error);
            }
        }, 2000),
        [uid, activeTab]
    );

    const handleChange = (e) => {
        const { name, value, files } = e.target;

        if (name === 'documents') {
            setFormData(prevState => ({
                ...prevState,
                documents: files ? Array.from(files) : []
            }));
        } else if (name === 'answer') {
            setFormData(prevState => ({
                ...prevState,
                answer: value
            }));

            // Call debounced function
            debouncedSubmitAnswer(value);
        }
    };

    const validateAnswer = (answer) => {
        if (!answer.trim()) {
            setErrors(prevState => ({ ...prevState, answer: 'This field is required' }));
            return false;
        }
        setErrors(prevState => ({ ...prevState, answer: '' })); // Clear error if valid
        return true;
    };

    const fileInputRef = useRef(null);

    const uploadDocuments = async () => {
        if (formData.documents.length === 0) {
            setErrors(prevState => ({ ...prevState, documents: "No documents selected for upload." }));
            return;
        }

        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('category_id', 3);
        // formData.documents.forEach((file, index) => {
        //     formDataToSend.append(`documents[${index}]`, file);
        // });

        formData.documents.forEach((file, index) => {
            formDataToSend.append(`documents[${index}]`, file);
            formDataToSend.append(`documents_name[${index}]`, document); // Add document name
        });

        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_update_documents}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.data.status === 'success') {
                setErrors({});
                setFormData(prevState => ({
                    ...prevState,
                    documents: [],
                    documents_name: ''
                }));
                window.location.reload();
                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                }
                fetchData(); // Fetch updated data
                // toast.success(response.data.message);
            } else {
                setErrors(prevState => ({ ...prevState, documents: response.data.message }));
            }
        } catch (error) {
            // console.error('Error uploading documents:', error);
        }
    };

    const handleAddLink = async () => {
        const link = newLink.trim();
        const name = linkName.trim();

        if (!link) {
            setErrors(prevState => ({ ...prevState, user_links: 'Link cannot be empty' }));
            return;
        }

        try {
            new URL(link);  // Validate URL
        } catch (e) {
            setErrors(prevState => ({ ...prevState, user_links: 'Invalid URL' }));
            return;
        }

        if (!name) {
            setErrors(prevState => ({ ...prevState, user_link_name: 'Link name cannot be empty' }));
            return;
        }

        const updatedLinks = [...formData.user_links, link];
        const updatedLinkNames = [...formData.user_link_name, name];

        setFormData(prevState => ({
            ...prevState,
            user_links: updatedLinks,
            user_link_name: updatedLinkNames
        }));

        setNewLink('');
        setLinkName('');

        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            formDataToSend.append('category_id', 3);
            formDataToSend.append('user_links', JSON.stringify(updatedLinks));
            formDataToSend.append('user_link_name', JSON.stringify(updatedLinkNames));

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_update_url_links}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === 'success') {
                const { user_links, user_link_name } = response.data.data;

                setFormData(prevState => ({
                    ...prevState,
                    user_links: JSON.parse(user_links),
                    user_link_name: JSON.parse(user_link_name),
                }));

                fetchData();
                setErrors({});
                // toast.success(response.data.message);
            } else {
                setErrors(prevState => ({ ...prevState, user_links: response.data.message }));
            }
        } catch (error) {
            console.error('Error adding link:', error);
        }
    };

    const navigate = useNavigate()
    useEffect(() => {
      if (!isSubscribedAccountant()) {
        navigate("/users/billings");
        window.location.reload();
      }
    }, []);

    return (
        <div className="card">
            <div className="card-header">
                <div className="row">
                    <div className="col-md-6 align-items-center d-flex">
                        <h5>Finance</h5>
                    </div>
                </div>
            </div>
            {loading ? (
                <div className="card-body">Loading...</div>
            ) : (
                <form>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-8 col-md-8">
                                <div className="form-group mb-3">
                                    <label className="form-label notes-labels">Notes</label>
                                    <textarea
                                        className={`form-control ${errors.answer ? 'is-invalid' : ''}`}
                                        rows={14}
                                        name="answer"
                                        value={formData.answer}
                                        onChange={handleChange}
                                    />
                                    {errors.answer && <div className="text-danger">{errors.answer}</div>}
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className="form-group mb-3">
                                    <label className="form-label notes-labels">Helpful Links</label>
                                    <div className="input-group mb-2">
                                        <input
                                            type="text"
                                            className={`form-control ${errors.user_links ? 'is-invalid' : ''}`}
                                            value={newLink}
                                            onChange={(e) => setNewLink(e.target.value)}
                                            placeholder="Enter link"
                                        />
                                    </div>
                                    {errors.user_links && <div className="text-danger">{errors.user_links}</div>}


                                    <input
                                        type="text"
                                        className={`form-control ${errors.user_link_name ? 'is-invalid' : ''}`}
                                        placeholder="Enter name for the link"
                                        value={linkName}
                                        onChange={(e) => setLinkName(e.target.value)}
                                    />

                                    {errors.user_link_name && <div className="text-danger">{errors.user_link_name}</div>}

                                    <Button type="button" className="mx-1 btn btn-primary mt-3" onClick={handleAddLink}>Add</Button>
                                    <ul className="list-unstyled">
                                        <p className="mt-2">Uploaded Helpful Links:</p>
                                        {formData.user_links.length > 0 && formData.user_link_name.length > 0 ? (
                                            formData.user_links.map((link, index) => (
                                                <li key={index}>
                                                    <Link
                                                        to="#"
                                                        onClick={() => window.open(link, '_blank')}
                                                        style={{ cursor: 'pointer', color: 'blue', fontSize: "16px" }}
                                                    >
                                                        {`${index + 1}. ${formData.user_link_name[index]}`}
                                                    </Link>
                                                </li>
                                            ))
                                        ) : (
                                            <div>No links available.</div>
                                        )}
                                    </ul>


                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label notes-labels">Useful Documents</label>
                                    <input
                                        className={`form-control ${errors.documents ? 'is-invalid' : ''}`}
                                        type="file"
                                        name="documents"
                                        onChange={handleChange}
                                        multiple
                                        ref={fileInputRef}
                                    />

                                    <input
                                        type="text"
                                        className="form-control mt-3"
                                        value={document}
                                        onChange={(e) => seDocument(e.target.value)}
                                        placeholder="Enter Document Name"
                                        ref={fileInputRef}
                                    />
                                    {errors.documents && <div className="text-danger">{errors.documents}</div>}
                                    <div className="text-end">
                                        <Button variant="primary" type="button" className="m-2" onClick={uploadDocuments}>Upload</Button>
                                    </div>
                                    <div className="mt-2">
                                        <div className="mt-2">
                                            <p>Uploaded Documents:</p>
                                            {Array.isArray(documentsByQuestion[activeTab]) && documentsByQuestion[activeTab].length > 0 ? (
                                                <ul>
                                                    {documentsByQuestion[activeTab].map((docUrl, index) => (
                                                        <li key={index}>
                                                            {/* Display the user_document_name if available */}
                                                            <Link to={docUrl} target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer', color: 'blue', fontSize: "16px" }}>
                                                                {formData.user_document_name[index] || `Document ${index + 1}`}

                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <div>No documents uploaded.</div>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </div>
    );
};

export default NoteFinance;
