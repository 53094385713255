import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { MdDelete } from 'react-icons/md';
import DataTable from 'react-data-table-component';
import { FaEye, FaRegEdit } from "react-icons/fa";
import DatePicker from "react-datepicker";
import API from "../../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import { debounce } from 'lodash';

const PACe = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const uid = localStorage.getItem("user_id");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const [formData, setFormData] = useState({
    kpis_process: "",
    name_of_process: "",
    person_accountable: "",
    // reason: ""
  });

  const [AffirmationEntries, setAffirmationEntries] = useState([]);

  useEffect(() => {
    fetchTasks();
  }, []);

  useEffect(() => {
    const isAnyFieldFilled = Object.values(formData).some(field => field.trim() !== '');
    setIsButtonDisabled(!isAnyFieldFilled);
  }, [formData]);

  const fetchTasks = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_all_process_accountability_chart}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === 'success') {
        // setAffirmationEntries(response.data.data);
        const sortedTasks = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setAffirmationEntries(sortedTasks);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Error during task fetching:', error);
    }
  };
  const [validationErrors, setValidationErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();

    // API Submission
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    formDataToSend.append('kpis_process', formData.kpis_process);
    formDataToSend.append('name_of_process', formData.name_of_process);
    formDataToSend.append('person_accountable', formData.person_accountable);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.add_process_accountability_chart}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        toast.success("KPI Added Successfully");
        fetchTasks();
        setFormData({
          kpis_process: "",
          name_of_process: "",
          person_accountable: "",
        });
        setIsPopupOpen(false);
        setIsButtonDisabled(true); // Disable the button again after successful submission
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error during task submission:", error);
    }
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Enable the button if at least one field is filled
    const isAnyFieldFilled = Object.values({ ...formData, [name]: value }).some(fieldValue => fieldValue.trim() !== "");
    setIsButtonDisabled(!isAnyFieldFilled);
  };




  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredEntries = AffirmationEntries.filter(entry =>
    entry.kpis_process.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [updatedTask, setUpdatedTask] = useState(null);

  const debouncedUpdateTask = useCallback(
    debounce(async (task) => {
      const formDataToSend = new FormData();
      formDataToSend.append('process_id', task.id);
      formDataToSend.append('field', task.field);
      formDataToSend.append('text', task.value);

      try {
        const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.update_process_accountability_chart}`,
          formDataToSend,
          {
            headers: {
              Authorization: `${API.AUTHORIZATION_TOKEN}`,
            },
          }
        );

        if (response.data.status === "success") {
          toast.success("KPI Updating Successfully");
          fetchTasks();
          // toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("An error occurred while updating data.");
      }
    }, 2000),
    []
  );

  const handleEditInputChange = (e, index, field, id) => {
    const { value } = e.target;
    const updatedEntries = [...AffirmationEntries];
    updatedEntries[index][field] = value;
    setUpdatedTask({ field, value, id });
  };

  useEffect(() => {
    if (updatedTask) {
      debouncedUpdateTask(updatedTask);
    }
  }, [updatedTask, debouncedUpdateTask]);

  const columns = [
    {
      name: 'Person Accountable',
      selector: row => row.person_accountable,
      cell: (row, index) => (
        <div className="businessroles-table">
          <input
            type="text"
            name="person_accountable"
            value={row.person_accountable}
            className="form-control"
            onChange={(e) => handleEditInputChange(e, index, "person_accountable", row.id)}

          />
        </div>
      ),

      sortable: true,
    },
    {
      name: 'Name of Process',
      selector: row => row.name_of_process,
      cell: (row, index) => (
        <div className="businessroles-table">

          <input
            type="text"
            name="name_of_process"
            value={row.name_of_process}
            className="form-control"
            onChange={(e) => handleEditInputChange(e, index, "name_of_process", row.id)}
          />
        </div>
      ),
      sortable: true,
    },
    {
      name: 'KPIs Better Faster Cheaper',
      selector: row => row.kpis_process,
      cell: (row, index) => (
        <div className="businessroles-table">
          <input
            type="text"
            value={row.kpis_process}
            name="kpis_process"
            className="form-control"
            onChange={(e) => handleEditInputChange(e, index, "kpis_process", row.id)}
          />
        </div>
      ),
      sortable: true,
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className=" align-items-center justify-content-between d-flex">
                  <h5>Goal Scorecard</h5>
                  {/* <Button className="btn-success main-buttons" onClick={togglePopup}>Add PACE</Button> */}
                </div>
              </div>
            </div>

            <div className="card-body">

              <div className="founderdriers mb-3">
                <p className="textnots-text">
                  <strong>Process Accountability Chart (PACE)</strong>
                </p>
                <p className="textnots-text">
                  <span className="textnote-span-one">1.</span>
                  kpis process 4 to 9 processes that drive your business
                </p>
                <p className="textnots-text">
                  <span className="textnote-span-one">2.</span>
                  Assign someone specific accountability for each process.
                </p>
                <p className="textnots-text">
                  <span className="textnote-span-one">3.</span>
                  List Key person accountable Indicators (KPIs) for each process (better, faster, cheaper).
                </p>

              </div>

              <form className="theme-form">
                <div className="row">
                <div className="form-group col-lg-4 col-md-6">
                    <label > <b>Name of Process</b></label>
                    <textarea
                      className={`form-control ${validationErrors.name_of_process ? 'is-invalid' : ''}`}
                      type="text"
                      name="name_of_process"
                      placeholder="Ente Name of Process."
                      value={formData.name_of_process}
                      onChange={handleInputChange}
                    />
                    {validationErrors.name_of_process && <div className="text-danger">{validationErrors.name_of_process}</div>}
                  </div>


                  <div className="form-group col-lg-4 col-md-6">
                    <label><b>KPIs Better Faster Cheaper</b></label>
                    <textarea
                      className={`form-control ${validationErrors.kpis_process ? 'is-invalid' : ''}`}
                      type="text"
                      name="kpis_process"
                      placeholder="Enter KPIs Better Faster Cheaper"
                      value={formData.kpis_process}
                      onChange={handleInputChange}
                    />
                    {validationErrors.kpis_process && <div className="text-danger">{validationErrors.kpis_process}</div>}
                  </div>

                  <div className="form-group col-lg-4 col-md-6">
                    <label><b>Person Accountable</b></label>
                    <textarea
                      className={`form-control ${validationErrors.person_accountable ? 'is-invalid' : ''}`}
                      type="text"
                      name="person_accountable"
                      placeholder="Enter Person Accountable."
                      value={formData.person_accountable}
                      onChange={handleInputChange}
                    />
                    {validationErrors.person_accountable && <div className="text-danger">{validationErrors.person_accountable}</div>}
                  </div>

                </div>
                <div className="d-flex justify-content-end align-content-center mb-2">
                  <Button variant="success" onClick={handleSubmit} disabled={isButtonDisabled}>Save</Button>
                </div>
              </form>
              <div className="row">
                <div className="col-md-9"></div>
                <div className="col-md-3">
                  <div className="form-group text-end mb-3 ">
                    <input className="form-control" type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchChange} />
                  </div>
                </div>
              </div>

              <DataTable
                columns={columns}
                data={filteredEntries}
                pagination
                persistTableHead={true}
                className="altimate-table"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PACe;
