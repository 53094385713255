import React, { useEffect } from "react";
import { Container, Row, Nav } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { FaArchive, FaBusinessTime, FaHome, FaPlus } from "react-icons/fa";
import { FaTruckPlane } from "react-icons/fa6";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isSubscribedAccountant } from "../../utils";

const Indexbusinessmodal = () => {
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false
                }
            }
        ]
    };

    const navigate = useNavigate()
    useEffect(() => {
      if (!isSubscribedAccountant()) {
        navigate("/users/billings");
        window.location.reload();
      }
    }, []);

    return (
        <Container fluid>
            <Row className="mx-3">
                {/* <Slider {...settings}> */}
                    <Nav variant="pills" className="border-tab nav-primary">
                        <Nav.Item>
                            <Nav.Link as={NavLink} to="/businessmodal/businessmodalcanvas" exact>
                                <FaBusinessTime  className="me-1" /> Business Model Canvas
                            </Nav.Link>
                        </Nav.Item>
        
                        {/* <Nav.Item>
                            <Nav.Link as={NavLink} exact to="/businessmodal/onepagepersonalplan">
                                <FaTruckPlane className="me-1" /> One-Page Personal Plan (OPPP)
                            </Nav.Link>
                        </Nav.Item> */}
                    </Nav>
                {/* </Slider> */}
            </Row>
        </Container>
    );
};

export default Indexbusinessmodal;
