import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import axios from 'axios';
import API from '../../../../../utils';
import { toast } from 'react-toastify';
import { FaCheck, FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const OperationsActions = () => {
    const actionsList = {
        "Operations Strategy": [
            { id: 1, name: "Perform a review of our end-to-end value chain (including expanding our supply base and exploring outsourcing options) to help maintain reliability and agility" },
            { id: 2, name: "Perform cost-benefit analysis of outsourcing/shared services centers" },
            { id: 3, name: "Re-align the operational strategy with (changed) product, service and channel requirements" },
            { id: 4, name: "Align operational strategy across functional silos and introduce key performance indicators/incentives accordingly" },
            { id: 5, name: "Simplify, automate, digitize overall operations and increase adaptability to support new digital business models by e.g. leveraging AI, RPA, blockchain technologies" },
            { id: 6, name: "Review current Operations strategy and verify that it fully considers geopolitical climate, talent, working capital, cost incentives and tax considerations" }
        ],
        "Operational Excellence and Continuous Improvement": [
            { id: 7, name: "Actively optimize our operating cost base" },
            { id: 8, name: "Improve our demand visibility by means of improved forecasting" },
            { id: 9, name: "Improve internal cross-functional collaboration and teaming" },
            { id: 10, name: "Create and empower a team to focus on continuous improvement" },
            { id: 11, name: "Seek greater collaboration with suppliers using integration techniques" },
            { id: 12, name: "Seek innovative ways to collaborate with customers to match service and product offers to changing customer needs and behavior" }
        ],
        "Operational Resilience and Sustainability": [
            { id: 13, name: "Develop full risk, resilience and sustainability strategy across the (extended) supply/value chain. Include outside, downside, and upside risks (risk taking required to realize the company’s growth and long-term value objectives)" },
            { id: 14, name: "Implement scenario planning based on different demand levels incl. impact on Operations requirements to maximize resilience and agility" },
            { id: 15, name: "Consider new sourcing models and alliances to build an inherently resilient and agile value chain" },
            { id: 16, name: "Enhance technology for full visibility of all partner/outsource activity" },
            { id: 17, name: "Define our corporate social responsibility goals and objectives" },
            { id: 18, name: "Review impact of operations against social responsibility criteria" },
            { id: 19, name: "Develop and implement a comprehensive corporate social responsibility program fully aligned with our Purpose" }
        ]
    };
    const [selectedActions, setSelectedActions] = useState([]);
    const [openCategory, setOpenCategory] = useState(null);
    const [checkedValue, setCheckedValue] = useState({});
    const uid = localStorage.getItem("user_id");

    const handleCategoryToggle = (category) => {
        setOpenCategory(openCategory === category ? null : category);
    };

    // Function to fetch data from API
    const fetchData = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_growth_operation}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.status === "success") {
                const jsonData = response.data.data.technology_action;

                if (jsonData) {
                    try {
                        const data = JSON.parse(jsonData);

                        const parseCategoryData = (categoryData) => {
                            return categoryData ? categoryData.split(',') : [];
                        };

                        const actions = {
                            "Operations Strategy": parseCategoryData(data.operation_strategy || ''),
                            "Operational Excellence and Continuous Improvement": parseCategoryData(data.excellence_continuous_improvement || ''),
                            "Operational Resilience and Sustainability": parseCategoryData(data.Resilience_Sustainability || ''),
                        };
                        setCheckedValue(actions);
                    } catch (error) {
                        console.error('Error parsing JSON:', error);
                    }
                } else {
                    console.error('No data found for technology_action.');
                }
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Fetch technology actions error', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Function to format selected actions for API
    const formatSelectedActions = (selectedActions) => {
        const categories = {
            "Operations Strategy": "operation_strategy[]",
            "Operational Excellence and Continuous Improvement": "excellence_continuous_improvement[]", 
            "Operational Resilience and Sustainability": "Resilience_Sustainability[]",

        };
    
        
        const formatted = Object.keys(categories).reduce((acc, category) => {
            const selectedData = selectedActions
                .filter(action => action.category === category)
                .map(action => {
                    const actionDetails = actionsList[category].find(a => a.id === action.id);
                    return actionDetails ? actionDetails.name : null;  
                })
                .filter(action => action !== null);
    
            acc[categories[category]] = selectedData;
            return acc;
        }, {});
    
    
        return formatted;
    };

  
    const handleUpdateActions = async (updatedActions) => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
    
            const formattedActions = formatSelectedActions(updatedActions);
    
            // Append formatted actions to FormData
            Object.entries(formattedActions).forEach(([key, value]) => {
                if (value.length > 0) {
                    value.forEach(item => formDataToSend.append(key, item));
                } else {
                    formDataToSend.append(key, '[]');  // To represent an empty array
                }
            });
    
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_update_operation_action}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Submit technology actions error', error);
        }
    };
    

    const handleAddToActions = async (category, action, index) => {
        try {
            const newCheckedValue = { ...checkedValue };
            const isSelected = newCheckedValue[category]?.includes(action.id);
            if (isSelected) {
                newCheckedValue[category] = newCheckedValue[category].filter(id => id !== action.id);
            } else {
                newCheckedValue[category] = [...(newCheckedValue[category] || []), action.id];
            }
            setCheckedValue(newCheckedValue);
    
            const updatedActions = Object.keys(newCheckedValue).flatMap(cat =>
                newCheckedValue[cat].map(id => ({ category: cat, id }))
            );
            setSelectedActions(updatedActions);
    
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
    
            const formattedActions = formatSelectedActions(updatedActions);
    
            Object.entries(formattedActions).forEach(([key, value]) => {
                if (value.length > 0) {
                    value.forEach(item => formDataToSend.append(key, item));
                } else {
                    formDataToSend.append(key, '[]');
                }
            });
    
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_update_operation_action}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            if (response.data.status === "success") {
                toast.success("Added to the task successfully");
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Submit technology actions error', error);
            toast.error("An error occurred while updating data.");
        }
    };

    const renderActions = (category) => {
        const categoryActions = actionsList[category] || [];

        return categoryActions.map((action, index) => (
            <Col xs={12} md={6} lg={3} key={action.id} className="mb-3">
                <div className="action-card p-3 border">
                    <p>{action.name}</p>
                    <Button
                        variant={checkedValue[category]?.includes(action.id) ? "success" : "primary"}
                        onClick={() => handleAddToActions(category, action, index)}
                        className="d-flex align-items-center mt-3 p-2"
                    >
                        {checkedValue[category]?.includes(action.id) ? <FaCheck className="me-2" /> : <FaPlus className="me-2" />}
                        {checkedValue[category]?.includes(action.id) ? "Selected" : "Add to Actions"}
                    </Button>
                </div>
            </Col>
        ));
    };

    return (
        <div className="col-sm-12">
            <div className="card mt-4">
                <div className="card-header">
                    <div className='justify-content-between  d-flex '>
                        <div className="">
                            <h5>Actions to Raise the Maturity Level for Operations</h5>
                        </div>
                        <div>
                            <Link className='backto-ondriver' to="/growthframeworks/founderdriver">Back to the Founder OS Drivers of Growth</Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="card-body">
                        <p>To help you achieve your goals we have created actions learned from our insights of working with market leaders. Add ones that you feel are best for your business.</p>
                        <Accordion defaultActiveKey="0" activeKey={openCategory}>
                            {Object.keys(actionsList).map(category => (
                                <Accordion.Item eventKey={category} key={category}>
                                    <Accordion.Header onClick={() => handleCategoryToggle(category)}>
                                        <div className='d-flex justify-content-between align-items-center w-100'>
                                            <h5 className='mb-0 fw-bold'>{category}</h5>
                                            <p className='mb-0 text-primary'>View Actions</p></div>

                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            {openCategory === category && renderActions(category)}
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OperationsActions;
