import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../../../utils";
import axios from "axios";

const Headlines = () => {
    const uid = localStorage.getItem("user_id");
    const [formData, setFormData] = useState({
        headlinesPlural: "",
        headlineSingular: "",
        
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
       
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('headune_singular', formData.headlinesPlural);
        formDataToSend.append('headlines_plural', formData.headlineSingular);
      
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.add_update_terminology_headlines}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error during task submission:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_all_terminology_headlines}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                const data = response.data.data[0];
                setFormData({
                    headlinesPlural: data.headlines_plural || "",
                    headlineSingular: data.headune_singular || "",
                   
                    
                });
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error during task fetching:', error);
        }
    };


    return (
        <>
          {/* Headlines */}
          <div className="card">
            <div className="card-header">
                <h5 className="card-title mb-0"><i className="fa fa-mixcloud mx-2"></i>Headlines</h5>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <div className="form-group mb-2">
                            <label className="col-sm-12 col-form-label"><b>Headüne (singular)</b></label>
                            <input
                                className={`form-control `}
                                type="text"
                                placeholder="Enter Headüne singular"
                                name="headlineSingular"
                                value={formData.headlineSingular}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                        <div className="form-group mb-2">
                            <label className="col-sm-12 col-form-label"><b>Headlines (Plural)</b></label>
                            <input
                                className={`form-control `}
                                type="text"
                                placeholder="Enter Headlines (Plural)"
                                name="headlinesPlural"
                                value={formData.headlinesPlural}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-end align-content-center">
                    <button type="submit" className="btn btn-success" onClick={handleSubmit}>Save</button>
                  </div>
                </div>
            </div>
        </div>

        </>
    );
};

export default Headlines;