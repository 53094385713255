import React, { useEffect } from "react";
import { Container, Row, Nav } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { FaArchive, FaHome, FaPlus, FaBeer, FaBusinessTime, FaFortAwesome } from "react-icons/fa";
import { TbReportSearch } from "react-icons/tb";
import { CiDiscount1 } from "react-icons/ci";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isSubscribedAccountant } from "../../utils";

const IndexCorporate = () => {
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false
                }
            }
        ]
    };

    const navigate = useNavigate()
    useEffect(() => {
        if (!isSubscribedAccountant()) {
            navigate("/users/billings");
            window.location.reload();
        }
    }, []);

    return (
        <Container fluid>
            <div className="mx-3">
                <Slider {...settings}>
                    <div className="slider-item">
                        <Nav variant="pills" className="border-tab nav-primary">
                            <Nav.Item>
                                <Nav.Link as={NavLink} to="/corporate/foundationstory" exact>
                                    <FaBusinessTime className="me-1" /> Foundation Story
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>

                    <div className="slider-item">

                        <Nav variant="pills" className="border-tab nav-primary">
                            <Nav.Item>
                                <Nav.Link as={NavLink} to="/corporate/corevalues" exact>
                                    <TbReportSearch className="me-1" />Core Values
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="slider-item">

                        <Nav variant="pills" className="border-tab nav-primary">
                            <Nav.Item>
                                <Nav.Link as={NavLink} to="/corporate/mission" exact>
                                    <FaArchive className="me-1" /> Mission
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="slider-item">

                        <Nav variant="pills" className="border-tab nav-primary">
                            <Nav.Item>
                                <Nav.Link as={NavLink} to="/corporate/foundationalpillars" exact>
                                    <FaFortAwesome className="me-1" /> Foundational Pillars
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="slider-item">

                        <Nav variant="pills" className="border-tab nav-primary">
                            <Nav.Item>
                                <Nav.Link as={NavLink} to="/corporate/productsservices" exact>
                                    <FaBeer className="me-1" /> Products & Services
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="slider-item">

                        <Nav variant="pills" className="border-tab nav-primary">
                            <Nav.Item>
                                <Nav.Link as={NavLink} to="/corporate/uniquevalueproposition" exact>
                                    <CiDiscount1 className="me-1" /> Unique Value Proposition
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="slider-item">
                        <Nav variant="pills" className="border-tab nav-primary">
                            <Nav.Item>
                                <Nav.Link as={NavLink} to="/corporate/vision" exact>
                                    <i className="fa fa-steam-square"></i> Vision
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>                
                     </div>


                     <div className="slider-item">

<Nav variant="pills" className="border-tab nav-primary">
    <Nav.Item>
        <Nav.Link as={NavLink} to="/corporate/branding-guidelines" exact>
            <i className="fa fa-steam-square"></i> Branding Guidelines
        </Nav.Link>
    </Nav.Item>
</Nav>                
</div>
<div className="slider-item">

<Nav variant="pills" className="border-tab nav-primary">
    <Nav.Item>
        <Nav.Link as={NavLink} to="/corporate/market-strategy" exact>
            <i className="fa fa-steam-square"></i> Go To Market Strategy
        </Nav.Link>
    </Nav.Item>
</Nav>                
</div>

                </Slider>
            </div>
        </Container>
    );
};

export default IndexCorporate;
