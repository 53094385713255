import React, { useEffect, useState } from "react";
import API from "../../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



const Todo = () => {
    const uid = localStorage.getItem("user_id");
    const [formData, setFormData] = useState({
        todosPagename: "",
        todosangutar: "",
        todosplural: "",
        todosTeam: "",
        todosPrivate: "",
        
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('page_name', formData.todosPagename);
        formDataToSend.append('to_do_sangutar', formData.todosangutar);
        formDataToSend.append('to_dos_plural', formData.todosplural);
        formDataToSend.append('team', formData.todosTeam);
        formDataToSend.append('private', formData.todosPrivate);
       

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.add_update_terminology_to_dos}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error during task submission:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_all_terminology_to_dos}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                const data = response.data.data[0];
                setFormData({
                    todosPagename: data.page_name || "",
                    todosangutar: data.to_do_sangutar || "",
                    todosplural: data.to_dos_plural || "",
                    todosTeam: data.team || "",
                    todosPrivate: data.private || "",
                });
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error during task fetching:', error);
        }
    };

    return (
        <>
         {/* To-Dos */}
         <div className="card">
            <div className="card-header">
                <h5 className="card-title mb-0"><i className="fa fa-check-square-o mx-2"></i>To-Dos</h5>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <div className="form-group mb-2">
                            <label className="col-sm-12 col-form-label"><b>Page Name</b></label>
                            <input
                                className={`form-control`}
                                type="text"
                                placeholder="Enter Page Name"
                                name="todosPagename"
                                value={formData.todosPagename}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                        <div className="form-group mb-2">
                            <label className="col-sm-12 col-form-label"><b>To-Do (Sangutar)</b></label>
                            <input
                                className={`form-control`}
                                type="text"
                                placeholder="Enter To-Do Sangutar"
                                name="todosangutar"
                                value={formData.todosangutar}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                        <div className="form-group mb-2">
                            <label className="col-sm-12 col-form-label"><b>To-Dos (Plural)</b></label>
                            <input
                                className={`form-control`}
                                type="text"
                                placeholder="Enter To-Do Sangutar"
                                name="todosplural"
                                value={formData.todosplural}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                        <div className="form-group mb-2">
                            <label className="col-sm-12 col-form-label"><b>Team</b></label>
                            <input
                                className={`form-control`}
                                type="text"
                                placeholder="Enter To-Do Team"
                                name="todosTeam"
                                value={formData.todosTeam}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                        <div className="form-group mb-2">
                            <label className="col-sm-12 col-form-label"><b>Private</b></label>
                            <input
                                className={`form-control`}
                                type="text"
                                placeholder="Enter To-Do Private"
                                name="todosPrivate"
                                value={formData.todosPrivate}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-end align-content-center">
                    <button type="submit" className="btn btn-success" onClick={handleSubmit}>Save</button>
                  </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default Todo;