import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
// import Licenses from "./licenses";
import API from "../../../../utils";
import axios from "axios";
import { toast } from 'react-toastify';
import { format } from 'date-fns';
// import RenewalModal from "../../../../cardDetailsmodal/renewModal";
// import MyLoader from "../../../common/myloader";
import { Navigate } from "react-router-dom";
import AddLicenseModal from "../licenceModal/addLicenseModal";
const Licences = () => {
  const [showRenewalModal, setShowRenewalModal] = useState(false);
  const [showAddLicenseModal, setShowAddLicenseModal] = useState(false); // For adding licenses
  const user_id = localStorage.getItem("user_id");
  const [isSubscriptionExpired, setIsSubscriptionExpired] = useState(false);
  const [expiryDate, setExpiryDate] = useState("");
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [loading, setLoading] = useState(false); // Define loading state
  const [licenses, setLicenses] = useState([]); // State to hold license data
  useEffect(() => {
    fetchLicenses();
  }, []);

  const fetchLicenses = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('user_id', user_id);

      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_licence}`, formDataToSend, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`
        },
      });

      if (response.data.status === "success") {
        setLicenses(response.data.data); 
      } else {
        //toast.error("Failed to fetch licenses.");
      }
    } catch (error) {
      console.error('Error fetching licenses:', error);
      // toast.error("An error occurred while fetching licenses.");
    }
  };
  const handleAddLicenseModalShow = () => setShowAddLicenseModal(true);
  const handleAddLicenseModalClose = () => setShowAddLicenseModal(false);

  return (
    <>
       <div className="mt-4">
              <h5>Licenses</h5>
              <ul>
                {licenses.map((license) => (
                  <li key={license.id}>
                   
                    <p>Transaction ID: {license.txn_id}</p>
                    <p>Amount: ${license.amount}</p>
                    <p>Created At: {format(new Date(license.created_at), 'MMMM d, yyyy')}</p>
                    <hr />
                  </li>
                ))}
              </ul>
              <button className="btn btn-secondary" onClick={handleAddLicenseModalShow}>
                Buy Additional Licenses
              </button>
            </div>

            {showAddLicenseModal && (
          <AddLicenseModal show={showAddLicenseModal} handleClose={handleAddLicenseModalClose} fetchData={fetchLicenses} />
        )}
          </>
  );
};

export default Licences;
