import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../../../utils";
import axios from "axios";


const Cascading = () => {
    const uid = localStorage.getItem("user_id");
    const [formData, setFormData] = useState({
        Cascadingmessagesingular:'',
        Cascadingmessageplural:'',
        CascadingmessageAction:'',
        CascadedPastTensel:'',
        
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
      
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('c_m_singular', formData.Cascadingmessagesingular);
        formDataToSend.append('c_m_plural', formData.Cascadingmessageplural);
        formDataToSend.append('c_m_action', formData.CascadingmessageAction);
        formDataToSend.append('cascaded_action_past_tensel', formData.CascadedPastTensel);
      
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.add_update_terminology_cascading_messages}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error during task submission:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_all_terminology_cascading_messages}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                const data = response.data.data[0];
                setFormData({
                    Cascadingmessagesingular: data.c_m_singular || "",
                    Cascadingmessageplural: data.c_m_plural || "",
                    CascadingmessageAction: data.c_m_action || "",
                    CascadedPastTensel: data.cascaded_action_past_tensel || "",
                   
                    
                });
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error during task fetching:', error);
        }
    };


    return (
        <>
          {/* Cascading Messages */}

          <div className="card">
            <div className="card-header">
                <h5 className="card-title mb-0"><i className="fa fa-envelope-o mx-2"></i>Cascading Messages</h5>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <div className="form-group mb-2">
                            <label className="col-sm-12 col-form-label"><b>Cascading Message (singular)</b></label>
                            <input
                                className={`form-control`}
                                type="text"
                                placeholder="Enter Cascading Message (singular)"
                                name="Cascadingmessagesingular"
                                value={formData.Cascadingmessagesingular}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                        <div className="form-group mb-2">
                            <label className="col-sm-12 col-form-label"><b>Cascading Messages (Plural)</b></label>
                            <input
                                className={`form-control `}
                                type="text"
                                placeholder="Enter Cascadingmessage (Plural)"
                                name="Cascadingmessageplural"
                                value={formData.Cascadingmessageplural}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                        <div className="form-group mb-2">
                            <label className="col-sm-12 col-form-label"><b>Cascading Messages (Action)</b></label>
                            <input
                                className={`form-control`}
                                type="text"
                                placeholder="Enter Cascading Message (Action)"
                                name="CascadingmessageAction"
                                value={formData.CascadingmessageAction}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                        <div className="form-group mb-2">
                            <label className="col-sm-12 col-form-label"><b>Cascaded (Action - Past Tensel)</b></label>
                            <input
                                className={`form-control`}
                                type="text"
                                placeholder="Enter Cascaded (Action - Past Tensel)"
                                name="CascadedPastTensel"
                                value={formData.CascadedPastTensel}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-end align-content-center">
                    <button type="submit" className="btn btn-success" onClick={handleSubmit}>Save</button>
                  </div>
                </div>
            </div>
        </div>

        </>
    );
};

export default Cascading;