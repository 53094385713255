import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { MdDelete } from 'react-icons/md';
import axios from "axios";
import { toast } from "react-toastify";
import API from '../../utils';
import _ from 'lodash';  // For debounce

const ContactuseprofilForms = () => {
    const user_id = localStorage.getItem('user_id');
    const primaryEmails = localStorage.getItem("email");
    
    const [additionalEmails, setAdditionalEmails] = useState([{ email: '', type: '' }]);
    const [primaryPhone, setPrimaryPhone] = useState('1234569870');
    const [additionalPhones, setAdditionalPhones] = useState(['']);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', user_id);
        
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_all_email_phone}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    },
                }
            );
    
            if (response.data.status === "success") {
                const data = response.data.data[0];
    
                // Update the additionalEmails state correctly
                const fetchedAdditionalEmails = data.additional_emails.map((email, index) => ({
                    email,
                    type: data.email_type[index] || ''
                })) || [{ email: '', type: '' }];
    
                setAdditionalEmails(fetchedAdditionalEmails);
                setPrimaryPhone(data.primary_phone || '1234567890');
                const additionalPhones = data.additional_phone.length > 0 
                    ? data.additional_phone 
                    : [''];
                setAdditionalPhones(additionalPhones);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
            // toast.error('An error occurred while fetching data.');
        }
    };
    

    

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const validatePhone = (phone) => {
        const re = /^[0-9]{10}$/;
        return re.test(String(phone));
    };

    const validateForm = () => {
        const newErrors = {};

        additionalEmails.forEach((emailData, index) => {
            if (!emailData.email || !validateEmail(emailData.email)) {
                newErrors[`additionalEmail${index}`] = 'Invalid email address';
            }
            if (!emailData.type) {
                newErrors[`additionalEmailType${index}`] = 'Email type is required';
            }
        });

        if (!primaryPhone || !validatePhone(primaryPhone)) {
            newErrors.primaryPhone = 'Invalid primary phone number';
        }

        additionalPhones.forEach((phone, index) => {
            if (!phone || !validatePhone(phone)) {
                newErrors[`additionalPhone${index}`] = 'Invalid phone number';
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const handleAddEmail = () => {
        setAdditionalEmails([...additionalEmails, { email: '', type: '' }]);
    };

 
    
    const handleAddPhone = () => {
        setAdditionalPhones([...additionalPhones, '']);
    };

    const handleRemovePhone = async (uid, value) => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            formDataToSend.append('additional_phone', value); // Send the correct phone number

            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.personal_contact}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData(); // Call function to refresh the data after successful removal
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("An error occurred while deleting the data.");
            console.error(error);
        }
    };

    const handleAPIUpdate = _.debounce(async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', user_id);
        formDataToSend.append('primary_email', primaryEmails); // Add primary email here
        formDataToSend.append('primary_phone', primaryPhone); // Add primary email here
    
        additionalEmails.forEach((emailData, index) => {
            formDataToSend.append(`additional_emails[${index}]`, emailData.email);
            formDataToSend.append(`email_type[${index}]`, emailData.type);
        });
    
        additionalPhones.forEach((phone, index) => {
            formDataToSend.append(`additional_phone[${index}]`, phone);
        });
    
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.add_contact}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        'Content-Type': 'multipart/form-data', // Ensure this is set properly
                    },
                }
            );
    
            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            // toast.error('An error occurred while submitting the form.');
        }
    }, 1000);
    
    const handleRemoveEmail = async (uid, value, type) => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            formDataToSend.append('additional_emails', value);
            formDataToSend.append('email_type', type);
    
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.personal_contact_email}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("An error occurred while deleting the data.");
            console.error(error);
        }
    };

    const handleEmailChange = (index, field, value) => {
        const newEmails = [...additionalEmails];
        newEmails[index][field] = value;
        setAdditionalEmails(newEmails);
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors[`additionalEmail${index}`];
            delete newErrors[`additionalEmailType${index}`];
            return newErrors;
        });
    };

    const handleEmailBlur = () => {
        if (validateForm()) {
            handleAPIUpdate({ additionalEmails, additionalPhones });
        }
    };

    const handlePhoneChange = (index, value) => {
        const newPhones = [...additionalPhones];
        newPhones[index] = value;
        setAdditionalPhones(newPhones);
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors[`additionalPhone${index}`];
            return newErrors;
        });
    };

    const handlePhoneBlur = () => {
        if (validateForm()) {
            handleAPIUpdate({ additionalEmails, additionalPhones });
        }
    };

    return (
        <form>
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title mb-0">Email</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="form-group mb-3">
                                <label className="form-label">Primary Email</label>
                                <input
                                    className="form-control"
                                    type="email"
                                    value={primaryEmails}
                               
                                />
                            </div>
                        </div>
                        {additionalEmails.map((emailData, index) => (
                            <div key={index} className="row">
                                <div className="col-md-5 col-sm-12 col-md-3">
                                    <div className="form-group mb-3">
                                        <label className="form-label">Additional Email</label>
                                        <input
                                            className={`form-control ${errors[`additionalEmail${index}`] ? 'is-invalid' : ''}`}
                                            type="email"
                                            placeholder='Add Email'
                                            value={emailData.email}
                                            onChange={(e) => handleEmailChange(index, 'email', e.target.value)}
                                            onBlur={handleEmailBlur}
                                        />
                                        {errors[`additionalEmail${index}`] && <div className="text-danger">{errors[`additionalEmail${index}`]}</div>}
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="form-group mb-3">
                                        <label>Email Type</label>
                                        <select
                                            className={`form-control ${errors[`additionalEmailType${index}`] ? 'is-invalid' : ''}`}
                                            value={emailData.type}
                                            onChange={(e) => handleEmailChange(index, 'type', e.target.value)}
                                            onBlur={handleEmailBlur}
                                        >
                                            <option value="">Choose Option</option>
                                            <option value="Business">Business</option>
                                            <option value="Personal">Personal</option>
                                            <option value="Other">Other</option>
                                        </select>
                                        {errors[`additionalEmailType${index}`] && <div className="text-danger">{errors[`additionalEmailType${index}`]}</div>}
                                    </div>
                                </div>
                                <div className="col-md-2 d-flex mt-3 align-items-center">
                                    <button
                                        type="button"
                                        class="btn-danger px-3"
                                        onClick={() => handleRemoveEmail(user_id, emailData.email, emailData.type)}
                                    >
                                        <MdDelete className='contactform-svg' />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="card-footer text-end">
                    <Button type="button" className='me-2' onClick={handleAddEmail}>Add More Emails</Button>
                </div>
            </div>

            <div className="card mt-3">
                <div className="card-header">
                    <h5 className="card-title mb-0">Phone Number</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-5 col-sm-12 col-md-3">
                            <div className="form-group mb-3">
                                <label className="form-label">Primary Phone</label>
                                <input
                                    className={`form-control ${errors.primaryPhone ? 'is-invalid' : ''}`}
                                    type="text"
                                    value={primaryPhone}
                                    onChange={(e) => setPrimaryPhone(e.target.value)}
                                    onBlur={handlePhoneBlur}
                                />
                                {errors.primaryPhone && <div className="text-danger">{errors.primaryPhone}</div>}
                            </div>
                        </div>
                        {additionalPhones.map((phone, index) => (
                            <div key={index} className="row">
                                <div className="col-md-5 col-sm-12 col-md-3">
                                    <div className="form-group mb-3">
                                        <label className="form-label">Additional Phone</label>
                                        <input
                                            className={`form-control ${errors[`additionalPhone${index}`] ? 'is-invalid' : ''}`}
                                            type="text"
                                            value={phone}
                                            onChange={(e) => handlePhoneChange(index, e.target.value)}
                                            onBlur={handlePhoneBlur}
                                        />
                                        {errors[`additionalPhone${index}`] && <div className="text-danger">{errors[`additionalPhone${index}`]}</div>}
                                    </div>
                                </div>
                                <div className="col-md-2 d-flex align-items-center">
                                        <button
                                            type="button"
                                            class="btn-danger"
                                            onClick={() => handleRemovePhone(user_id, phone)}
                                        >
                                            <MdDelete className='contactform-svg' />
                                        </button>
                                    </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="card-footer text-end">
                    <Button type="button" className='me-2' onClick={handleAddPhone}>Add More Phone</Button>
                </div>
            </div>
        </form>
    );
};

export default ContactuseprofilForms;
