import React, { useState, useEffect, useCallback } from "react";
import { Button } from 'react-bootstrap';
import moment from 'moment-timezone';
import axios from 'axios';
import API, { isSubscribedAccountant } from '../utils';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import debounce from 'lodash/debounce';

const NewCompany = () => {
    const uid = localStorage.getItem("user_id");
    const [formData, setFormData] = useState({
        mobilenumber: '',
        cname: '',
        country: '',
        state: '',
        zipcode: '',
        timezone: '',
        address: '',
    });
    const [gratitudeEntries, setGratitudeEntries] = useState([]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleTimezoneChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            timezone: e.target.value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('company_phone_number', formData.mobilenumber);
        formDataToSend.append('company_name', formData.cname);
        formDataToSend.append('country', formData.country);
        formDataToSend.append('state', formData.state);
        formDataToSend.append('zip_code', formData.zipcode);
        formDataToSend.append('timezone', formData.timezone);
        formDataToSend.append('company_address', formData.address);

        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_update_new_company}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success("Company Added Successfully");
                setFormData({
                    mobilenumber: '',
                    cname: '',
                    country: '',
                    state: '',
                    zipcode: '',
                    timezone: '',
                    address: '',
                });
                fetchTasks();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error during task submission:", error);
            toast.error("An error occurred while submitting the task.");
        }
    };

    const [searchQuery, setSearchQuery] = useState("");

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredEntries = (gratitudeEntries || []).filter(entry =>
        (entry.cname || "").toLowerCase().includes((searchQuery || "").toLowerCase()) ||
        (entry.country || "").toLowerCase().includes((searchQuery || "").toLowerCase()) ||
        (entry.mobilenumber || "").toLowerCase().includes((searchQuery || "").toLowerCase()) ||
        (entry.state || "").toLowerCase().includes((searchQuery || "").toLowerCase()) ||
        (entry.zipcode || "").toLowerCase().includes((searchQuery || "").toLowerCase())
    );

    useEffect(() => {
        fetchTasks();
    }, []);

    const fetchTasks = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_all_new_company}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );
            if (response.data.status === 'success') {
                // const tasksWithParsedAgenda = response.data.tasks.map(task => ({
                //     ...task,
                // }));

                // setGratitudeEntries(response.data.data);
                const sortedTasks = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setGratitudeEntries(sortedTasks);

            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error during task fetching:', error);
        }
    };

    // Get list of time zones
    const timezones = moment.tz.names();

    const navigate = useNavigate();
    useEffect(() => {
        if (!isSubscribedAccountant()) {
            navigate("/users/billings");
            window.location.reload();
        }
    }, []);

    const [updatedTask, setUpdatedTask] = useState(null);

    const debouncedUpdateTask = useCallback(
        debounce(async (task) => {
            const formDataToSend = new FormData();
            formDataToSend.append('id', task.id);
            formDataToSend.append('filed', task.field);
            formDataToSend.append('text', task.value);

            try {
                const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.update_new_company}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );

                if (response.data.status === "success") {
                    fetchTasks();
                    toast.success("Company Updating Successfully");
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                toast.error("An error occurred while updating data.");
            }
        }, 2000),
        []
    );

    const handleEditInputChange = (e, index, field, id) => {
        const { value } = e.target;
        const updatedOutcomes = [...gratitudeEntries];
        updatedOutcomes[index][field] = value;

        setUpdatedTask({ field, value, id });
    };

    useEffect(() => {
        if (updatedTask) {
            debouncedUpdateTask(updatedTask);
        }
    }, [updatedTask, debouncedUpdateTask]);

    const columns = [
        {
            name: 'Company Name',
            selector: row => row.company_name,
            cell: (row, index) =>
            (
                <div className="meeting-name">
                    <input
                        type="text"
                        value={row.company_name}
                        className="form-control"
                        onChange={(e) => handleEditInputChange(e, index, "company_name", row.id)}
                    />
                </div>
            ), sortable: true
        },
        {
            name: 'Address',
            selector: row => row.address,
            cell: (row, index) =>
            (
                <div className="meeting-name">
                    <input
                        type="text"
                        value={row.company_address}
                        className="form-control"
                        onChange={(e) => handleEditInputChange(e, index, "company_address", row.id)}
                    />
                </div>
            ), sortable: true
        },
        {
            name: 'State',
            selector: row => row.state,
            cell: (row, index) =>
            (
                <div className="meeting-name">
                    <input
                        type="text"
                        value={row.state}
                        className="form-control"
                        onChange={(e) => handleEditInputChange(e, index, "state", row.id)}
                    />
                </div>
            ), sortable: true
        },
        {
            name: 'Country',
            selector: row => row.country,
            cell: (row, index) =>
            (
                <div className="meeting-name">
                    <input
                        type="text"
                        value={row.country}
                        className="form-control"
                        onChange={(e) => handleEditInputChange(e, index, "country", row.id)}
                    />
                </div>
            ), sortable: true
        },

        {
            name: 'Zip Code',
            selector: row => row.zip_code,
            cell: (row, index) =>
            (
                <div className="meeting-name">
                    <input
                        type="text"
                        value={row.zip_code}
                        className="form-control"
                        onChange={(e) => handleEditInputChange(e, index, "zip_code", row.id)}
                    />
                </div>
            ), sortable: true
        },
        {
            name: 'Company Phone Number',
            selector: row => row.company_phone_number,
            cell: (row, index) =>
            (
                <div className="meeting-name">
                    <input
                        type="text"
                        value={row.company_phone_number}
                        className="form-control"
                        onChange={(e) => handleEditInputChange(e, index, "company_phone_number", row.id)}
                    />
                </div>
            ), sortable: true
        },
        {
            name: 'Time Zone',
            selector: row => row.timezone,
            cell: (row, index) =>
            (
                <div className="meeting-Status">
                    <select
                        type="text"
                        value={row.timezone}
                        className="form-control"
                        onChange={(e) => handleEditInputChange(e, index, "timezone", row.id)}
                    >
                        <option value="">Select Time Zone</option>
                        {timezones.map((tz) => (
                            <option key={tz} value={tz}>
                                {tz}
                            </option>
                        ))}
                    </select>
                </div>
            ), sortable: true
        },
    ];

    const isSaveButtonEnabled = () => formData.cname?.trim() !== '';

    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-6 align-items-center d-flex">
                                <h5>Name of the New Company</h5>
                            </div>
                        </div>
                    </div>
                    <form>
                        <div className="card-body">
                            <div className="row">
                                <div className='row m-0 p-0'>
                                    <div className="col-lg-6 col-md-6 ">
                                        <div className="form-group mb-3">
                                            <label className="form-label"><b>Company Name</b></label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="cname"
                                                placeholder='Enter a Company Name'
                                                value={formData.cname}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group mb-3">
                                            <label className="form-label"><b>Company Address</b></label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder='Enter Company Address'
                                                name="address"
                                                value={formData.address}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group mb-3">
                                        <label><b>State</b></label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="state"
                                            placeholder='Enter State'
                                            value={formData.state}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group mb-3">
                                        <label><b>Country</b></label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="country"
                                            placeholder='Enter Country'
                                            value={formData.country}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group mb-3">
                                        <label className="form-label"><b>Zip Code</b></label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="zipcode"
                                            placeholder='Enter a Zip Code'
                                            value={formData.zipcode}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group mb-3">
                                        <label className="form-label"><b>Company Phone Number</b></label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder='Enter a Mobile Number'
                                            name="mobilenumber"
                                            value={formData.mobilenumber}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group mb-3">
                                        <label className="form-label"><b>TimeZone</b></label>
                                        <select
                                            className="form-control"
                                            name="timezone"
                                            value={formData.timezone}
                                            onChange={handleTimezoneChange}
                                        >
                                            <option value="">Select Time Zone</option>
                                            {timezones.map((tz) => (
                                                <option key={tz} value={tz}>
                                                    {tz}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer text-end">
                                <Button variant="success" type="submit" className="mx-1" onClick={handleSubmit} disabled={!isSaveButtonEnabled()}>Add</Button>
                            </div>
                            <div>
                                <div className='mb-2 d-flex justify-content-end'>
                                    <div className="form-group mb-2 text-end  ">
                                        <input className="form-control" type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchChange} />
                                    </div>
                                </div>
                                <DataTable
                                    columns={columns}
                                    data={filteredEntries}
                                    pagination
                                    persistTableHead={true}
                                    className='keyrelationship-table'
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default NewCompany;
