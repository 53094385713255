import React, { useEffect, useState } from "react";
import { Container, Dropdown, Tab, Tabs } from "react-bootstrap";
import FinanceAnalysis from "./financeanalysis";
import FinanceAssessment from "./financeassessment";
import FinanceActions from "./financeaction";

const Financeindex = () => {
    const [tabs] = useState([
        { id: 1, sub_category: 'Finance Analysis' },
        { id: 2, sub_category: 'Finance Assessment' },
        { id: 3, sub_category: 'Finance Actions' },
    ]);

    const [activeTab, setActiveTab] = useState('Finance Analysis');
    const [isMobile, setIsMobile] = useState(false);

    // Function to handle tab selection
    const handleTabSelect = (key) => {
        setActiveTab(key); // Update active tab state
    };

    // Function to render tab content based on selected tab
    const getTabContent = (tabName, activeTab) => {
        switch (tabName) {
            case 'Finance Analysis':
                return <FinanceAnalysis activeTab={activeTab} />;
            case 'Finance Assessment':
                return <FinanceAssessment activeTab={activeTab} />;
            case 'Finance Actions':
                return <FinanceActions activeTab={activeTab} />;
            default:
                return null;
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Set breakpoint for mobile screens
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    
    return (
        <Container fluid>
        {isMobile ? (
            <>
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {activeTab}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {tabs.map((tab) => (
                            <Dropdown.Item
                                key={tab.id}
                                onClick={() => handleTabSelect(tab.sub_category)}
                            >
                                {tab.sub_category}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
                {getTabContent(activeTab)} {/* Render only once for mobile */}
            </>
        ) : (
            <Tabs
                activeKey={activeTab}
                onSelect={handleTabSelect}
                id="justify-tab-example"
                className="border-tab nav-primary mb-3"
            >
                {tabs.map(tab => (
                    <Tab key={tab.id} eventKey={tab.sub_category} title={tab.sub_category}>
                        {getTabContent(tab.sub_category)} {/* Render content inside the Tabs */}
                    </Tab>
                ))}
            </Tabs>
        )}
    </Container>
    );
};

export default Financeindex;
