import React, { useEffect } from "react";
import { Container, Nav, Row } from "react-bootstrap";
import { FaArchive, FaHome, FaPlus } from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isSubscribedAccountant } from "../../utils";

const Indexmytask = () => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // centerMode: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  };

  const navigate = useNavigate()
  useEffect(() => {
    if (!isSubscribedAccountant()) {
      navigate("/users/billings");
      window.location.reload();
    }
  }, []);


  return (
    <Container fluid>
      <Row className="mx-2">
        <Slider {...settings}>
          <div className="slider-item">
            <Nav variant="pills" className="border-tab nav-primary">
              <Nav.Item>
                <Nav.Link as={NavLink} to="/my-task/task" exact>
                  <FaHome className="me-1" />
                  My Tasks
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>

          <div className="slider-item">
            <Nav variant="pills" className="border-tab nav-primary">
              <Nav.Item>
                <Nav.Link as={NavLink} exact to="/my-task/power-hour-planner">
                  <FaPlus className="me-1" />
                  Power Hour Planner
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>

          <div className="slider-item">
            <Nav variant="pills" className="border-tab nav-primary">
              <Nav.Item>
                <Nav.Link as={NavLink} exact to="/my-task/achievments">
                  <FaArchive className="me-1" />
                  Achievements
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>

          {/* <div className="slider-item">
            <Nav variant="pills" className="border-tab nav-primary">
              <Nav.Item>
                <Nav.Link as={NavLink} exact to="/my-task/continuous-learning">
                  <FaPlus className="me-1" />
                  Continuous Learning
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div> */}
        </Slider>
      </Row>
    </Container>
  );
};

export default Indexmytask;
