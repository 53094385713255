import React, { useState, useEffect } from "react";
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, Tab, TabContent, TabPane } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import HelpCenter from "./helpcenter";
import ContactForm from "./contactform";
import LiveTraining from "./livetraining";
import { FaHome, FaPlus } from "react-icons/fa";
import { isSubscribedAccountant } from "../../utils";
import { TbReportSearch } from "react-icons/tb";


const Indexsupport = () => {
    const [activeTab, setActiveTab] = useState();
    const [VerticleTab, setVerticleTab] = useState('2');
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const navigate = useNavigate()
    useEffect(() => {
      if (!isSubscribedAccountant()) {
        navigate("/users/billings");
        window.location.reload();
      }
    }, []);
    return (
        <>
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">

                    

                        <Nav variant="pills" className="border-tab nav-primary">
                   

                    <Nav.Item>
                        <Nav.Link as={NavLink} to="/customerSupport/frequentlyAskedQuestion" exact>
                            <TbReportSearch className="me-1" /> Frequently Asked Questions
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={NavLink} to="/customerSupport/foudersosUniversity" exact>
                            <TbReportSearch className="me-1" /> Founders OS University
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={NavLink} to="/customerSupport/contactSupport" exact>
                            <FaHome className="me-1" />Contact Support
                        </Nav.Link>
                    </Nav.Item>
                    
                </Nav>


                           
                      
                       
                       
                    </div>
                </div>
            </div >
        </>
    );
};

export default Indexsupport;
