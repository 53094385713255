import React, { useEffect, useState, useRef } from "react";
import API from "../../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaPlus } from "react-icons/fa";
import { Modal, ModalHeader, ModalBody, Button, FormGroup, Label, Input, ModalFooter } from 'reactstrap';
import { MdDelete } from "react-icons/md";

const CompanyInformation = () => {
    const [formData, setFormData] = useState([]); // List of companies
    const [modalFormData, setModalFormData] = useState({
        companyName: "",
        companyLogo: null,
        companyNumber: "",
        industry: "",
        employees: ""
    }); // Separate state for modal form data
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const fileInputRef = useRef([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', localStorage.getItem('user_id'));

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_company_info}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    },
                }
            );
            if (response.data.status === "success") {
                const companiesData = response.data.data.map(company => ({
                    id: company.id,
                    Company: company.company_name || '',
                    Phonen: company.number || '',
                    Image: company.image || null,
                    employees: company.employees,
                    industry: company.industry
                }));
                setFormData(companiesData);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleChange = async (e, index) => {
        const { name, value, files } = e.target;
    
        const updatedCompanies = [...formData];
        if (name === "Image" && files.length > 0) {
            updatedCompanies[index] = {
                ...updatedCompanies[index],
                [name]: files[0]
            };
        } else {
            updatedCompanies[index] = {
                ...updatedCompanies[index],
                [name]: value
            };
        }
    
        setFormData(updatedCompanies);
    
        // Immediately call the update function without debounce
        const companyId = updatedCompanies[index].id;
        await updateCompanyInfo(updatedCompanies[index], companyId);
    };

    const updateCompanyInfo = async (data, companyId) => {
        const formDataToSend = new FormData();
        formDataToSend.append('id', companyId);
        formDataToSend.append('company_name', data.Company);
        formDataToSend.append('number', data.Phonen);
        formDataToSend.append('employees', data.employees);
        formDataToSend.append('industry', data.industry);
    
        if (data.Image) {
            formDataToSend.append('image', data.Image);
        }
    
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.updated_new_company}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    },
                }
            );
    
            if (response.data.status === "success") {
               // toast.success("Company information updated successfully!");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
            toast.error("Failed to update company information.");
        }
    };

    const handleImageClick = (index) => {
        if (fileInputRef.current[index]) {
            fileInputRef.current[index].click();
        }
    };

    const [companyModalOpen, setCompanyModalOpen] = useState(false);

    const toggleCompanyModal = () => {
        setCompanyModalOpen(!companyModalOpen);
        
    };

    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const user_id = localStorage.getItem('user_id');

    const handleFileChange = (e) => {
        setModalFormData({ ...modalFormData, companyLogo: e.target.files[0] });
        if (submitted) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                companyLogo: ""
            }));
        }
    };

    const handleModalChange = (e) => {
        const { name, value } = e.target;
        setModalFormData({ ...modalFormData, [name]: value });

        if (submitted) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: ""
            }));
        }
    };

    const handleToggle = () => {
        setErrors({});
        setModalFormData({
            companyName: "",
            companyLogo: null,
            companyNumber: "",
            industry: "",
            employees: ""
        });
        toggleCompanyModal();
    };

    const handleCompanySubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true); // Set submitted state to true

        const validationErrors = {};

        if (modalFormData.companyName.trim() === "") {
            validationErrors.companyName = "This Field Is Required";
        }
        if (modalFormData.industry.trim() === "") {
            validationErrors.industry = "This Field Is Required";
        }
        if (modalFormData.employees.trim() === "") {
            validationErrors.employees = "This Field Is Required";
        }
        if (!modalFormData.companyLogo) {
            validationErrors.companyLogo = "This Field Is Required";
        }
        if (modalFormData.companyNumber.trim() === "") {
            validationErrors.companyNumber = "This Field Is Required";
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        const form = new FormData();
        form.append('company_name', modalFormData.companyName);
        form.append('image', modalFormData.companyLogo);
        form.append('number', modalFormData.companyNumber);
        form.append('industry', modalFormData.industry);
        form.append('employees', modalFormData.employees);
        form.append('user_id', user_id);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.add_new_company}`,
                form,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success("Company information Added successfully!");
                setModalFormData({
                    companyName: "",
                    companyLogo: null,
                    companyNumber: "",
                    industry: "",
                    employees: ""
                });
                fetchData();
                handleToggle();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            toast.error('An error occurred while submitting the form.');
        }
    };
    const deleteCompany = async (companyId) => {
        const formDataToSend = new FormData();
        formDataToSend.append('id', companyId);
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.delete_company}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );
    
            if (response.data.status === "success") {
                toast.success("Company deleted successfully!");
                fetchData(); // Refresh the list after deletion
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error deleting company:", error);
            toast.error("Failed to delete company.");
        }
    };
    
    return (
        <>
            <form>
                <div className="card">
                    <div className="card-header">
                        <div className='justify-content-between d-flex align-items-center'>
                            <div className="">
                                <h5>Company Information</h5>
                            </div>
                            <div>
                                <button className=" " type="button" onClick={toggleCompanyModal}>
                                    <FaPlus className="me-2" /> Create New Company
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {formData.length > 0 ? (
                            formData.map((company, index) => (
                                <React.Fragment key={index}>
                                    <div className="col-lg-4">
                                        <img
                                            className="profile-image mt-4 mx-3 mx-lg-4 p-l-10 rounded-circle"
                                            alt={company.Company}
                                            src={
                                                company.Image
                                            }
                                        />
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="form-group col-lg-6 col-md-12 mb-3">
                                                    <label className="form-label">Company Logo</label>
                                                    <input
                                                        className="form-control"
                                                        type="file"
                                                        name="Image"
                                                        accept="image/*"
                                                        onChange={(e) => handleChange(e, index)}
                                                        ref={(el) => (fileInputRef.current[index] = el)}
                                                    />
                                                </div>
                                                <div className="form-group col-lg-6 col-md-12 mb-3">
                                                    <label className="form-label">Company Name</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Enter A Company Name"
                                                        name="Company"
                                                        value={company.Company}
                                                        onChange={(e) => handleChange(e, index)}
                                                    />
                                                </div>
                                                <div className="form-group col-lg-6 col-md-12 mb-3">
                                                    <label className="form-label">Company EIN</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Enter A Company EIN"
                                                        name="Phonen"
                                                        value={company.Phonen}
                                                        onChange={(e) => handleChange(e, index)}
                                                    />
                                                </div>
                                                <div className="form-group col-lg-6 col-md-12 mb-3">
                                                    <label className="form-label">Industry</label>
                                                    <select
                                                        className="form-control"
                                                        name="industry"
                                                        value={company.industry}
                                                        onChange={(e) => handleChange(e, index)}
                                                    >
                                                        <option value="Information Technology and Services">Information Technology and Services</option>
                                <option value="Financial Services">Financial Services</option>
                                <option value="Healthcare">Healthcare</option>
                                <option value="Education">Education</option>

                                <option value="Marketing and Advertising">Marketing and Advertising</option>
                                <option value="Manufacturing">Manufacturing</option>
                                <option value="Retail">Retail</option>
                                <option value="Telecommunications">Telecommunications</option>

                                <option value="Real Estate">Real Estate</option>
                                <option value="Nonprofit Organization Management">Nonprofit Organization Management</option> 
                                <option value="Automotive">Automotive</option>
                                <option value="Consumer Goods">Consumer Goods</option>
                                <option value="Fitness and Wellness">Fitness and Wellness</option>
                                <option value="Staffing and Recruiting">Staffing and Recruiting</option>
                                <option value="Events Services">Events Services</option>
                                <option value="Law Practice">Law Practice</option>
                                <option value="Motion Pictures and Film">Motion Pictures and Film</option>
                                <option value="Photography">Photography</option>
                                <option value="Professional Training and Coaching">Professional Training and Coaching</option>
                                <option value="Restaurants">Restaurants</option>
                                <option value="Retail">Retail</option>

                                                    </select>
                                                </div>
                                                <div className="form-group col-lg-6 col-md-12 mb-3">
                                                    <label className="form-label">Employees</label>
                                                    <select
                                                        className="form-control"
                                                        name="employees"
                                                        value={company.employees}
                                                        onChange={(e) => handleChange(e, index)}
                                                    >
                                                        <option value="">Choose Option</option>
                                                        <option value="1-10">1-10</option>
                                                        <option value="11-50">11-50</option>
                                                        <option value="51-250">51-250</option>
                                                        <option value="250-1k">250-1k</option>
                                                        <option value="1k+">1k+</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-lg-12 mb-3">
                                                    <Button color="danger" onClick={() => deleteCompany(company.id)}>
                                                        <MdDelete />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        {index < formData.length - 1 && <hr className="my-4" />}
                                    </div>
                                </React.Fragment>
                            ))
                        ) : (
                            <div className="col-12 text-center">
                                <p className="my-2"><b>No data available</b></p>
                            </div>
                        )}
                    </div>
                </div>
            </form>

            {/* <CreateNewCompanyModal isOpen={companyModalOpen} toggle={toggleCompanyModal} /> */}
            <Modal isOpen={companyModalOpen} toggle={handleToggle}>
                <ModalHeader toggle={handleToggle}>Add New Company</ModalHeader>
                <form onSubmit={handleCompanySubmit}>
                    <ModalBody>
                        <FormGroup>
                            <Label for="companyName">Company Name</Label>
                            <Input
                                id="companyName"
                                name="companyName"
                                placeholder="Enter company name"
                                value={modalFormData.companyName}
                                onChange={handleModalChange}
                                invalid={!!errors.companyName}
                            />
                            {errors.companyName && <div className="text-danger">{errors.companyName}</div>}
                        </FormGroup>
                        <FormGroup>
                            <Label for="companyLogo">Company Logo</Label>
                            <Input
                                id="companyLogo"
                                name="companyLogo"
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                invalid={!!errors.companyLogo}
                            />
                            {errors.companyLogo && <div className="text-danger">{errors.companyLogo}</div>}
                        </FormGroup>
                        <FormGroup>
                            <Label for="companyNumber">Company EIN</Label>
                            <Input
                                id="companyNumber"
                                name="companyNumber"    
                                placeholder="Enter company EIN"
                                value={modalFormData.companyNumber}
                                onChange={handleModalChange}
                                invalid={!!errors.companyNumber}
                            />
                            {errors.companyNumber && <div className="text-danger">{errors.companyNumber}</div>}
                        </FormGroup>
                        <FormGroup>
                            <Label for="industry">Industry</Label>
                            <Input
                                type="select"

                                id="industry"
                                name="industry"
                                value={modalFormData.industry}
                                onChange={handleModalChange}
                                invalid={!!errors.industry}
                            >
                                <option value="">Choose Option</option>
                                <option value="Information Technology and Services">Information Technology and Services</option>
                                <option value="Financial Services">Financial Services</option>
                                <option value="Healthcare">Healthcare</option>
                                <option value="Education">Education</option>

                                <option value="Marketing and Advertising">Marketing and Advertising</option>
                                <option value="Manufacturing">Manufacturing</option>
                                <option value="Retail">Retail</option>
                                <option value="Telecommunications">Telecommunications</option>

                                <option value="Real Estate">Real Estate</option>
                                <option value="Nonprofit Organization Management">Nonprofit Organization Management</option> 
                                <option value="Automotive">Automotive</option>
                                <option value="Consumer Goods">Consumer Goods</option>
                                <option value="Fitness and Wellness">Fitness and Wellness</option>
                                <option value="Staffing and Recruiting">Staffing and Recruiting</option>
                                <option value="Events Services">Events Services</option>
                                <option value="Law Practice">Law Practice</option>
                                <option value="Motion Pictures and Film">Motion Pictures and Film</option>
                                <option value="Photography">Photography</option>
                                <option value="Professional Training and Coaching">Professional Training and Coaching</option>
                                <option value="Restaurants">Restaurants</option>
                                <option value="Retail">Retail</option>



                            </Input>
                            {errors.industry && <div className="text-danger">{errors.industry}</div>}
                        </FormGroup>
                        <FormGroup >
                            <Label for="employees">Employees</Label>
                            <Input
                                id="employees"
                                name="employees"
                                value={modalFormData.employees}
                                onChange={handleModalChange}
                                invalid={!!errors.employees}
                                type="select"

                            >
                                <option value="">Choose Option</option>
                                <option value="1-10">1-10</option>
                                <option value="11-50">11-50</option>
                                <option value="51-250">51-250</option>
                                <option value="250-1k">250-1k</option>
                                <option value="1k+">1k+</option>
                            </Input>
                            {errors.employees && <div className="text-danger">{errors.employees}</div>}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleToggle}>Cancel</Button>
                        <Button  color="success"  type="submit">Save</Button>
                    </ModalFooter>
                </form>
            </Modal>
        </>
    );
};

export default CompanyInformation;
