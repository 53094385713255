import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import axios from 'axios';
import API from '../../../../../utils';
import { toast } from 'react-toastify';
import { FaCheck, FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const TransactionsActions = () => {
    const actionsList = {
        "Transaction Strategy and Planning": [
            { id: 1, name:"Build an understanding of changed market trends and dynamics, business ecosystem and competitive landscape, potential industry consolidation, sector convergence and disruptive trends to decide on the right capital strategy"},
            { id: 2, name:"Build/enhance the acquisition pipeline and develop a robust capital allocation plan fully aligned to the business’ long-term sustainable growth strategy"},
            { id: 3, name:"Determine executive responsibility for M&A activity and build/enhance the appropriate team"},
            { id: 4, name:"Create standard plan for entering new markets (incl. aspects like corporate tax assessment, government economic stimulus programs, regulatory provisions and legislation, etc.)"},
            { id: 5, name:"Create standard plan for due diligence, structuring and post-merger integration"},
            { id: 6, name:"Improve/optimize due diligence and ensure it includes aspects like disruptive technology, workforce models, geopolitical issues and environmental, social and governance (ESG) assessments"},
            { id: 7, name:"Enable data-intensive, virtual due diligence processes for deal closures, using advanced analytics and leveraging technology such as Virtual Data Rooms and virtual tours"},
            { id: 8, name:"Improve valuation models to include the identification of long-term business synergies that drive post-merger integration activities"},
            { id: 9, name:"Review existing business portfolio and identify assets which do not fit the overall business strategy"},
            { id: 10, name:"Develop divestment plan to free up capital through monetization of identified non-core assets, to invest in areas that help the business to position itself for future growth"}
        ],
        "Transaction Effectiveness": [
            { id: 11, name:"Optimize deal terms and include mechanisms for sharing future success, bridging the valuation gap (e.g. earn-outs and retention agreements)"},
            { id: 12, name:"Enhance M&A capabilities including digital technologies to increase M&A deal speed and reduce transaction costs"},
            { id: 13, name:"Create robust Day 1 integration plan, including people, cost and tax"},
            { id: 14, name:"Create formal process to identify and capture synergies and eliminate redundancies"},
            { id: 15, name:"Develop key metrics to track integration performance"},
            { id: 16, name:"Regularly review post-merger benefits realization"},
            { id: 17, name:"Conduct a systematic risk analysis of transactions processes, incl. outside, downside, and upside risks (risk taking required to realize the company’s growth and long-term value objectives)"}
        ],
        "Partnerships and Alliances": [
            { id: 18, name:"Build an understanding of changed market trends and dynamics, business ecosystem and competitive landscape, potential industry consolidation, sector convergence and disruptive trends to decide on the right partnership and alliance strategy"},
            { id: 19, name:"Determine existing core competencies and map desired competencies (e.g. innovation, technology, talent, channel distribution) to seek alliances that deliver scale, improved revenue, profitability and/or customer and shareholder value"},
            { id: 20, name:"Build a partnership and alliance pipeline"},
            { id: 21, name:"Determine executive responsibility for partnership and alliance activity and build/enhance the appropriate team"},
            { id: 22, name:"Develop and agree on key metrics to track and monitor the partnership/alliance’s success"},
            { id: 23, name:"Build/improve the capabilities to structure, manage and operate partnerships and alliances (more) successfully, maximizing its ROI"},
            { id: 24, name:"Develop/Improve exit scenarios and plans for partnerships and alliances (incl. e.g. asset evaluation, operational separation, intellectual property rights, residual ownership, rebuilding capabilities stand-alone)"},
            { id: 25, name:"Conduct a systematic risk analysis of partnership and alliance processes, incl. outside, downside, and upside risks (risk taking required to realize the company’s growth and long-term value objectives)"}
        ]
    };
    const [selectedActions, setSelectedActions] = useState([]);
    const [openCategory, setOpenCategory] = useState(null);
    const [checkedValue, setCheckedValue] = useState({});
    const uid = localStorage.getItem("user_id");

    const handleCategoryToggle = (category) => {
        setOpenCategory(openCategory === category ? null : category);
    };

    // Function to fetch data from API
    const fetchData = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_growth_transactions}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.status === "success") {
                const jsonData = response.data.data.technology_action;

                if (jsonData) {
                    try {
                        const data = JSON.parse(jsonData);

                        const parseCategoryData = (categoryData) => {
                            return categoryData ? categoryData.split(',') : [];
                        };

                        const actions = {
                            "Transaction Strategy and Planning": parseCategoryData(data.transaction_strategy_and_planning || ''),
                            "Transaction Effectiveness": parseCategoryData(data.transaction_effectiveness || ''),
                            "Partnerships and Alliances": parseCategoryData(data.partnerships_and_alliances || ''),
                        };

                        setCheckedValue(actions);
                    } catch (error) {
                        console.error('Error parsing JSON:', error);
                    }
                } else {
                    console.error('No data found for technology_action.');
                }
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Fetch technology actions error', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Function to format selected actions for API
    const formatSelectedActions = (selectedActions) => {
        const categories = {
            "Transaction Strategy and Planning": "transaction_strategy_and_planning[]",
            "Transaction Effectiveness": "transaction_effectiveness[]",
            "Partnerships and Alliances": "partnerships_and_alliances[]",
        };
    
     
        const formatted = Object.keys(categories).reduce((acc, category) => {
            const selectedData = selectedActions
                .filter(action => action.category === category)
                .map(action => {
                    const actionDetails = actionsList[category].find(a => a.id === action.id);
                    return actionDetails ? actionDetails.name : null;  // Directly returning the action name
                })
                .filter(action => action !== null);
    
            // Ensure this is an array, even if empty
            acc[categories[category]] = selectedData;
            return acc;
        }, {});
    
    
        return formatted;
    };

    // Function to handle checkbox change
    const handleCheckboxChange = (category, actionId) => {
        setSelectedActions(prev => {
            const updated = [...prev];
            const index = updated.findIndex(a => a.category === category && a.id === actionId);

            if (index > -1) {
                updated.splice(index, 1);
            } else {
                updated.push({ category, id: actionId });
            }

            // Trigger the API call on each checkbox change
            handleUpdateActions(updated);
            
            return updated;
        });
    };

    // Function to handle updating actions on checkbox change
    const handleUpdateActions = async (updatedActions) => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
    
            // Format the actions correctly
            const formattedActions = formatSelectedActions(updatedActions);
    
            // Append formatted actions to FormData
            Object.entries(formattedActions).forEach(([key, value]) => {
                if (value.length > 0) {
                    value.forEach(item => formDataToSend.append(key, item));
                } else {
                    formDataToSend.append(key, '[]');  // To represent an empty array
                }
            });
    
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_update_transactions_action}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Submit technology actions error', error);
        }
    };
    

    const handleAddToActions = async (category, action, index) => {
        try {
            // Toggle selection state
            const newCheckedValue = { ...checkedValue };
            const isSelected = newCheckedValue[category]?.includes(action.id);
            if (isSelected) {
                newCheckedValue[category] = newCheckedValue[category].filter(id => id !== action.id);
            } else {
                newCheckedValue[category] = [...(newCheckedValue[category] || []), action.id];
            }
            setCheckedValue(newCheckedValue);
    
            // Update selected actions state
            const updatedActions = Object.keys(newCheckedValue).flatMap(cat =>
                newCheckedValue[cat].map(id => ({ category: cat, id }))
            );
            setSelectedActions(updatedActions);
    
            // Make API call
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
    
            // Format the actions correctly
            const formattedActions = formatSelectedActions(updatedActions);
    
            // Append formatted actions to FormData
            Object.entries(formattedActions).forEach(([key, value]) => {
                if (value.length > 0) {
                    value.forEach(item => formDataToSend.append(key, item));
                } else {
                    formDataToSend.append(key, '[]');  // To represent an empty array
                }
            });
    
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_update_transactions_action}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Submit technology actions error', error);
            toast.error("An error occurred while updating data.");
        }
    };
    const renderActions = (category) => {
        const categoryActions = actionsList[category] || [];

        return categoryActions.map((action, index) => (
            <Col xs={12} md={6} lg={3} key={action.id} className="mb-3">
                <div className="action-card p-3 border">
                    <p>{action.name}</p>
                    <Button 
                        variant={checkedValue[category]?.includes(action.id) ? "success" : "primary"} 
                        onClick={() => handleAddToActions(category, action, index)}
                        className="d-flex align-items-center mt-3 p-2"
                    >
                        {checkedValue[category]?.includes(action.id) ? <FaCheck className="me-2" /> : <FaPlus className="me-2" />}
                        {checkedValue[category]?.includes(action.id) ? "Selected" : "Add to Actions"}
                    </Button>
                </div>
            </Col>
        ));
    };

    return (
        <div className="col-sm-12">
        <div className="card mt-4">
            <div className="card-header">
                <div className='justify-content-between d-flex '>
                    <div className="">
                        <h5>Actions for Transactions</h5>
                    </div>
                    <div>
                        <Link className='backto-ondriver' to="/growthframeworks/founderdriver">Back to the Founder OS Drivers of Growth</Link>
                    </div>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="card-body">
                    <p>To help you achieve your goals we have created actions learned from our insights of working with market leaders. Add ones that you feel are best for your business.</p>
                    <Accordion defaultActiveKey="0" activeKey={openCategory}>
                {Object.keys(actionsList).map(category => (
                    <Accordion.Item eventKey={category} key={category}>
                        <Accordion.Header onClick={() => handleCategoryToggle(category)}>
                        <div className='d-flex justify-content-between align-items-center w-100'>
              <h5 className='mb-0 fw-bold'>{category}</h5>
              <p className='mb-0 text-primary'>View Actions</p></div>
                           
                        </Accordion.Header>
                        <Accordion.Body>
                            <Row>
                                {openCategory === category && renderActions(category)}
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </div>
        </div>
        </div>
        </div>
    );
};

export default TransactionsActions;
