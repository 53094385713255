import React, { useEffect, useState } from "react";
import axios from "axios";
import API from "../../../utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Process = () => {
    const [errors, setErrors] = useState({});
    const uid = localStorage.getItem("user_id");
    const [formData, setFormData] = useState({
        Accountabilitypagename: "",
        Process: "",
        Seatsplural:"",
        Core: "",
        Step: "",
        Plural: "",
        Sub_Steps: "",
        singular: "",
        Documented: "",
        Followed:"",
        Analyze:"",
        Effective:"",
    });

    useEffect(() => {
        fetchData();
      }, []);
    
      const fetchData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
       
        try {
          const response = await axios.post(
            `${API.BASE_URL}${API.ENDPOINTS.get_all_terminology_process}`,
            formDataToSend,
            {
              headers: {
                Authorization: `${API.AUTHORIZATION_TOKEN}`,
              },
            }
          );
    
          if (response.data.status === 'success') {
     
            const data = response.data.data[0];
            setFormData({
                Accountabilitypagename: data.page_name || "",
                Process: data.process_singulad || "",
                Seatsplural: data.process_plural || "",
                Core: data.core_process || "",
                Step: data.step_singular || "",
                Plural: data.stepe_plural || "",
                singular: data.sub_step_singular || "",
                Sub_Steps: data.sub_steps || "",
                Documented: data.documented || "",
                Followed: data.followed_by_all || "",
                Analyze: data.people_analyze || "",
                Effective: data.effective || "",
            });
          } else {
            toast.error(response.data.message);
                }
        } catch (error) {
          console.error('Error during task fetching:', error);
        }
      };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('page_name', formData.Accountabilitypagename);
        formDataToSend.append('process_singulad', formData.Process);
        formDataToSend.append('process_plural', formData.Seatsplural);
        formDataToSend.append('core_process', formData.Core);
        formDataToSend.append('step_singular', formData.Step);
        formDataToSend.append('stepe_plural', formData.Plural);
        formDataToSend.append('sub_step_singular', formData.singular);
        formDataToSend.append('sub_steps', formData.Sub_Steps);
        formDataToSend.append('documented', formData.Documented);
        formDataToSend.append('followed_by_all', formData.Followed);
        formDataToSend.append('people_analyze', formData.Analyze);
        formDataToSend.append('effective', formData.Effective);
        try {
          const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_update_terminology_process}`,
            formDataToSend,
            {
              headers: {
                Authorization: `${API.AUTHORIZATION_TOKEN}`,
              },
            }
          );
      
          if (response.data.status === "success") {
            toast.success(response.data.message);
            fetchData();
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
          console.error("Error during task submission:", error);
          
        }
    };

    return (
        <>
  <div className="card">
            <div className="card-header">
                <h5 className="card-title mb-0"><i className="fa fa-circle-o-notch mx-2"></i>Process</h5>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <div className="form-group mb-2">
                            <label className="col-sm-12 col-form-label"><b>Page Name</b></label>
                            <input
                                className={`form-control ${errors.Accountabilitypagename ? 'is-invalid' : ''}`}
                                type="text"
                                placeholder="Enter Page Name"
                                name="Accountabilitypagename"
                                value={formData.Accountabilitypagename}
                                onChange={handleChange}
                            />
                            {errors.Accountabilitypagename && <div className="text-danger">{errors.Accountabilitypagename}</div>}
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                        <div className="form-group mb-2">
                            <label className="col-sm-12 col-form-label"><b>Process singulad</b></label>
                            <input
                                className={`form-control ${errors.Process ? 'is-invalid' : ''}`}
                                type="text"
                                placeholder="Enter Process singulad"
                                name="Process"
                                value={formData.Process}
                                onChange={handleChange}
                            />
                            {errors.Process && <div className="text-danger">{errors.Process}</div>}
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                        <div className="form-group mb-2">
                            <label className="col-sm-12 col-form-label"><b>Process (plural)</b></label>
                            <input
                                className={`form-control ${errors.Seatsplural ? 'is-invalid' : ''}`}
                                type="text"
                                placeholder="Enter Process (plural)"
                                name="Seatsplural"
                                value={formData.Seatsplural}
                                onChange={handleChange}
                            />
                            {errors.Seatsplural && <div className="text-danger">{errors.Seatsplural}</div>}
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                        <div className="form-group mb-2">
                            <label className="col-sm-12 col-form-label"><b>Core Process</b></label>
                            <input
                                className={`form-control ${errors.Core ? 'is-invalid' : ''}`}
                                type="text"
                                placeholder="Enter Core Process"
                                name="Core"
                                value={formData.Core}
                                onChange={handleChange}
                            />
                            {errors.Core && <div className="text-danger">{errors.Core}</div>}
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="form-group mb-2">
                            <label className="col-sm-12 col-form-label"><b>Step (singular)</b></label>
                            <input
                                className={`form-control ${errors.Step ? 'is-invalid' : ''}`}
                                type="text"
                                placeholder="Enter Step (singular)"
                                name="Step"
                                value={formData.Step}
                                onChange={handleChange}
                            />
                            {errors.Step && <div className="text-danger">{errors.Step}</div>}
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                        <div className="form-group mb-2">
                            <label className="col-sm-12 col-form-label"><b>Stepe Plural</b></label>
                            <input
                                className={`form-control ${errors.Plural ? 'is-invalid' : ''}`}
                                type="text"
                                placeholder="Enter Annual Stepe (Plural)"
                                name="Plural"
                                value={formData.Plural}
                                onChange={handleChange}
                            />
                            {errors.Plural && <div className="text-danger">{errors.Plural}</div>}
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                        <div className="form-group mb-2">
                            <label className="col-sm-12 col-form-label"><b>Sub-Step (singular)
                            </b></label>
                            <input
                                className={`form-control ${errors.singular ? 'is-invalid' : ''}`}
                                type="text"
                                placeholder="Enter Sub-Step (singular)                                       "
                                name="singular"
                                value={formData.singular}
                                onChange={handleChange}
                            />
                            {errors.singular && <div className="text-danger">{errors.singular}</div>}
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                        <div className="form-group mb-2">
                            <label className="col-sm-12 col-form-label"><b>Sub-Steps</b></label>
                            <input
                                className={`form-control ${errors.Sub_Steps ? 'is-invalid' : ''}`}
                                type="text"
                                placeholder="Enter Sub-Steps"
                                name="Sub_Steps"
                                value={formData.Sub_Steps}
                                onChange={handleChange}
                            />
                            {errors.Sub_Steps && <div className="text-danger">{errors.Sub_Steps}</div>}
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                        <div className="form-group mb-2">
                            <label className="col-sm-12 col-form-label"><b>Documented</b></label>
                            <input
                                className={`form-control ${errors.Documented ? 'is-invalid' : ''}`}
                                type="text"
                                placeholder="Enter Documented"
                                name="Documented"
                                value={formData.Documented}
                                onChange={handleChange}
                            />
                            {errors.Documented && <div className="text-danger">{errors.Documented}</div>}
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                        <div className="form-group mb-2">
                            <label className="col-sm-12 col-form-label"><b>Followed By All</b></label>
                            <input
                                className={`form-control ${errors.Followed ? 'is-invalid' : ''}`}
                                type="text"
                                placeholder="Enter Followed By All"
                                name="Followed"
                                value={formData.Followed}
                                onChange={handleChange}
                            />
                            {errors.Followed && <div className="text-danger">{errors.Followed}</div>}
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                        <div className="form-group mb-2">
                            <label className="col-sm-12 col-form-label"><b>People Analyze</b></label>
                            <input
                                className={`form-control ${errors.Analyze ? 'is-invalid' : ''}`}
                                type="text"
                                placeholder="Enter People Analyze"
                                name="Analyze"
                                value={formData.Analyze}
                                onChange={handleChange}
                            />
                            {errors.Analyze && <div className="text-danger">{errors.Analyze}</div>}
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                        <div className="form-group mb-2">
                            <label className="col-sm-12 col-form-label"><b>Effective</b></label>
                            <input
                                className={`form-control ${errors.Effective ? 'is-invalid' : ''}`}
                                type="text"
                                placeholder="Enter Effective"
                                name="Effective"
                                value={formData.Effective}
                                onChange={handleChange}
                            />
                            {errors.Effective && <div className="text-danger">{errors.Effective}</div>}
                        </div>
                    </div>
                    <div className="d-flex justify-content-end align-content-center">
                    <button type="submit" className="btn btn-success" onClick={handleSubmit}>Save</button>
                  </div>
                </div>
            </div>
        </div>

        </>
    );
};

export default Process;