import React, { Fragment, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Resetpwdbg from "../assets/images/authimg/resetpwd.svg";
import Logosvg from '../assets/images/logo/auth.svg';
import API from '../utils';
import { toast, ToastContainer } from 'react-toastify';
import MyLoader from '../components/common/myloader';

const ResetPwd = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [formData, setFormData] = useState({
        password: '',
        confirm_password: ''
    });

    const [validationErrors, setValidationErrors] = useState({});
    const [loading, setLoading] = useState(false);

    // Extract userId and forgot_pass_key from URL
    const searchParams = new URLSearchParams(location.search);
    const userId = searchParams.get('userId');
    const forgotPassKey = searchParams.get('forgot_pass_key');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '' // Clear specific field error
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = {};

        if (!formData.password) {
            errors.password = 'New password is required';
        } else if (formData.password.length < 6) {
            errors.password = 'Password must be at least 6 characters';
        }

        if (!formData.confirm_password) {
            errors.confirm_password = 'Please confirm your password';
        } else if (formData.password !== formData.confirm_password) {
            errors.confirm_password = 'Passwords do not match';
        }

        if (Object.keys(errors).length === 0) {
            // Submit the form
            setLoading(true);
            try {
                const formDataToSend = new FormData();
                formDataToSend.append('user_id', userId);
                formDataToSend.append('token', forgotPassKey);
                formDataToSend.append('password', formData.password);
                formDataToSend.append('confirm_password', formData.confirm_password);

                const response = await axios.post(`${API.BASE_URLRESERT}${API.ENDPOINTS.resetpassword}`, formDataToSend, {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                });

                if (response.data.status === "success") {
                    toast.success(response.data.message);
                    navigate('/');
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                toast.error("Failed to reset password");
            } finally {
                setLoading(false);
            }
        } else {
            setValidationErrors(errors);
        }
    };

    return (
        <>
            <MyLoader active={loading}>
                <Fragment>
                    <div className="auth-page-wrapper">
                        <div className="page-wrapper">
                            <div className="container-fluid">
                                <div className="authentication-main">
                                    <div className="row m-0">
                                        <div className="col-md-12 p-0">
                                            <div className="auth-innerright">
                                                <div className="authentication-box">
                                                    <div className="card mt-4">
                                                        <div className='row m-0'>
                                                            <div className="col-lg-6 col-md-6 col-sm-12 login-main p-0">
                                                                <div className="h-100">
                                                                    <img src={Resetpwdbg} className="login-bg" alt="login-Bg" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-12 p-0">
                                                                <div className="p-lg-5 p-md-3 p-sm-3">
                                                                    <div className="text-center">
                                                                        <img className="Logosvg-auth w-25" src={Logosvg} alt="logo-svg" />
                                                                        <span className='lifelogosignin'>Founders O/S</span>
                                                                    </div>
                                                                    <div>
                                                                        <h5 className="text-center text-primary text-login-h5">Reset Password 🔒</h5>
                                                                    </div>
                                                                    <form className="theme-form" onSubmit={handleSubmit}>
                                                                        <div className="form-group">
                                                                            <label className="col-form-label">New Password</label>
                                                                            <input
                                                                                className={`form-control ${validationErrors.password ? 'is-invalid' : ''}`}
                                                                                name="password"
                                                                                type="password"
                                                                                placeholder="············"
                                                                                value={formData.password}
                                                                                onChange={handleChange}
                                                                            />
                                                                            {validationErrors.password && (
                                                                                <div className="text-danger">{validationErrors.password}</div>
                                                                            )}
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label className="col-form-label">Confirm Password</label>
                                                                            <input
                                                                                className={`form-control ${validationErrors.confirm_password ? 'is-invalid' : ''}`}
                                                                                name="confirm_password"
                                                                                type="password"
                                                                                placeholder="············"
                                                                                value={formData.confirm_password}
                                                                                onChange={handleChange}
                                                                            />
                                                                            {validationErrors.confirm_password && (
                                                                                <div className="text-danger">{validationErrors.confirm_password}</div>
                                                                            )}
                                                                        </div>
                                                                        <div className="form-group mt-3 mb-0 d-grid">
                                                                            <button className="btn btn-primary" type="submit" disabled={loading}>
                                                                                {loading ? 'Processing...' : 'Set New Password'}
                                                                            </button>
                                                                        </div>
                                                                    </form>
                                                                    <div className="text-center mt-4">
                                                                        <p className="mb-0 mt-4">Wait, I remember my password...
                                                                            <Link to="/login" className="fw-semibold text-primary text-decoration-underline">Click here</Link>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ToastContainer />
                    </div>
                </Fragment>
            </MyLoader>
        </>
    );
};

export default ResetPwd;
