import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ViewGoalModal = ({ show, onHide, selectedItem, thStyle }) => {
  return (
    <Modal centered={true} show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>10-15 Years Aspiration</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table">
          <thead></thead>
          <tbody>
            <tr>
              <th style={thStyle} colSpan={2}>10-15 Years Aspiration</th>
            </tr>
            <tr>
              <td>Name</td>
              <td>{selectedItem.name}</td>
            </tr>
            <tr>
              <td>Owner</td>
              <td>{selectedItem.owner}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{selectedItem.status}</td>
            </tr>
            <tr>
              <td>Type</td>
              <td>{selectedItem.type}</td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-secondary" onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewGoalModal;
