import React, { useState, useEffect } from "react";
import axios from "axios";
import API from "../../../utils";
import { toast } from "react-toastify";
import MyLoader from "../../common/myloader";

const Notificationsuserprofile = () => {
    const user_id = localStorage.getItem('user_id');
    const [notificationData, setNotificationData] = useState([
        { id: "8", day: "Weekly Round-Up", time: "", isChecked: 0 },
        { id: "1", day: "Monday", time: "", isChecked: 0 },
        { id: "2", day: "Tuesday", time: "", isChecked: 0 },
        { id: "3", day: "Wednesday", time: "", isChecked: 0 },
        { id: "4", day: "Thursday", time: "", isChecked: 0 },
        { id: "5", day: "Friday", time: "", isChecked: 0 },
        { id: "6", day: "Saturday", time: "", isChecked: 0 },
        { id: "7", day: "Sunday", time: "", isChecked: 0 },
    ]);
    const [previousCheckedId, setPreviousCheckedId] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchNotificationData();
        fetchemailsend();
    }, []);

    const fetchemailsend = async () => {
        setLoading(true);
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', user_id);

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.notification_send_by_email}`, formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.data.status === "success") {
                console.log(response.data.message);
            } else {
                console.error("Error:", response.data.message);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false); // Hide loader
        }
    };


    const fetchNotificationData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', user_id);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_all_notification}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.data.status === "success") {
                const apiData = response.data.data;
                const formattedData = notificationData.map(item => {
                    const found = apiData.find(d => d.days_id === item.id);
                    return found
                        ? { ...item, isChecked: parseInt(found.is_check), time: found.time !== "00:00:00" ? found.time : "00:00:00" }
                        : { ...item, isChecked: 0, time: "" };
                });
                setNotificationData(formattedData);
                const initiallyChecked = formattedData.find(item => item.isChecked === 1);
                if (initiallyChecked) {
                    setPreviousCheckedId(initiallyChecked.id);
                }
            } else {
                console.error("Error:", response.data.message);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // const handleCheckboxChange = async (id) => {
    //     // Toggle the isChecked value for the selected day
    //     const updatedData = notificationData.map(item =>
    //         item.id === id
    //             ? { ...item, isChecked: item.isChecked === 1 ? 0 : 1 }
    //             : item
    //     );
    
    //     setNotificationData(updatedData);
    
    //     const itemToSend = updatedData.find(item => item.id === id);
    
    //     if (!itemToSend) {
    //         console.error("Item to send not found:", id);
    //         return;
    //     }
    
    //     const { time, isChecked } = itemToSend;
    
    //     const formDataToSend = new FormData();
    //     formDataToSend.append('user_id', user_id);
    //     formDataToSend.append('select_day', id);
    //     formDataToSend.append('time', time);
    //     formDataToSend.append('is_check', isChecked);
    
    //     try {
    //         const response = await axios.post(
    //             `${API.BASE_URL}${API.ENDPOINTS.notification_schedule}`,
    //             formDataToSend,
    //             {
    //                 headers: {
    //                     Authorization: `${API.AUTHORIZATION_TOKEN}`,
    //                     'Content-Type': 'multipart/form-data',
    //                 },
    //             }
    //         );
    
    //         if (response.data.status === "success") {
    //             toast.success(response.data.message);
    //             fetchNotificationData();
    //             fetchemailsend();
    //         } else {
    //             console.error("Error:", response.data.message);
    //         }
    //     } catch (error) {
    //         console.error("Error submitting data:", error);
    //     }
    // };
    
    
    const handleCheckboxChange = async (id) => {
        const updatedData = notificationData.map(item =>
            item.id === id
                ? { ...item, isChecked: item.isChecked === 1 ? 0 : 1 }
                : item
        );
    
        setNotificationData(updatedData);
    
        const itemToSend = updatedData.find(item => item.id === id);
        if (!itemToSend) {
            console.error("Item to send not found:", id);
            return;
        }
    
    
        const { time, isChecked } = itemToSend;
    
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', user_id);
        formDataToSend.append('select_day', id);
        formDataToSend.append('time', time);
        formDataToSend.append('is_check', isChecked);
    
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.notification_schedule}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
    
            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchNotificationData();
                fetchemailsend();
            } else {
                console.error("Error:", response.data.message);
            }
        } catch (error) {
            console.error("Error submitting data:", error);
        }
    };
    

    const handleTimeChange = (id, time) => {
        setNotificationData(prevData =>
            prevData.map(item =>
                item.id === id
                    ? { ...item, time }
                    : item
            )
        );
    };

    return (
        <>
            <MyLoader active={loading} >
                <form>
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title mb-0">Email Notification Scheduling</h5>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-border-horizon tal">
                                    <tbody>
                                        {notificationData.map((data, index) => (
                                            <tr
                                                key={index}
                                                className={data.id === "8" ? "weekly-roundup-row" : "double"}
                                                style={data.id === "8" ? { backgroundColor: "#f8f9fa", fontWeight: "bold" } : {}}
                                            >
                                                <td>
                                                    <input
                                                        className="checkbox_animated"
                                                        type="checkbox"
                                                        checked={data.isChecked === 1}
                                                        onChange={() => handleCheckboxChange(data.id)}
                                                    />
                                                </td>
                                                <td>{data.day}</td>
                                                <td className="time-digits">
                                                    <input
                                                        className="form-control digits"
                                                        type="time"
                                                        value={data.time}
                                                        onChange={(e) => handleTimeChange(data.id, e.target.value)}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </form>
            </MyLoader>
        </>
    );
};

export default Notificationsuserprofile;
