import React, { useState, useEffect, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import API from '../../../utils';
import axios from 'axios';
import { toast } from 'react-toastify';
import debounce from 'lodash/debounce'; 

const Technology = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [gratitudeEntries, setGratitudeEntries] = useState([]);
  const user_id = localStorage.getItem('user_id');
  const [formData, setFormData] = useState({
    id: "", 
    name: "",
    details: "",
    renewal: "",
    own_out_right: '',
    renewal_cost: '',
    admin: '',
    annually: '',
    year: '',
    type: '',
  });
  const [updatedTask, setUpdatedTask] = useState(null); 
  const [selectedItem, setSelectedItem] = useState(null); 

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', user_id);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_businesstools_technology}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === 'success') {
    
        const formattedData = response.data.info.map((entry) => ({
          id: entry.id,
          name: entry.name,
          details: entry.details,
          type: entry.type,
          renewal: entry.renewal,
          own_out_right: entry.own_out_right,
          renewal_cost: entry.renewal_cost,
          annually: entry.annually,
          year: entry.year,
          admin: entry.admin,
          created_at: entry.created_at,
        }));

        const sortedTasks = formattedData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setGratitudeEntries(sortedTasks);
      
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Error during task fetching:', error);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('user_id', user_id);
    formDataToSend.append('name', formData.name);
    formDataToSend.append('type', formData.type);
    formDataToSend.append('renewal', formData.renewal);
    formDataToSend.append('annually', formData.annually);
    formDataToSend.append('year', formData.year);
    formDataToSend.append('own_out_right', formData.own_out_right);
    formDataToSend.append('renewal_cost', formData.renewal_cost);
    formDataToSend.append('admin', formData.admin);
    formDataToSend.append('details', formData.details);
    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_businesstools_technology}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
            'Content-Type': 'multipart/form-data'
          },
        }
      );

      if (response.data.status === "success") {
        toast.success("Technology Added Successfully");
        setFormData({
          name: "",
          details: "",
          renewal: "",
          own_out_right: '',
          renewal_cost: '',
          admin: '',
        });
        fetchTasks();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An error occurred while submitting data.");
    }
  };

  const debouncedUpdateTask = useCallback(
    debounce(async (task) => {
      const formDataToSend = new FormData();
      formDataToSend.append('technology_id', task.id);
      formDataToSend.append('field', task.field);
      formDataToSend.append('text', task.value);

      try {
        const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.edit_businesstools_technology}`,
          formDataToSend,
          {
            headers: {
              Authorization: `${API.AUTHORIZATION_TOKEN}`,
            },
          }
        );

        console.log("Response received:", response.data);

        if (response.data.status === "success") {
          fetchTasks();
          toast.success("Technology Updating Successfully");
          // toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("An error occurred while updating data.");
      }
    }, 1000),
    []
  );

  const handleEditInputChange = (e, index, field, id) => {
    const { value } = e.target;
    const updatedOutcomes = [...gratitudeEntries];
    updatedOutcomes[index][field] = value;
    setUpdatedTask({ field, value, id });
  };

  useEffect(() => {
    if (updatedTask) {
      debouncedUpdateTask(updatedTask);
    }
  }, [updatedTask, debouncedUpdateTask]);

  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const handleView = (item) => {
    setSelectedItem(item);
    toggleViewModal();
  };

  const toggleViewModal = () => {
    setIsViewModalOpen(!isViewModalOpen);
  };


  const columns = [
    {
      name: "Expense Name",
      selector: (row) => row.name,
      cell: (row, index) =>
      (
        <div className="business-tool-table">
          <input
            type="text"
            name="name"
            value={row.name}
            className="form-control"
            onChange={(e) => handleEditInputChange(e, index, "name", row.id)}
          />
        </div>
      ),
      sortable: true,
    },
    {
      name: "Renewal Term",
      selector: (row) => row.renewal,
      cell: (row, index) =>
      (
        <div className="business-tool-table">
          <select className="form-control"
            onChange={(e) => handleEditInputChange(e, index, "renewal", row.id)}
            name="renewal"
            value={row.renewal}
          >
            <option value="">Select Technology Type</option>
            <option value="Monthly">Monthly</option>
            <option value="Annually">Annually</option>
            <option value="3-Year">3-Year</option>
            <option value="Own Out Right / No Renewal">Own Out Right / No Renewal</option>
          </select>
        </div>
      ),
      sortable: true,
    },

    {
      name: "Renewal Cost",
      selector: (row) => row.renewal_cost,
      cell: (row, index) =>
      (
        <div className="business-tool-table">
          <input
            type="text"
            name="renewal_cost"
            value={row.renewal_cost}
            className="form-control"
            onChange={(e) => handleEditInputChange(e, index, "renewal_cost", row.id)}
          />
        </div>
      ),
      sortable: true,
    },
    {
      name: "Technical/Vendor/Internal Admin",
      selector: (row) => row.admin,
      cell: (row, index) =>
      (
        <div className="business-tool-table">
          <input
            type="text"
            name="admin"
            value={row.admin}
            className="form-control"
            onChange={(e) => handleEditInputChange(e, index, "admin", row.id)}
          />
        </div>
      ),
      sortable: true,
    },

    {
      name: "Notes",
      selector: (row) => row.details,
      cell: (row, index) =>
      (
        <div className="business-tool-table">
          <textarea
            type="text"
            name="details"
            value={row.details}
            className="form-control"
            onChange={(e) => handleEditInputChange(e, index, "details", row.id)}
          />
        </div>
      ),
      sortable: true,
    },
  ];

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredEntries = gratitudeEntries.filter(entry =>
    (entry.name || "").toLowerCase().includes((searchQuery || "").toLowerCase()) ||
    (entry.details || "").toLowerCase().includes((searchQuery || "").toLowerCase())
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let formattedValue = value;
  
    // Format Renewal Cost with $ sign
    if (name === 'renewal_cost') {
      formattedValue = value.replace(/[^0-9.]/g, ''); // Remove non-numeric characters except the dot
      if (formattedValue) {
        formattedValue = `$${formattedValue}`;
      }
    }
  
    setFormData({ ...formData, [name]: formattedValue });
  };
  

 
  const isSaveButtonEnabled = () => formData.name.trim() !== '';

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="align-items-center justify-content-between d-flex">
                    <h5>Technology</h5>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <form>
                  <div className='row'>
                    <div className="form-group mb-3 col-lg-6 col-md-6">
                      <label><b>Expense Name</b></label>
                      <input type='text' className="form-control" name="name" value={formData.name} onChange={handleInputChange} placeholder='Technology Name ' />
                    </div>

                    <div className="form-group mb-3 col-lg-6 col-md-6">
                      <label><b>Renewal Term</b></label>
                      <select className="form-control" onChange={handleInputChange} name="renewal" value={formData.renewal}>
                        <option value="">Select Technology Type</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Annually">Annually</option>
                        <option value="3-Year">3-Year</option>
                        <option value="Own Out Right / No Renewal">Own Out Right / No Renewal</option>
                      </select>
                    </div>


                    <div className="form-group mb-3 col-lg-6 col-md-6">
                      <label><b>Renewal Cost</b></label>
                      <input
  type='text'
  className="form-control"
  name="renewal_cost"
  value={formData.renewal_cost}
  onChange={handleInputChange}
  placeholder='Renewal Cost'
/>
                    </div>
                    <div className="form-group mb-3 col-lg-6 col-md-6">
                      <label><b>Technical/Vendor/Internal Admin</b></label>
                      <input
                        type='text'
                        className="form-control"
                        name="admin"
                        value={formData.admin}
                        onChange={handleInputChange}
                        placeholder='Admin'
                      />
                    </div>

                    <div className="mb-3 form-group col-lg-12">
                      <label><b>Notes</b></label>
                      <textarea type='text' name="details" className="form-control" value={formData.details} onChange={handleInputChange} placeholder='Notes' />
                    </div>

                    <div className="form-group text-end">
                      <Button
                        variant="success"
                        disabled={!isSaveButtonEnabled()}
                        onClick={handleSave}
                      >Save</Button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-9"></div>
                  <div className="col-md-3">
                    <div className="form-group text-end mb-3">
                      <input className="form-control" type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchChange} />
                    </div>
                  </div>
                </div>
                <div>
                  <DataTable
                    columns={columns}
                    data={filteredEntries}
                    pagination
                    persistTableHead={true}
                    className='keyrelationship-table'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Technology;
