import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "react-bootstrap";
import { FaRegCalendarAlt } from "react-icons/fa";
import DataTable from 'react-data-table-component';
import { Line } from "react-chartjs-2";
import { gradientChartData, gradientChartOptions, gradientsecorChartOptions } from "../../../data/default";
import { Chart } from "react-google-charts";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { GiRubElHizb } from "react-icons/gi";
import { GrScorecard } from "react-icons/gr";
import API, { isSubscribedAccountant } from "../../../utils";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configDB from "../../../data/customizer/config";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { useNavigate } from "react-router-dom";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const primary = localStorage.getItem("primary_color") || configDB.data.color.primary_color;

const AdminDashboard = () => {
  const uid = localStorage.getItem("user_id");

 

  return (
    <>
      <Container fluid={true}>
        <Row>
          <Col sm="12" className="p-0">
            <Card>
              <CardHeader>
            
              </CardHeader>
              <CardBody>
              
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AdminDashboard;
