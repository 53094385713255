import React, { useState, useEffect, useCallback } from "react";
import { FaCaretDown } from "react-icons/fa";
import { toast } from 'react-toastify';
import axios from 'axios';
import moment from 'moment-timezone';
import API from "../../../utils";
import { debounce } from 'lodash';

const Preferences = () => {
    const user_id = localStorage.getItem('user_id');
    const [formData, setFormData] = useState({
        teams: '',
        timezone: 'US/Eastern',
        autoTimezone: true
    });

    const [errors, setErrors] = useState({});

    // Fetch the user preferences data when the component mounts
    useEffect(() => {
        fetchPreferences();
    }, []);

    const fetchPreferences = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', user_id);
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_preference}`, formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                });

            if (response.data.status === "success") {
                const { teams, time_zone } = response.data.data;
                setFormData({
                    teams: teams || 'Light', // Default to 'Light' if teams value is empty
                    timezone: time_zone || 'US/Eastern',
                    autoTimezone: true // Adjust according to your data
                });
            } else {
               // toast.error("Failed to fetch preferences.");
            }
        } catch (error) {
           // toast.error("Error fetching preferences.");
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '', // Clear previous error for this field
        }));
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
        debounceSavePreferences();
    };

    // Debounced function to save preferences
    const savePreferences = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', user_id);
        formDataToSend.append('teams', formData.teams);
        formDataToSend.append('timezone', formData.timezone);
        formDataToSend.append('auto_timezone', formData.autoTimezone ? 'true' : 'false'); // Checkbox value

        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_update_preference}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                toast.success(response.data.message);
                // fetchPreferences();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Failed to submit form.");
        }
    };

    // Create a debounced version of the savePreferences function
    const debounceSavePreferences = useCallback(debounce(savePreferences, 1000), [formData]);

    return (
        <>
            <form onSubmit={e => e.preventDefault()}> {/* Prevent default form submission */}
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title mb-0">Preferred Timezone</h5>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <span>Select a timezone for your account to be used for timezone emails.</span>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group mb-3">
                                        <div className="dropdown-container">
                                            <select
                                                className={`form-control ${errors.timezone ? 'is-invalid' : ''}`}
                                                name="timezone"
                                                value={formData.timezone}
                                                onChange={handleChange}
                                            >
                                                {moment.tz.names().map((timezone) => (
                                                    <option key={timezone} value={timezone}>
                                                        {`(${moment.tz(timezone).format('Z')}) ${timezone}`}
                                                    </option>
                                                ))}
                                            </select>
                                            <FaCaretDown className="dropdown-icon" /> 
                                        </div>
                                        {errors.timezone && <div className="text-danger">{errors.timezone}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>            
            </form>
        </>
    );
};

export default Preferences;
