import React, { useEffect, useState } from "react";
import { Button, Container, Dropdown, Tab, Tabs } from "react-bootstrap";
import RiskActions from "./riskaction";
import RiskAnalysis from "./riskanalysis";
import RiskAssessment from "./riskassessment";

const Riskindex = () => {
    const [tabs] = useState([
        { id: 1, sub_category: 'Risk Analysis' },
        { id: 2, sub_category: 'Risk Assessment' },
        { id: 3, sub_category: 'Risk Actions' },
    ]);

    const [activeTab, setActiveTab] = useState('Risk Analysis');
    const [isMobile, setIsMobile] = useState(false);

    // Function to handle tab selection
    const handleTabSelect = (key) => {
        setActiveTab(key); // Update active tab state
    };

    const getTabContent = (tabName, activeTab) => {
        switch (tabName) {
            case 'Risk Analysis':
                return <RiskAnalysis activeTab={activeTab} />;
            case 'Risk Assessment':
                return <RiskAssessment activeTab={activeTab} />;
            case 'Risk Actions':
                return <RiskActions activeTab={activeTab} />;
            default:
                return null;
        }
    };
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Set breakpoint for mobile screens
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return (
        <Container fluid>
            {isMobile ? (
                <>
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {activeTab}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {tabs.map((tab) => (
                                <Dropdown.Item
                                    key={tab.id}
                                    onClick={() => handleTabSelect(tab.sub_category)}
                                >
                                    {tab.sub_category}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    {getTabContent(activeTab)} {/* Render only once for mobile */}
                </>
            ) : (
                <Tabs
                    activeKey={activeTab}
                    onSelect={handleTabSelect}
                    id="justify-tab-example"
                    className="border-tab nav-primary mb-3"
                >
                    {tabs.map(tab => (
                        <Tab key={tab.id} eventKey={tab.sub_category} title={tab.sub_category}>
                            {getTabContent(tab.sub_category)} {/* Render content inside the Tabs */}
                        </Tab>
                    ))}
                </Tabs>
            )}
        </Container>
        // <Container fluid>
        //     {isMobile ? (
        //         <Dropdown>
        //             <Dropdown.Toggle variant="success" id="dropdown-basic">
        //                 {activeTab}
        //             </Dropdown.Toggle>

        //             <Dropdown.Menu>
        //                 {tabs.map((tab) => (
        //                     <Dropdown.Item
        //                         key={tab.id}
        //                         onClick={() => handleTabSelect(tab.sub_category)}
        //                     >
        //                         {tab.sub_category}
        //                     </Dropdown.Item>
        //                 ))}
        //             </Dropdown.Menu>
        //         </Dropdown>
        //     ) : (
        //         <Tabs
        //             activeKey={activeTab}
        //             onSelect={handleTabSelect}
        //             id="justify-tab-example"
        //             className="border-tab nav-primary mb-3"
        //         >
        //             {tabs.map(tab => (
        //                 <Tab key={tab.id} eventKey={tab.sub_category} title={tab.sub_category}>
        //                     {getTabContent(tab.sub_category)}
        //                 </Tab>
        //             ))}
        //         </Tabs>
        //     )}
        //     {getTabContent(activeTab)}
        // </Container>
    );
};

export default Riskindex;
