import React, { useState, useEffect } from "react";
import logo from "../assets/images/endless-logo.png";
import man from "../assets/images/user/user_default.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Login, LOGIN, YourName, Password, RememberMe } from "../constant";
import { Link, useNavigate } from "react-router-dom";
// import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { FaFacebook } from "react-icons/fa";
import FacebookLogin from 'react-facebook-login';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import API from '../utils';
import MyLoader from "../components/common/myloader";
import Loginbg from "../../src/assets/images/logo/bg.png";


const Signin = () => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [userData, setProfileData] = useState({
    email: localStorage.getItem("email") || '',
    name: localStorage.getItem("name") || '',
    user_name: localStorage.getItem("user_name") || '',
    profileURL: localStorage.getItem("profileURL")
  });

  const navigate = useNavigate();

  const handleLoginSuccess = (userData) => {
    localStorage.setItem("login", JSON.stringify(userData));
    localStorage.setItem("profileURL", userData.profileURL);
  };

  const validate = () => {
    let emailError = "";
    let passwordError = "";

    if (!email) {
      emailError = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      emailError = "Email is invalid.";
    }

    if (!password) {
      passwordError = "Password is required.";
    } else if (password.length < 6) {
      passwordError = "Password must be at least 6 characters.";
    }

    if (emailError || passwordError) {
      setEmailError(emailError);
      setPasswordError(passwordError);
      return false;
    }

    return true;
  };



  const checkSubscription = async (userId) => {
    // try {
    //   const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.checkSubscription}/${userId}`, {
    //     headers: {
    //       Authorization: `${API.AUTHORIZATION_TOKEN}`
    //     }
    //   });

    //   // Assuming response.data.status will be "success" and message will be a descriptive text
    //   if (response.data.status === "success" && response.data.message.includes("not expired")) {
    //     return true; // Subscription is valid
    //   } else {
    //     return false; // Subscription is not valid
    //   }
    // } catch (error) {
    //   console.error('Error checking subscription:', error);
    //   return false; // Default to invalid subscription on error
    // }



    const formDataToSend = new FormData();
    formDataToSend.append('userid', userId);

    try {
      const response = await fetch(`${API.BASE_URL}${API.ENDPOINTS.check_subscription}`, {
        method: 'POST',
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`
        },
        body: formDataToSend
      });

      const data = await response.json();

      if (data.status === "success") {
        return true;
      } else {
        localStorage.setItem("isSubscriptionValid", false);
        return false;
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const loginAuth = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (!isValid) return;
    setLoading(true);

    const formDataToSend = new FormData();
    formDataToSend.append('email', email);
    formDataToSend.append('password', password);

    try {
      const response = await fetch(`${API.BASE_URL}${API.ENDPOINTS.login}`, {
        method: 'POST',
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`
        },
        body: formDataToSend
      });

      const data = await response.json();

      if (data.status === "success") {
        toast.success(data.message);
        localStorage.setItem("user_id", data.user_data.id);
        localStorage.setItem("user_name", data.user_data.user_name);
        localStorage.setItem("email", data.user_data.email);

        const userData = {
          email: data.user_data.email,
          name: data.user_data.name,
          profileURL: data.user_data.profileURL || man,
          user_name: data.user_data.user_name,
        };

        const isValidSubscription = await checkSubscription(data.user_data.id);
        localStorage.setItem("isSubscriptionValid", isValidSubscription);

        if (isValidSubscription) {
          toast.success(data.message);
          navigate(`/`);
          handleLoginSuccess(userData);
        } else {
          navigate(`/users/userEdit`);
          handleLoginSuccess(userData);
          toast.success(data.message);
        }
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error during login:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSuccess = async (response) => {
    try {
      const token = response.credential; // The ID token returned by Google
      const userData = jwtDecode(token); // Decode the token to get user info

      console.log("Google user data:", userData);

      // Here you can handle the login with your backend
      const userCheckResponse = await checkGoogleUser(userData.email, userData.name, userData.sub);
      if (userCheckResponse.isValid) {
        handleLoginSuccess(userData);
      } else {
        toast.error("User validation failed. Please try again.");
      }
    } catch (error) {
      console.error("Google login error:", error);
      toast.error("Google login failed. Please try again.");
    }
  };

  const handleGoogleFailure = (error) => {
    console.error("Google login failed:", error);
    toast.error("Google login failed. Please ensure you're connected to the internet and try again.");
  };

  const handleGoogleLogin = () => {
    window.google.accounts.id.prompt((notification) => {
      if (notification.isNotDisplayed()) {
        console.error("Google login prompt was not displayed");
        toast.error("Google login prompt was not displayed.");
      } else if (notification.isSkippedMoment()) {
        console.log("Google login prompt was skipped");
        toast.error("Google login was skipped.");
      } else {
        // Successfully displayed the login prompt
        console.log("Google login prompt displayed");
      }
    });
  };

  const loadGoogleScript = () => {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.defer = true;
    script.onload = () => {
      window.google.accounts.id.initialize({
        client_id:"1077855235576-pvkhaga0uqmo4s9rih9as02rg1gvfi9n.apps.googleusercontent.com",
        callback: handleGoogleSuccess,
      });
      window.google.accounts.id.renderButton(
        document.getElementById("google-button"),
        { theme: "outline", size: "large" } // Customize the button here
      );
    };
    document.body.appendChild(script);
  };

  useEffect(() => {
    loadGoogleScript();
  }, []);

  const checkGoogleUser = async (email, name, sub) => {
    // Your logic to check the user with your backend
    setLoading(true);
    const formDataToSend = new FormData();
    formDataToSend.append('email', email);
    formDataToSend.append('name', name);
    formDataToSend.append('sub', sub);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.googleLogin}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`
          }
        }
      );

      if (response.data.status === "success") {
        localStorage.setItem("googlelogin", true);
        localStorage.setItem("user_id", response.data.user_data.id);
        localStorage.setItem("user_name", response.data.user_data.user_name);
        localStorage.setItem("email", response.data.user_data.email);
        localStorage.setItem("profileURL", response.data.user_data.profileURL || man);

        const isValidSubscription = await checkSubscription(response.data.user_data.id);
        localStorage.setItem("isSubscriptionValid", isValidSubscription);

        if (isValidSubscription) {
          navigate(`/`);
          toast.success(response.data.message);
          return { isValid: true, userData: response.data.user_data };

        } else {
          navigate(`/users/userEdit`);
          toast.success(response.data.message);
          return { isValid: true, userData: response.data.user_data };
        }
      } else {
        toast.error(response.data.message);
        return { isValid: false };
      }
    } catch (error) {
      console.error("Error checking Google user:", error);
      toast.error("An error occurred while checking Google user.");
      return { isValid: false };
    } finally {
      setLoading(false);
    }
  };

  const checkFacebookUser = async (email, name, sub) => {
    setLoading(true);

    // Check if sub (facebook_id) is provided
    if (!sub) {
      toast.error("Facebook ID is required.");
      setLoading(false);
      return { isValid: false, message: "Facebook ID is required." };
    }

    const formDataToSend = new FormData();
    formDataToSend.append('email', email);
    formDataToSend.append('name', name);
    formDataToSend.append('sub', sub);

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.facebookLogin}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`
          }
        }
      );

      if (response.data.status === "success") {
        localStorage.setItem("facebookLogin", true);
        localStorage.setItem("user_id", response.data.user_data.id);
        localStorage.setItem("user_name", response.data.user_data.user_name);
        localStorage.setItem("email", response.data.user_data.email);
        localStorage.setItem("profileURL", response.data.user_data.profileURL || man);


        const isValidSubscription = await checkSubscription(response.data.user_data.id);
        localStorage.setItem("isSubscriptionValid", isValidSubscription);

        if (isValidSubscription) {
          navigate(`/`);
          toast.success(response.data.message);
          return { isValid: true, userData: response.data.user_data };

        } else {
          navigate(`/users/userEdit`);
          toast.success(response.data.message);
          return { isValid: true, userData: response.data.user_data };
        }

      } else {
        toast.error(response.data.message);
        return { isValid: false };
      }
    } catch (error) {
      console.error("Error checking Facebook user:", error);
      toast.error("An error occurred while checking Facebook user.");
      return { isValid: false, error };
    } finally {
      setLoading(false);
    }
  };

  const responseFacebook = async (response) => {
    try {
      const userData = {
        profileURL: response.picture ? response.picture.data.url : '',
        email: response.email,
        name: response.name,
        sub: response.id
      };

      const userCheckResponse = await checkFacebookUser(userData.email, userData.name, userData.sub);

      if (userCheckResponse.isValid) {
        handleLoginSuccess(userData);

      } else {
        toast.error("User validation failed. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred during the Facebook login process.");
      console.error("Facebook login error:", error);
    }
  };

  const handleFacebookFailure = (error) => {
    console.error("Facebook login failed:", error);
    toast.error("Facebook login failed.");
  };

  useEffect(() => {
    localStorage.setItem("profileURL", userData.profileURL);
    localStorage.getItem("user_id", userData.user_id);
    localStorage.getItem("user_name", userData.user_name);
  }, [userData]);
  const handleSignupClick = () => {
    setLoading(true); // Show spinner
    setTimeout(() => {
        navigate('/signup'); // Navigate to signup
        setLoading(false); // Hide spinner once navigation completes
    }, 1000); // Simulate a small delay
};

  return (
    <>
      <MyLoader active={loading} >
        <div className="login-container d-flex">
        <div className="background-image">
                            <div className="p-3 text-white justify-content-end text-end align-items-center">
                                <div className="">
                                    <div className="d-flex justify-content-end">
                                        <img src={Loginbg} className="Logosvg-main1" alt="background" />
                                    </div>
                                    <div className="d-flex align-items-lg-center">
                                        <h2 className="text-white  mb-0 fw-bold ">Your Roadmap to a $1 <br /> Million Business & Beyond</h2>
                                        {/* <h1 className="display-4 fw-bold ">Personal OS</h1> */}
                                    </div>
                                </div>  
                            </div>
                        </div>
          <div className="login-box">
            <div className="auth-heding-main">
              <h2 className="auth-heding">Login</h2>
              <p className="auth-peregraft">Enter your Username and Password</p>
            </div>
            <form>
              <div className="form-group">
                <label className="col-form-label pt-0">Email</label>
                <input
                  className={`form-control ${emailError ? 'is-invalid' : ''}`}
                  type="email"
                  name="email"
                  value={email}
                  placeholder="Enter Your Email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError("");
                  }}
                />
                {emailError && <div className="text-danger">{emailError}</div>}
              </div>
              <div className="form-group">
                <label className="col-form-label">{Password}</label>
                <input
                  className={`form-control ${passwordError ? 'is-invalid' : ''}`}
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordError("");
                  }}
                  placeholder="**********"
                />
                {passwordError && <div className="text-danger">{passwordError}</div>}
              </div>
              <div className="checkbox-solid-info checkbox d-flex justify-content-between">
                <div>

                </div>
                <div className="password-help">
                  <Link to="/forgetPwd" >Forgot password?</Link>
                </div>
              </div>

              {/* {/ {/ <button type="submit" className="login-button">Login</button> /} /} */}
              <div className="form-group  mt-3 mb-0 d-grid">
                <button className="login-button" onClick={loginAuth} >
                  {Login}
                </button>
              </div>
            </form>
            <div className="divider d-flex align-items-center my-3">
                                <p className="text-center fw-bold mx-3 mb-0">OR</p>
                            </div>
            <div className="row my-4">
              <div className="col-lg-12 col-md-12 col-sm-12 my-2">
                <button
                  className="login-button"
                  onClick={(e) => {
                    handleGoogleLogin();
                  }}
                >
                  Sign in with Google
                </button>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12  my-2">
              <FacebookLogin
                appId="511836008262121"
                fields="name,email,picture"
                callback={responseFacebook}
                onFailure={handleFacebookFailure}
                // icon={<FaFacebook className="ms-1 Facebook-icon" />}
                render={renderProps => (
                  <button
                    className="login-button"
                    onClick={renderProps.onClick}
                  >
                    <img alt="Facebook icon" className="logo" />
                    <span className="flex-shrink-0">with Facebook</span>
                  </button>
                )}
              />
            </div>

            <div className="account-auth text-center my-3">
                                    <p className="mb-0">
                                        Don't have an account?
                                        <span
                                            onClick={handleSignupClick}
                                            className='me-1'
                                            style={{ cursor: 'pointer', color: '#00bcd4' }}
                                        >
                                            Signup
                                        </span>
                                    </p>
                                    {loading && (
                                        <MyLoader />
                                    )}
                                </div>
          </div>
        </div >
      </MyLoader >
      <ToastContainer />
    </>
  );
};

export default Signin;