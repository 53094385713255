import React, { useEffect, useState } from "react";

import API from "../../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Vtom = () => {
    const uid = localStorage.getItem("user_id");
    const [formData, setFormData] = useState({
        vtompagename:'',
        vtomsingular:'',
        vtoplural:'',
        TractionItali:'',
        swottab:'',
        
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('page_name', formData.vtompagename);
        formDataToSend.append('VITO_singular', formData.vtomsingular);
        formDataToSend.append('VTO_Plural', formData.vtoplural);
        formDataToSend.append('Traction_Itali', formData.TractionItali);
        formDataToSend.append('SWOT_Teb', formData.swottab);

        
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.add_update_terminology_v_tom}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error during task submission:", error);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_all_terminology_v_tom}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                const data = response.data.data[0];
                setFormData({
                    vtompagename: data.page_name || "",
                    vtomsingular: data.VITO_singular || "",
                    vtoplural: data.VTO_Plural || "",
                    TractionItali: data.Traction_Itali || "",
                    swottab: data.SWOT_Teb || "",

                    
                });
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error during task fetching:', error);
        }
    };


    return (
        <div className="card">
        <div className="card-header">
            <h5 className="card-title mb-0"><i className="fa fa-search-plus mx-2"></i>V/TOM</h5>
        </div>
        <div className="card-body">
            <div className="row">
                <div className="col-sm-12 col-md-6">
                    <div className="form-group mb-2">
                        <label className="col-sm-12 col-form-label"><b>Page Name</b></label>
                        <input
                            className={`form-control `}
                            type="text"
                            placeholder="Enter Page Name"
                            name="vtompagename"
                            value={formData.vtompagename}
                            onChange={handleChange}
                        />

                    </div>
                </div>

                <div className="col-sm-12 col-md-6">
                    <div className="form-group mb-2">
                        <label className="col-sm-12 col-form-label"><b>VITO (singular)</b></label>
                        <input
                            className={`form-control `}
                            type="text"
                            placeholder="Enter VITO (singular)"
                            name="vtomsingular"
                            value={formData.vtomsingular}
                            onChange={handleChange}
                        />
                        
                    </div>
                </div>

                <div className="col-sm-12 col-md-6">
                    <div className="form-group mb-2">
                        <label className="col-sm-12 col-form-label"><b>VTO (Plural)</b></label>
                        <input
                            className={`form-control `}
                            type="text"
                            placeholder="Enter VTO (Plural)"
                            name="vtoplural"
                            value={formData.vtoplural}
                            onChange={handleChange}
                        />
                        
                    </div>
                </div>

                <div className="col-sm-12 col-md-6">
                    <div className="form-group mb-2">
                        <label className="col-sm-12 col-form-label"><b>Traction (Itali)</b></label>
                        <input
                            className={`form-control `}
                            type="text"
                            placeholder="Enter Traction (Itali)"
                            name="TractionItali"
                            value={formData.TractionItali}
                            onChange={handleChange}
                        />
                       
                    </div>
                </div>


                <div className="col-sm-12 col-md-6">
                    <div className="form-group mb-2">
                        <label className="col-sm-12 col-form-label"><b>SWOT (Teb)</b></label>
                        <input
                            className={`form-control`}
                            type="text"
                            placeholder="Enter SWOT (Teb)"
                            name="swottab"
                            value={formData.swottab}
                            onChange={handleChange}
                        />
                        
                    </div>
                </div>
                <div className="d-flex justify-content-end align-content-center">
                    <button type="submit" className="btn btn-success" onClick={handleSubmit}>Save</button>
                  </div>
            </div>
        </div>
    </div>
    );
};

export default Vtom;