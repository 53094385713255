import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { FaPlus, FaUnlockAlt } from "react-icons/fa";
import { Modal, ModalHeader, ModalBody, Button, FormGroup, Label, Input, ModalFooter } from "reactstrap"; // Assuming you're using Bootstrap for modals
import company from "../../../assets/images/user/company.png";
import { ProgressBar } from "react-bootstrap";
import API from "../../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AccountChart = () => {
    const [validated, setValidated] = useState(false);
    const [errors, setErrors] = useState({});
    const uid = localStorage.getItem("user_id");
    const [formData, setFormData] = useState({
        Accountabilitypagename:'',
        Seatsingular:'',
        Seatsplural:'',
        SeatHellersingular:'',
        SeutHoldersPlural:'',
        Responsibilities:'',
        Visionary:"",
        Vitegrator:"",
        Chartsingular:'',
        Chartsplural:'',
       
    });





    const handleChange = (e) => {
        const { name, value } = e.target;
      
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('page_name', formData.Accountabilitypagename);
        formDataToSend.append('seat_singular', formData.Seatsingular);
        formDataToSend.append('seats_plural', formData.Seatsplural);
        formDataToSend.append('seat_heller_singular', formData.SeatHellersingular);
        formDataToSend.append('seut_holders_plural', formData.SeutHoldersPlural);
        formDataToSend.append('roles_and_esponsibilities', formData.Responsibilities);
        formDataToSend.append('visionary', formData.Visionary);
        formDataToSend.append('vitegrator', formData.Vitegrator);
        formDataToSend.append('chart_singular', formData.Chartsingular);
        formDataToSend.append('charts_plural', formData.Chartsplural);

        try {
          const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_update_terminology_accountability_chart}`,
            formDataToSend,
            {
              headers: {
                Authorization: `${API.AUTHORIZATION_TOKEN}`,
              },
            }
          );
      
          if (response.data.status === "success") {
            toast.success(response.data.message);
            fetchData();
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
          console.error("Error during task submission:", error);
          
        }
    };


    useEffect(() => {
        fetchData();
      }, []);
    
      const fetchData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
       
        try {
          const response = await axios.post(
            `${API.BASE_URL}${API.ENDPOINTS.get_all_terminology_accountability_chart}`,
            formDataToSend,
            {
              headers: {
                Authorization: `${API.AUTHORIZATION_TOKEN}`,
              },
            }
          );
    
          if (response.data.status === 'success') {
     
            const data = response.data.data[0];
            setFormData({
                Accountabilitypagename: data.page_name || "",
                Seatsingular: data.seat_singular || "",
                seats_plural: data.Seatsplural || "",
                seat_heller_singular: data.SeatHellersingular || "",
                seut_holders_plural: data.SeutHoldersPlural || "",
                roles_and_esponsibilities: data.Responsibilities || "",
                visionary: data.Visionary || "",
                vitegrator: data.Vitegrator || "",
                chart_singular: data.Chartsingular || "",
                charts_plural: data.Chartsplural || "",
            });
          } else {
            toast.error(response.data.message);
                }
        } catch (error) {
          console.error('Error during task fetching:', error);
        }
      };



    return (
        <div className="card">
        <div className="card-header">
            <h5 className="card-title mb-0"><i className="fa fa-sitemap mx-2"></i>Accountability Chart</h5>
        </div>
        <div className="card-body">
            <div className="row">
                <div className="col-sm-12 col-md-6">
                    <div className="form-group mb-2">
                        <label className="col-sm-12 col-form-label"><b>Page Name</b></label>
                        <input
                            className={`form-control ${errors.Accountabilitypagename ? 'is-invalid' : ''}`}
                            type="text"
                            placeholder="Enter Page Name"
                            name="Accountabilitypagename"
                            value={formData.Accountabilitypagename}
                            onChange={handleChange}
                        />
                        {errors.Accountabilitypagename && <div className="text-danger">{errors.Accountabilitypagename}</div>}
                    </div>
                </div>

                <div className="col-sm-12 col-md-6">
                    <div className="form-group mb-2">
                        <label className="col-sm-12 col-form-label"><b>Seat (singular)</b></label>
                        <input
                            className={`form-control ${errors.Seatsingular ? 'is-invalid' : ''}`}
                            type="text"
                            placeholder="Enter Seat (singular)"
                            name="Seatsingular"
                            value={formData.Seatsingular}
                            onChange={handleChange}
                        />
                        {errors.Seatsingular && <div className="text-danger">{errors.Seatsingular}</div>}
                    </div>
                </div>

                <div className="col-sm-12 col-md-6">
                    <div className="form-group mb-2">
                        <label className="col-sm-12 col-form-label"><b>Seats (Plural)</b></label>
                        <input
                            className={`form-control ${errors.Seatsplural ? 'is-invalid' : ''}`}
                            type="text"
                            placeholder="Enter Seats (Plural)"
                            name="Seatsplural"
                            value={formData.Seatsplural}
                            onChange={handleChange}
                        />
                        {errors.Seatsplural && <div className="text-danger">{errors.Seatsplural}</div>}
                    </div>
                </div>

                <div className="col-sm-12 col-md-6">
                    <div className="form-group mb-2">
                        <label className="col-sm-12 col-form-label"><b>Seat Heller (singular)</b></label>
                        <input
                            className={`form-control ${errors.SeatHellersingular ? 'is-invalid' : ''}`}
                            type="text"
                            placeholder="Enter Traction (Itali)"
                            name="SeatHellersingular"
                            value={formData.SeatHellersingular}
                            onChange={handleChange}
                        />
                        {errors.SeatHellersingular && <div className="text-danger">{errors.SeatHellersingular}</div>}
                    </div>
                </div>


                <div className="col-sm-12 col-md-6">
                    <div className="form-group mb-2">
                        <label className="col-sm-12 col-form-label"><b>Seut Holders (Plural)</b></label>
                        <input
                            className={`form-control ${errors.SeutHoldersPlural ? 'is-invalid' : ''}`}
                            type="text"
                            placeholder="Enter Seut Holders (Plural)"
                            name="SeutHoldersPlural"
                            value={formData.SeutHoldersPlural}
                            onChange={handleChange}
                        />
                        {errors.SeutHoldersPlural && <div className="text-danger">{errors.SeutHoldersPlural}</div>}
                    </div>
                </div>

                <div className="col-sm-12 col-md-6">
                    <div className="form-group mb-2">
                        <label className="col-sm-12 col-form-label"><b>Roles and Responsibilities</b></label>
                        <input
                            className={`form-control ${errors.Responsibilities ? 'is-invalid' : ''}`}
                            type="text"
                            placeholder="Enter Seut Holders (Plural)"
                            name="Responsibilities"
                            value={formData.Responsibilities}
                            onChange={handleChange}
                        />
                        {errors.Responsibilities && <div className="text-danger">{errors.Responsibilities}</div>}
                    </div>
                </div>

                <div className="col-sm-12 col-md-6">
                    <div className="form-group mb-2">
                        <label className="col-sm-12 col-form-label"><b>Visionary</b></label>
                        <input
                            className={`form-control ${errors.Visionary ? 'is-invalid' : ''}`}
                            type="text"
                            placeholder="Enter Visionary"
                            name="Visionary"
                            value={formData.Visionary}
                            onChange={handleChange}
                        />
                        {errors.Visionary && <div className="text-danger">{errors.Visionary}</div>}
                    </div>
                </div>

                <div className="col-sm-12 col-md-6">
                    <div className="form-group mb-2">
                        <label className="col-sm-12 col-form-label"><b>Vitegrator</b></label>
                        <input
                            className={`form-control ${errors.Vitegrator ? 'is-invalid' : ''}`}
                            type="text"
                            placeholder="Enter Vitegrator"
                            name="Vitegrator"
                            value={formData.Vitegrator}
                            onChange={handleChange}
                        />
                        {errors.Vitegrator && <div className="text-danger">{errors.Vitegrator}</div>}
                    </div>
                </div>

                <div className="col-sm-12 col-md-6">
                    <div className="form-group mb-2">
                        <label className="col-sm-12 col-form-label"><b>Chart singular</b></label>
                        <input
                            className={`form-control ${errors.Chartsingular ? 'is-invalid' : ''}`}
                            type="text"
                            placeholder="Enter Chart Singular"
                            name="Chartsingular"
                            value={formData.Chartsingular}
                            onChange={handleChange}
                        />
                        {errors.Chartsingular && <div className="text-danger">{errors.Chartsingular}</div>}
                    </div>
                </div>

                <div className="col-sm-12 col-md-6">
                    <div className="form-group mb-2">
                        <label className="col-sm-12 col-form-label"><b>Charts (plural)</b></label>
                        <input
                            className={`form-control ${errors.Chartsplural ? 'is-invalid' : ''}`}
                            type="text"
                            placeholder="Enter Charts (plural)"
                            name="Chartsplural"
                            value={formData.Chartsplural}
                            onChange={handleChange}
                        />
                        {errors.Chartsplural && <div className="text-danger">{errors.Chartsplural}</div>}
                    </div>
                </div>
                <div className="d-flex justify-content-end align-content-center">
                    <button type="submit" className="btn btn-success" onClick={handleSubmit}>Save</button>
                  </div>
            </div>
        </div>
    </div>
    );
};

export default AccountChart;