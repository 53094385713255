import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const DeleteGoalModal = ({ show, onHide, handleDelete }) => {
  return (
    <Modal centered={true} show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-secondary" onClick={onHide}>Cancel</Button>
        <Button className="delete-btn  btn-danger me-1" onClick={onHide}>Delete</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteGoalModal;
