import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Licenses from "./licenses";
import API from "../../../utils";
import axios from "axios";
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import RenewalModal from "../../../auth/cardDetailsmodal/renewModal";
import MyLoader from "../../common/myloader";
import { Navigate } from "react-router-dom";
import Inviteteammatesmember from "../../invite-teammates/inviteteammates";
import Licences from "./licenceModal/licence";

const Billings = () => {
  const [showRenewalModal, setShowRenewalModal] = useState(false);
  const user_id = localStorage.getItem("user_id");
  const [isSubscriptionExpired, setIsSubscriptionExpired] = useState(false);
  const [expiryDate, setExpiryDate] = useState("");
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [loading, setLoading] = useState(false); // Define loading state

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', user_id);

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_user_details}`, formDataToSend, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`
        },
      });

      if (response.data.status === "success") {
        const userData = response.data.user_data;



        const subscriptionDate = userData.subscription_date ? new Date(userData.subscription_date) : null;
        const subscriptionDuration = userData.subscription_duration ? parseInt(userData.subscription_duration, 10) : 0;

        if (subscriptionDate && subscriptionDuration) {
          subscriptionDate.setDate(subscriptionDate.getDate() + subscriptionDuration);
          const formattedExpiryDate = format(subscriptionDate, "eeee, MMMM d, yyyy");
          setExpiryDate(formattedExpiryDate);
          setIsSubscriptionExpired(subscriptionDate < new Date());
        } else {
          setExpiryDate("Not Available");
          setIsSubscriptionExpired(true);
          localStorage.setItem("isSubscriptionValid", "false");
        }

        setSubscriptionStatus(userData.subscription_status);
      } else {
        Navigate(`/login`);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleRenewalModalShow = () => setShowRenewalModal(true);
  const handleRenewalModalClose = () => setShowRenewalModal(false);

  return (
    <>
      <MyLoader active={loading} >
        <Tabs
          defaultActiveKey="billing"
          id="justify-tab-example"
          className="border-tab nav-primary pt-3 mb-3"
        >
          <Tab eventKey="billing" title="Billing">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title mb-0">Subscription overview:</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <ul className="billing">
                    {!isSubscriptionExpired && (
                      <div className="mt-2">
                        <h4><i className="fa fa-check-square-o"></i>Stripe Billing</h4>
                        <h6>Subscription expires on</h6>
                        <b>{expiryDate}</b>
                      </div>
                    )}
                    {isSubscriptionExpired && (subscriptionStatus === "0" || subscriptionStatus === "2") && (
                      <div className="text-danger mt-2">
                        <h6>Take New subscription For Access The Application</h6>
                        <button
                          className="btn btn-primary"
                          style={{ cursor: 'pointer' }}
                          onClick={handleRenewalModalShow}
                        >
                          Buy new Subscription
                        </button>
                      </div>
                    )}
                    {isSubscriptionExpired && subscriptionStatus === "1" && (
                      <div className="text-danger mt-2">
                        <h6>Your subscription has expired.</h6>
                        <button
                          className="btn btn-primary"
                          style={{ cursor: 'pointer' }}
                          onClick={handleRenewalModalShow}
                        >
                          Renew your subscription
                        </button>
                      </div>
                    )}
                  </ul>
                </div>
              </div>
              {!isSubscriptionExpired && (
                <div className="card-body">
                  <Licences />
                </div>
              )}
            </div>
          </Tab>
          {!isSubscriptionExpired && (
            <Tab eventKey="licenses" title="Licenses">
              <Inviteteammatesmember />
            </Tab>
          )}
        </Tabs>
        <RenewalModal loading={loading} show={showRenewalModal} handleClose={handleRenewalModalClose} fetchData={fetchData} />
      </MyLoader>
    </>
  );
};

export default Billings;
