import React, { useEffect, useState } from 'react';
import ReactFlow, { Background, Controls, MiniMap, useNodesState, useEdgesState } from 'reactflow';
import { Card, CardBody, CardHeader, Col, Container, Row } from "react-bootstrap";
import 'reactflow/dist/style.css';
import API from '../../../utils';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';

const Salesfeacthdata = () => {
    const user_id = localStorage.getItem('user_id');
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const index = queryParams.get('index'); // Extract the index from the URL
    const spacingY = 150;
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [cardBodies, setCardBodies] = useState([]);
    const [currentCardIndex, setCurrentCardIndex] = useState(null);

    const shapeStyles = {
        Rectangle: { borderRadius: '0%' },
        Round: {
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'center', 
            width: '80px',
            height: '80px', 
            borderRadius: '50%' 
        },
        Diamond: {
            width: '100px',
            height: '100px',
            backgroundColor: 'green',
            clipPath: 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            fontSize: '12px',
        },
    };

    useEffect(() => {
        if (index) {
            fetchData(index); // Fetch data for the specific index
        }
    }, [index]);

    const fetchData = async (index) => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', user_id);
            formDataToSend.append('category_name', "Sales");
            formDataToSend.append('index', index); // Send index to fetch data for specific workflow

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_founderos_data_by_cat_name}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`
                },
            });

            if (response.data.status === "success") {
                const data = response.data.data[0];
                const workflowData = data.workflow_name;

                const updatedCardBodies = Object.keys(workflowData).map(idx => {
                    if (idx === index) {
                        const { workflow_name, shapes } = workflowData[idx];

                        // Create nodes with the Process Stepss
                        const nodes = workflow_name.map((name, idx) => ({
                            id: (idx + 1).toString(),
                            data: { label: name },
                             position: { x: 250, y: 100 + (idx * spacingY) }, // Use consistent spacing
                            draggable: false,
                            style: { backgroundColor: 'green', color: 'white', ...shapeStyles[shapes[idx]] },
                        }));

                        // Add Start node
                        nodes.unshift({
                            id: 'start',
                            data: { label: 'Start' },
                            position: { x: 50, y: 100 },
                            draggable: false,
                             style: { backgroundColor: 'black', color: 'white', ...shapeStyles['Rectangle'] },
                        });

                        // Add End node
                        nodes.push({
                            id: 'end',
                            data: { label: 'End' },
                               position: { x: 250, y: 100 + (nodes.length * spacingY) },
                            draggable: false,
                             style: { backgroundColor: 'black', color: 'white', ...shapeStyles['Rectangle'] },
                        });

                        // Create edges
                        const edges = nodes.slice(0, -1).map((node, idx) => ({
                            id: `e-${node.id}-${nodes[idx + 1].id}`,
                            source: node.id,
                            target: nodes[idx + 1].id,
                            animated: true,
                        }));

                        return {
                            id: Date.now().toString(),
                            nodes: [...nodes],
                            edges: [...edges],
                            visible: true,
                        };
                    }
                    return null;
                }).filter(item => item !== null);

                setCardBodies(updatedCardBodies);
                setCurrentCardIndex(index);

            } else {
                console.error('Error: Status not success');
            }
        } catch (error) {
            console.error('Error fetching workflow steps:', error);
        }
    };

    return (
        <Container fluid>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader>
                            <div className='justify-content-between d-flex'>
                            <div className=''>
                                <h5>Sales Work Flow</h5>
                            </div>
                            <div>
                                  <Link className='backto-ondriver' to="/sales">Back to the Founder OS </Link>
                                  </div>
                                  </div>

                        </CardHeader>
                        <CardBody>
                            {cardBodies.map((cardBody) => (
                                <div key={cardBody.id} style={{ marginBottom: '20px' }}>
                                    {cardBody.visible && (
                                        <div style={{ height: '500px', width: '100%' }}>
                                            <ReactFlow
                                                key={cardBody.id}
                                                nodes={cardBody.nodes}
                                                edges={cardBody.edges}
                                                onNodesChange={onNodesChange}
                                                onEdgesChange={onEdgesChange}
                                            >
                                                <Background />
                                                <Controls />
                                                <MiniMap />
                                            </ReactFlow>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Salesfeacthdata;
