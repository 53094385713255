import React, { useEffect, useState, useCallback } from "react";
import { Button } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import axios from "axios";
import { toast } from "react-toastify";
import debounce from 'lodash/debounce';
import API from "../../../utils";

const GoalScorecard = () => {
    const [annualOutcomes, setAnnualOutcomes] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [formData, setFormData] = useState({
        metrictype: '',
        metric: '',
        actual: '',
        target: '',
        outcomes: '',
        reason: ''
    });

    const user_id = localStorage.getItem('user_id');

    const handleSubmit = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', user_id);
        formDataToSend.append('metric_type', formData.metrictype);
        formDataToSend.append('metric', formData.metric);
        formDataToSend.append('target', formData.target);
        formDataToSend.append('actual', formData.actual);

        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_reporting_goal_scorecard}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        'Content-Type': 'multipart/form-data'
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success("Goal Added Successfully");
                setFormData({
                    metrictype: "",
                    metric: "",
                    target: "",
                    actual: "",
                });
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("An error occurred while submitting data.");
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredEntries = annualOutcomes.filter(entry =>
        entry.metric_type.toLowerCase().includes(searchQuery.toLowerCase()) ||
        entry.metric.toLowerCase().includes(searchQuery.toLowerCase()) ||
        entry.target.toLowerCase().includes(searchQuery.toLowerCase()) ||
        entry.actual.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const [updatedTask, setUpdatedTask] = useState(null);

    const debouncedUpdateTask = useCallback(
        debounce(async (task) => {
            const { goalid, field, value } = task;
            const formDataToSend = new FormData();
            formDataToSend.append('scorecard_id', goalid);
            formDataToSend.append('field', field);
            formDataToSend.append('text', value);

            try {
                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.update_reporting_goal_scorecard}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );

                if (response.data.status === "success") {
                    toast.success("Goal Updating Successfully");
                    fetchData();
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error("Error during update:", error);
            }
        }, 2000),
        []
    );

    const handleEditInputChange = (e, index, field, goalid) => {
        const { value } = e.target;
        const updatedOutcomes = [...annualOutcomes];
        updatedOutcomes[index][field] = value;
        setAnnualOutcomes(updatedOutcomes);

        setUpdatedTask({ field, value, goalid });
    };

    useEffect(() => {
        if (updatedTask) {
            debouncedUpdateTask(updatedTask);
        }
    }, [updatedTask, debouncedUpdateTask]);

    const columns = [
        {
            name: 'Metric Type',
            selector: (row, index) => (
                <div className="repoting-table">
                    <select
                        type="text"
                        value={row.metric_type}
                        className="form-control"
                        onChange={(e) => handleEditInputChange(e, index, "metric_type", row.id)}
                    >
                        <option value="">Select</option>
                        <option value="Outcomes">Outcomes</option>
                        <option value="Leading Indicator">Leading Indicator</option>
                    </select>
                </div>
            ),
            sortable: true,
        },
        {
            name: 'Metric',
            selector: (row, index) => (
                <div className="repoting-table">
                    <input
                        type="text"
                        value={row.metric}
                        className="form-control"
                        onChange={(e) => handleEditInputChange(e, index, "metric", row.id)}
                    />
                </div>
            ),
            sortable: true,
        },
        {
            name: 'TARGET',
            selector: (row, index) => (
                <div className="repoting-table">
                    <input
                        type="text"
                        value={row.target}
                        className="form-control"
                        onChange={(e) => handleEditInputChange(e, index, "target", row.id)}
                    />
                </div>
            ),
            sortable: true,
        },
        {
            name: 'Actual',
            selector: (row, index) => (
                <div className="repoting-table">
                    <input
                        type="text"
                        value={row.actual}
                        className="form-control"
                        onChange={(e) => handleEditInputChange(e, index, "actual", row.id)}
                    />
                </div>
            ),
            sortable: true,
        },
    ];

    const fetchData = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', user_id);

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_all_reporting_goal_scorecard}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                // setAnnualOutcomes(response.data.data);
                const sortedTasks = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setAnnualOutcomes(sortedTasks);

            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("An error occurred while fetching data.");
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const isSaveButtonEnabled = () => formData.metrictype.trim() !== '';

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="align-items-center justify-content-between d-flex">
                                    <h5>Goal Scorecard</h5>
                                </div>
                            </div>
                        </div>

                        <div className="card-body">
                            <form className="theme-form">
                                <div className="row">
                                    <div className="mb-3 col-lg-4 col-md-6">
                                        <label className="col-form-label pt-0"><b>Metric Type</b></label>
                                        <select
                                            className="form-select"
                                            name="metrictype"
                                            value={formData.metrictype}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select</option>
                                            <option value="Outcomes">Outcomes</option>
                                            <option value="Leading Indicator">Leading Indicator</option>
                                        </select>
                                    </div>
                                    <div className="mb-3 col-lg-4 col-md-6">
                                        <label className="col-form-label pt-0"><b>Metric</b></label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Metric"
                                            name="metric"
                                            value={formData.metric}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-3 col-lg-4 col-md-6">
                                        <label className="col-form-label pt-0"><b>Target</b></label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            placeholder="Target"
                                            name="target"
                                            value={formData.target}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-3 col-lg-4 col-md-6">
                                        <label className="col-form-label pt-0"><b>Actual</b></label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            placeholder="Actual"
                                            name="actual"
                                            value={formData.actual}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end align-content-center mb-2">
                                    <Button variant="success"
                                        disabled={!isSaveButtonEnabled()}
                                        onClick={handleSubmit}>Save</Button>
                                </div>
                            </form>
                            <div className="row">
                                <div className="col-md-9"></div>
                                <div className="col-md-3">
                                    <div className="form-group text-end mb-3 ">
                                        <input className="form-control" type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchChange} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <DataTable
                                    columns={columns}
                                    data={filteredEntries}
                                    persistTableHead={true}
                                    pagination
                                    className="altimate-table"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GoalScorecard;
