import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../../utils';
import axios from 'axios';
import { debounce } from 'lodash';

const DynamicStep = ({ questions, formData, handleChange }) => (
    <div className='row'>
        {questions.map((question, index) => {
            const isLast = index === questions.length - 1;
            const colClass = isLast ? "col-lg-12" : "col-md-6 col-lg-6 col-sm-12";

            return (
                <div key={index} className={colClass}>
                    <div className="form-group mb-2">
                        <label><b>{question.question}</b></label>
                        <textarea
                            className="form-control"
                            name={question.question_id}
                            value={formData[question.question_id]}
                            onChange={(e) => handleChange(e, question.question_id)}
                        />
                    </div>
                </div>
            );
        })}
    </div>
);


const SevenStrata = () => {
    const [questions, setQuestions] = useState([]);
    const uid = localStorage.getItem('user_id') || '';
    const [formData, setFormData] = useState({});

    useEffect(() => {
        fetchQuestions();
    }, []);

    const fetchQuestions = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('b_s_m_c_id', "1");

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_business_strategy_question}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    },
                }
            );
            if (response.data.status === "success") {
                setQuestions(response.data.data || []);
                const initialFormData = {};
                (response.data.data || []).forEach((question) => {
                    initialFormData[question.question_id] = question.answer || '';
                });
                setFormData(initialFormData);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const debouncedHandleChange = useCallback(
        debounce(async (question_id, value) => {
            const formDataToSend = new FormData();
            formDataToSend.append('question_id[1]', question_id);
            formDataToSend.append('answer[1]', value);
            formDataToSend.append('user_id', uid);

            try {
                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.add_update_business_strategy}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );

                if (response.data.status === 'success') {
                    // toast.success(response.data.message);
                    fetchQuestions();
                } else {
                    toast.error(response.data.message || 'Failed to update answer');
                }
            } catch (error) {
                console.error('Error updating answer:', error);
                toast.error('Failed to update answer. Please try again later.');
            }
        }, 2000), 
        [uid] 
    );

    const handleChange = (e, question_id) => {
        const { value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [question_id]: value
        }));
        debouncedHandleChange(question_id, value);
    };

    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-header">
                        <h5>7 Strata</h5>
                    </div>
                    <form>
                        <div className="card-body m-3">
                            <DynamicStep questions={questions} formData={formData} handleChange={handleChange}  />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default SevenStrata;
