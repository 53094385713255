import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../../utils';
import axios from 'axios';
import { debounce } from 'lodash';

const DynamicStep = ({ questions, formData, handleChange, errors }) => (
    <div className='row'>
        {questions.map((question, index) => (
            <div key={index} className="col-md-12 col-lg-12 col-sm-12">
                <div className="form-group mb-2">
                    <label><b>{question.question}</b></label>
                    <textarea
                        className={`form-control ${errors[question.question_id] ? 'is-invalid' : ''}`}
                        name={question.question_id}
                        value={formData[question.question_id]}
                        onChange={handleChange}
                    />
                    {errors[question.question_id] && <div className="text-danger">{errors[question.question_id]}</div>}
                </div>
            </div>
        ))}
    </div>
);

const Vision = () => {
    const [errors, setErrors] = useState({});
    const [questions, setQuestions] = useState([]);
    const uid = localStorage.getItem('user_id') || '';
    const [formData, setFormData] = useState({});

    useEffect(() => {
        fetchQuestions();
    }, []);

    const fetchQuestions = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('b_s_m_c_id', "2");

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_business_strategy_question}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    },
                }
            );
            if (response.data.status === "success") {
                setQuestions(response.data.data || []);
                const initialFormData = {};
                (response.data.data || []).forEach((question) => {
                    initialFormData[question.question_id] = question.answer || '';
                });
                setFormData(initialFormData);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const validate = () => {
        let newErrors = {};
        questions.forEach((question) => {
            if (!formData[question.question_id]) {
                newErrors[question.question_id] = "This field is required";
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const debouncedSave = useCallback(
        debounce(async (updatedData) => {
            const formDataToSend = new FormData();
            Object.keys(updatedData).forEach((key, index) => {
                formDataToSend.append(`question_id[${index + 1}]`, key);
                formDataToSend.append(`answer[${index + 1}]`, updatedData[key] || '');
            });

            formDataToSend.append('user_id', uid);

            try {
                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.add_update_business_strategy}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );

                if (response.data.status === 'success') {
                    fetchQuestions();
                    toast.success(response.data.message);
                } else {
                    toast.error(response.data.message || 'Failed to update answers');
                }
            } catch (error) {
                console.error('Error updating answers:', error);
                toast.error('Failed to update answers. Please try again later.');
            }
        }, 1000),
        [uid, questions]
    );

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => {
            const updatedData = {
                ...prevData,
                [name]: value
            };
            debouncedSave(updatedData);
            return updatedData;
        });
    };

    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-header">
                        <h5>Vision Summary</h5>
                    </div>
                    <div className="card-body m-3">
                        <DynamicStep questions={questions} formData={formData} handleChange={handleChange} errors={errors} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Vision;
