import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, CardHeader, Container, Modal, Row } from "react-bootstrap";
import { FaEye, FaEdit, FaTrash } from "react-icons/fa";
import axios from 'axios';
import API from "../../../utils";
import { toast } from "react-toastify";

const thStyle = {
    border: "1px solid gray",
    padding: "8px",
    textAlign: "left",
    background: "black",
    color: "white"
};

const tbodystyle = {
    height: "200px",
    overflowY: "auto", // Only vertical scrolling
};

const AchievementTracker = () => {
    const [showModal, setShowModal] = useState(false);
    const [currentAchievement, setCurrentAchievement] = useState(null);
    const [achievementsData, setAchievementsData] = useState([]);
    const [formData, setFormData] = useState({
        achievement_name: "",
        description: "",
        date: ""
    });
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});
    const user_id = localStorage.getItem('user_id');

    const toggleModal = (achievement = null) => {
        setCurrentAchievement(achievement);
        setShowModal(!showModal);

        if (!achievement) {
            setFormData({
                achievement_name: "",
                description: "",
                date: new Date().toLocaleDateString("en-US")
            });
            setValidationErrors({});
        } else {
            // Populate formData with selected achievement for editing
            setFormData({
                achievement_name: achievement.achievement_name,
                description: achievement.description,
                
                date: achievement.date
            });
        }
    };

    useEffect(() => {
        fetchAchievements();
    }, [user_id]);

    const fetchAchievements = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', user_id);

        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_all_achievement}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                }
            });
            if (response.data.status === "success") {
                setAchievementsData(response.data.data);
            } else {
                console.error("Failed to fetch achievements");
            }
        } catch (error) {
            console.error("Error fetching achievements:", error.message);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        setValidationErrors({
            ...validationErrors,
            [name]: ""
        });
    };

    const handleSubmit = async () => {
        const errors = validateForm(formData);
        if (Object.keys(errors).length === 0) {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', user_id);
            formDataToSend.append('achievement_name', formData.achievement_name);
            formDataToSend.append('description', formData.description);
            formDataToSend.append('date', formData.date);

            try {
                const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_achievements}`, formDataToSend, {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    }
                });

                if (response.data.status === "success") {
                    toast.success("Achievement Added Successfully");
                    setFormData({
                        achievement_name: "",
                        description: "",
                        date: new Date().toLocaleDateString("en-US")
                    });
                    setValidationErrors({}); // Clear validation errors
                    toggleModal();
                    fetchAchievements();
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error("Error:", error.message);
            }
        } else {
            setValidationErrors(errors);
        }
    };

    const validateForm = (data) => {
        let errors = {};

        if (!data.achievement_name.trim()) {
            errors.achievement_name = "This field is required";
        }

        if (!data.description.trim()) {
            errors.description = "This field is required";
        }

        return errors;
    };

    const handleEdit = async () => {
        const errors = validateForm(formData);
        if (Object.keys(errors).length === 0) {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', user_id);
            formDataToSend.append('achievement_name', formData.achievement_name);
            formDataToSend.append('description', formData.description);
            formDataToSend.append('date', formData.date);
            formDataToSend.append('id', currentAchievement.id); // Assuming each achievement has an id

            try {
                const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.update_achievements}`, formDataToSend, {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    }
                });

                if (response.data.status === "success") {
                    toast.success("Achievement Updated Successfully");
                    toggleModal();
                    fetchAchievements();
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error("Error:", error.message);
            }
        } else {
            setValidationErrors(errors);
        }
    };

    const handleDelete = async (id) => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('id', id);
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.delete_achievement}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`
                }
            });

            if (response.data.status === "success") {
                toast.success("Achievement Deleted Successfully");
                fetchAchievements();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error:", error.message);
        }
    };

    const truncateText = (text, limit) => {
        const words = text.split(" ");
        if (words.length > limit) {
            return words.slice(0, limit).join(" ") + "...";
        }
        return text;
    };
    const handleView = (item) => {
        setSelectedItem(item);
        toggleViewModal();
    };
    const toggleViewModal = () => {
        setIsViewModalOpen(!isViewModalOpen);
    };
    return (
        <>
            <Container fluid={true}>
                <Card>
                    <CardHeader>
                        <div className="row">
                            <div className="align-items-center justify-content-between d-flex">
                                <h5>Achievement Tracker</h5>
                                <Button className="btn-success main-buttons" onClick={() => toggleModal()}>Add Achievement</Button>
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            {achievementsData && achievementsData.length > 0 ? achievementsData.map((achievement, index) => (
                                <div key={index} className="col-lg-6 col-md-4 col-sm-6 achievement-section">
                                    <div className="card bg-primary">
                                        <div className="card-body">
                                            <div className="d-flex faq-widgets">
                                                <div className="flex-grow-1">
                                                    <h5>{achievement.achievement_name}</h5>
                                                    <p>{truncateText(achievement.description, 50)}</p> {/* Truncated Description */}
                                                    <p><small>Date: {new Date(achievement.date).toLocaleDateString("en-US")}</small></p> {/* Date Stamp */}
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <FaEye className="ml-2 mx-2 achivementdata-icon"  onClick={() => handleView(achievement)} />
                                                    <FaEdit className="ml-2 mx-2 achivementdata-icon" onClick={() => toggleModal(achievement)} />
                                                    <FaTrash className="ml-2 mx-2 achivementdata-icon" onClick={() => handleDelete(achievement.id)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) : <p className="text-center"><b>No Achievements available.</b></p>}
                        </Row>
                    </CardBody>
                </Card>

                <Modal show={showModal} onHide={() => toggleModal()} className=''>
                    <Modal.Header closeButton>
                        <Modal.Title>{currentAchievement ? 'Edit Achievement' : 'Add Achievement'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={currentAchievement ? tbodystyle : null}>
                        <form>
                            <div className="form-group">
                                <label htmlFor="achievement_name">Achievement Name</label>
                                <input
                                    type="text"
                                    id="achievement_name"
                                    name="achievement_name"
                                    value={formData.achievement_name}
                                    onChange={handleInputChange}
                                    className={`form-control ${validationErrors.achievement_name ? "is-invalid" : ""}`}
                                />
                                {validationErrors.achievement_name && <div className="invalid-feedback">{validationErrors.achievement_name}</div>}
                            </div>

                            <div className="form-group">
                                <label htmlFor="description">Description</label>
                                <textarea
                                    id="description"
                                    name="description"
                                    rows="4"
                                    value={formData.description}
                                    onChange={handleInputChange}
                                    className={`form-control ${validationErrors.description ? "is-invalid" : ""}`}
                                />
                                {validationErrors.description && <div className="invalid-feedback">{validationErrors.description}</div>}
                            </div>

                            <div className="form-group">
                                <label htmlFor="date">Date</label>
                                <input
                                    type="date"
                                    id="date"
                                    name="date"
                                    value={formData.date}
                                    onChange={handleInputChange}
                                    className={`form-control ${validationErrors.date ? "is-invalid" : ""}`}
                                />
                                {validationErrors.date && <div className="invalid-feedback">{validationErrors.date}</div>}
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => toggleModal()}>Close</Button>
                        <Button variant="primary" onClick={currentAchievement ? handleEdit : handleSubmit}>
                            {currentAchievement ? "Update" : "Save"}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div className="card-body btn-showcase">
                {isViewModalOpen && (
                    <Modal centered={true} show={isViewModalOpen} onHide={toggleViewModal} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>Achievement Tracker</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <table className="table">
                                <thead></thead>
                                <tbody>
                                    <tr>
                                        <th style={thStyle} colSpan={2}>Achievement Tracker</th>
                                    </tr>
                                    <tr>
                                        <td>Achievement Name</td>
                                        <td>{selectedItem.date}</td>
                                    </tr>
                                    <tr>
                                        <td>Description</td>
                                        <td>{selectedItem.description}</td>
                                    </tr>
                                    <tr>
                                        <td>Date</td>
                                        <td>{selectedItem.date}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-secondary" onClick={toggleViewModal}>Close</button>
                        </Modal.Footer>
                    </Modal>
                )}
            </div>
            </Container>
        </>
    );
};

export default AchievementTracker;
