import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { MdDelete } from 'react-icons/md';
import DataTable from 'react-data-table-component';
import { FaEye, FaRegEdit } from "react-icons/fa";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from "../../../utils";
import axios from 'axios';

const Agenda = () => {
    const uid = localStorage.getItem("user_id");
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [formData, setFormData] = useState({
        name: '',
        meeting_name: '',
        agendaItems: []
    });
    const [validationErrors, setValidationErrors] = useState({});
    const [annualOutcomes, setAnnualOutcomes] = useState([]);
    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
       
    };

    const handleCancel = () => {
        setFormData({
            name: '',
            meeting_name: '',
            agendaItems: []
        });
        setIsPopupOpen(false);
    };
    const fetchData = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_meeting_agenda}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success" && Array.isArray(response.data.info)) {
                const sortedTasks = response.data.info.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setAnnualOutcomes(sortedTasks);
            } else {
                setAnnualOutcomes([]);
                toast.error(response.data.message);
            }

        } catch (error) {
            console.error('Fetch gratitude error', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
    
      if (formData.agendaItems) {
            formData.agendaItems
                .filter(item => item.checked) 
                .forEach((item) => {
                    formDataToSend.append('agenda[]', item.item);
                });
        }
    
        formDataToSend.append('meeting_name', formData.meeting_name);
    
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.add_meeting_agenda}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );
    
            if (response.data.status === "success") {
                toast.success("Agenda Added Successfully");
    
                setFormData({
                    meeting_name: '',
                    agendaItems: []
                });
    
                setAgendaItemInput('');
                setIsPopupOpen(false);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error during submission:", error);
            toast.error('An error occurred while submitting the form.');
        }
    };
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };
    const toggleDeleteModal = () => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
    };
    const toggleEditModal = () => {
        setIsEditModalOpen(!isEditModalOpen);
        if (isEditModalOpen) {
            setFormData({
                name: '',
            });
          
        }
    };
    const toggleViewModal = () => {
        setIsViewModalOpen(!isViewModalOpen);
    };

    const handleDelete = (item) => {
        setSelectedItem(item);
        toggleDeleteModal();
    };

    const handleEdit = (item) => {
        setSelectedItem(item);
        setFormData({
            name: item.agenda,
            meeting_name:item.meeting_name,
        });
        toggleEditModal();
    };

    const handleDeleteItem = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('agenda_id', selectedItem.id);
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.delete_meeting_agenda}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success("Agenda Deleting Successfully");
                toggleDeleteModal();
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Failed to delete the item.");
        }
    };

    const handleEditItem = async () => {
        const errors = {};
    
        // Validation for agenda name
        if (!formData.name.trim()) {
            errors.name = "This field is required";
        }
    
        if (Object.keys(errors).length === 0) {
            const formDataToSend = new FormData();
            formDataToSend.append('agenda_id', selectedItem.id);
            formDataToSend.append('meeting_name', formData.meeting_name);
            formDataToSend.append('agenda[]', formData.name); // Ensure this is the correct field name
    
            try {
                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.edit_meeting_agenda_name}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );
    
                if (response.data.status === "success") {
                    toast.success("Agenda Updated Successfully");
    
                    // Update your state based on the new data
                    const { meeting_name, agenda } = response.data.info; // Assuming API response contains these
                    const updatedEntries = annualOutcomes.map(entry => {
                        if (entry.id === selectedItem.id) {
                            return {
                                ...entry,
                                meeting_name,  // Update with new meeting name from response
                                agenda,        // Update with new agenda from response
                            };
                        }
                        return entry;
                    });
                    setAnnualOutcomes(updatedEntries);
    
                    // Reset form data and validation errors
                    setFormData({
                        name: '',
                        meeting_name: '',
                    });
                    
                    fetchData(); // Fetch updated data if necessary
                    toggleEditModal(); // Close modal
                } else {
                    toast.error(response.data.message); // Display error message from server
                }
            } catch (error) {
                console.error("Error during submission:", error);
                if (error.response) {
                    console.error("Response data:", error.response.data);
                    console.error("Response status:", error.response.status);
                    console.error("Response headers:", error.response.headers);
                }
                toast.error('An error occurred while submitting the form.');
            }
        } else {
            setValidationErrors(errors); // Set validation errors in state
        }
    };
    

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleView = (item) => {
        setSelectedItem(item);
        toggleViewModal();
    };

    const filteredEntries = annualOutcomes.filter(entry =>
        (entry.team_name || "").toLowerCase().includes((searchQuery || "").toLowerCase())
    );

    const thStyle = {
        border: "1px solid gray",
        padding: "8px",
        textAlign: "left",
        background: "black",
        color: "white"
    };

    const columns = [
        {
            name: 'Meeting Name',
            selector: row => row.meeting_name,
            sortable: true,
        },
        {
            name: 'Agenda',
            selector: row => {
                let agenda = row.agenda;
        
                // Check if agenda is a string and parse it if it starts with '['
                if (typeof agenda === 'string' && agenda.startsWith('[')) {
                    try {
                        agenda = JSON.parse(agenda); // Parse JSON string to an array
                    } catch (error) {
                        console.error("Error parsing agenda:", error);
                        return agenda; // Return original agenda if parsing fails
                    }
                }
        
                // If agenda is an array, format it as "1. item1, 2. item2, ..."
                if (Array.isArray(agenda)) {
                    return agenda.map((item, index) => `${index + 1}. ${item}`).join(', ');
                }
        
                // If agenda is not an array, return it as is
                return agenda || ''; // Return an empty string if agenda is undefined or null
            },
            sortable: true,
        },        
        {
            name: 'Date',
            selector: row => new Date(row.created_at).toLocaleDateString(),
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => (
                <>
                    <div className="d-lg-flex">
                        <Button className="btn-secondary btn-pill main-edit-view-buttons m-1" onClick={() => handleView(row)}><FaEye /></Button>
                        <Button className="btn-secondary btn-pill main-edit-view-buttons m-1" onClick={() => handleEdit(row)}><FaRegEdit /></Button>
                        <Button className="btn-danger btn-pill main-delete-buttons m-1" onClick={() => handleDelete(row)}><MdDelete /></Button>
                    </div>
                </>
            ),
        },
    ];

    const [agendaItemInput, setAgendaItemInput] = useState([]);
    const handleAddAgendaItem = async () => {
        const name = agendaItemInput.trim();
        const formDataToSend = new FormData();
        formDataToSend.append("user_id", uid);
        formDataToSend.append("agenda[]", name);
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.add_meeting_agenda_items}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                setAgendaItemInput("");
               

                setFormData((prevData) => ({
                    ...prevData,
                    agendaItems: [
                        ...prevData.agendaItems,
                        { item: name, checked: false },
                    ],
                }));

                toast.success("Agenda Items Added Successfully");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error during submission:", error);
            toast.error("An error occurred while submitting the form.");
        }
    };
    const handleAgendaItemChange = (index, checked) => {
        setFormData((prevData) => {
            const updatedAgendaItems = [...prevData.agendaItems];
            updatedAgendaItems[index].checked = checked;

            return {
                ...prevData,
                agendaItems: updatedAgendaItems
            };
        });
    };
    const handleDeleteAgendaItem = (index) => {
        const updatedItems = formData.agendaItems.filter((_, i) => i !== index);
        setFormData({ agendaItems: updatedItems });
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className=" align-items-center justify-content-between d-flex">
                                    <h5>Add New Meeting Agenda</h5>
                                    <Button className="btn-success main-buttons" onClick={togglePopup}>Add New Meeting Agenda Template</Button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-9"></div>
                                <div className="col-md-3">
                                    <div className="form-group text-end mb-3">
                                        <input className="form-control" type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchChange} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <DataTable
                                    columns={columns}
                                    data={filteredEntries}
                                    pagination
                                    persistTableHead={true}
                                    className="altimate-table"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isPopupOpen && (
                <Modal centered={true} show={isPopupOpen} onHide={togglePopup}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add New Agenda</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div className="form-group">
                                <label><b>Meeting Name</b></label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Meeting Name"
                                    name="meeting_name"
                                    value={formData.meeting_name}
                                    onChange={handleInputChange}  />
                            </div>

                            <div className="form-group">
                                <label><b>Agenda Items</b></label>
                                <div className="input-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="agendaItems"
                                        placeholder="Enter agenda item"
                                        value={agendaItemInput}
                                        onChange={(e) => setAgendaItemInput(e.target.value)}
                                    />
                                    <Button className="mx-1 agenda_items_btton" variant="primary" onClick={handleAddAgendaItem}>
                                        Add
                                    </Button>
                                </div>
                                <ul className="list-group mt-2">
                                    {formData.agendaItems.map((item, index) => (
                                        <li
                                            key={index}
                                            className="list-group-item d-flex justify-content-between align-items-center"
                                        >
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    checked={item.checked}
                                                    onChange={(e) =>
                                                        handleAgendaItemChange(index, e.target.checked)
                                                    }
                                                />
                                                <span className="ms-2">{item.item}</span>
                                            </div>
                                            <Button
                                                variant="danger"
                                                onClick={() => handleDeleteAgendaItem(index)}
                                            >
                                                <MdDelete />
                                            </Button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-secondary" onClick={handleCancel}>Cancel</button>
                        <button className="btn btn-success" onClick={handleSubmit}>Save</button>
                    </Modal.Footer>

                </Modal>
            )}
            {isDeleteModalOpen && (
                <Modal centered={true} show={isDeleteModalOpen} onHide={toggleDeleteModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Item</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure you want to delete {selectedItem && selectedItem.name}?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-secondary" onClick={toggleDeleteModal}>Cancel</button>
                        <button className="delete-btn  btn-danger me-1" onClick={handleDeleteItem}>Delete</button>
                    </Modal.Footer>
                </Modal>
            )}
            <Modal show={isEditModalOpen} onHide={toggleEditModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Agenda </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                         <div className="form-group">
                                <label><b>Meeting Name</b></label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="meeting_name"
                                    value={formData.meeting_name}
                                    onChange={handleInputChange}  />
                            </div>
                        <div className="mb-3">
                            <label htmlFor="editName" className="form-label"><b>Agenda</b></label>
                            <textarea
                                className="form-control"
                                id="editName"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                            />
                            {validationErrors.name && <span className="text-danger">{validationErrors.name}</span>}
                        </div>

                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={toggleEditModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleEditItem}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="card-body btn-showcase">
                {isViewModalOpen && (
                    <Modal centered={true} show={isViewModalOpen} onHide={toggleViewModal} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>Agenda  Detail</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <table className="table">
                                <thead></thead>
                                <tbody>
                                    <tr>
                                        <th style={thStyle} colSpan={2}>Agenda  Detail</th>
                                    </tr>
                                    <tr>
                                        <td>Agenda</td>
                                        <td>{selectedItem.agenda}</td>
                                    </tr>
                                    <tr>
                                        <td>Agenda</td>
                                        <td>{selectedItem.metting_name}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-secondary" onClick={toggleViewModal}>Close</button>
                        </Modal.Footer>
                    </Modal>
                )}
            </div>
        </div>
    );
};

export default Agenda;