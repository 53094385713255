import React, { useEffect, useState, useCallback } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { debounce } from 'lodash';
import { isSubscribedAccountant } from "../../utils";
import { useNavigate } from "react-router-dom";
import API from "../../utils";

const AddtoDirectory = () => {
    const [forms, setForms] = useState([{ first_name: '', last_name: '', email: '' }]);
    const [updatedForm, setUpdatedForm] = useState(null);
    const uid = localStorage.getItem('user_id');

    // Add a new form entry
    const addForm = () => {
        setForms([...forms, { first_name: '', last_name: '', email: '' }]);
    };

    const debouncedUpdateForm = useCallback(
        debounce(async (form, index) => {
            if (!form.id) return; // Ensure there is an ID to update
    
            const formDataToSend = new FormData();
            formDataToSend.append('id', form.id);
            formDataToSend.append(`first_name[${index}]`, form.first_name);
            formDataToSend.append(`last_name[${index}]`, form.last_name);
            formDataToSend.append(`email[${index}]`, form.email);
    
            try {
                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.update_teammates_to_directory}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
    
                if (response.data.status === "success") {    
                    toast.success(response.data.message);
                    // Update the forms state with the new data from the response
                    setForms(prevForms => prevForms.map((f, i) => 
                        i === index ? {
                            ...f,
                            first_name: response.data.data.first_name[i],
                            last_name: response.data.data.last_name[i],
                            email: response.data.data.email[i],
                        } : f
                    ));
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                toast.error("Failed to update the form. Please try again later.");
            }
        }, 1000),
        []
    );
    
    // Handle form input change
    const handleChange = (index, field, value) => {
        const newForms = [...forms];
        newForms[index][field] = value;
        setForms(newForms);
        setUpdatedForm({ ...newForms[index], index });
    };
    
    // Trigger debounced function when updatedForm changes
    useEffect(() => {
        if (updatedForm) {
            debouncedUpdateForm(updatedForm, updatedForm.index);
        }
    }, [updatedForm, debouncedUpdateForm]);

    // Fetch data and ensure there is at least one form entry
    // const fetchData = async () => {
    //     const formDataToSend = new FormData();
    //     formDataToSend.append('user_id', uid);

    //     try {
    //         const response = await axios.post(
    //             `${API.BASE_URL}${API.ENDPOINTS.get_all_teammates_to_directory}`,
    //             formDataToSend,
    //             {
    //                 headers: {
    //                     Authorization: `${API.AUTHORIZATION_TOKEN}`,
    //                 },
    //             }
    //         );

    //         if (response.data.status === 'success') {
    //             const data = response.data.data || [];
    //             const formattedForms = data.map((item, index) => ({
    //                 id: item.id,
    //                 first_name: Array.isArray(item.first_name) ? item.first_name[index] : '', 
    //                 last_name: Array.isArray(item.last_name) ? item.last_name[index] : '',
    //                 email: Array.isArray(item.email) ? item.email[index] : ''
    //             }));

    //             // Ensure at least one form entry
    //             if (formattedForms.length === 0) {
    //                 formattedForms.push({ first_name: '', last_name: '', email: '' });
    //             }

    //             setForms(formattedForms);
    //         } else {
    //             toast.error(response.data.message);
    //         }
    //     } catch (error) {
    //         console.error('Error during data fetching:', error);
    //     }
    // };

    useEffect(() => {
        fetchData();
    }, []);
    
    const fetchData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
    
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_all_teammates_to_directory}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );
    
            if (response.data.status === 'success') {
                const data = response.data.data || [];
                const formattedForms = data.map((item, index) => ({
                    id: item.id,
                    first_name: Array.isArray(item.first_name) ? item.first_name[index] : '', 
                    last_name: Array.isArray(item.last_name) ? item.last_name[index] : '',
                    email: Array.isArray(item.email) ? item.email[index] : ''
                }));
    
                // Ensure at least one form entry
                if (formattedForms.length === 0) {
                    formattedForms.push({ first_name: '', last_name: '', email: '' });
                }
    
                setForms(formattedForms);
    
                // Target the last index
                const lastIndex = formattedForms.length - 1;
                console.log("Last Index:", lastIndex);
                // Optionally, you can update or manipulate the last form here
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error during data fetching:', error);
        }
    };
    

    const handleSubmit = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        forms.forEach((form, index) => {
            formDataToSend.append(`first_name[${index}]`, form.first_name);
            formDataToSend.append(`last_name[${index}]`, form.last_name);
            formDataToSend.append(`email[${index}]`, form.email);
        });

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.add_teammates_to_directory}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                // Clear the form and fetch the updated data
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error during request:", error);
            toast.error('Failed to add to directory. Please try again later.');
        }
    };

    // Delete a form entry
    const deleteForm = async (index) => {
        const formToDelete = forms[index]; // Get the form to delete using the index

        if (!formToDelete || !formToDelete.id) {
            toast.error('Invalid form data.');
            return;
        }

        const formDataToSend = new FormData();
        formDataToSend.append('id', formToDelete.id);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.delete_teammates_to_directory}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error during delete request:", error);
            toast.error('Failed to delete the form. Please try again later.');
        }
    };

    // Fetch data on component mount
    useEffect(() => {
        fetchData();
    }, []);

    const navigate = useNavigate();
    useEffect(() => {
        if (!isSubscribedAccountant()) {
            navigate("/users/billings");
            window.location.reload();
        }
    }, [navigate]);

    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-6 align-items-center d-flex">
                                <h5>Add People to Directory</h5>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <span className="m-2">
                                <b>Add an entry to the Directory. </b>
                                The entry will still appear in the Org Chart, but won't have a login attached and can't be assigned contents like Rocks, To-Dos, and Issues.
                            </span>
                            {forms.map((form, index) => (
                                <div className="card-body invertmember-addmore my-2 m-3" key={index}>
                                    <div className="card-body">
                                        <div className="row">
                                            {index > 0 && (
                                                <div className="col-md-12 text-end">
                                                    <Button variant="danger" className="invert-member text-end p-0 m-0" onClick={() => deleteForm(index)}>
                                                        <i className="fa fa-times"></i>
                                                    </Button>
                                                </div>
                                            )}
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group mb-3">
                                                    <label className="form-label"><b>First Name</b></label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="First Name"
                                                        name="first_name"
                                                        value={form.first_name}
                                                        onChange={(e) => handleChange(index, 'first_name', e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group mb-3">
                                                    <label className="form-label"><b>Last Name</b></label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Last Name"
                                                        value={form.last_name}
                                                        onChange={(e) => handleChange(index, 'last_name', e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group mb-3">
                                                    <label className="form-label"><b>Email</b></label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Enter Email"
                                                        value={form.email}
                                                        onChange={(e) => handleChange(index, 'email', e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="card-footer col-lg-12 col-md-12 text-end">
                        <Button variant="secondary" className="me-2" onClick={addForm}>Add New User</Button>
                        <Button variant="success" onClick={handleSubmit}>Save</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddtoDirectory;
