import React, { useState } from 'react';
import { Button, ToastContainer } from 'react-bootstrap';
import { MdDelete } from 'react-icons/md';
import API from '../../../utils';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

const ValueImprovement = () => {
    const uid = localStorage.getItem('user_id');
    const [formState, setFormState] = useState({
        leadershipTeam: '',
        businessModel: '',
        valuesExecution: '',
        industryExperience: '',
        eatPains: '',
        houseInOrder: '',
        protectedAssets: '',
        brainstormIdeas: '',
        topIdeas: [{ idea: '', owner: '', deadline: '', inprove: '', build: '', }],
    });


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
        setFormState((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const handleTopIdeasChange = (index, e) => {
        const { name, value, type, checked } = e.target;
        const updatedIdeas = [...formState.topIdeas];
        if (type === 'checkbox') {
            updatedIdeas[index][name] = checked;
        } else {
            updatedIdeas[index][name] = value;
        }
        setFormState({ ...formState, topIdeas: updatedIdeas });
    };

    const addNewIdea = () => {
        setFormState({ ...formState, topIdeas: [...formState.topIdeas, { idea: '', inprove: '', build: '', owner: '', deadline: '' }] });
    };

    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let valid = true;
        const newErrors = {};

        // Validate radio buttons
        const criteriaFields = ['leadershipTeam', 'businessModel', 'valuesExecution', 'industryExperience', 'eatPains', 'houseInOrder', 'protectedAssets'];
        criteriaFields.forEach(field => {
            if (!formState[field]) {
                newErrors[field] = 'This field is required';
                valid = false;
            }
        });

        // Validate brainstormIdeas textarea
        if (!formState.brainstormIdeas.trim()) {
            newErrors.brainstormIdeas = 'This field is required';
            valid = false;
        }

        // Validate top ideas fields
        formState.topIdeas.forEach((idea, index) => {
            if (!idea.idea || !idea.owner || !idea.deadline || (idea.inprove === '' && idea.build === '')) {
                if (!newErrors[`idea${index}`]) newErrors[`idea${index}`] = '';
                if (!newErrors[`owner${index}`]) newErrors[`owner${index}`] = '';
                if (!newErrors[`deadline${index}`]) newErrors[`deadline${index}`] = '';

                if (!idea.idea) newErrors[`idea${index}`] = 'Idea is required';
                if (!idea.owner) newErrors[`owner${index}`] = 'Owner is required';
                if (!idea.deadline) newErrors[`deadline${index}`] = 'Deadline is required';
                if (idea.inprove === '' && idea.build === '') newErrors[`inprovebuild${index}`] = 'At least one option (Improve or build) must be selected';

                valid = false;
            }
        });

        setErrors(newErrors);
        return valid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('long_term_contracts', formState.leadershipTeam);
        formDataToSend.append('auto_renewal_subiscription', formState.businessModel);
        formDataToSend.append('capital_investment_subscription', formState.valuesExecution);
        formDataToSend.append('subscriptions', formState.industryExperience);
        formDataToSend.append('capital_investment_consumables', formState.eatPains);
        formDataToSend.append('consumables', formState.houseInOrder);
        formDataToSend.append('brainstorm_ideas', formState.brainstormIdeas);

        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.Revenue_insert}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error during request:", error);
            toast.error('Failed to submit the form. Please try again later.');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_revenue}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                const data = response.data.data;
                setFormState({
                    leadershipTeam: data.long_term_contracts || "",
                    businessModel: data.auto_renewal_subiscription || "",
                    valuesExecution: data.capital_investment_subscription || "",
                    industryExperience: data.subscriptions || "",
                    eatPains: data.capital_investment_consumables || "",
                    houseInOrder: data.consumables || "",
                    brainstormIdeas: data.brainstorm_ideas || "",
                    topIdeas: data.top_ideas || [], // Set default value to empty array

                });
            } else {
                // toast.error(response.data.message || 'Failed to fetch data.');
            }
        } catch (error) {
            console.error('Error during data fetching:', error);
            //toast.error('Failed to fetch data. Please try again later.');
        }
    };

    const deleteIdea = async (index) => {
        if (formState.topIdeas.length > 1) {
            const idea = formState.topIdeas[index];

            // Create FormData object to send the delete request
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);  // Assuming `uid` is defined and holds the user ID
            formDataToSend.append('idea[]', idea.idea);
            formDataToSend.append('owner[]', idea.owner);
            formDataToSend.append('inprove[]', idea.inprove);
            formDataToSend.append('build[]', idea.build);
            formDataToSend.append('deadline[]', idea.deadline);

            try {
                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.Revenue_ideas_delete}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                            'Content-Type': 'multipart/form-data', // Ensure content type is set for FormData
                        },
                    }
                );

                if (response.data.status === 'success') {
                    // Remove the deleted idea from the local state
                    const updatedIdeas = formState.topIdeas.filter((_, i) => i !== index);
                    setFormState({ ...formState, topIdeas: updatedIdeas });
                    toast.success(response.data.message);
                } else {
                    toast.error(response.data.message || 'Failed to delete idea.');
                }
            } catch (error) {
                console.error('Error during delete request:', error);
                toast.error('Failed to delete idea. Please try again later.');
            }
        }
    };

    const fetchmulData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.Revenue_ideas_get}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                const data = response.data.data;

                // Parse ideas if it exists
                const parsedIdeas = data.ideas ? JSON.parse(data.ideas) : [];

                setFormState({
                    topIdeas: parsedIdeas.map(idea => ({
                        idea: idea.idea || '',
                        owner: idea.owner || '',
                        inprove: idea.owner || '',
                        build: idea.owner || '',
                        deadline: idea.deadline || '',
                    })),
                });
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error during data fetching:', error);
        }
    };

    useEffect(() => {
        fetchmulData();
    }, []);

    const handlemulSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        formState.topIdeas.forEach((idea, index) => {
            formDataToSend.append(`idea[${index}]`, idea.idea);
            formDataToSend.append(`owner[${index}]`, idea.owner);
            formDataToSend.append(`deadline[${index}]`, idea.deadline);
            formDataToSend.append(`inprove[${index}]`, idea.inprove ? 'true' : 'false');
            formDataToSend.append(`build[${index}]`, idea.build ? 'true' : 'false');
        });

        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.Revenue_ideas_insert}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchmulData();

            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error during request:", error);
            toast.error('Failed to submit the form. Please try again later.');
        }
    };

    return (
        <div className="card">
            <div className="card-header">
                <div className="row">
                    <div className="col-md-12 align-items-center d-flex">
                        <h5>Value Improvement from Recurring Revenue</h5>
                    </div>
                </div>
            </div>
            <form >
                <div className="card-body">
                    <div className="form-container">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Individually evaluate your organization based on the following criteria:</th>
                                        <th>None</th>
                                        <th>Week</th>
                                        <th>Solid</th>
                                        <th>Great</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Long-Term Contracts</td>
                                        <td><input type="radio" className="radio_animated" name="leadershipTeam" value="none" checked={formState.leadershipTeam === 'none'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="leadershipTeam" value="week" checked={formState.leadershipTeam === 'week'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="leadershipTeam" value="soild" checked={formState.leadershipTeam === 'soild'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="leadershipTeam" value="great" checked={formState.leadershipTeam === 'great'} onChange={handleInputChange} /></td>
                                    </tr>
                                    <tr>
                                        <td>Auto-Renewal Subiscription</td>
                                        <td><input type="radio" className="radio_animated" name="businessModel" value="none" checked={formState.businessModel === 'none'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="businessModel" value="week" checked={formState.businessModel === 'week'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="businessModel" value="soild" checked={formState.businessModel === 'soild'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="businessModel" value="great" checked={formState.businessModel === 'great'} onChange={handleInputChange} /></td>
                                    </tr>
                                    <tr>
                                        <td>Capital Investment Subscription</td>
                                        <td><input type="radio" className="radio_animated" name="valuesExecution" value="none" checked={formState.valuesExecution === 'none'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="valuesExecution" value="week" checked={formState.valuesExecution === 'week'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="valuesExecution" value="soild" checked={formState.valuesExecution === 'soild'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="valuesExecution" value="great" checked={formState.valuesExecution === 'great'} onChange={handleInputChange} /></td>
                                    </tr>
                                    <tr>
                                        <td>Subscriptions</td>
                                        <td><input type="radio" className="radio_animated" name="industryExperience" value="none" checked={formState.industryExperience === 'none'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="industryExperience" value="week" checked={formState.industryExperience === 'week'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="industryExperience" value="soild" checked={formState.industryExperience === 'soild'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="industryExperience" value="great" checked={formState.industryExperience === 'great'} onChange={handleInputChange} /></td>
                                    </tr>
                                    <tr>
                                        <td>Capital Investment Consumables</td>
                                        <td><input type="radio" className="radio_animated" name="eatPains" value="none" checked={formState.eatPains === 'none'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="eatPains" value="week" checked={formState.eatPains === 'week'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="eatPains" value="soild" checked={formState.eatPains === 'soild'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="eatPains" value="great" checked={formState.eatPains === 'great'} onChange={handleInputChange} /></td>
                                    </tr>
                                    <tr>
                                        <td>Consumables</td>
                                        <td><input type="radio" className="radio_animated" name="houseInOrder" value="none" checked={formState.houseInOrder === 'none'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="houseInOrder" value="week" checked={formState.houseInOrder === 'week'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="houseInOrder" value="soild" checked={formState.houseInOrder === 'soild'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="houseInOrder" value="great" checked={formState.houseInOrder === 'great'} onChange={handleInputChange} /></td>
                                    </tr>
                                </tbody>
                            </table>

                            <div className="form-group brainstorm mb-3">
                                <label><b>Brainstorm Ideas</b></label>
                                <textarea
                                    className={`form-control ${errors.brainstormIdeas && 'is-invalid'}`}
                                    name="brainstormIdeas"
                                    value={formState.brainstormIdeas}
                                    onChange={handleInputChange}
                                    rows="4"
                                    placeholder="Enter A Compensation with Culture and Strategy"></textarea>
                                {errors.brainstormIdeas && <div className="invalid-feedback">{errors.brainstormIdeas}</div>}
                            </div>

                            <div className='card-footer'>
                                <div className='d-flex justify-content-end'>
                                    <Button type="submit" variant="success" className="me-1 float-end" onClick={handleSubmit}>Save</Button>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='d-flex justify-content-between'>
                                    <label className='d-flex align-items-center m-l-15'><b>Top 1-3 Ideas</b></label>
                                    <Button type="button" className='m-r-15' onClick={addNewIdea}>Add Idea</Button>
                                </div>
                            </div>
                            <div className='row m-4'>
                                {formState.topIdeas.map((idea, index) => (
                                    <div key={index} className='col-lg-6 col-sm-12 p-3 shadow shadow-showcase'>
                                        <div className="top-idea">
                                            {formState.topIdeas.length > 1 && (
                                                <div className='col-md-12 my-2 d-flex justify-content-end'>
                                                    <Button className="btn-danger btn-pill main-delete-buttons mx-1" onClick={() => deleteIdea(index)}>
                                                        <MdDelete />
                                                    </Button>
                                                </div>
                                            )}
                                            <div className='col-md-12 my-2'>
                                                <input
                                                    className={`form-control ${errors[`idea${index}`] && 'is-invalid'}`}
                                                    type="text"
                                                    name="idea"
                                                    placeholder="Idea"
                                                    value={idea.idea}
                                                    onChange={(e) => handleTopIdeasChange(index, e)}
                                                />
                                                {errors[`idea${index}`] && <div className="invalid-feedback">{errors[`idea${index}`]}</div>}
                                            </div>
                                            <div className='col-md-12 my-2'>
                                                <input
                                                    className={`form-control ${errors[`owner${index}`] && 'is-invalid'}`}
                                                    type="text"
                                                    name="owner"
                                                    placeholder="Owner"
                                                    value={idea.owner}
                                                    onChange={(e) => handleTopIdeasChange(index, e)}
                                                />
                                                {errors[`owner${index}`] && <div className="invalid-feedback">{errors[`owner${index}`]}</div>}
                                            </div>
                                            <div className="col-md-12 col-lg-6 my-2">
                                                <label className="d-block">
                                                    <b>
                                                        <input
                                                            className="checkbox_animated"
                                                            name="inprove"
                                                            checked={idea.inprove}
                                                            onChange={(e) => handleTopIdeasChange(index, e)}
                                                            type="checkbox"
                                                        />
                                                        Improve
                                                    </b>
                                                </label>
                                            </div>
                                            <div className="col-md-12 col-lg-6 my-2">
                                                <label className="d-block">
                                                    <b>
                                                        <input
                                                            className="checkbox_animated"
                                                            name="build"
                                                            checked={idea.build}
                                                            onChange={(e) => handleTopIdeasChange(index, e)}
                                                            type="checkbox"
                                                        />
                                                        Build
                                                    </b>
                                                </label>
                                            </div>
                                            <div className='col-md-12 my-2'>
                                                <input
                                                    className={`form-control ${errors[`deadline${index}`] && 'is-invalid'}`}
                                                    type="text"
                                                    name="deadline"
                                                    placeholder="Deadline"
                                                    value={idea.deadline}
                                                    onChange={(e) => handleTopIdeasChange(index, e)}
                                                />
                                                {errors[`deadline${index}`] && <div className="invalid-feedback">{errors[`deadline${index}`]}</div>}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>


                        </div>
                    </div>
                </div>

                <div className='card-footer'>
                    <div className='d-flex justify-content-end'>
                        <Button type="submit" variant="success" className="me-1 float-end" onClick={handlemulSubmit}>Save</Button>
                    </div>
                </div>
            </form >
        </div>
    );
};

export default ValueImprovement;
