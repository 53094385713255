import { Home,UserPlus , } from "react-feather";

export const MENUITEMS = [
  {
    title: "All Users",
    icon: Home,
    path: "/admin/user",
    type: "link",
    active: false,
  },
  {
    title: "FounderOS University",
    icon: Home,
    path: "/admin/university",
    type: "link",
    active: false,
  },

  {
    title: "Frequently Asked Questions",
    icon: UserPlus,
    path: "/admin/frequently",
    type: "link",
    active: false,
  },
];