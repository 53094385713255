import React, { useEffect, useState } from 'react';
import { FaPlus, FaUnlockAlt } from 'react-icons/fa';
import ChangePassword from '../../../pages/changepassword';
import CreateNewCompanyModal from '../modals/createNewCompanyModal';
import API from '../../../utils';
import axios from 'axios';
import { toast } from 'react-toastify';
const AccountDetail = () => {
    const primaryEmail = localStorage.getItem("email")
    const uid = localStorage.getItem("user_id")
    const [passwordModalOpen, setPasswordModalOpen] = useState(false);
    const [companyModalOpen, setCompanyModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        PhoneNumber: '',
    });
    const [validated, setValidated] = useState(false);
    const [errors, setErrors] = useState({});

    const togglePasswordModal = () => {
        setPasswordModalOpen(!passwordModalOpen);
    };

    const toggleCompanyModal = () => {
        setCompanyModalOpen(!companyModalOpen);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };
    useEffect(() => {
        fetchData();
    }, []);


    const fetchData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_login_info}`, formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                const number = response.data.data[0].phone;
                setFormData({ PhoneNumber: number });

            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            // toast.error("User Profile update failed.");
            console.error(error);
        }


    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = {};


        if (formData.PhoneNumber.trim() === "") {
            validationErrors.PhoneNumber = "Phone Number is required";
        } else if (!/^\d{10}$/.test(formData.PhoneNumber.trim())) {
            validationErrors.PhoneNumber = "Phone Number must be a 10-digit number";
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        setValidated(true);

        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('number', formData.PhoneNumber);

        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_update_login_info}`, formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("User Profile update failed.");
            console.error(error);
        }


    };

    return (
        <>
            <form>
                {/* <div className="card">
                    <div className="card-header">
                        <h5 className="card-title mb-0">Login Information</h5>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-3">
                                    <label>Primary Email</label>
                                    <input
                                        className={`form-control ${errors.Email ? 'is-invalid' : ''}`}
                                        type="text"
                                        name="Email"
                                        value={primaryEmail}
                                        readOnly
                                    />
                                    <span>This email is used to log in Founder o/s and for platform-related communications.</span>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group mb-3">
                                    <label>Primary Phone Number <span>(Not Verified)</span></label>
                                    <input
                                        className={`form-control ${errors.PhoneNumber ? 'is-invalid' : ''}`}
                                        type="number"
                                        name="PhoneNumber"
                                        placeholder="Enter Your PhoneNumber"
                                        value={formData.PhoneNumber}
                                        onChange={handleChange}
                                    />
                                    {errors.PhoneNumber && <div className="text-danger">{errors.PhoneNumber}</div>}
                                    <span>This phone number is used for MFA Authentication.</span>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body text-end">
                                <button className="btn btn-success" onClick={handleSubmit} type="button">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <div className="card">
                    <div className="card-header">
                        <h5 className="card-title mb-0">Multi-Factor Authentication (MFA)</h5>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-3">
                                    <label>Enable MFA</label>
                                    <div className="form-group mb-3">
                                        <label className="switch">
                                            <input type="checkbox" />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                    <span className="">Upon login, an authentication SMS will be sent to the phone number listed above. Depending on your carrier, SMS charges may apply.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title mb-0">Change Password</h5>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <span>We recommend changing your password every Founders OS days to keep your account secure.</span>
                            </div>
                            <div className="col-md-12">
                                <button type="button" className="btn btn-outline-primary" onClick={togglePasswordModal}><FaUnlockAlt /> Change Password</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* 
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title mb-0">Create a New Founders OS Account</h5>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <span>This account will have separate billing, users, and information from any current companies you are in.</span>
                            </div>
                            <div className="col-md-12">
                                <button className="btn btn-outline-primary" type="button" onClick={toggleCompanyModal}>
                                    <FaPlus className="me-2" /> Create New Company
                                </button>
                            </div>
                        </div>
                    </div>
                </div> */}
            </form>

            <ChangePassword isOpen={passwordModalOpen} toggle={togglePasswordModal} />

            <CreateNewCompanyModal isOpen={companyModalOpen} toggle={toggleCompanyModal} />
        </>
    );
};

export default AccountDetail;
