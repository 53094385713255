import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import API from '../../../utils';

const AdminUserPanel = () => {
    const user_id = localStorage.getItem("user_id");
    const [profile_img, setProfileImg] = useState(null);
    const [user_name, setUserName] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', user_id);

            try {
                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.get_user_details}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`
                        },
                    }
                );
                if (response.data.status === "success") {
                    const userData = response.data.user_data;
                    setProfileImg(userData.profile);
                    setUserName(userData.user_name);
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error(error);
                // toast.error("Failed to fetch user data");
            }
        };
        fetchData();
    }, [user_id]);

    return (
        <Fragment>
            <div className="sidebar-user text-center">
                <div className='d-flex justify-content-between'>
                    <div className='img-user'>
                        <img
                            className="img-45 rounded-circle lazyloaded blur-up"
                            alt=""
                            src={profile_img}
                        />
                    </div>
                    <div className="profile-edit">
                        <Link to={'/admin/profile'}>
                            {/* <Edit /> */}
                        </Link>
                    </div>
                    <div className="px-lg-3 align-items-center d-flex">
                        <h6 className="f-14">{user_name}</h6>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default AdminUserPanel;
