import React, { useEffect, useState } from "react";
import { Container, Row, Nav } from "react-bootstrap";
import { FaArchive, FaEdgeLegacy, FaHome, FaSuperpowers } from "react-icons/fa";
import { TbReportSearch } from "react-icons/tb";
import { FaMaskVentilator } from "react-icons/fa6";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink, useNavigate } from "react-router-dom";
import { isSubscribedAccountant } from "../../utils";

const IndexStrategyDevelopment = () => {

    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        // centerMode: true,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false
                }
            }
        ]
    };

    const navigate = useNavigate()
    useEffect(() => {
      if (!isSubscribedAccountant()) {
        navigate("/users/billings");
        window.location.reload();
      }
    }, []);

    return (
        <Container fluid>
            <div className="mx-3">
                <Slider {...settings}>
                    <Nav variant="pills" className="border-tab nav-primary">
                        <Nav.Item>
                            <Nav.Link as={NavLink} to="/strategy-deveopment/seven-strata" exact>
                                <FaHome className="me-1" /> 7 Strata
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <Nav variant="pills" className="border-tab nav-primary">
                        <Nav.Item>
                            <Nav.Link as={NavLink} exact to="/strategy-deveopment/vision-summry">
                                <TbReportSearch className="me-1" /> Vision Summary
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <Nav variant="pills" className="border-tab nav-primary">
                        <Nav.Item>
                            <Nav.Link as={NavLink} exact to="/strategy-deveopment/brand-promise">
                                <FaEdgeLegacy  className="me-1" /> Brand Promise
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <Nav variant="pills" className="border-tab nav-primary">
                        <Nav.Item>
                            <Nav.Link as={NavLink} exact to="/strategy-deveopment/swot">
                                <FaArchive className="me-1" /> SWOT
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <Nav variant="pills" className="border-tab nav-primary">
                        <Nav.Item>
                            <Nav.Link as={NavLink} exact to="/strategy-deveopment/customer-avtar">
                                <FaMaskVentilator className="me-1" /> Customer Avatar
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <Nav variant="pills" className="border-tab nav-primary">
                        <Nav.Item>
                            <Nav.Link as={NavLink} exact to="/strategy-deveopment/purpose-review">
                                <FaSuperpowers className="me-1" /> Purpose Review
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>

                    {/* <Nav variant="pills" className="border-tab nav-primary">
                        <Nav.Item>
                            <Nav.Link as={NavLink} exact to="/strategy-deveopment/sandbox">
                                <TbReportSearch className="me-1" /> Sandbox
                            </Nav.Link>
                        </Nav.Item>
                    </Nav> */}
                </Slider>
            </div>
        </Container>
    );
};

export default IndexStrategyDevelopment;
