import React, { useEffect, useState } from 'react';
import API from '../../../utils';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FaEye, FaRegEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';

const Fosuniversity = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [users, setUsers] = useState([]);
  const [episodeNumber, setEpisodeNumber] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [btnText, setBtnText] = useState('');
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [isTitleValid, setIsTitleValid] = useState(false);
  const uid = localStorage.getItem("user_id");
  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);

  };
  const toggleViewModal = () => {
    setIsViewModalOpen(!isViewModalOpen);
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const formDataToSend = new FormData();
  //   formDataToSend.append('title', title);
  //   formDataToSend.append('description', description);
  //   formDataToSend.append('btn_text', btnText);
  //   formDataToSend.append('image', image);
  //   formDataToSend.append('video', thumbnailUrl);


  //   try {
  //     const response = await axios.post(
  //       `${API.BASE_URL}${API.ENDPOINTS.create_content}`,
  //       formDataToSend,
  //       {
  //         headers: {
  //           Authorization: `${API.AUTHORIZATION_TOKEN}`,
  //         },
  //       }
  //     );

  //     if (response.data.status === "success") {
  //       toast.success('Video added successfully!');
  //       // Optionally reset form or redirect
  //     } else {
  //       toast.error(response.data.message);
  //     }
  //   } catch (error) {
  //     console.error("Error during submission:", error);
  //     toast.error('An error occurred while submitting the form.');
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title) {
      toast.error('Title is required.');
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append('title', title);
    formDataToSend.append('description', description);
    formDataToSend.append('btn_text', btnText);
    formDataToSend.append('image', image);
    formDataToSend.append('video', thumbnailUrl); // Change if `video` is the correct field for URL

    try {
      console.log([...formDataToSend]);

      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.create_content}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        toast.success('Video added successfully!');
        // Optionally reset form
        setTitle('');
        setDescription('');
        setBtnText('');
        setThumbnailUrl('');
        setImage(null);
        setVideo(null);
        fetchData();
        toggleModal();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('An error occurred while submitting the form.');
    }
  };


  const [searchQuery, setSearchQuery] = useState("");
  const handleView = (item) => {
    setSelectedItem(item);
    toggleViewModal();
  };
  const handleEdit = (item) => {
    setSelectedItem(item);
    setTitle(item.title);
    setDescription(item.description);
    setBtnText(item.btn_text);
    setThumbnailUrl(item.video);
    toggleEditModal();
  };

  const thStyle = {
    border: "1px solid gray",
    padding: "8px",
    textAlign: "left",
    background: "black",
    color: "white"
  };
  const handleDelete = (item) => {
    setSelectedItem(item);
    toggleDeleteModal();
  };
  const fetchData = async () => {
    try {
      const formDataToSend = new FormData();


      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_all_contents}`, formDataToSend, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === "success") {
        // Sort the data based on created_at field
        const sortedUsers = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setUsers(sortedUsers);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An error occurred while fetching data.");
    }
  };
  const columns = [
    {
      name: 'Title',
      selector: row => row.title,
      sortable: true,

    },
    {
      name: 'Description',
      selector: row => row.description,
      sortable: true,

    },
    {
      name: 'Image',
      selector: row => (
        <img
          src={row.image}
          alt={row.title}
          style={{ width: '50px', height: '50px' }}
        />
      ),
      sortable: true,

    },
    {
      name: 'Video',
      selector: row => (
        <a href={row.video} target="_blank" rel="noopener noreferrer">
          Watch Video
        </a>
      ),
      sortable: true,

    },
    {
      name: 'Actions',
      cell: row => (
        <div className="d-lg-flex">
          <Button className="btn-secondary btn-pill main-edit-view-buttons m-1" onClick={() => handleView(row)}><FaEye /></Button>
          <Button className="btn-secondary btn-pill main-edit-view-buttons m-1" onClick={() => handleEdit(row)}><FaRegEdit /></Button>
          <Button className="btn-danger btn-pill main-delete-buttons m-1" onClick={() => handleDelete(row)} ><MdDelete /></Button>
        </div>
      ),

    },

  ];

  const filteredEntries = users.filter(entry =>
    Object.values(entry).some(value =>
      value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );


  const handleDeleteItem = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('id', selectedItem.id);
    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.delete_content}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        toggleDeleteModal();
        fetchData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed to delete the item.");
    }
  };

  const handleEditItem = async () => {
    if (!selectedItem) return;

    const formDataToSend = new FormData();
    formDataToSend.append('id', selectedItem.id);
    formDataToSend.append('title', title);
    formDataToSend.append('description', description);
    formDataToSend.append('btn_text', btnText);
    formDataToSend.append('video', thumbnailUrl);
    if (image) {
      formDataToSend.append('image', image); // Append image only if updated
    }

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.update_content}`, // Correct endpoint
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        toast.success('Content updated successfully!');
        toggleEditModal();
        fetchData(); 
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error during edit:", error);
      toast.error('An error occurred while updating the content.');
    }
  };

  // const handleEditItem = async () => {
  //   const errors = {};

  //   if (Object.keys(errors).length === 0) {
  //     const formDataToSend = new FormData();
  //     formDataToSend.append('agenda_id', selectedItem.id);

  //     try {
  //       const response = await axios.post(
  //         `${API.BASE_URL}${API.ENDPOINTS.edit_meeting_agenda_name}`,
  //         formDataToSend,
  //         {
  //           headers: {
  //             Authorization: `${API.AUTHORIZATION_TOKEN}`,
  //           },
  //         }
  //       );

  //       if (response.data.status === "success") {
  //         toast.success("Agenda Updated Successfully");
  //         fetchData();
  //         toggleEditModal();
  //       } else {
  //         toast.error(response.data.message);
  //       }
  //     } catch (error) {
  //       console.error("Error during submission:", error);
  //       if (error.response) {
  //         console.error("Response data:", error.response.data);
  //         console.error("Response status:", error.response.status);
  //         console.error("Response headers:", error.response.headers);
  //       }
  //       toast.error('An error occurred while submitting the form.');
  //     }
  //   } else {
  //   }
  // };

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    setTitle('');
    setDescription('');
    setBtnText('');
    setThumbnailUrl('');
    setImage(null);
    setVideo(null);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className=" align-items-center justify-content-between d-flex">
                    <h5>FounderOS University</h5>
                    <Button className="btn-success main-buttons" onClick={toggleModal}>Add Video</Button>
                  </div>
                </div>
              </div>
              <div className='card-body'>
                <div>
                  <DataTable
                    columns={columns}
                    data={filteredEntries}
                    persistTableHead={true}
                    pagination
                    className="altimate-table"
                  />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>



      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add New Video</ModalHeader>
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="form-group">
              <label>Title</label>
              <input
                type="text"
                className="form-control"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setIsTitleValid(e.target.value.trim() !== ''); // Check if title is not empty
                }}
                placeholder="e.g., How to Get Ahead of 99% of People"
              />
            </div>

            <div className="form-group">
              <label>Description</label>
              <textarea
                className="form-control"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter the video description"
              />
            </div>

            {/* <div className="form-group">
              <label>Button Text</label>
              <input
                type="text"
                className="form-control"
                value={btnText}
                onChange={(e) => setBtnText(e.target.value)}
                placeholder="e.g., Watch Now"
              />
            </div> */}

            <div className="form-group">
              <label>Video</label>
              <input
                type="text"
                className="form-control"
                value={thumbnailUrl}
                onChange={(e) => setThumbnailUrl(e.target.value)}
                placeholder="Enter video or thumbnail URL"
              />
            </div>

            <div className="form-group">
              <label>Image</label>
              <input
                type="file"
                className="form-control"
                onChange={(e) => setImage(e.target.files[0])}
              />
            </div>


          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>Cancel</Button>
            <Button color="primary" type="submit" disabled={!isTitleValid}>Add Video</Button>{' '}
          </ModalFooter>
        </form>
      </Modal>


      {isDeleteModalOpen && (
        <Modal isOpen={isDeleteModalOpen} toggle={toggleDeleteModal} centered>
          <ModalHeader toggle={toggleDeleteModal}>Delete Item</ModalHeader>
          <ModalBody>
            <p>Are you sure you want to delete {selectedItem && selectedItem.name}?</p>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleDeleteModal}>Cancel</Button>
            <Button color="danger" onClick={handleDeleteItem}>Delete</Button>
          </ModalFooter>
        </Modal>
      )}

      {isEditModalOpen && selectedItem && (
        <Modal isOpen={isEditModalOpen} toggle={toggleEditModal} centered>
          <ModalHeader toggle={toggleEditModal}>Edit Video</ModalHeader>
          <ModalBody>
            <form>
              <div className="form-group">
                <label>Title</label>
                <input
                  type="text"
                  className="form-control"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Title"
                  defaultValue={selectedItem.title}
                />
              </div>

              <div className="form-group">
                <label>Description</label>
                <textarea
                  className="form-control"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Description"
                  defaultValue={selectedItem.description}
                />
              </div>
              {/* 
              <div className="form-group">
                <label>Button Text</label>
                <input
                  type="text"
                  className="form-control"
                  value={btnText}
                  onChange={(e) => setBtnText(e.target.value)}
                  placeholder="Button Text"
                  defaultValue={selectedItem.btn_text}
                />
              </div> */}

              <div className="form-group">
                <label>Video URL</label>
                <input
                  type="text"
                  className="form-control"
                  value={thumbnailUrl}
                  onChange={(e) => setThumbnailUrl(e.target.value)}
                  placeholder="Video URL"
                  defaultValue={selectedItem.video}
                />
              </div>

              <div className="form-group">
                <label>Image</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) => setImage(e.target.files[0])}
                />
                {selectedItem.image && (
                  <img src={selectedItem.image} alt={selectedItem.title} style={{ width: '200px', height: 'auto', marginTop: '10px' }} />
                )}
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleEditModal}>Cancel</Button>
            <Button color="primary" onClick={handleEditItem}>Save Changes</Button>
          </ModalFooter>
        </Modal>
      )}


      {isViewModalOpen && selectedItem && (
        <Modal isOpen={isViewModalOpen} toggle={toggleViewModal} centered size="lg">
          <ModalHeader toggle={toggleViewModal}>Video Details</ModalHeader>
          <ModalBody>

            <table className="table">
              <thead></thead>
              <tbody>
                <tr>
                  <th style={thStyle} colSpan={2}>Agenda  Detail</th>
                </tr>
                <tr>
                  <td>Title</td>
                  <td>{selectedItem.title}</td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td>{selectedItem.description}</td>
                </tr>

                <tr>
                  <td>Video</td>
                  <td> <a href={selectedItem.video} target="_blank" rel="noopener noreferrer">Watch Video</a>                                        </td>
                </tr>

                <tr>
                  <td>Image</td>
                  <td>
                    <img src={selectedItem.image} alt={selectedItem.title} style={{ width: '200px', height: 'auto' }} />
                  </td>
                </tr>

              </tbody>
            </table>

          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleViewModal}>Close</Button>
          </ModalFooter>
        </Modal>
      )}

    </>
  );
};

export default Fosuniversity;
