import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { MdDelete } from 'react-icons/md';
import DataTable from 'react-data-table-component';
import { FaEye, FaRegEdit } from "react-icons/fa";
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from "../../../utils";
import { useNavigate } from "react-router-dom";

const Meeting = () => {
    const uid = localStorage.getItem("user_id");
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [formData, setFormData] = useState({
        session_name: '',
        agendas: '',
        duration: '',

    });
    const [validationErrors, setValidationErrors] = useState({});

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
        setFormData({
            session_name: '',
            agendas: '',
            duration: "",

        });
    };

    const handleCancel = () => {
        setFormData({
            session_name: '',
            agendas: '',
            duration: "",

        });
        setValidationErrors({});
        setIsPopupOpen(false);
    };

    const fetchData = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_all_meeting}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                // setAnnualOutcomes(response.data.data);
                const sortedTasks = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setAnnualOutcomes(sortedTasks);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Fetch gratitude error', error);

        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSubmit = async () => {
       const errors = {};
       

        if (Object.keys(errors).length === 0) {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            formDataToSend.append('session_name', formData.session_name);
            formDataToSend.append('duration', formData.duration);
            formDataToSend.append('agendas', formData.agendas);

            try {
                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.add_meeting}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );

                if (response.data.status === "success") {
                    toast.success(response.data.message); 
                    console.log("API Response:", response.data); 
                    fetchData();
                    setFormData({
                        session_name: "",
                        duration: "",
                        agendas: "",
                    });
                    setValidationErrors({});
                    setIsPopupOpen(false); 
                } else {
                    toast.error(response.data.message);
                    console.log("API Response Error:", response.data); 
                }
            } catch (error) {
                console.error("Error during submission:", error); 
                toast.error('An error occurred while submitting the form.'); 
            }
        } else {
            setValidationErrors(errors);
        }
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        const errorsCopy = { ...validationErrors };
        switch (name) {
            case "session_name":
                errorsCopy.session_name = value.trim() ? "" : "This field is required";
                break;
            case "duration":
                errorsCopy.duration = value.trim() ? "" : "This field is required";
                break;
            case "agendas":
                errorsCopy.agendas = value.trim() ? "" : "This field is required";
                break;

            default:
                break;
        }
        setValidationErrors(errorsCopy);
    };
    const [annualOutcomes, setAnnualOutcomes] = useState([
    ]);
    const toggleDeleteModal = () => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
    };
    const toggleEditModal = () => {
        setIsEditModalOpen(!isEditModalOpen);
        if (isEditModalOpen) {
            setFormData({
                session_name: '',
                duration: '',
                agendas: '',
            });
            setValidationErrors({});
        }
    };
    const handleDelete = (item) => {
        setSelectedItem(item);
        toggleDeleteModal();
    };
    const handleEdit = (item) => {
        setSelectedItem(item);
        setFormData({
            session_name: item.session_name,
            duration: item.duration,
            agendas: item.agendas,
        });
        toggleEditModal();
    };

    const handleDeleteItem = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('id', selectedItem.id);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.delete_meeting}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                toggleDeleteModal();
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Failed to delete the item.");
        }
    };

    const validateForm = () => {
        const errors = {};
   
        return errors;
    };
   
    const handleEditItem = async () => {
        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            const updatedEntries = annualOutcomes.map(entry => {
                if (entry.id === selectedItem.id) {
                    return {
                        ...entry, 
                        session_name: formData.session_name || '',
                        duration: formData.duration || '',
                        agendas: formData.agendas || '',
                    };
                }
                return entry;
            });
            setAnnualOutcomes(updatedEntries);
            const formDataToSend = new FormData();
            formDataToSend.append('id', selectedItem.id);
            formDataToSend.append('session_name', formData.session_name || '');
            formDataToSend.append('duration', formData.duration || '');
            formDataToSend.append('agendas', formData.agendas || '');

            try {
                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.update_meeting}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );

                if (response.data.status === "success") {
                    toast.success(response.data.message);
                    fetchData(); 
                    setFormData({
                        session_name: '',
                        duration: '',
                        agendas: '',
                    });
                    setValidationErrors({});
                    toggleEditModal();
                } else {
                    toast.error(response.data.message); 
                }
            } catch (error) {
                console.error("Error during submission:", error); 
                toast.error('An error occurred while submitting the form.'); 
            }
        } else {
            setValidationErrors(errors);
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredEntries = annualOutcomes.filter(entry =>
        (entry.session_name || "").toLowerCase().includes((searchQuery || "").toLowerCase()) ||
        (entry.agendas || "").toLowerCase().includes((searchQuery || "").toLowerCase()) ||
        (entry.duration || "").toLowerCase().includes((searchQuery || "").toLowerCase())
    );


    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const handleView = (item) => {
        setSelectedItem(item);
        toggleViewModal(); 
    };
    const toggleViewModal = () => {
        setIsViewModalOpen(!isViewModalOpen); 
    };
    const thStyle = {
        border: "1px solid gray",
        padding: "8px",
        textAlign: "left",
        background: "black",
        color: "white"
    };
    const columns = [
        {
            name: 'Meeting Name',
            selector: (row) => row.session_name,
            sortable: true,
            width:"20%"
        },
        {
            name: 'Duration (minutes)',
            selector: (row) => row.duration,
            sortable: true,
            width:"25%"
        },
        {
            name: 'Agendas',
            selector: (row) => row.agendas,
            sortable: true,
            width:"35%"
        },
        {
            name: 'Action ',
            cell: (row) => (
                <>
                    <Button className="btn-secondary btn-pill main-edit-view-buttons mx-1" onClick={() => handleView(row)} ><FaEye /></Button>
                    <Button className="btn-secondary btn-pill main-edit-view-buttons mx-1" onClick={() => handleEdit(row)}><FaRegEdit /></Button>
                    <Button className="btn-danger btn-pill  main-delete-buttons mx-1" onClick={() => handleDelete(row)}>
                        <MdDelete />
                    </Button>
                </>
            ),
            width:"20%"
        },
    ];
const navigate=useNavigate();

    const redirectToAgendaPage = () => {
        navigate('/meeting/agenda'); 
    };
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-6 align-items-center d-flex">
                                    <h5>Meeting Agendas</h5>
                                </div>
                                
                                <div className="col-md-6 text-end">
                                    <Button className="btn-success main-buttons m-r-10" onClick={redirectToAgendaPage}>Show Agenda List </Button>
                                    <Button className="btn-success main-buttons" onClick={togglePopup}>Add Meeting</Button>
                                </div>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-9"></div>
                                <div className="col-md-3">
                                    <div className="form-group text-end mb-3 ">
                                        <input className="form-control" type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchChange} />
                                    </div>
                                </div>
                            </div>
                            <div >
                                <DataTable
                                    columns={columns}
                                    data={filteredEntries}
                                    persistTableHead={true}
                                    pagination
                                    className="altimate-table"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isPopupOpen && (
                <Modal centered={true} show={isPopupOpen} onHide={handleCancel}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Meeting Agenda</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="theme-form">
                            <div className="form-group">
                                <label className="col-form-label pt-0" htmlFor="session_name">Meeting Name</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="session_name"
                                    placeholder="Enter Session Name"
                                    value={formData.session_name}
                                    onChange={handleInputChange}
                                />
                               
                            </div>

                            <div className="form-group">
                                <label className="col-form-label pt-0" htmlFor="duration">Duration (minutes)</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    name="duration"
                                    placeholder="Enter Duration (minutes)"
                                    value={formData.duration}
                                    onChange={handleInputChange}
                                />
                              
                            </div>

                            <div className="form-group">
                                <label className="col-form-label pt-0" htmlFor="agendas">Agendas</label>
                                <div className="form-group mb-3">
                                    <div className="row">
                                        {["Default Agendas", "Level 10 Meeting", "Quarterly Session", "Annual Session - Day One", "Annual Session - Day Two"].map((agenda, index) => (
                                            <label key={index} className="d-block" htmlFor={`agenda-${index}`}>
                                                <input
                                                    className="radio_animated"
                                                    id={`agenda-${index}`}
                                                    type="radio"
                                                    name="agendas"
                                                    value={agenda}
                                                    checked={formData.agendas === agenda}
                                                    onChange={handleInputChange}
                                                />
                                                {agenda}
                                            </label>
                                        ))}
                                    </div>
                                   
                                </div>

                                <Button variant="primary" onClick={redirectToAgendaPage}>
                    Add Custom Agenda
                </Button>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-secondary" onClick={handleCancel}>Cancel</button>
                        <button className="btn btn-success" onClick={handleSubmit}>Save</button>
                    </Modal.Footer>
                </Modal>
            )}

            <Modal show={isDeleteModalOpen} onHide={toggleDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete {selectedItem && selectedItem.name}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={toggleDeleteModal}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteItem}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal centered={true} show={isEditModalOpen} onHide={toggleEditModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Meeting</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="theme-form">
                        <div className="form-group">
                            <label className="col-form-label pt-0" htmlFor="session_name">Teams Name</label>
                            <input
                                className="form-control"
                                type="text"
                                name="session_name"
                                placeholder="Enter Teams Name"
                                value={formData.session_name}
                                onChange={handleInputChange}
                            />
                       
                        </div>

                        <div className="form-group">
                            <label className="col-form-label pt-0" htmlFor="duration">Duration (minutes)</label>
                            <input
                                className="form-control"
                                type="number"
                                name="duration"
                                placeholder="Enter Duration (minutes)"
                                value={formData.duration}
                                onChange={handleInputChange}
                            />
                        
                        </div>

                        <div className="form-group">
                            <label className="col-form-label pt-0" htmlFor="agendas">Agendas</label>
                            <div className="form-group mb-3">
                                <div className="row">
                                    {["Default Agendas", "Level 10 Meeting", "Quarterly Session", "Annual Session - Day One", "Annual Session - Day Two"].map((agenda, index) => (
                                        <label key={index} className="d-block" htmlFor={`agenda-${index}`}>
                                            <input
                                                className="radio_animated"
                                                id={`agenda-${index}`}
                                                type="radio"
                                                name="agendas"
                                                value={agenda}
                                                checked={formData.agendas === agenda}
                                                onChange={handleInputChange}
                                            />
                                            {agenda}
                                        </label>
                                    ))}
                                </div>
                              
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={toggleEditModal}>Cancel</button>
                    <button className="btn btn-success" onClick={handleEditItem}>Save</button>
                </Modal.Footer>
            </Modal>
            <div className="card-body btn-showcase">
                {isViewModalOpen && (
                    <Modal centered={true} show={isViewModalOpen} onHide={toggleViewModal} size="lg" >
                        <Modal.Header closeButton>
                            <Modal.Title>Meeting Detail</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <table className="table">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th style={thStyle} colSpan={2}>Meeting Detail</th>
                                    </tr>
                                    <tr>
                                        <td>Meeting  Name</td>
                                        <td>{selectedItem.session_name}</td>
                                    </tr>
                                    <tr>
                                        <td>Duration (minutes)</td>
                                        <td>{selectedItem.duration}</td>
                                    </tr>
                                    <tr>
                                        <td>Agendas</td>
                                        <td>{selectedItem.agendas}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-secondary" onClick={toggleViewModal}>Close</button> {/* Close button */}
                        </Modal.Footer>
                    </Modal>
                )}
            </div>
        </div>
    );
}

export default Meeting;
