import React, { useState, useEffect } from 'react';
import usereditdata from "../../../api/user-edit-table.json";
import axios from 'axios';
import API from '../../../utils';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MypuffLoader from '../../common/MypuffLoader';
import Autocomplete from 'react-google-autocomplete';
import Preferences from './preferences';
const PersonalInformation = () => {
  const uid = localStorage.getItem("user_id");
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    user_name: '',
    firstname: '',
    lastName: '',
    title: '',
    street: '',
    city: '',
    region: '',
    pinCode: '',
    country: '',
    biography: '',
    EmailAddress: '',
    PostalCode: '',
  });
  const [imagePreview, setImagePreview] = useState(null);
  const [profile, setProfileImg] = useState(null);
  const [forms, setForms] = useState([{ id: Date.now(), name: '', relationship: '', phoneNumber: '' }]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setData(usereditdata);
  }, []);

  useEffect(() => {
    const storedProfileImg = localStorage.getItem('profile');
    if (storedProfileImg) {
      setProfileImg(storedProfileImg);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);

    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_user_details}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`
          },
        }
      );
      if (response.data.status === "success") {
        const userData = response.data.user_data;
        const emergencyContacts = response.data.emergencontactdata;

        setProfileImg(userData.profile);
        setFormData({
          user_name: userData.user_name || '',
          firstname: userData.first_name || '',
          lastName: userData.last_name || '',
          title: userData.job_title || '',
          street: userData.street || '',
          city: userData.city || '',
          region: userData.state || '',
          pinCode: userData.zip_code || '',
          country: userData.country || '',
          biography: userData.biography || '',
        });

        setForms(emergencyContacts.map(contact => ({
          id: contact.id,
          name: contact.name,
          relationship: contact.relationship,
          phoneNumber: contact.number,
        })));
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    if (profile && typeof profile === 'object') {
      formDataToSend.append('profile', profile);
    }
    formDataToSend.append('user_name', formData.user_name);
    formDataToSend.append('first_name', formData.firstname);
    formDataToSend.append('last_name', formData.lastName);
    formDataToSend.append('jobtitle', formData.title);
    formDataToSend.append('biography', formData.biography);
    formDataToSend.append('street', formData.street);
    formDataToSend.append('city', formData.city);
    formDataToSend.append('state', formData.region);
    formDataToSend.append('country', formData.country);
    formDataToSend.append('zipcode', formData.pinCode);
    forms.forEach((form, index) => {
      formDataToSend.append(`name[${index}]`, form.name);
      formDataToSend.append(`relationship[${index}]`, form.relationship);
      formDataToSend.append(`number[${index}]`, form.phoneNumber);
    });

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.update_profile}`,
        formDataToSend, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`
        },
      });
      if (response.data.status === "success") {
        toast.success(response.data.message);
        fetchData();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Profile update failed.");
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImg(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  
  const handlePlaceSelected = (place) => {
    let street = '';
    let city = '';
    let postalCode = '';

    // Extract address components
    place.address_components.forEach((component) => {
      const types = component.types;

      // if (types.includes('street') || types.includes('route')) {
      //   street += component.long_name + ' ';
      // }

      if (types || types.includes('route')) {
        street += component.long_name + ' ';
      }

      if (types.includes('locality')) {
        city = component.long_name;
      }

      if (types.includes('postal_code')) {
        postalCode = component.long_name;
      }
    });

    setFormData({
      ...formData,
      street: street.trim(),  // Update street field
      city,                   // Update city field
      pinCode: postalCode,     // Update postal code field
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <MypuffLoader active={loading} />
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">Personal Information</h5>
            </div>

            <div className="row">
              <div className="col-lg-4">
                <div className="col-auto">
                  <div className="profile-container">
                    <img
                      className="profile-image mt-4 mx-3 mx-lg-4 p-l-10 rounded-circle"
                      alt="profile"
                      src={imagePreview || profile}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label className="form-label"><b>Profile Image</b></label>
                        <input
                          id="file-input"
                          type="file"
                          accept="image/*"
                          name="profile"
                          className="form-control"
                          onChange={handleImageChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group mb-3">
                        <label className="form-label"><b>Name</b></label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter A Name"
                          name="user_name"
                          value={formData.user_name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label className="form-label"><b>Title</b></label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter A Title"
                          value={formData.title}
                          name="title"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label"><b>Street Address</b></label>
                    <Autocomplete
                      apiKey="AIzaSyBbK3ugSWXRAvw3D_XM-zE215vHJMyhOfw"
                      onPlaceSelected={handlePlaceSelected}
                      options={{
                        types: ['geocode'],
                      }}
                      placeholder="Enter Street Address"
                      className="form-control"
                      value={formData.street}
                      onChange={(e) => handleChange({ target: { name: 'street', value: e.target.value } })}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label"><b>City</b></label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter City"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label"><b>Postal Code</b></label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Postal Code"
                      name="pinCode"
                      value={formData.pinCode}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                  </div>
                </div>
                <div className='card-footer text-end'>
                  <button type="submit" className="btn btn-success">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">Address</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label"><b>Street Address</b></label>
                    <Autocomplete
                      apiKey="AIzaSyBbK3ugSWXRAvw3D_XM-zE215vHJMyhOfw"
                      onPlaceSelected={handlePlaceSelected}
                      options={{
                        types: ['geocode'],
                      }}
                      placeholder="Enter Street Address"
                      className="form-control"
                      value={formData.street}
                      onChange={(e) => handleChange({ target: { name: 'street', value: e.target.value } })}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label"><b>City</b></label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter City"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label"><b>Postal Code</b></label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Postal Code"
                      name="pinCode"
                      value={formData.pinCode}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='card-footer text-end'>
              <button type="submit" className="btn btn-success">
                Save
              </button>
            </div>
          </div> */}
           <Preferences />
        </form>
      )}
    </>
  );
};

export default PersonalInformation;
