import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import API from '../../../../../utils';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useCallback } from 'react';
import { debounce } from 'lodash';
import { useEffect } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar'; // Make sure to install this package
import 'react-circular-progressbar/dist/styles.css';

function TransactionsAssessment() {
    const [selectedOption, setSelectedOption] = useState('');
    const [cultureOption, setCultureOption] = useState('');
    const [planningOption, setPlanningOption] = useState('');
    const [performanceOption, setPerformanceOption] = useState('');
    const [feedbackOption, setFeedbackOption] = useState('');
    const [valuesOption, setValuesOption] = useState('');
    const [innovationOption, setInnovationOption] = useState('');
    const [embeddingOption, setEmbeddingOption] = useState('');
    const [leadershipOption, setLeadershipOption] = useState('');
    const [rewardsOption, setRewardsOption] = useState('');
    const [rewardsStrategyOption, setRewardsStrategyOption] = useState('');
    const [RewardsDigitalContextOption, setRewardsDigitalContextOption] = useState('');
    const uid = localStorage.getItem("user_id");


    const updateTask = async (formDataToSend) => {
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.add_update_transactions_assesment}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: API.AUTHORIZATION_TOKEN,
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("An error occurred while updating data.");
        }
    };

    useEffect(() => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('Funding_Growth_Sources_Capital', selectedOption);
        formDataToSend.append('Funding_Growth_Working_Capital', cultureOption);
        formDataToSend.append('Funding_Growth', planningOption);
        formDataToSend.append('People_Organization_People', performanceOption);
        formDataToSend.append('People_Organization_Organization', feedbackOption);
        formDataToSend.append('Finance_Process_Controls_Finance_Processes', valuesOption);
        formDataToSend.append('Finance_Process_Controls_Finance_Controls', innovationOption);
        formDataToSend.append('Data_Technology_Technology', embeddingOption);
        formDataToSend.append('Data_Technology_Data', leadershipOption);
        formDataToSend.append('Stakeholder_Management_Stakeholder_Identification', rewardsOption);
        formDataToSend.append('Stakeholder_Management', rewardsStrategyOption);
        formDataToSend.append('Rewards_Strategy', RewardsDigitalContextOption);

        // Only update if any field has a value
        if (selectedOption || cultureOption || planningOption || performanceOption || feedbackOption || valuesOption || innovationOption || leadershipOption || embeddingOption || rewardsOption || rewardsStrategyOption || RewardsDigitalContextOption) {
            updateTask(formDataToSend);
        }
    }, [selectedOption, cultureOption, planningOption, performanceOption, feedbackOption, valuesOption, innovationOption, leadershipOption, embeddingOption, rewardsOption, rewardsStrategyOption, RewardsDigitalContextOption]);

    const handleChange = (setter) => (event) => {
        setter(event.target.value);
    };

    const [checkedValue, setCheckedValue] = useState({ transaction_assesment: '' });


    const fetchData = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_growth_transactions}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                // const data = response.data.data || {};
                // console.log('data',data);

                // setCheckedValue({
                //     transaction_assesment: data.transaction_assesment 
                // });
                const jsonData = response.data.data.transaction_assesment;

                // Decode the JSON string into an object
                let data;
                try {
                    data = JSON.parse(jsonData);
                } catch (error) {
                    console.error('Error parsing JSON:', error);

                    return;
                }

                // Update the state with the decoded values
                setSelectedOption(data.Funding_Growth_Sources_Capital);
                setCultureOption(data.Funding_Growth_Working_Capital);
                setPlanningOption(data.Funding_Growth);
                setPerformanceOption(data.People_Organization_People);
                setFeedbackOption(data.People_Organization_Organization);
                setValuesOption(data.Finance_Process_Controls_Finance_Processes);
                setInnovationOption(data.Finance_Process_Controls_Finance_Controls);
                setEmbeddingOption(data.Data_Technology_Technology);
                setLeadershipOption(data.Data_Technology_Data);
                setRewardsOption(data.Stakeholder_Management_Stakeholder_Identification);
                setRewardsStrategyOption(data.Stakeholder_Management);
                setRewardsDigitalContextOption(data.Rewards_Strategy);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Fetch meeting types error', error);
        }
    };


    useEffect(() => {
        fetchData();
    }, []);

    const calculatePercentage = () => {
        const values = {
            Leading: 8.34,
            Established: 5.56,
            Developing: 2.78,
        };

        const scores = [
            values[selectedOption] || 0,
            values[cultureOption] || 0,
            values[planningOption] || 0,
            values[performanceOption] || 0,
            values[feedbackOption] || 0,
            values[valuesOption] || 0,
            values[innovationOption] || 0,
            values[embeddingOption] || 0,
            values[leadershipOption] || 0,
            values[rewardsOption] || 0,
            values[rewardsStrategyOption] || 0,
            values[RewardsDigitalContextOption] || 0,
        ];

        const totalScore = scores.reduce((acc, score) => acc + score, 0);
        const maxScore = 12 * 8.34; // 12 questions, max value of 8.34
        return (totalScore / maxScore) * 100;
    };

    const percentage = calculatePercentage();


    useEffect(() => {
        // Calculate and store the percentage
        const percentage = calculatePercentage();

        // Set the percentage in local storage
        localStorage.setItem('transactionCompletionPercentage', percentage);

        // Optionally log it
        console.log("Completion Percentage:", percentage);
    }, [
        selectedOption,
        cultureOption,
        planningOption,
        performanceOption,
        feedbackOption,
        valuesOption,
        innovationOption,
        embeddingOption,
        leadershipOption,
        rewardsOption,
        rewardsStrategyOption,
        RewardsDigitalContextOption,
    ]); // Dependencies to recalculate when any option changes


    return (
        <div className="col-sm-12">
            <div className="card mt-4">
            <div className="card-header">
                <div className='justify-content-between align-items-center d-flex'>
                        <div className=' align-items-center d-flex'>
                            <h5>Transactions Assessment</h5>
                        </div>
                       
                        <div className='ms-3 align-items-center d-flex ms-3'>
                        <div className='m-r-15'>
                            <Link className='backto-ondriver' to="/growthframeworks/founderdriver">Back to the Founder OS Drivers of Growth</Link>
                        </div>
                                <CircularProgressbar   className='assessmentprogress'
                                   value={percentage}
                                   text={`${Math.round(percentage)}%`}
                                    styles={{
                                        path: {
                                            stroke: 'green', // Change the stroke color to black
                                        },
                                        text: {
                                            fill: 'black', // Change the text color to black
                                            fontSize: '16px', // Optional: Adjust font size
                                        },
                                        trail: {
                                            stroke: '#d6d6d6', // Optional: Change the trail color (if you want)
                                        },
                                    }}
                                />

                            </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="card-body">


                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Transactions – Transactions Strategy and Planning – Valuation Models</strong>
                                </b></label>
                                <p className="textnots-text">
                                    Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                                </p>
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        checked={cultureOption === 'Leading'}
                                        onChange={handleChange(setCultureOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Valuation models clearly identify long-term business synergies and drive post-merger integration activities.
                                </p>

                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        checked={cultureOption === 'Established'}
                                        onChange={handleChange(setCultureOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Valuation models are robust, dynamic and longer-term.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"
                                        checked={cultureOption === 'Developing'}
                                        onChange={handleChange(setCultureOption)}

                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Valuation models are basic and short-term.
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>
                                        Transactions – Transactions Strategy and Planning - Strategy
                                    </strong>
                                </b></label>
                                <p className="textnots-text">
                                    Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)                                </p>
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        checked={cultureOption === 'Leading'}
                                        onChange={handleChange(setCultureOption)}

                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The business has developed a strong portfolio strategy including a capital allocation plan for long-term sustainable growth.
                                </p>

                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        checked={cultureOption === 'Established'}
                                        onChange={handleChange(setCultureOption)}

                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The transaction strategy drives short term growth to fill gaps within the business portfolio (e.g. products and geographies).
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"
                                        checked={cultureOption === 'Developing'}
                                        onChange={handleChange(setCultureOption)}

                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The transaction strategy is opportunistic.
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Transactions – Transactions Effectiveness - Resourcing</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        checked={planningOption === 'Leading'}
                                        onChange={handleChange(setPlanningOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	There is a highly effective combination of an internal team working with external advisors from deal identification through to post-merger integration.
                                </p>

                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        checked={planningOption === 'Established'}
                                        onChange={handleChange(setPlanningOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	An internal team identifies opportunities, performs due diligence and negotiates transactions through to close.
                                </p>

                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Developing"
                                        checked={planningOption === 'Developing'}
                                        onChange={handleChange(setPlanningOption)}

                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The business has limited transaction experience.
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>
                                    <strong>Transactions – Transactions Effectiveness – Maximizing Transaction Value</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>
                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"

                                        checked={performanceOption === 'Leading'}
                                        onChange={handleChange(setPerformanceOption)}

                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	There is a well-defined post-merger integration process with clear accountability for people, culture, cost synergies, tax and divestitures of unwanted assets.
                                </p>
                                <p className="textnots-text">
                                    •	Best practice reflects the learning from executed deals.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"

                                        checked={performanceOption === 'Established'}
                                        onChange={handleChange(setPerformanceOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	There are measures in place to track performance against plans after the deal is closed.
                                </p>
                            </div>
                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"
                                        checked={performanceOption === 'Developing'}
                                        onChange={handleChange(setPerformanceOption)}

                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Leadership has optimistic expectations of the level of synergies and how to capture them.
                                </p>
                            </div>
                        </div>
                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>
                                    <strong>Transactions – Partnerships and Alliances - Strategy</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>
                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        checked={feedbackOption === 'Leading'}
                                        onChange={handleChange(setFeedbackOption)}

                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The business' approach to partnerships and alliances is highly strategic and is fundamental to its successful inorganic growth strategy.
                                </p>
                            </div>
                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Established"
                                        checked={feedbackOption === 'Established'}
                                        onChange={handleChange(setFeedbackOption)}

                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Partnerships and alliances are pro-actively planned to complement existing core competencies and to fill gaps within the business portfolio (e.g. products and geographies).
                                </p>
                            </div>
                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"
                                        checked={feedbackOption === 'Developing'}
                                        onChange={handleChange(setFeedbackOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The approach to partnerships and alliances is opportunistic.
                                </p>
                            </div>
                        </div>



                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>
                                    <strong>Transactions – Partnerships and Alliances - Focus</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>
                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        checked={valuesOption === 'Leading'}
                                        onChange={handleChange(setValuesOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The business uses strategic alliances and partnerships to source innovation, technology and talent as well as drive customer and shareholder value.
                                </p>
                            </div>
                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        checked={valuesOption === 'Established'}
                                        onChange={handleChange(setValuesOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Leaders have a clear view of each others competencies. They drive relationships that deliver scale or improved revenue and profitability.
                                </p>
                            </div>
                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"
                                        checked={valuesOption === 'Developing'}
                                        onChange={handleChange(setValuesOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Partnerships and alliances provide access to new customers and markets.
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Transactions – Data and Technology - Technology</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        checked={innovationOption === 'Leading'}
                                        onChange={handleChange(setInnovationOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Efficient and effective financial systems enable Transactions and tax resources to focus on enhancing business performance.
                                </p>
                                <p className="textnots-text">
                                    •	New technologies (e.g. AI, blockchain, RPA) are deployed at speed creating a transparent, data-driven and smart function.
                                </p>
                                <p className="textnots-text">
                                    •	Transactions processes are automated end-to-end, reducing cost and making the function highly agile and future-ready.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Established"
                                        checked={innovationOption === 'Established'}
                                        onChange={handleChange(setInnovationOption)}

                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Multiple legacy systems support financial and tax processes at a relatively high cost.
                                </p>
                            </div>
                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"
                                        checked={innovationOption === 'Developing'}
                                        onChange={handleChange(setInnovationOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Transactions and tax teams rely heavily on spreadsheets to compensate for underdeveloped core Transactions systems.
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>
                                    <strong>Transactions – Data and Technology - Data</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        checked={embeddingOption === 'Leading'}
                                        onChange={handleChange(setEmbeddingOption)}

                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Financial data is an asset that is actively managed and highly valued. It strongly enhances strategic decision-making.
                                </p>
                                <p className="textnots-text">
                                    •	The Transactions function's data and analytics capabilities have transformed the business' forecasting and scenario planning, risk management, cost reduction and understanding of value drivers.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        checked={embeddingOption === 'Established'}
                                        onChange={handleChange(setEmbeddingOption)}

                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Common financial data enables analysis across the enterprise.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Developing"
                                        checked={embeddingOption === 'Developing'}
                                        onChange={handleChange(setEmbeddingOption)}

                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Accuracy is the primary focus of financial data owners.
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Transactions – Stakeholder Management – Stakeholder Identification</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"

                                        checked={leadershipOption === 'Leading'}
                                        onChange={handleChange(setLeadershipOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Key stakeholders are banks, primary investors, regulators, people, policy-makers and communities.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        checked={leadershipOption === 'Established'}
                                        onChange={handleChange(setLeadershipOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Key stakeholders are banks, primary investors, regulators and people.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Developing"
                                        checked={leadershipOption === 'Developing'}
                                        onChange={handleChange(setLeadershipOption)}

                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Key stakeholders are banks and primary investors.
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Transactions – Stakeholder Management</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        checked={rewardsOption === 'Leading'}
                                        onChange={handleChange(setRewardsOption)}

                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The business clearly articulates its whole corporate 'story' and tailors it to individual stakeholders.
                                </p>
                                <p className="textnots-text">
                                    •	The business builds further trust and confidence with stakeholders by pro-actively reporting on its performance beyond just the financials (e.g. environmental, social impact, governance (ESG) and diversity) as part of its long-term value creation.
                                </p>
                                <p className="textnots-text">
                                    •	There is a track record of consistent delivery against promises - no surprises.                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Established"
                                        checked={rewardsOption === 'Established'}
                                        onChange={handleChange(setRewardsOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	There is visible evidence of links between high performance, rewards and the achievement of business objectives.
                                </p>
                                <p className="textnots-text">
                                    •	There are clearly defined roles and responsibilities to manage key stakeholders.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Developing"
                                        checked={rewardsOption === 'Developing'}
                                        onChange={handleChange(setRewardsOption)}

                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The business builds trust with stakeholders by focusing on compliance and statutory requirements.
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Transactions - Rewards - Strategy</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>
                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        checked={rewardsStrategyOption === 'Leading'}
                                        onChange={handleChange(setRewardsStrategyOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Behaviors, as well as outcomes, are rewarded for both the team and the individual.
                                </p>
                                <p className="textnots-text">
                                    •	A strong employer brand is used to attract, motivate and retain high performers and teams.
                                </p>
                            </div>
                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Established"
                                        checked={rewardsStrategyOption === 'Established'}
                                        onChange={handleChange(setRewardsStrategyOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	There are various incentives and total rewards programs segmented to specific employee populations.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Developing"
                                        checked={rewardsStrategyOption === 'Developing'}
                                        onChange={handleChange(setRewardsStrategyOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Rewards are provided to attract and retain individuals and to meet business objectives.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TransactionsAssessment;
