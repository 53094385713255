import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const EditprocessModal = ({ show, onHide, formData, validationErrors, handleInputChange, handleEditItem }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Meeting Type</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group mb-3">
          <label><b>Select Meeting Type Name</b></label>
          

            <input
            className={`form-control ${validationErrors.type ? 'is-invalid' : ''}`}
            onChange={handleInputChange}
            name="type"
            type='text'
            value={formData.type}>
            </input>
      
          {validationErrors.type && <p className="text-danger">{validationErrors.type}</p>}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button variant="success" onClick={handleEditItem}>Save Changes</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditprocessModal;
