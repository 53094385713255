import React, { useState, useEffect } from "react";
import { Button, Modal, Dropdown, Image, Accordion } from "react-bootstrap";
import 'react-datepicker/dist/react-datepicker.css';
import { FaTasks, FaTimes } from 'react-icons/fa';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from "../../../utils";
import { FaSortDown } from 'react-icons/fa';
import { CgToday } from "react-icons/cg";
import { Link, useNavigate } from "react-router-dom";

const formatDate = (dateTimeString) => {
    const date = new Date(dateTimeString);
    // Format to "YYYY-MM-DD"
    return date.toISOString().split('T')[0];
};



const Meetings = () => {
    const [agendaNotes, setAgendaNotes] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedMeetingAgenda, setSelectedMeetingAgenda] = useState([]);
    const uid = localStorage.getItem("user_id");
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [annualOutcomes, setAnnualOutcomes] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const handleLinkClick = (meetingType) => {
        console.log("meetingType", meetingType)
        setSelectedMeetingAgenda(meetingType); 
        setModalVisible(true); 
    };
    const handleInputChange = (event, member) => {
        const { name, value, type, checked } = event.target;

        if (name === 'meeting_type') {
            const [meetingName, meetingType] = value.split(' - ');
           

            setFormData((prevData) => ({
                ...prevData,
                meeting_name: meetingName,
                meeting_type: meetingType,
            }));
        }

        if (type === 'checkbox') {
            setSelectedAttendees((prevSelected) =>
                checked
                    ? [...prevSelected, member]
                    : prevSelected.filter((attendee) => attendee.team_name !== member.team_name)
            );
        }
    };

    const [modalopen, setModalOpen] = useState(false);
    const [currentAttendees, setCurrentAttendees] = useState([]);
    const handleSave = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            formDataToSend.append('meeting_m_c_id', "1");
            formDataToSend.append('meeting_type', formData.meeting_type);
            formDataToSend.append('meeting_name', formData.meeting_name);
            selectedAttendees.forEach(attendee => {
                formDataToSend.append('attendees[]', attendee.id);
            });

            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.add_meeting_main_sidebar}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success("Meeting Added Successfully");
                fetchTasks();
                setFormData({
                    meeting_name: "",
                    meeting_type: "",
                    meeting_date: "",
                    status: "",
                    agenda: [],
                    address: "",
                    attendees: [],
                });
                setSelectedAttendees([])
                agendafetchData();
                setShowModal(false);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error during submission:", error);
            toast.error('An error occurred while submitting the form.');
        }
    };
    useEffect(() => {
        agendafetchData();
    }, []);
    const agendafetchData = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_meeting_agenda}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                if (Array.isArray(response.data.info)) {
                    setAnnualOutcomes(response.data.info);
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        meeting_type: "",
                    }));
                } else {
                    console.error("Response data is not an array");
                }
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Fetch meeting types error', error);
        }
    };
    const [formData, setFormData] = useState({
        meeting_name: "",
        meeting_type: "",
        meeting_date: "",
        status: "",
        agenda: [],
        address: "",
    });

    const [gratitudeEntries, setGratitudeEntries] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [activeTab, setActiveTab] = useState('all');

    useEffect(() => {
        fetchTasks();
    }, [activeTab]);

    const fetchTasks = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('meeting_m_c_id', "1");
        formDataToSend.append('filter', activeTab);
    
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.check_meetings_filter}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );
    
            if (response.data.status === 'success') {
                const tasksWithParsedAgenda = response.data.tasks.map(task => {
                    let parsedAgenda = [];
                    try {
                        if (task.agenda) {
                            parsedAgenda = JSON.parse(task.agenda);
                        }
                    } catch (e) {
                        console.error(`Failed to parse agenda for task ID ${task.id}:`, e);
                    }
                    return {
                        ...task,
                        agenda: parsedAgenda,
                    };
                });
    
                // Sort tasks by creation date
                tasksWithParsedAgenda.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setGratitudeEntries(tasksWithParsedAgenda);
    
                const initialCompletionStatus = tasksWithParsedAgenda[0].is_complete.map(status => status === '1');
                const initialNotes = tasksWithParsedAgenda[0].meeting_agenda_note;
    
                setAgendaCompletionStatus(initialCompletionStatus);
                setAgendaNotes(initialNotes);
    
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error during task fetching:', error);
        }
    };
    
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const tabHeadings = {
        'all': 'All Metting',
        'completed': 'Completed',
    };

    const tabIcons = {
        'all': <FaTasks />,
        'completed': <CgToday />,

    };
    const filteredEntries = gratitudeEntries.filter(entry =>
        (entry.meeting_name || "").toLowerCase().includes((searchQuery || "").toLowerCase()) ||
        (entry.meeting_type || "").toLowerCase().includes((searchQuery || "").toLowerCase()) ||
        (entry.meeting_date || "").toLowerCase().includes((searchQuery || "").toLowerCase())
    );
    const [editedMeeting, setEditedMeeting] = useState(null);
    const handleInputChangemetting = (e, field) => {
        setEditedMeeting({
            ...editedMeeting,
            [field]: e.target.value,
        });
    };


    const [selectedMeeting, setSelectedMeeting] = useState(null);
    useEffect(() => {
        if (selectedMeeting) {
            setEditedMeeting({ ...selectedMeeting });
        }
    }, [selectedMeeting]);
    const handleRemoveAttendee = (attendeeToRemove) => {
        setSelectedAttendees(selectedAttendees.filter(attendee => attendee.id !== attendeeToRemove.id));
    };
  
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleMeetingNameClick = (meeting) => {
        setCurrentAttendees(meeting.agenda || []);
        setSelectedMeeting(meeting);
        setMeetingId(meeting.id) 
        setIsModalOpen(true); 
    };
    const handleCloseModalmetting = () => {
        setIsModalOpen(false);
        setModalOpen(false);
    };



    const handleCheckboxChange = async (taskid, isChecked) => {
        const newTasks = gratitudeEntries.map(task =>
            task.id === taskid ? { ...task, is_complete: isChecked ? "1" : "0" } : task
        );
        setGratitudeEntries(newTasks);
        const formDataToSend = new FormData();
        formDataToSend.append('meeting_id', taskid);
        formDataToSend.append('is_checked', isChecked ? "1" : "0");

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.check_meetings}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchTasks();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error during checkbox status update:", error);
            toast.error('An error occurred while updating the checkbox status.');
        }
    };

    const [meetingId, setMeetingId] = useState("")
    const handleEditAttendees = (row) => {
        console.log("row", row.id)
        setCurrentAttendees(row.agenda || []);
        setMeetingId(row.id)
        setModalOpen(true);
    };
    const columns = [
        {
            name: 'Completed',
            selector: row => row.is_checked,
            cell: row => (
                <input
                    type="checkbox"
                    className="checkbox_animated"
                    checked={row.is_checked==="1"}
                    onChange={(e) => handleCheckboxChange(row.id, e.target.checked)}
                />
            ),
            sortable: true
        },
        {
            name: 'Meeting Name',
            selector: row => row.meeting_name,
            cell: (row) => (
                <span
                    className="meeting-name-clickable"
                    onClick={() => handleMeetingNameClick(row)}
                >
                    {row.meeting_name}
                </span>
            ),
            sortable: true
        },
        {
            name: 'Meeting Date',
            selector: row => formatDate(row.created_at),
            cell: (row) => (
                <span
                    className="meeting-name-clickable"
                    onClick={() => handleMeetingNameClick(row)}
                >
                    {formatDate(row.created_at)}  
                </span>
            ),
            sortable: true
        }
        ,
        {
            name: 'Attendees',
            selector: row => row.attendees,
            cell: (row) => {
                const attendeesList = row.attendees && row.attendees.length > 0
                    ? row.attendees.map((attendee, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                            <img
                                src={attendee.profile}
                                alt={attendee.team_name}
                                style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '5px' }}
                            />
                            <span>{attendee.team_name}</span>
                        </div>
                    ))
                    : <span>No attendees</span>;

                return (
                    <div>
                        {attendeesList}

                        <button
                            className="edit-attendees-button"
                            onClick={() => handleEditAttendees(row)}
                        >
                            +
                        </button>
                    </div>
                );
            },
            sortable: true
        },
        {
            name: 'Meeting Agenda',
            selector: row => row.meeting_type,
            cell: (row) => (
                <div>
                    {row.meeting_type.map((type, index) => (
                        <Link
                            key={index}
                            className="meeting-name-clickable"
                            onClick={() => handleLinkClick(row.meeting_type)}  
                            style={{ display: 'block', marginBottom: '5px' }} 
                        >
                            {type}
                        </Link>
                    ))}
                </div>
            ),
            sortable: true
        }
        
    ];
    const [selectedAttendees, setSelectedAttendees] = useState([]);
    const handleAddAttendee = () => {
        setIsAccordionOpen(!isAccordionOpen);
    };
 
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const today = new Date();
    const futureDate = new Date(today);
    futureDate.setDate(today.getDate() + 30);
    const navigate = useNavigate();
    const buttonClick = () => {
        navigate('/meeting/agenda');
    };

    const [members, setMembers] = useState([])
    const fetchmembers = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_all_team_membres}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                const sortedTasks = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setMembers(sortedTasks);

            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Fetch gratitude error', error);
        }
    };
    useEffect(() => {
        fetchmembers();
    }, []);

    const handleTeamMember = () => {

        navigate("/users/team")
    };
    const handleCloseAccordion = () => {
        setIsAccordionOpen(false);
    };


    const handleSaveChanges = async (meeting_id) => {
        const formDataToSend = new FormData();
        formDataToSend.append('meeting_notes', editedMeeting.meeting_notes);
        formDataToSend.append('id', meeting_id);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.update_all_meeting_main_sidebar}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success("Meeting Updated Successfully");
                handleCloseModalmetting(); 
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error during update:", error);
            toast.error("Failed to update meeting. Please try again.");
        }
    };

 
    const handleAttendeeChange = async (event, id) => {
        const isChecked = event.target.checked; 
        const formDataToSend = new FormData();
        formDataToSend.append('meeting_id', meetingId);
        formDataToSend.append('attendees[]', id);
        formDataToSend.append('count', isChecked ? 1 : 0); 
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.update_meeting_sidebar_attendes}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            // Handle response
            if (response.data.status === "success") {
                setModalOpen(false)
                toast.success("Meeting Updated Successfully");
                // Call additional method like fetchTasks if needed
                fetchTasks();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error during update:", error);
            toast.error("Failed to update meeting. Please try again.");
        }
    }

    const [previousCompletionStatus, setPreviousCompletionStatus] = useState(Array(selectedMeetingAgenda.length).fill(false));

    const [agendaCompletionStatus, setAgendaCompletionStatus] = useState(
        Array(selectedMeetingAgenda.length).fill(false) // Initialize all to false
    );

    const handleAgendaCheckboxChange = (event, index) => {
        const isChecked = event.target.checked;
        const updatedCompletionStatus = [...agendaCompletionStatus];
        updatedCompletionStatus[index] = isChecked; 
        setAgendaCompletionStatus(updatedCompletionStatus);
    };
    
    const handleNotesChange = (e, index) => {
        const updatedNotes = [...agendaNotes]; // Use array spread to keep the structure
        updatedNotes[index] = e.target.value; 
        setAgendaNotes(updatedNotes);
    };
    
    
    
    const handleSaveagenda = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
    
            selectedMeetingAgenda.forEach((agenda, index) => {
                formDataToSend.append(`meeting_type[${index}]`, agenda);
    
                formDataToSend.append(`is_complete[${index}]`, agendaCompletionStatus[index] ? 1 : 0);
    
                formDataToSend.append(`meeting_agenda_note[${index}]`, agendaNotes[index] || '');
            });
    
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.meeting_type_note}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );
    
            // Handle the response
            if (response.data.status === "success") {
                toast.success("Agenda items updated successfully.");
                setModalVisible(false); // Close the modal on success
                setPreviousCompletionStatus([...agendaCompletionStatus]); // Update previous completion statuses
            } else {
                toast.error("Failed to update agenda items.");
            }
        } catch (error) {
            console.error("Error during save:", error);
            toast.error("Failed to update agenda items. Please try again.");
        }
    };
    
    
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="align-items-center justify-content-between d-flex">
                                        <h5>Company Meetings</h5>
                                        <button onClick={buttonClick}>Add New Meeting Agenda</button>
                                    </div>
                                </div>
                            </div>
                            <form className="m-3">
                                <div className="row">
                                    <div className="form-group mt-2 mb-3 col-lg-4 col-md-6">
                                        <label><b>Select Meeting Type and Agenda</b></label>
                                        <select

                                            className="form-control"
                                            name="meeting_type"
                                            onChange={handleInputChange}
                                            value={`${formData.meeting_name} - ${formData.meeting_type}`}
                                        >
                                            <option value="">Select Meeting Type and Agenda</option>
                                            {annualOutcomes.map((type, index) => (
                                                <option key={index} value={`${type.meeting_name} - ${type.agenda}`}>
                                                    {type.meeting_name} - {type.agenda}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group mt-2 mb-3 col-lg-4 col-md-6">
                                        <label><b>Attendees</b></label>
                                        <div className="d-flex flex-column align-items-start">
                                            <button
                                                type="button"
                                                className="btn btn-primary attendees_btn"
                                                onClick={handleAddAttendee}
                                            >
                                                +
                                            </button>

                                            {isAccordionOpen && (
                                                <div className="accodian-overlay"> 
                                                    <div className="accordian-content"> 
                                                        <div className="d-flex justify-content-between">

                                                        </div>
                                                        <Accordion defaultActiveKey="0">

                                                            <Accordion.Item eventKey="0">
                                                                <Button variant="secondary" className="d-flex float-end attendees_btn accordian-btn" onClick={handleCloseAccordion}>x</Button>

                                                                <Accordion.Body>
                                                                    <div className="row">
                                                                        {members.map((member, index) => (
                                                                            <div key={index} className="form-check mt-2">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="checkbox_animated"
                                                                                    id={`member-${index}`}
                                                                                    onChange={() => {
                                                                                        const isChecked = selectedAttendees.includes(member);
                                                                                        if (!isChecked) {
                                                                                            setSelectedAttendees([...selectedAttendees, member]);
                                                                                        } else {
                                                                                            setSelectedAttendees(selectedAttendees.filter(m => m !== member));
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                <Image
                                                                                    className="agenda_image me-2"
                                                                                    src={member.profile}
                                                                                    alt={`${member.team_name} profile`}
                                                                                />
                                                                                <label className="form-check-label" htmlFor={`member-${index}`}>
                                                                                    {member.team_name}
                                                                                </label>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary mt-2 mx-2 attendees_btn"
                                                                        onClick={handleTeamMember}
                                                                    >
                                                                        +
                                                                    </button>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="mt-2 attendee-row">
                                                {selectedAttendees.length > 0 ? (
                                                    selectedAttendees.map((attendee, index) => (
                                                        <div key={index} className="attendee-image-container position-relative">
                                                            <Image
                                                                src={attendee.profile}
                                                                alt={`${attendee.team_name} profile`}
                                                                roundedCircle
                                                                className="attendee-image"
                                                            />
                                                            <Button
                                                                variant="danger"
                                                                className="delete-button"
                                                                onClick={() => handleRemoveAttendee(attendee)}
                                                            >
                                                                <FaTimes />
                                                            </Button>
                                                        </div>
                                                    ))
                                                ) : (
                                                    " "
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end align-content-center">
                                    <Button variant="success" onClick={handleSave} disabled={(formData.meeting_type === ""|| formData.meeting_name==="") || selectedAttendees.length === 0}>Start Meeting</Button>
                                </div>
                            </form>
                            <div className="card-body">
                                <div className="row">
                                    <div className='d-flex justify-content-between mb-3'>
                                        <Dropdown>
                                            <Dropdown.Toggle as="div" id="dropdown-basic">
                                                <Button
                                                    variant="secondary"
                                                    className="align-items-center main-menu">
                                                    Filter <FaSortDown className='mytask-toggle-icon mb-2' />
                                                </Button>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {Object.keys(tabHeadings).map(tab => (
                                                    <Dropdown.Item
                                                        key={tab}
                                                        className={activeTab === tab ? 'active' : ''}
                                                        onClick={() => handleTabChange(tab)}
                                                    >
                                                        {tabIcons[tab]} {tabHeadings[tab]}
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <div className="form-group text-end">
                                            <input className="form-control" type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchChange} />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <DataTable
                                        columns={columns}
                                        data={filteredEntries}
                                        pagination
                                        persistTableHead={true}
                                        className='keyrelationship-table'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={isModalOpen} onHide={handleCloseModalmetting}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Meeting Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {editedMeeting && (
                        <>
                            <div className="form-group">
                                <label>Meeting Name:</label>
                                <input
                                    type="text"
                                    value={editedMeeting.meeting_name}
                                    className="form-control"
                                    onChange={(e) => handleInputChangemetting(e, 'meeting_name')}
                                    disabled
                                />
                            </div>
                            <div className="form-group">
                                <label>Meeting Date:</label>
                                <input
                                    type="date"
                                    value={formatDate(editedMeeting.created_at)}
                                    className="form-control"
                                    onChange={(e) => handleInputChangemetting(e, 'created_at')}
                                    disabled
                                />

                            </div>
                            <div className="form-group">
                                <label>Meeting Agenda:</label>
                                <input
                                    type="text"
                                    value={editedMeeting.meeting_type}
                                    className="form-control"
                                    onChange={(e) => handleInputChangemetting(e, 'meeting_type')}
                                    disabled
                                />
                            </div>
                            <div className="form-group">
                                <label>Attendees:</label>
                                {members.map((member, index) => (
                            <div key={index} className="form-check mt-2">
                                <input
                                    type="checkbox"
                                    className="checkbox_animated"
                                    id={`member-${index}`}
                                    checked={currentAttendees.includes(member.id)}
                                    onChange={(e) => handleAttendeeChange(e, member.id)}
                                />
                                <Image
                                    className="agenda_image me-2"
                                    src={member.profile}
                                    alt={`${member.team_name} profile`}
                                />
                                <label className="form-check-label" htmlFor={`member-${index}`}>
                                    {member.team_name}
                                </label>
                            </div>
                        ))}
                            </div>


                            <div className="form-group">
                                <label>Notes</label>
                                <textarea
                                    row={4}
                                    name="notes"
                                    value={editedMeeting.meeting_notes}
                                    className="form-control"
                                    onChange={(e) => handleInputChangemetting(e, 'meeting_notes')}
                                />
                            </div>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModalmetting}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => handleSaveChanges(editedMeeting.id)} 
                    >
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            {modalopen && (
                <Modal show={modalopen} onHide={handleCloseModalmetting}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Attendees</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {members.map((member, index) => (
                            <div key={index} className="form-check mt-2">
                                <input
                                    type="checkbox"
                                    className="checkbox_animated"
                                    id={`member-${index}`}
                                    checked={currentAttendees.includes(member.id)}
                                    onChange={(e) => handleAttendeeChange(e, member.id)}
                                />
                                <Image
                                    className="agenda_image me-2"
                                    src={member.profile}
                                    alt={`${member.team_name} profile`}
                                />
                                <label className="form-check-label" htmlFor={`member-${index}`}>
                                    {member.team_name}
                                </label>
                            </div>
                        ))}
                        <a href="/users/team" className="mx-3">+ Add New </a>
                    </Modal.Body>
                </Modal>
            )}
{modalVisible && (
    <Modal show={modalVisible} onHide={() => setModalVisible(false)}>
        <Modal.Header closeButton>
            <Modal.Title>Meeting Agenda</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
    {selectedMeetingAgenda.map((agenda, index) => (
        <li key={index} style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <input 
                    type="checkbox" 
                    className="checkbox_animated"
                    style={{ margin: '10px' }} 
                    checked={agendaCompletionStatus[index] || false} // Fallback to false if undefined
                    onChange={(e) => handleAgendaCheckboxChange(e, index)} 
                />
                <span 
                    style={{ 
                        fontWeight: 'bold', 
                        marginRight: '10px', 
                        textDecoration: agendaCompletionStatus[index] ? 'line-through' : 'none'
                    }}
                >
                    {agenda} 
                </span>
            </div>
            <textarea
                className="mt-2"
                id={`agendaNotes${index}`} 
                value={agendaNotes[index] || ''} 
                onChange={(e) => handleNotesChange(e, index)} 
                rows="2"
                style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
                placeholder="Write your notes here..."
            />
        </li>
    ))}
</ul>



        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => setModalVisible(false)}>
                Close
            </Button>
            <Button variant="primary" onClick={handleSaveagenda}>
                Save
            </Button>
        </Modal.Footer>
    </Modal>
)}

        </>
    );
};
export default Meetings;
