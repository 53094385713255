import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import API from '../../../utils';
import axios from 'axios';
import { toast } from 'react-toastify';
import debounce from 'lodash/debounce';

const KeyResponsibilitiesTable = () => {
    const [formData, setFormData] = useState({
        key_responsibilities: '',
        rating: '',
        comments: '',
    });
    const uid = localStorage.getItem("user_id");
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        fetchTasks();
    }, []);

    const fetchTasks = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_job_scorecard_responsibilities}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                const sortedTasks = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setTableData(sortedTasks);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error during task fetching:', error);
            toast.error("An error occurred while fetching tasks.");
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('key_responsibilities', formData.key_responsibilities);
        formDataToSend.append('rating', formData.rating);
        formDataToSend.append('comments', formData.comments);

        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_job_scorecard_responsibilities}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchTasks();
                setFormData({
                    key_responsibilities: '',
                    rating: '',
                    comments: '',
                });
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error during task submission:", error);
            toast.error("An error occurred during submission.");
        }
    };

    const [updatedTask, setUpdatedTask] = useState(null);

    const debouncedUpdateTask = useCallback(
        debounce(async (task) => {
            const formDataToSend = new FormData();
            formDataToSend.append('id', task.id);
            formDataToSend.append('field', task.field);
            formDataToSend.append('text', task.value);

            try {
                const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.update_job_scorecard_responsibilities}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );

                if (response.data.status === "success") {
                    fetchTasks();
                    toast.success("Talent Assessment Updating Successfully");
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                toast.error("An error occurred while updating data.");
            }
        }, 2000),
        []
    );

    const handleEditInputChange = (e, index, field, id) => {
        const { value } = e.target;
        const updatedOutcomes = [...tableData];
        updatedOutcomes[index][field] = value;


        setUpdatedTask({ field, value, id });
    };

    useEffect(() => {
        if (updatedTask) {
            debouncedUpdateTask(updatedTask);
        }
    }, [updatedTask, debouncedUpdateTask]);

    const columns = [
        {
            name: 'Key Responsibilities',
            selector: row => row.key_responsibilities,
            cell: (row, index) => (
                <div className="repoting-table">
                    <input
                        type="text"
                        value={row.key_responsibilities}
                        name="key_responsibilities"
                        className="form-control"
                        onChange={(e) => handleEditInputChange(e, index, "key_responsibilities", row.id)}
                    />
                </div>
            ),
            sortable: true,
        },
        {
            name: 'rating',
            selector: row => row.rating,
            cell: (row, index) => (
                <div className="repoting-table">
                    <input
                        type="text"
                        value={row.rating}
                        name="rating"
                        className="form-control"
                        onChange={(e) => handleEditInputChange(e, index, "rating", row.id)}
                    />
                </div>
            ),
            sortable: true,
        },
        {
            name: 'comments',
            selector: row => row.comments,
            cell: (row, index) => (
                <div className="repoting-table">
                    <input
                        type="text"
                        value={row.comments}
                        name="comments"
                        className="form-control"
                        onChange={(e) => handleEditInputChange(e, index, "comments", row.id)}
                    />
                </div>
            ),
            sortable: true,
        },
    ];

    const isSaveButtonEnabled = () => formData.key_responsibilities.trim() !== '';

    return (
        <div className="card">
            <div className="card-body chart-block">
                <div className="card-body">
                    <form className="theme-form">
                        <div className="row">
                            <div className="mb-3 col-lg-4 col-md-6">
                                <label className="col-form-label pt-0"><b>Key Responsibilities</b></label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Key Responsibilities"
                                    name="key_responsibilities"
                                    value={formData.key_responsibilities}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="mb-3 col-lg-4 col-md-6">
                                <label className="col-form-label pt-0"><b>rating</b></label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="rating"
                                    name="rating"
                                    value={formData.rating}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="mb-3 col-lg-4 col-md-6">
                                <label className="col-form-label pt-0"><b>comments</b></label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="comments"
                                    name="comments"
                                    value={formData.comments}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-end align-content-center mb-2">
                            <Button variant="success" disabled={!isSaveButtonEnabled()}
                                onClick={handleSubmit}>
                                Save
                            </Button>
                        </div>
                    </form>
                </div>

                <DataTable
                    columns={columns}
                    data={tableData}
                    pagination
                    persistTableHead={true}
                    className="rolmodel-table"
                />
            </div>
        </div>

    );
};

export default KeyResponsibilitiesTable;