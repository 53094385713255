import React, { useCallback, useEffect, useState } from "react";
import { Button } from 'react-bootstrap';
import API from '../../../utils';
import axios from "axios";
import { toast } from "react-toastify";
import debounce from 'lodash/debounce';

const GrowthDataTable = () => {
    const uid = localStorage.getItem("user_id");
    const [formData, setFormData] = useState({
        desired_results: '',
        kpi: '',
        sg: '',
        green: '',
        yellow: '',
        red: '',
    });

    const [tableData, setTableData] = useState([]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('desired_results', formData.desired_results);
        formDataToSend.append('kpi', formData.kpi);
        formDataToSend.append('sg', formData.sg);
        formDataToSend.append('green', formData.green);
        formDataToSend.append('yellow', formData.yellow);
        formDataToSend.append('red', formData.red);
        try {
          const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_job_scorecard}`,
            formDataToSend,
            {
              headers: {
                Authorization: `${API.AUTHORIZATION_TOKEN}`,
              },
            }
          );
    
          if (response.data.status === "success") {
            toast.success(response.data.message);
            // toast.success("Talent Assessment Added Successfully");
            fetchTasks();
            setFormData({
                desired_results: '',
                kpi: '',
                sg: '',
                green: '',
                yellow: '',
                red: '',
            });
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
          console.error("Error during task submission:", error);
          toast.error("An error occurred during submission.");
        }
      };


    useEffect(() => {
        fetchTasks();
    }, []);

    const fetchTasks = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_job_scorecard}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                const sortedTasks = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setTableData(sortedTasks);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error during task fetching:', error);
            toast.error("An error occurred while fetching tasks.");
        }
    };

    const [updatedTask, setUpdatedTask] = useState(null);

    const debouncedUpdateTask = useCallback(
      debounce(async (task) => {
        const formDataToSend = new FormData();
        formDataToSend.append('id', task.id);
        formDataToSend.append('field', task.field);
        formDataToSend.append('text', task.value);
  
        try {
          const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.update_job_scorecard}`,
            formDataToSend,
            {
              headers: {
                Authorization: `${API.AUTHORIZATION_TOKEN}`,
              },
            }
          );
  
          if (response.data.status === "success") {
            fetchTasks();
            toast.success("Talent Assessment Updating Successfully");
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
          toast.error("An error occurred while updating data.");
        }
      }, 2000),
      []
    );
  
    const handleEditInputChange = (e, index, field, id) => {
      const { value } = e.target;
      const updatedOutcomes = [...tableData];
      updatedOutcomes[index][field] = value;
  
  
      setUpdatedTask({ field, value, id });
    };
  
    useEffect(() => {
      if (updatedTask) {
        debouncedUpdateTask(updatedTask);
      }
    }, [updatedTask, debouncedUpdateTask]);
  
  



    const isSaveButtonEnabled = () => formData.desired_results.trim() !== '';

    return (
        <div className="card">
            <div className="card-body chart-block">

                <div class="alert alert-success" role="alert">
                    <h4 class="alert-heading m-0 jobscorecaed">Note: Rate on a scale of 1-10 or green, yellow, red</h4>
                </div>

                <form className="theme-form">
                    <div className="row">
                        <div className="mb-3 col-lg-4 col-md-6">
                            <label className="col-form-label pt-0"><b>Desired Results</b></label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Desired Results"
                                name="desired_results"
                                value={formData.desired_results}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="mb-3 col-lg-4 col-md-6">
                            <label className="col-form-label pt-0"><b>kpi</b></label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="kpi"
                                name="kpi"
                                value={formData.kpi}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="mb-3 col-lg-4 col-md-6">
                            <label className="col-form-label pt-0"><b>sg (A+)</b></label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Core Value Score"
                                name="sg"
                                value={formData.sg}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="mb-3 col-lg-4 col-md-6">
                            <label className="col-form-label pt-0"><b>Green</b></label>
                            <input
                                className="form-control"
                                type="number"
                                placeholder="green"
                                name="green"
                                value={formData.green}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="mb-3 col-lg-4 col-md-6">
                            <label className="col-form-label pt-0"><b>Yellow</b></label>
                            <input
                                className="form-control"
                                type="number"
                                placeholder="yellow"
                                name="yellow"
                                value={formData.yellow}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="mb-3 col-lg-4 col-md-6">
                            <label className="col-form-label pt-0"><b>Red</b></label>
                            <input
                                className="form-control"
                                type="number"
                                placeholder="red"
                                name="red"
                                value={formData.red}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-end align-content-center mb-2">
                        <Button variant="success" disabled={!isSaveButtonEnabled()} onClick={handleSubmit}>
                            Save
                        </Button>
                    </div>
                </form>
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th className='p-2 text-center' >Desired Results</th>
                                <th className='p-2 text-center'>Kpi</th>
                                <th className='p-2 text-center' style={{ backgroundColor: 'rgb(44 171 142)' }}>Sg (A+)</th>
                                <th className='p-2 text-center' style={{ backgroundColor: 'rgb(142 198 180)' }}>Green</th>
                                <th className='p-2 text-center' style={{ backgroundColor: 'rgb(239 203 71)' }}>Yellow</th>
                                <th className='p-2 text-center' style={{ backgroundColor: 'rgb(163 33 70)' }}>Red</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((row, index) => (
                                <tr key={row.id}>
                                    <td className="p-1">
                                        <div className="repoting-table">
                                            <input
                                                type="text"
                                                value={row.desired_results}
                                                className="form-control"
                                                onChange={(e) => handleEditInputChange(e, index, "desired_results", row.id)}
                                            />
                                        </div>
                                    </td>
                                    <td className="p-1">
                                        <div className="repoting-table">
                                            <input
                                                type="text"
                                                value={row.kpi}
                                                className="form-control"
                                                onChange={(e) => handleEditInputChange(e, index, "kpi", row.id)}
                                            />
                                        </div>
                                    </td>
                                    <td className="p-1">
                                        <div className="repoting-table">
                                            <input
                                                type="text"
                                                value={row.sg}
                                                className="form-control"
                                                onChange={(e) => handleEditInputChange(e, index, "sg", row.id)}
                                            />
                                        </div>
                                    </td>
                                    <td className="p-1">
                                        <div className="repoting-table">
                                            <input
                                                type="text"
                                                value={row.green}
                                                className="form-control"
                                                onChange={(e) => handleEditInputChange(e, index, "green", row.id)}
                                            />
                                        </div>
                                    </td>
                                    <td className="p-1">
                                        <div className="repoting-table">
                                            <input
                                                type="text"
                                                value={row.yellow}
                                                className="form-control"
                                                onChange={(e) => handleEditInputChange(e, index, "yellow", row.id)}
                                            />
                                        </div>
                                    </td>
                                    <td className="p-1">
                                        <div className="repoting-table">
                                            <input
                                                type="text"
                                                value={row.red}
                                                className="form-control"
                                                onChange={(e) => handleEditInputChange(e, index, "red", row.id)}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default GrowthDataTable;
