import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const EditGoalModal = ({ show, onHide, formData, validationErrors, handleInputChange, handleEditItem }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit 1 year Goals</Modal.Title>
      </Modal.Header>
      <Modal.Body>
            <div className="form-group mb-3">
              <label><b>Select Goal Type</b></label>
              <select className={`form-control ${validationErrors.type ? 'is-invalid' : ''}`} onChange={handleInputChange} name="type" value={formData.type}>
                <option value="">Select Goal Type</option>
                <option value="This Quarter">90 Days Sprint</option>
                <option value="1 Year">1 Year Goal</option>
                <option value="10-25 Years">10-25 Year Aspiration</option>
              </select>
              {validationErrors.type && <p className="text-danger">{validationErrors.type}</p>}
            </div>
            <div className="form-group mb-3">
              <label><b>Name Of Goal</b></label>
              <input type='text' className={`form-control ${validationErrors.name ? 'is-invalid' : ''}`} name="name" value={formData.name} onChange={handleInputChange} placeholder='Name'></input>
              {validationErrors.name && <p className="text-danger">{validationErrors.name}</p>}
            </div>
            <div className=" mb-3 form-group">
              <label><b>Name Of Owner</b></label>
              <input type='text' name="owner" className={`form-control ${validationErrors.owner ? 'is-invalid' : ''}`} value={formData.owner} onChange={handleInputChange} placeholder='Owner'></input>
              {validationErrors.owner && <p className="text-danger">{validationErrors.owner}</p>}
            </div>
            <div className="form-group mb-3">
              <label><b>Status</b></label>
              <select className={`form-control ${validationErrors.status ? 'is-invalid' : ''}`} onChange={handleInputChange} name="status" value={formData.status}>
                <option value="">Select Status</option>
                <option value="New">New</option>
                <option value="Pending">Pending</option>
                <option value="Complete">Complete</option>
              </select>
              {validationErrors.status && <p className="text-danger">{validationErrors.status}</p>}
            </div>
            <div className="mb-3 form-group">
              <label><b>Contact Date</b></label>
              <input type='date' className={`form-control ${validationErrors.Contactdate ? 'is-invalid' : ''}`} name="Contactdate" value={formData.Contactdate} onChange={handleInputChange} placeholder='Contactdate'></input>
              {validationErrors.Contactdate && <p className="text-danger">{validationErrors.Contactdate}</p>}
            </div>
          </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button variant="success" onClick={handleEditItem}>Save Changes</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditGoalModal;
