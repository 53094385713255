import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { FaPlus, FaUnlockAlt } from "react-icons/fa";
import { Modal, ModalHeader, ModalBody, Button, FormGroup, Label, Input, ModalFooter } from "reactstrap"; // Assuming you're using Bootstrap for modals
import company from "../../../assets/images/user/company.png";
import { ProgressBar } from "react-bootstrap";
import MyNinty from "../ourterminologycategories/myNinty";
import Scorecard from "../ourterminologycategories/scorecard";
import Rocks from "../ourterminologycategories/rocks";
import Milestones from "../ourterminologycategories/milestones";
import Todo from "../ourterminologycategories/todos";
import Issue from "../ourterminologycategories/issues";
import Meetings from "../ourterminologycategories/meetings";
import Headlines from "../ourterminologycategories/headlines";
import Cascading from "../ourterminologycategories/cascading";
import Vtom from "../ourterminologycategories/vtom";
import AccountChart from "../ourterminologycategories/accountchart";
import OneOnOne from "../ourterminologycategories/oneOnOne";
import Process from "../ourterminologycategories/process";
import Directory from "../ourterminologycategories/directory";
import EosToolBox from "../ourterminologycategories/eosToolBox";
import Measurables from "../ourterminologycategories/measurables";

const Step1 = () => (
    <>
        <MyNinty/>


       <Scorecard/>
       <Measurables/>
<Rocks/>

      <Milestones/>
    </>
);

const Step2 = () => (
    <>
     
<Todo/>
       <Issue/>
     <Meetings/>
       <Headlines/>

    <Cascading/>
    </>
);

const Step3 = () => (
    <>
      <Vtom/>

      <AccountChart/>

     <OneOnOne/>
<Process/>

    <Directory/>

  <EosToolBox/>

    </>
);

const Oureminology = () => {
    const [step, setStep] = useState(1);
    const handleNext = () => {
            setStep(step + 1);
    };

    const handlePrevious = () => {
        setStep(step - 1);
    };


    const progress = (step / 3) * 100;
    const progressValue = 24;



    return (
        <>
            <form >
                <div className="card">
                    <div className="card-body">
                        <ProgressBar now={progress} label={`Step ${step} of 3`} className=" h-25" />
                    </div>
                </div>
                {step === 1 && <Step1  />}
                {step === 2 && <Step2  />}
                {step === 3 && <Step3 />}

                <div className="card-footer text-end">
                    {step > 1 && <button type="button" className="btn btn-secondary mx-1" onClick={handlePrevious}>Previous</button>}
                    {step < 3 && <button type="button" className="btn btn-primary mx-1" onClick={handleNext}>Next</button>}
                </div>
            </form>
        </>
    );
};

export default Oureminology;