import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap"; // Import Modal from react-bootstrap
import DataTable from 'react-data-table-component';
import axios from "axios";
import { toast } from "react-toastify";
import API from "../../../utils";
import { FaEye } from "react-icons/fa";

const UserDatail = () => {
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedUser, setSelectedUser] = useState(null); // State to hold selected user for the modal
    const [showModal, setShowModal] = useState(false); // Modal visibility state

    const user_id = localStorage.getItem('user_id');

    // Filter the entries based on search query
    const filteredEntries = users.filter(entry =>
        Object.values(entry).some(value =>
            value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
    );

    // Fetch user data from the API
    const fetchData = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', user_id);

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.all_users}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                // Sort the data based on created_at field
                const sortedUsers = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setUsers(sortedUsers);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("An error occurred while fetching data.");
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Define the columns for the DataTable
    const columns = [
        {
            name: 'User Name',
            selector: row => row.user_name,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Subscription Status',
            selector: row => (
                <span
                    style={{
                        color: row.subscription_status === "1" ? "green" : "red",
                        fontWeight: 'bold'
                    }}
                >
                    {row.subscription_status === "1" ? "Active" : "Inactive"}
                </span>
            ),
            sortable: true,
        },
        {
            name: 'Subscription Date',
            selector: row => new Date(row.subscription_date).toLocaleDateString(),
            sortable: true,
        },
        {
            name: 'Created At',
            selector: row => new Date(row.created_at).toLocaleDateString(),
            sortable: true,
        },
        {
            name: 'Actions',
            cell: (row) => (
                <Button
                    variant="primary"
                    className="btn-secondary btn-pill main-edit-view-buttons m-1 btn btn-primary"
                    onClick={() => handleViewUser(row)}
                >
                    <FaEye />
                </Button>
            ),
        }
    ];

    // Handle view button click and open modal
    const handleViewUser = (user) => {
        setSelectedUser(user); // Set the selected user
        setShowModal(true); // Show the modal
    };

    // Close modal function
    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedUser(null); // Clear the selected user
    };
    const thStyle = {
        border: "1px solid gray",
        padding: "8px",
        textAlign: "left",
        background: "black",
        color: "white"
    };
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center justify-content-between d-flex">
                                <h5>All Users</h5>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-9"></div>
                                <div className="col-md-3">
                                    <div className="form-group text-end mb-3">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Search..."
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <DataTable
                                    columns={columns}
                                    data={filteredEntries}
                                    persistTableHead={true}
                                    pagination
                                    className="altimate-table"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal to display user details */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>User Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedUser ? (
                        <table className="table">
                            <thead></thead>
                            <tbody>
                                <tr>
                                    <th style={thStyle} colSpan={2}>User Details</th>
                                </tr>
                                <tr>
                                    <td>User Name:</td>
                                    <td>{selectedUser.user_name}</td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>{selectedUser.email}</td>
                                </tr>
                                <tr>
                                    <td>Subscription Status:</td>
                                    <td> {selectedUser.subscription_status === "1" ? "Active" : "Inactive"}</td>
                                </tr>
                                <tr>
                                    <td>Subscription Date:</td>
                                    <td>{new Date(selectedUser.subscription_date).toLocaleDateString()} </td>
                                </tr>
                                <tr>
                                    <td>Created At:</td>
                                    <td>{new Date(selectedUser.created_at).toLocaleString()} </td>
                                </tr>
                            </tbody>
                        </table>

                    ) : (
                        <p>No user selected.</p>
                    )}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default UserDatail;
