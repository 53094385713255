import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarController,
    BarElement,
    ArcElement,
    RadialLinearScale
} from 'chart.js';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarController,
    BarElement,
    ArcElement,
    RadialLinearScale
);
export var barChartData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July','August','September','October','November','December'],
    datasets: [
        {
            label: 'y',
            lagend: 'y',
            data: [28, 90, 40, 19, 86, 127, 90,20,70,10,200,100],
            borderColor: "#4466f2",
            backgroundColor: "rgba(68, 102, 242, 0.4)",
            highlightFill: "rgba(68, 102, 242, 0.95)",
            highlightStroke: "#4466f2",
            borderWidth: 2
        },
        {
            label: 'z',
            lagend: 'z',
            data: [35, 59, 80, 81, 56, 55, 100,44,88,90,12,65],
            borderColor: "#2ca6ec",
            backgroundColor: "rgba(30, 166, 236, 0.4)",
            highlightFill: "rgba(30, 166, 236, 0.95)",
            highlightStroke: "#2ca6ec",
            borderWidth: 2
        }
    ],
    plugins: {
        datalabels: {
            display: false,
            color: 'white'
        }
    }
}
export var barChartOptions = {
    maintainAspectRatio: true,
    plugins: {
        legend: {
            display: true,
        },
        tooltip: {
            enabled: true,
        },
        datalabels: {
            display: false,
        },
    },
};
